import React from 'react';
import {Flex} from '@chakra-ui/react';

export const AuthCard = ({children}: {children: React.ReactChild[]}) => {
    return (
        <Flex
            flexDir={'column'}
            w={'full'}
            maxW={'470px'}
            borderRadius={'4px'}
            bgColor={'#ffffff'}
            px={'2.188rem'}
            py={'1.813rem'}
            margin={'80px auto 4rem'}
            boxShadow={'0 28px 51px 0 rgb(5 5 5 / 8%)'}
            position={'relative'}
        >
            {children}
        </Flex>
    );
};
