/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Heading, Stack, Switch, Text, Select } from '@chakra-ui/react';
import RHFEditor from '@/components/RHFEditor/RHFEditor';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import DatePicker from 'react-datepicker';
import { isAfter } from 'date-fns';

import { createBanner } from '../api/createBanner';
import { BANNER_TYPES } from '../utils';
import { BannerFormProps } from '../types';
import { updateBanner } from '../api/updateBanner';
import { useNotificationStore } from '@/stores/notifications';

type Mode = 'create' | 'edit';

export const BannerForm = ({ banner }: BannerFormProps) => {
    const { addNotification } = useNotificationStore();
    const [mode, setMode] = React.useState<Mode>('create');

    const schema = yup.object({
        id: yup.number(),
        createdAt: yup.string(),
        isActive: yup.boolean(),
        isPublished: yup.boolean(),
        message: yup.string().required('Message is required'),
        type: yup.number().required('Type is required'),
        startDate: yup.date(),
        endDate: yup.date(),
    });

    const defaultValues = {
        id: banner?.id || 0,
        isActive: banner?.isActive || false,
        isPublished: banner?.isPublished || false,
        message: banner?.message || '',
        type: banner?.type || 1,
        startDate: banner?.startDate || new Date(),
        endDate: banner?.endDate || new Date(),
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues,
    });

    const {
        register,
        handleSubmit,
        clearErrors,
        watch,
        setValue,
        setError,
        getValues,
        formState,
        formState: { errors, isValid, isDirty },
    } = methods;

    const message = useWatch({ name: 'message', control: methods.control });

    // TODO: Date handling will be added in the future (JVA-9584)
    const datePickerConfig = {
        placeholderText: 'mm.dd.yyyy',
        dateFormat: 'MM.dd.yyyy hh:mm a',
        showTimeSelect: true,
    };

    const filterPassedTime = (time: Date) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const filterPassedTimeByStartDate = (time: Date) => {
        const currentDate = new Date(time);
        const selectedDate = new Date(getValues('startDate'));

        return currentDate.getTime() > selectedDate.getTime();
    };

    const onSubmit = handleSubmit(async (data) => {
        try {
            const payload = {
                type: data.type,
                message: data.message,
                published: Number(data.isPublished),
                id: data.id,
            };

            if (mode === 'create') {
                await createBanner({ data: payload });
            } else {
                await updateBanner({ data: payload });
            }

            addNotification({
                type: 'success',
                title: 'Banner saved successfully',
            });
        } catch (error) {
            addNotification({
                type: 'error',
                title: 'There was an error saving the banner. Please try again.',
            });
            console.error(error);
        }
    });

    useEffect(() => {
        const messageText = new DOMParser().parseFromString(message, 'text/html').querySelector('p')?.innerText;

        if (messageText && messageText.length > 40) {
            setError('message', {
                type: 'manual',
                message: 'Message cannot exceed 40 characters.',
            });
        } else {
            clearErrors('message');
        }
    }, [message]);

    useEffect(() => {
        if (banner && banner.id) {
            setMode('edit');
        }
    }, [banner]);

    return (
        <form onSubmit={onSubmit}>
            <FormProvider {...methods}>
                <Box display="flex" mb={3} alignItems="center" justifyContent="space-between">
                    <Heading size="lg">Show Banner</Heading>

                    <Stack direction="row" spacing={4}>
                        <FormControl display="flex" alignItems="center" isRequired>
                            <FormLabel htmlFor="isPublished" mb="0" justifyContent="flex-start">
                                Publish Banner
                            </FormLabel>
                            <Switch {...register('isPublished', { required: true })} id="isPublished" />
                        </FormControl>
                    </Stack>
                </Box>
                <Box pt={6}>
                    <FormControl isInvalid={errors.hasOwnProperty('messahe')} isRequired>
                        <FormLabel htmlFor="bannerMessage" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                            Banner Message
                        </FormLabel>
                        <RHFEditor placeholder="Please write something" name="message" />
                        <Text fontSize={12} color="red">
                            {errors.message?.message}
                        </Text>
                    </FormControl>
                    {/* <Box display="flex" flexDirection="row" pt={6}>
                        <FormControl isInvalid={errors.hasOwnProperty('startDate')}>
                            <FormLabel htmlFor="StartDate" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                                Start Date
                            </FormLabel>
                            <DatePicker
                                {...datePickerConfig}
                                {...register('startDate')}
                                autoComplete="off"
                                id="StartDate"
                                className="datePicker"
                                selected={watch('startDate')}
                                onChange={(date: Date) => setValue('startDate', date)}
                                minDate={new Date()}
                                filterTime={filterPassedTime}
                            />
                            <Text>{errors.startDate?.message}</Text>
                            <Text fontSize="12px" lineHeight="18px">
                                The date and time selected reflect your local time zone.
                            </Text>
                        </FormControl>

                        <FormControl isInvalid={errors.hasOwnProperty('endDate')}>
                            <FormLabel htmlFor="EndDate" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                                End Date
                            </FormLabel>
                            <DatePicker
                                {...datePickerConfig}
                                {...register('endDate', { required: true })}
                                autoComplete="off"
                                id="EndDate"
                                className="datePicker"
                                selected={watch('endDate')}
                                onChange={(date: Date) => setValue('endDate', date)}
                                minDate={isAfter(getValues('startDate'), new Date()) ? getValues('startDate') : new Date()}
                                filterTime={filterPassedTimeByStartDate}
                            />
                            <Text>{errors.endDate?.message}</Text>
                            <Text fontSize="12px" lineHeight="18px">
                                The date and time selected reflect your local time zone.
                            </Text>
                        </FormControl>
                    </Box> */}

                    <Box pt={5}>
                        <FormControl w={200} isInvalid={errors.hasOwnProperty('type')} isRequired>
                            <FormLabel htmlFor="type" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                                Type
                            </FormLabel>
                            <Select {...register('type', { required: true })}>
                                <option value="" disabled>
                                    Select a type
                                </option>
                                {BANNER_TYPES.map(({ key, label }) => (
                                    <option key={key} value={key}>
                                        {label}
                                    </option>
                                ))}
                            </Select>
                            <Text>{errors.type?.message}</Text>
                        </FormControl>
                    </Box>

                    <Box pt={10} display="flex" justifyContent="flex-end">
                        <Button type="submit" colorScheme="blue" isLoading={formState.isSubmitting} disabled={!isValid || !isDirty}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </FormProvider>
        </form>
    );
};
