import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Spinner,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    Box,
    Td,
} from '@chakra-ui/react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from 'react-query';
import * as yup from "yup";
import { HiX } from "react-icons/hi";

import { debounce } from "@/utils/helpers";
import { Pagination } from '@/components/Pagination';
import Icon from "@/components/Icon";
import { ArticlesListItem } from "@/features/Quiz/components/ArticlesListItem";
import { buildQuizzesArticleListParams, postQuizzesArticles } from '@/features/Quiz/api/getArticles';

type Props = {
    onSelectArticle: (id: number) => void;
};

let searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

export type SearchFormData = {
    keyword: string;
};

export const ArticlesList = ({onSelectArticle}: Props) => {
    const [activePage, setActivePage] = useState(1);
    const [total, setTotal] = useState(0);
    const [activeSearchKeyword, setActiveSearchKeyword] = useState<string | number>('');

    const { isFetching, data: articlesData, isLoading } = useQuery(['search', activeSearchKeyword, activePage], () =>
        postQuizzesArticles(
            buildQuizzesArticleListParams(activePage, activeSearchKeyword, 20, false, null, 0)
        )
    );

    const { register, reset, setValue, formState: { isSubmitting } } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        }
    });

    const onSearchKeywordChange = debounce(useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            setActivePage(1);
            setActiveSearchKeyword(/^-?\d+$/.test(e.target.value ?? '') ? Number(e.target.value) : e.target.value ?? '');
            setValue('keyword', e.target.value);
        },
        []
    ), 500);

    const handleReset = useCallback(() => {
        reset();

        setActivePage(1);
        setActiveSearchKeyword('');
        setValue('keyword', '');
    }, []);

    useEffect(() => {
        if (!articlesData?.result) {
            return setTotal(0);
        }

        if (typeof articlesData?.navigation?.total_pages === 'number') {
            setTotal(articlesData?.navigation?.total_pages ?? 0);
        } else {
            articlesData?.navigation?.total_items
            && articlesData?.navigation?.items_on_page
            && setTotal((Math.ceil(articlesData.navigation.total_items / articlesData.navigation.items_on_page)))
        }
    }, [articlesData?.navigation?.total_items, articlesData?.navigation?.items_on_page]);

    return (
        <>
            {isLoading ?
                <Spinner/>
                :
                <Box>
                    <form>
                        <InputGroup minW={'400px'}>
                            <InputLeftElement
                                pointerEvents={isSubmitting ? 'none' : 'auto'}
                            >
                                {(isSubmitting || isLoading || isFetching) ? <Icon icon='loader'/> : <Icon icon='search'/>}
                            </InputLeftElement>
                            <Input
                                placeholder="Search by Title or Section ID"
                                bg={'#ffffff'}
                                id={'keyword'}
                                {...register('keyword')}
                                onChange={onSearchKeywordChange}
                            />
                            {activeSearchKeyword &&
                                <InputRightElement cursor="pointer" onClick={handleReset}>
                                    <HiX />
                                </InputRightElement>
                            }
                        </InputGroup>
                        <button type={'submit'}></button>
                    </form>
                    <Table bgColor={'#ffffff'}>
                        <Thead>
                            <Tr>
                                <Th>ID</Th>
                                <Th>Title</Th>
                                <Th>Type</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {articlesData?.result?.length ?
                                articlesData.result.map((article) =>
                                    <ArticlesListItem
                                        key={article.id}
                                        article={article}
                                        onSelectArticle={onSelectArticle}
                                    />
                                ) : (
                                    <Tr>
                                        <Td>No articles found</Td>
                                    </Tr>
                                )
                            }
                        </Tbody>
                    </Table>
                </Box>
            }
            {articlesData && total > 1 &&
                <Pagination
                    currentPage={activePage}
                    totalPages={total}
                    handlePagination={(page: number) => setActivePage(page)}
                />
            }
        </>
    );
};
