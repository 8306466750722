import Cookies from "js-cookie";

const storagePrefix = 'jove_admin';

const storage = {
    getToken: () => {
        let cookie = Cookies.get(`${storagePrefix}token`);
        if(!cookie) return null;
        return JSON.parse(cookie as string);
    },
    setToken: (token: string) => {
        Cookies.set(`${storagePrefix}token`, JSON.stringify(token), {secure: true})
    },
    clearToken: () => {
        window.localStorage.removeItem(`${storagePrefix}token`);
        Cookies.remove(`${storagePrefix}token`, {secure: true})

    },
};

export default storage;