import { createAsyncThunk, createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { getPlaylistSectionMenu } from '../../../../features/Playlists/api';
import { RootState } from '../../../store';
import { PlaylistSectionSubject } from '../../../../features/Playlists';

type SubjectTreeState = {
    playlistSectionMenu: PlaylistSectionSubject;
    isLoading: boolean;
    isError: boolean;
}

export const getSectionSubjectTree = createAsyncThunk(
    'playlist/articles/getSectionMenu',
    () => getPlaylistSectionMenu());

const sectionSubjectTree = createSlice({
    name: 'getSectionMenu',
    initialState: {
        playlistSectionMenu: {
            Education: [{title: '',
                sectionId: 1}],
            Research: [{title: '',
                sectionId: 1}]
        },
        isLoading: false,
        isError: false,
    },
    reducers:{},
    extraReducers: {
        [getSectionSubjectTree.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getSectionSubjectTree.fulfilled.type]: (state, action: PayloadAction<PlaylistSectionSubject>) => {
            state.playlistSectionMenu = action.payload;
            state.isLoading = false;
            state.isError = false;
        },
        [getSectionSubjectTree.rejected.type]: (state) => {
            state.isLoading = false;
            state.isError = true;
        },
    }
})

export const subjectTree = sectionSubjectTree.reducer;

const selectState = (state: RootState) => state.playlistReducer.subjectTree;

export const selectSectionSubjectsEducation= createSelector([selectState], el => el.playlistSectionMenu.Education);
export const selectSectionSubjectsResearch= createSelector([selectState], el => el.playlistSectionMenu.Research);


