import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';

import { GetListBodyStructure, GetListParams } from "@/types";
import { SponsorArticlesResponse } from "@/features/Sponsors/types";

const buildArticlesParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    activeSortDirection: string,
    activeSortProperty: string,
    isSponsored?: boolean,
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword ? [{
                    bool_operator: 'and',
                    condition: {
                        property: 'domain_id',
                        operator: 'equals',
                        value: activeSearchKeyword ?? 0
                    }
                }] : []),
            ]
        },
        sort: [
            [
                activeSortProperty,
                activeSortDirection
            ]
        ],
        withSponsor: isSponsored ? 1 : 2
    }
}

export const getSponsorArticles = (data?: GetListBodyStructure): Promise<SponsorArticlesResponse> => {
    return axios.post(`/api/admin/sponsor/article_list`, data);
};

export const useSponsorArticles = ({
                                       activePage,
                                       activeSearchKeyword,
                                       activeSortDirection,
                                       activeSortProperty,
                                       isSponsored,
                                   }: GetListParams) => {
    return useQuery<SponsorArticlesResponse, Error>({
        queryKey: [
            'sponsorArticles',
            activePage,
            activeSearchKeyword,
            activeSortDirection,
            activeSortProperty,
            isSponsored,
        ],
        queryFn: () => {
            let params = buildArticlesParams(activePage, activeSearchKeyword, activeSortDirection, activeSortProperty, isSponsored);
            return getSponsorArticles(params);
        },
        keepPreviousData: true
    });
};
