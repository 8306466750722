import { GetLinkItems } from '../types/getLinkItems';

export const getLinkItemsForSuperAdmin: GetLinkItems = (featureFlags: Record<string, boolean>) => [
    { name: 'Dashboard', icon: 'table-columns', url: '/dashboard' },
    { name: 'Methods Collections', icon: 'books', url: '/methods-collections' },
    { name: 'Playlists', icon: 'play-circle', url: '/playlist' },
    { name: 'LTI', icon: 'list-check', url: '/lti' },
    { name: 'Classes', icon: 'graduation-cap', url: '/classes' },
    { name: 'Users Management', icon: 'id-card', url: '/admins/list' },
    { name: 'Institutions', icon: 'building-columns', url: '/institutions/list' },
    { name: 'Quizzes', icon: 'arrow-up-to-line', url: '/quiz' },
    { name: 'Class Products', icon: 'school-lock', url: '/class-access' },
    { name: 'Admin Client Hub', icon: 'user-lock', url: '/client-hub-access' },
    { name: 'Client Hub Playlists', icon: 'list-music', url: '/client-hub-playlist' },
    { name: 'Identity Providers', icon: 'passport', url: '/identity-providers' },
    { name: 'Sponsors', icon: 'circle-dollar', url: '/sponsors' },
    { name: 'FRC', icon: 'play-pause', url: '/faculty-resources' },
    { name: 'Translations', icon: 'circle-dollar', url: '/translations', hide: !featureFlags.adminTranslations },
    { name: 'Banner', icon: 'comment', url: '/banner', hide: !featureFlags.adminBanner },
    { name: 'Publish', icon: 'newspaper', url: '/publish/auto-translation' },
    { name: 'MoEngage', icon: 'analytics', url: '/moengage' },
];
