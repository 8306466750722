import React from 'react';
import {Flex} from '@chakra-ui/react';

interface Props {
    isActive?: boolean,
    isChevron?: boolean,
    onPaginationClick: () => void;
    children: React.ReactChild
}

export const PaginationButton = ({children, isActive, isChevron, onPaginationClick}: Props) => {
    return (
        <Flex
            alignItems={'center'}
            justifyContent={'center'}
            cursor={'pointer'}
            color={isChevron ? '#4193F4' : isActive ? '#ffffff' : '#000000'}
            fontSize={'18px'}
            backgroundColor={isChevron ? 'rgba(65,147,244,0.08)' : isActive ? '#000000' : 'transparent'}
            _hover={{
                backgroundColor: '#000000',
                color: '#ffffff'
            }}
            borderRadius={'3px'}
            textAlign={'center'}
            fontWeight={'bold'}
            textDecoration={'none'}
            transition={'all .25s ease'}
            minWidth={'20px'}
            p={'2px'}
            onClick={onPaginationClick}
        >
            {children}
        </Flex>
    );
};
