import { LinkItemProps } from '../types/linkItemProps';

export const linkItemsForCSUser: Array<LinkItemProps> = [
    { name: 'Dashboard', icon: 'table-columns', url: '/dashboard' },
    { name: 'Playlists', icon: 'play-circle', url: '/playlist/' },
    { name: 'Classes', icon: 'graduation-cap', url: '/classes' },
    { name: 'Quizzes', icon: 'arrows-up-to-line', url: '/quiz' },
];

export const linkItemsForCSLTIUser: Array<LinkItemProps> = [
    { name: 'Dashboard', icon: 'table-columns', url: '/dashboard' },
    { name: 'Playlists', icon: 'play-circle', url: '/playlist/' },
    { name: 'Classes', icon: 'graduation-cap', url: '/classes' },
    { name: 'Quizzes', icon: 'arrows-up-to-line', url: '/quiz' },
    { name: 'LTI', icon: 'list-check', url: '/lti' },
];
