import { axios } from '@/lib/axios';
import { useMutation, useQueryClient } from 'react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Sponsor } from "@/features/Sponsors/types";
import { SponsorDetailFormData } from "@/features/Sponsors/Components/SponsorForm";

type UpdateSponsorData = {
    data: SponsorDetailFormData;
    sponsorId: number;
};

export const updateSponsor = ({ data, sponsorId }: UpdateSponsorData): Promise<Sponsor> => {
    return axios.patch(`/api/admin/sponsor/${sponsorId}`, data);
};

export const useUpdateSponsor = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation({
        onMutate: async (updatingCollection: any) => {
            await queryClient.cancelQueries(['sponsor', updatingCollection?.id]);
        },
        onError: () => {
            addNotification({
                type: 'error',
                title: 'Unable to update Sponsor',
            });
        },
        onSuccess: (res) => {
            queryClient.refetchQueries(['sponsor', String(res.id)]);
            addNotification({
                type: 'success',
                title: 'Sponsor successfully updated',
            });
        },
        mutationFn: updateSponsor,
    });
};
