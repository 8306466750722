import { getLinkItemsForCorporateAdmin } from '@/components/Layout/utils/getLinkItemsForCorporateAdmin';
import React, { ReactNode, useCallback, useState } from 'react';
import { Box, CloseButton, Flex, useColorModeValue, Drawer, DrawerContent, useDisclosure, BoxProps, Image } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { MobileNav } from '@/components/Layout/MobileNav';
import { NavItem } from '@/components/Layout/NavItem';
import Logo from '@/images/jovelogo.svg';
import { useAuth } from '@/context/AuthContext';
import Icon from '../Icon';
import { getLinkItems } from './utils/getLinkItems';
import { getLinkItemsForSuperAdmin } from './utils/getLinkItemsForSuperAdmin';
import { linkItemsForCSUser, linkItemsForCSLTIUser } from './utils/linkItemsForCSUser';
import { LinkItemsForPublishUser } from './utils/linkItemsForPublishUser';

export const Layout = ({ children }: { children: ReactNode }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isNavPanelSmall, setIsNavPanelSmall] = useState(false);

    const handleToggleNavSize = useCallback(() => {
        setIsNavPanelSmall(!isNavPanelSmall);
    }, [isNavPanelSmall]);

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                isNavPanelSmall={isNavPanelSmall}
                handleToggleNavSize={handleToggleNavSize}
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            <MobileNav onOpen={onOpen} />
            <Box transition={'margin .3s ease-in-out'} ml={isNavPanelSmall ? { base: 0, md: '70px' } : { base: 0, md: 60 }} overflowX={'auto'}>
                <Box p={4} pt={0} mt={'1rem'}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

interface SidebarProps extends BoxProps {
    onClose: () => void;
    handleToggleNavSize?: () => void;
    isNavPanelSmall?: boolean;
}

const SidebarContent = ({ onClose, handleToggleNavSize, isNavPanelSmall, ...rest }: SidebarProps) => {
    const { user } = useAuth();
    const { adminTranslations, adminBanner } = useFlags();

    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');
    const isCorporateAdmin = user?.roles.includes('ROLE_CORPORATE_ADMIN');
    const isAdmin = user?.roles.includes('ROLE_ADMIN');
    const isCSUser = user?.roles.includes('ROLE_CS_USER');
    const isPublishUser = user?.roles.includes('ROLE_PUBLISH');
    const isCSLTIUser = user?.roles.includes('ROLE_CS_LTI_USER');

    const getAdminsLinkItems = () => {
        if (isSuperAdmin) return getLinkItemsForSuperAdmin({ adminTranslations, adminBanner });
        if (isCorporateAdmin) return getLinkItemsForCorporateAdmin({ adminTranslations });
        if (isAdmin) return getLinkItems({ adminTranslations });
        if (isCSUser) return linkItemsForCSUser;
        if (isPublishUser) return LinkItemsForPublishUser;
        if (isCSLTIUser) return linkItemsForCSLTIUser;
    };

    const adminsLinkItems = getAdminsLinkItems();

    return (
        <Box
            borderRight="1px"
            bg={'gray.900'}
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={isNavPanelSmall ? '71px' : { base: 'full', md: 60 }}
            transition={'width .3s ease-in-out'}
            pos="fixed"
            h="full"
            {...rest}>
            <Box
                h="100vh"
                overflowY="auto"
                maxW="250px"
                minW="60px"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '7px',
                    },
                    '&::-webkit-scrollbar-track': {
                        width: '7px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#fff',
                        borderRadius: '24px',
                    },
                }}>
                <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                    {!isNavPanelSmall && <Image src={Logo} alt="jove logo" height={'44px'} />}
                    <CloseButton color={'#ffffff'} display={{ base: 'flex', md: 'none' }} onClick={onClose} />
                </Flex>
                <Flex flexDirection="column" mb="60px">
                    {adminsLinkItems?.length
                        ? adminsLinkItems.map((link) => {
                              if (link.hide) return null;

                              return (
                                  <NavItem key={link.name} icon={link.icon} url={link.url}>
                                      {isNavPanelSmall ? '' : link.name}
                                  </NavItem>
                              );
                          })
                        : null}
                    <Flex position="absolute" bottom="2" right="5" padding="5px 10px" _hover={{ cursor: 'pointer' }} onClick={handleToggleNavSize}>
                        <Box transform={isNavPanelSmall ? 'scale(-1)' : ''}>
                            <Icon color="white" icon="angle-left" fontSize="18px" />
                        </Box>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
};
