import React, { Dispatch, SetStateAction } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button
} from '@chakra-ui/react';
import { InstitutionsList } from './InstitutionsList';
import { InstitutionModalTypes } from "@/features/Playlists";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    handleScormDownload?: (id: number) => void;
    handleCCDownload?: (id: number) => void;
    handleQtiDownload?: (id: number) => void;
    handleProviderInstitutionId?: (id: number, title?: string) => void;
    handleAssignInstitutionId?: (id: number, title: string) => void;
    type: InstitutionModalTypes;
    isLoading?: boolean;
};

const InstitutionsModal = ({
    isOpen,
    onClose,
    handleScormDownload,
    isLoading,
    handleCCDownload,
    handleQtiDownload,
    handleProviderInstitutionId,
    handleAssignInstitutionId,
    type
}: Props) => {

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
        >
            <ModalOverlay />
            <ModalContent>
                {type !== 'provider' && type !== 'users-management' ? <ModalHeader>
                        Select the Institution to download
                        <Text display={'inline'} textTransform={'uppercase'}>
                            {type}
                        </Text>
                    </ModalHeader>
                    :
                    <ModalHeader>
                        Select Institution
                    </ModalHeader>
                }
                <ModalCloseButton />
                <ModalBody>
                    <InstitutionsList
                        isLoading={isLoading}
                        handleScormDownload={handleScormDownload}
                        handleCCDownload={handleCCDownload}
                        handleQtiDownload={handleQtiDownload}
                        handleProviderInstitutionId={handleProviderInstitutionId}
                        handleAssignInstitutionId={handleAssignInstitutionId}
                        type={type}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default InstitutionsModal;
