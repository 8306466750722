import React, { Component, ErrorInfo, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, Flex, Heading} from "@chakra-ui/react";

const ErrorFallback = () => {
    const navigate = useNavigate();

    return (
        <Flex
            w={'full'}
            h={'full'}
            flexDir={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Heading size="lg" fontWeight={'bold'}>Oops, something went wrong</Heading>
            <Button
                mt={4}
                onClick={() => navigate('/')}
            >
                Refresh
            </Button>
        </Flex>
    );
};

type Props = {
    children?: ReactNode;
}

type State = {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorFallback/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
