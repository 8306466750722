import React from 'react';
import {HStack, Box} from '@chakra-ui/react';
import {PaginationButton} from '@/components/Pagination/PaginationButton';
import Icon from '@/components/Icon';

interface Props {
    totalPages: number,
    currentPage: number,
    handlePagination: (page: number) => void;
}

export const Pagination = ({currentPage, handlePagination, totalPages}: Props) => {
    return (
        <HStack spacing={3}>
            {currentPage > 1 &&
            <>
                <PaginationButton
                    isChevron
                    onPaginationClick={() => currentPage !== 1 && handlePagination(currentPage - 1)}
                >
                    <Icon icon='chevron-left' fontSize={'16px'}/>
                </PaginationButton>

            </>
            }
            {currentPage > 2 &&
            <>
                <PaginationButton
                    onPaginationClick={() => handlePagination(1)}
                >1</PaginationButton>
                {totalPages > 3 &&
                <Box>...</Box>
                }
            </>
            }
            {currentPage > 1 &&
            <PaginationButton
                onPaginationClick={() => currentPage !== 1 && handlePagination(currentPage - 1)}
            >{currentPage - 1}</PaginationButton>
            }
            <PaginationButton isActive onPaginationClick={() => {
            }}>{currentPage}</PaginationButton>
            {currentPage < totalPages &&
            <PaginationButton
                onPaginationClick={() => currentPage !== totalPages && handlePagination(currentPage + 1)}
            >{currentPage + 1}</PaginationButton>
            }
            {currentPage <= totalPages - 2 &&
            <>
                {totalPages > 3 &&
                <Box>...</Box>
                }
                <PaginationButton
                    onPaginationClick={() => handlePagination(totalPages)}
                >{totalPages}</PaginationButton>
            </>
            }
            {currentPage < totalPages &&
            <PaginationButton
                isChevron
                onPaginationClick={() => currentPage < totalPages && handlePagination(currentPage + 1)}
            >
                <Icon icon='chevron-right' fontSize={'16px'}/>
            </PaginationButton>
            }
        </HStack>
    );
};
