import React, { FC } from 'react';
import { Modal, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { useDeleteTranslationGroup } from '@/features/Translations/api/deleteTranslationGroup';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    groupId: number;
    name: string;
};

export const DeleteTranslationGroupModal: FC<Props> = (props) => {
    const { isOpen, onClose, groupId, name } = props;

    const deleteTranslationMutation = useDeleteTranslationGroup(onClose);

    const onSubmit = async () => {
        deleteTranslationMutation.mutate(groupId);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete translation</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{`Are you sure you want to delete ${name}?`}</ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme="red" onClick={onSubmit} isLoading={deleteTranslationMutation.isLoading}>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
