import React from 'react';
import { Modal, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { useDeleteSponsor } from '@/features/Sponsors/api/deleteSponsor';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    id: number;
    name: string;
};

export const DeleteSponsorModal = ({ isOpen, onClose, id, name }: Props) => {
    const deleteSponsorMutation = useDeleteSponsor(onClose);

    const onSubmit = async () => {
        deleteSponsorMutation.mutate(id);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Sponsor</ModalHeader>
                <ModalCloseButton />
                <ModalBody>Are you sure you want to delete {name}?</ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme="red" onClick={onSubmit} isLoading={deleteSponsorMutation.isLoading}>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
