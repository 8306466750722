import React, {useEffect, useState} from 'react';
import { useDisclosure, Image, Box } from '@chakra-ui/react';
import { FaPlay } from 'react-icons/fa';
import { VideoModal } from '../VideoModal';

type Props = {
    videoData?: {
        articleId: number;
        images: {
            large: string;
            short?: string;
        };
        subtitles?: Record<string, string>;
        title: string;
        videoHash: string | null;
        videoUrl: string | null;
    } | null;
    showCaption?: boolean;
};
export const ViewQuizVideoContainer = ({ videoData, showCaption }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [image, setImage] = useState<string | undefined>();

    useEffect(() => {
        setImage(videoData?.images?.short || videoData?.images?.large ||  '/assets/VideoComingSoonTeaser.webp');
    }, [videoData]);

    const handleError = () => setImage('/assets/VideoComingSoonTeaser.webp');

    return (
        <>
            <Image
                w={'100%'}
                src={image}
                onError={handleError}
                alt={videoData?.title}
            />
            {(videoData?.images?.large || videoData?.images?.short) && (
                <Box
                    border={'14px solid white'}
                    align="center"
                    justify="center"
                    borderRadius="50%"
                    h={'150px'}
                    width={'150px'}
                    position={'absolute'}
                    top={'calc(50% - 75px)'}
                    left={'calc(50% - 75px)'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    color={'white'}
                    cursor={'pointer'}
                    onClick={onOpen}
                >
                    <FaPlay size={'40px'} />
                </Box>
            )}
            <VideoModal
                videoId={videoData?.articleId ?? null}
                subtitles={(showCaption !== undefined ? showCaption : true) && videoData?.subtitles
                    ? videoData.subtitles
                    : null
                }
                isOpen={isOpen}
                onClose={onClose}
            />
        </>
    );
};
