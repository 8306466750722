import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { Banner } from '../types';

export const getLastBanner = (): Promise<Banner> => {
    return axios.get(`/api/admin/banner`);
};

export const useGetLastBanner = () => {
    return useQuery<Banner, Error>({
        queryKey: ['banner'],
        queryFn: () => {
            return getLastBanner();
        },
    });
};
