import { nanoid } from 'nanoid';
import create from 'zustand';

export type TNotification = {
    id: string;
    type: 'info' | 'warning' | 'success' | 'error';
    title: string;
    message?: string;
};

type NotificationsStore = {
    notifications: TNotification[];
    addNotification: (notification: Omit<TNotification, 'id'>) => void;
    dismissNotification: (id: string) => void;
};

export const useNotificationStore = create<NotificationsStore>((set) => ({
    notifications: [],
    addNotification: (notification) => {
        let id = nanoid()
        set((state) => ({
            notifications: [...state.notifications, {id, ...notification}],
        }))
        setTimeout(() => {
            set((state) => ({
                notifications: state.notifications.filter((notification) => notification.id !== id),
            }))
        }, 4000);
    },
    dismissNotification: (id) =>
        set((state) => ({
            notifications: state.notifications.filter((notification) => notification.id !== id),
        })),
}));
