import React, { useCallback, useEffect, useState } from 'react';
import { Sponsor } from '@/features/Sponsors/types';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useUpdateSponsor } from '@/features/Sponsors/api/updateSponsor';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useFileUpload } from 'use-file-upload';
import { SponsorsAssignedArticlesList } from '../SponsorAssignedArticlesList';
import { uploadSponsorLogo, uploadSponsorVideo } from '@/features/Sponsors/api/uploadSponsorFiles';
import 'react-image-crop/dist/ReactCrop.css';
import { useNotificationStore } from '@/stores/notifications';
import { useAddSponsor } from '@/features/Sponsors/api/addSponsor';
import { useQueryClient } from 'react-query';
import Icon from '@/components/Icon';
import { CropModal } from '@/features/Institutions/components/CropModal';
import parse from 'html-react-parser';

export type SponsorDetailFormData = {
    companyName: string;
    companyUrl: string;
};

const sponsorDetailsSchema = yup.object().shape({
    companyName: yup.string().required('This field is required'),
    companyUrl: yup
        .string()
        .required('This field is required')
        .test('is-url-valid', 'URL is not valid', (value) => {
            try {
                new URL(value || '');
            } catch (e) {
                return false;
            }
            return true;
        }),
});

type Props = {
    sponsorData?: Sponsor;
    isCreate?: boolean;
};

export const SponsorForm = ({ sponsorData, isCreate = false }: Props) => {
    const [, selectFile] = useFileUpload();
    const { isOpen: isOpenCropModal, onClose: onCloseCropModal, onOpen: onOpenCropModal } = useDisclosure();

    const [uploadImagePreview, setUploadImagePreview] = useState<any>();
    const [uploadImageFile, setUploadImageFile] = useState<File | null>(null);
    const [uploadImageSubmitting, setUploadImageSubmitting] = useState<boolean>(false);
    const [uploadImageError, setUploadImageError] = useState('');

    const [uploadVideoError, setUploadVideoError] = useState('');
    const [uploadVideoIsLoading, setUploadVideoIsLoading] = useState(false);

    const { addNotification } = useNotificationStore();

    const queryClient = useQueryClient();

    const updateSponsorMutation = useUpdateSponsor();
    const addSponsorMutation = useAddSponsor();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SponsorDetailFormData>({
        resolver: yupResolver(sponsorDetailsSchema),
        defaultValues: {
            companyName: String(parse(sponsorData?.companyName ?? '')) ?? '',
            companyUrl: sponsorData?.companyUrl ?? 'https://',
        },
    });

    useEffect(() => {
        uploadImageFile && !uploadImageError && onOpenCropModal();
    }, [uploadImageFile, uploadImageError]);

    const base64toFile = (base64String: string): File => {
        const arr = base64String.split(',');
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], uploadImageFile?.name?.replaceAll(' ', '-') || 'logo.png', { type: 'image/png' });
    };

    const handleLogoUploadSubmit = async (fileBase64: string) => {
        const file = base64toFile(fileBase64);
        const formData = new FormData();
        formData.append('file', file);
        setUploadImageSubmitting(true);

        if (sponsorData) {
            try {
                setUploadImageSubmitting(true);
                await uploadSponsorLogo(sponsorData?.id, formData);
                addNotification({
                    type: 'success',
                    title: 'Logo successfully uploaded',
                });
                onCloseCropModal();
                setUploadImageSubmitting(false);
                await queryClient.refetchQueries(['sponsor', String(sponsorData.id)]);
            } catch (e: any) {
                addNotification({
                    type: 'error',
                    title: e.message,
                });
                setUploadImageSubmitting(false);
            }
        }
    };

    const onVideoUpload = useCallback(() => {
        selectFile({ accept: 'video/mp4', multiple: false }, async (props) => {
            if (props && !Array.isArray(props) && sponsorData) {
                const allowedTypes = ['video/mp4'];

                if (props.file.size > 2000000) {
                    setUploadVideoError('File size limit exceeded (2 MB). Please reduce and try again.');
                } else if (!allowedTypes.includes(props.file.type)) {
                    setUploadVideoError('Invalid file type. Accepted format: mp4.');
                } else {
                    setUploadVideoError('');

                    const formData = new FormData();

                    formData.append('file', props.file);
                    try {
                        setUploadVideoIsLoading(true);
                        await uploadSponsorVideo(sponsorData.id, formData);
                        onCloseCropModal();
                        await queryClient.refetchQueries(['sponsor', String(sponsorData.id)]);
                        addNotification({
                            type: 'success',
                            title: 'Video successfully uploaded',
                        });

                        setUploadVideoIsLoading(false);
                    } catch (e: any) {
                        addNotification({
                            type: 'error',
                            title: e.message,
                        });
                        setUploadVideoIsLoading(false);
                    }
                }
            }
        });
    }, [selectFile]);

    const onSubmitDetails = async (values: SponsorDetailFormData) => {
        if (isCreate) {
            const data = {
                ...values,
            };
            await addSponsorMutation.mutate({ data });
        } else {
            if (sponsorData) {
                const data = {
                    ...values,
                };
                await updateSponsorMutation.mutate({ data, sponsorId: sponsorData?.id });
            }
        }
    };

    return (
        <>
            <Heading size="md" mb={3}>
                Sponsor Details{' '}
                <Tooltip
                    label={
                        'All functionalities, including logo upload, preroll upload, and adding product links, are optional, and the user can choose to perform any combination of these actions.'
                    }
                    bgColor="#2183ED"
                    color="white"
                    placement="auto-start">
                    <Text mt="3px" display="inline">
                        <Icon icon="circle-info" />
                    </Text>
                </Tooltip>
            </Heading>
            {uploadImagePreview && (
                <CropModal
                    isOpen={isOpenCropModal}
                    imagePreviewUrl={uploadImagePreview}
                    submitting={uploadImageSubmitting}
                    onSubmit={handleLogoUploadSubmit}
                    onClose={onCloseCropModal}
                />
            )}
            <form onSubmit={handleSubmit(onSubmitDetails)}>
                <Stack mb={2} spacing={4}>
                    <FormControl isInvalid={errors.hasOwnProperty('companyName')}>
                        <FormLabel htmlFor="companyName">Company Name</FormLabel>
                        <Input id="companyName" placeholder="Enter Company Name" {...register('companyName')} />
                        <FormErrorMessage>{errors.companyName?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.hasOwnProperty('companyUrl')}>
                        <FormLabel htmlFor="companyUrl">Company Url</FormLabel>
                        <Input id="companyUrl" placeholder="Enter Company Url" {...register('companyUrl')} />
                        <FormErrorMessage>{errors.companyUrl?.message}</FormErrorMessage>
                    </FormControl>

                    {sponsorData && (
                        <>
                            <Flex alignItems="center">
                                <Button mr={5} onClick={onVideoUpload} minW="230px">
                                    <Icon icon={'upload'} />
                                    &nbsp;
                                    {uploadVideoIsLoading ? (
                                        <Box w="74px">
                                            <LoadingSpinner />
                                        </Box>
                                    ) : (
                                        'Upload Corporate Preroll'
                                    )}
                                </Button>
                                <Text wordBreak={'break-word'}>{sponsorData.videoUrl || 'No video uploaded'}</Text>
                            </Flex>
                            {uploadVideoError && (
                                <Text mt={'10px'} color={'#e53e3e'}>
                                    {uploadVideoError}
                                </Text>
                            )}
                            <Text fontSize="14px">MP4 Format Only. Max Size: 2MB.</Text>

                            <Flex alignItems="center" pt="20px">
                                <Button
                                    mr={5}
                                    minW="230px"
                                    onClick={() =>
                                        selectFile({ accept: 'image/png', multiple: false }, async (props) => {
                                            if (props && !Array.isArray(props)) {
                                                if (props.file.size > 1000000) {
                                                    setUploadImageError('File size limit exceeded (1 MB). Please reduce and try again.');
                                                } else if (!props.file.type.includes('png')) {
                                                    setUploadImageError('Invalid file type. Accepted format: PNG');
                                                } else {
                                                    setUploadImageError('');
                                                    setUploadImageFile(props.file);
                                                }

                                                const fileReader = new FileReader();
                                                fileReader.onload = (e) => {
                                                    const result = e?.target?.result;
                                                    if (result) {
                                                        setUploadImagePreview(result);
                                                    }
                                                };
                                                fileReader.readAsDataURL(props.file);
                                            }
                                        })
                                    }>
                                    <Icon icon="upload" />
                                    &nbsp; Upload Corporate Logo
                                </Button>
                                <Text wordBreak="break-word">{sponsorData.logoUrl || 'No logo uploaded'}</Text>
                            </Flex>
                            {uploadImageError && (
                                <Text mt="10px" color={'#e53e3e'}>
                                    {uploadImageError}
                                </Text>
                            )}
                            <Text fontSize="14px">PNG Format Only. Max Height: 50px. Max Width: 150px. Max Size: 1MB</Text>
                            <Box py={6}>
                                <SponsorsAssignedArticlesList articlesList={sponsorData.articleList} sponsorId={sponsorData.id} />
                            </Box>
                        </>
                    )}
                </Stack>

                <Button mt={4} variant="brand" isLoading={updateSponsorMutation.isLoading} type={'submit'}>
                    Save Changes
                </Button>
            </form>
        </>
    );
};
