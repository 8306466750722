import { axios } from '@/lib/axios';
import { useMutation, useQueryClient } from 'react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Translation } from '@/features/Translations/types';

type UpdateTranslationData = {
    translationId: number;
    data: { groupId: number; key: string; language: string; value: string };
};

export const updateTranslation = ({ translationId, data }: UpdateTranslationData): Promise<Translation> => {
    return axios.patch(`/api/admin/site_translation/edit/${translationId}`, data);
};

export const useUpdateTranslation = (
    closeModalFunction: () => void,
    activePage: number,
    activeSearchKeyword: string | number,
    activeSortDirection: string,
    activeSortProperty: string,
    activeLanguage: string
) => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries([
                'translation',
                activePage,
                activeSearchKeyword,
                activeSortDirection,
                activeSortProperty,
                activeLanguage,
            ]);
        },
        onError: () => {
            addNotification({
                type: 'error',
                title: 'Unable to update translation',
            });
            closeModalFunction();
        },
        onSuccess: (res) => {
            queryClient.refetchQueries(['translation', activePage, activeSearchKeyword, activeSortDirection, activeSortProperty, activeLanguage]);

            addNotification({
                type: 'success',
                title: 'The value was successfully updated',
            });
            closeModalFunction();
        },
        mutationFn: updateTranslation,
    });
};
