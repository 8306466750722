import Icon from '@/components/Icon';
import { useAuth } from '@/context/AuthContext';
import { useNotificationStore } from '@/stores/notifications';
import React, { ReactNode, useCallback, useState, MouseEvent, Fragment } from 'react';
import { Tr, Td, Text, useDisclosure, Flex, Tooltip, Box, Spinner } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
    AssignQuizToUsersRequest,
    Quiz,
    SharedListMyQuiz,
    QuizLibrary,
} from '../../types';
import { DeleteQuizModal } from "@/features/Quiz/components/DeleteQuizModal";
import { DistributeQuizModal } from "@/features/Quiz/components/DistributeQuizModal";
import { saveSharedUsersAdminQuizById } from '@/features/Quiz/api/getQuizzes';
import { format } from 'date-fns';
import InstitutionsModal from "@/features/Playlists/components/InstitutionsModal";
import { useQti } from "@/api/qtiApi";
import { usePlaylistCC } from "@/api/ccApi";
import { InstitutionModalTypes } from "@/features/Playlists";
import { usePlaylistScorm } from "@/api/scormApi";

type Props = {
    quiz: QuizLibrary;
    isAdminQuiz: boolean;
}

const TextEl = ({ children }: { children: ReactNode }) => (
    <Text color="black" fontSize={'14px'} lineHeight={'21px'} fontWeight={'500'} textAlign="center" display="inline-block" p="0 10px" w={'auto'}>
        {children}
    </Text>
);

export const SharedQuizItem = ({ quiz, isAdminQuiz }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isInstitutionsOpen, onOpen: onInstitutionsOpen, onClose: onInstitutionsClose } = useDisclosure();
    const [type, setType] = useState<InstitutionModalTypes>(null);
    const { addNotification } = useNotificationStore();

    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const downloadType = isAdminQuiz ? "from_admin_quiz" : "from_quiz"

    const [error, setError] = useState('');
    const [openDistributeQuiz, setOpenDistributeQuiz] = useState(false);

    const handleDistributeQuizClose = useCallback(() => {
        setOpenDistributeQuiz(false);
        setError('')
    }, []);

    const handleDistributeQuizOpen = useCallback((e: MouseEvent) => {
        e.preventDefault();

        setOpenDistributeQuiz(true);
    }, []);

    const handleDistributeQuizSubmit = useCallback(async (data: AssignQuizToUsersRequest) => {
        try {
            const res = await saveSharedUsersAdminQuizById(quiz?.id ?? 0, data);
            if (res.already_shared.length) {
                addNotification({
                    type: 'error',
                    title: `Already shared: ${res.already_shared.join(', ')}`,
                });
            }
            if (res.shared.length) {
                addNotification({
                    type: 'success',
                    title: `Shared: ${res.shared.join(', ')}`,
                });
            }
            if (res.not_access.length) {
                addNotification({
                    type: 'error',
                    title: `Not access: ${res.not_access.join(', ')}`,
                });
            }
            if (res.not_found.length) {
                addNotification({
                    type: 'error',
                    title: `Not found: ${res.not_found.join(', ')}`,
                });
            }
            setError('');
        } catch (e: any) {
            setError(e?.message);
            throw e;
        }
    }, []);

    const { isLoading: isQtiLoading, handleQtiDownload } = useQti({
        qtiType: downloadType,
        qtiId: quiz?.id ?? 0,
        title: quiz?.title ?? ''
    });

    const { isLoading: isCCLoading, handleCCDownload } = usePlaylistCC({
        ccType: downloadType, ccId: quiz?.id ?? 0, title: quiz?.title ?? ''
    });

    const { isLoading: isScormLoading, handleScormDownload } = usePlaylistScorm({
        scormType: downloadType, scormId: quiz?.id ?? 0, playlistTitle: quiz?.title ?? ''
    });

    const handleOpenInstitutionsQti = () => {
        setType('qti');
        onInstitutionsOpen();
    };

    const handleOpenInstitutionsCC = () => {
        setType('cc');
        onInstitutionsOpen();
    };

    const handleOpenInstitutionsScorm = () => {
        setType('scorm');
        onInstitutionsOpen();
    };

    return (
        <>
            <InstitutionsModal
                isOpen={isInstitutionsOpen}
                onClose={onInstitutionsClose}
                handleQtiDownload={handleQtiDownload}
                handleScormDownload={handleScormDownload}
                handleCCDownload={handleCCDownload}
                isLoading={isScormLoading || isCCLoading || isQtiLoading}
                type={type}
            />
            <DeleteQuizModal
                isOpen={isOpen}
                onClose={onClose}
                quiz={quiz as Quiz | QuizLibrary}
                quizType={isAdminQuiz ? 'admin' : 'regular'}
            />
            <DistributeQuizModal
                isOpen={openDistributeQuiz}
                onClose={handleDistributeQuizClose}
                onSubmit={handleDistributeQuizSubmit}
                quiz={quiz as Quiz | QuizLibrary}
                errorMessage={error}
                sharedList={!isSuperAdmin ? (quiz?.sharedUserList as SharedListMyQuiz[]) : null}
            />
            <Tr>
                <Td w={'65%'} py={'10px'}>
                    <Flex as={Link}
                          to={isAdminQuiz
                              ? `/quiz/admin-quiz/view/${quiz?.id}`
                              : `/quiz/my-quiz/view/${quiz?.id}`
                          } py={'10px'}>
                        <Text color={'black'}>
                            {quiz?.title}
                        </Text>
                    </Flex>
                </Td>
                <Td w={'45%'} py={'10px'}>
                    {quiz?.sharedList
                        ? quiz?.sharedList?.map((item, index) => (
                            <Fragment key={index}>
                                <Box color={'black'} lineHeight={'21px'}>
                                    {item.user.firstName} {item.user.lastName} | {item.user.email}
                                </Box>
                                <br />
                            </Fragment>
                        ))
                        : quiz?.sharedUserList && quiz?.sharedUserList?.map((item, index) => (
                            <Fragment key={index}>
                                <Box color={'black'} lineHeight={'21px'}>
                                    {item?.first_name} {item?.last_name} | {item?.email}
                                </Box>
                                <br />
                            </Fragment>
                        ))
                    }
                </Td>
                <Td w={'10%'}>
                    <Text color={'black'} lineHeight={'21px'}>
                        {quiz?.createdAt ? format(new Date(quiz?.createdAt), 'MM/dd/yyyy') : '-'}
                    </Text>
                </Td>
                <Td textAlign="center">
                    <TextEl>
                        {quiz?.countOfQuestions ? `${quiz?.countOfQuestions} Questions` : '-'}
                    </TextEl>
                </Td>
                <Td w={'15%'} textAlign="center">
                    <TextEl>
                        {quiz?.endDate ? format(new Date(quiz?.endDate), 'MM/dd/yyyy') : '-'}
                    </TextEl>
                </Td>

                <Td w="auto">
                    <Flex columnGap={'10px'} align="center" justifyContent={'start'}>

                        <Box
                            onClick={handleOpenInstitutionsScorm}
                        >
                            <Tooltip hasArrow label={'Download SCORM'} aria-label={'Download SCORM'}>
                                {isScormLoading ?
                                    <Spinner size={'xs'} />
                                    :
                                    <span><Icon highlightOnHover icon='file-zipper'/></span>
                                }
                            </Tooltip>
                        </Box>
                        <Box
                            onClick={handleOpenInstitutionsQti}
                        >
                            <Tooltip hasArrow label={'Download QTI'} aria-label={'Download QTI'}>
                                <span><Icon fontSize={'20px'} highlightOnHover icon='scroll' /></span>
                            </Tooltip>
                        </Box>
                        <Box
                            onClick={handleOpenInstitutionsCC}
                        >
                            <Tooltip hasArrow label={'Download CC'} aria-label={'Download CC'}>
                                {isCCLoading ?
                                    <Spinner size={'xs'} />
                                    :
                                    <span><Icon fontSize={'20px'} highlightOnHover icon='closed-captioning'/></span>
                                }
                            </Tooltip>
                        </Box>
                        <Box
                            variant="playlistSmBtn"
                            borderRadius={'16px'}
                            height={'28px'}
                            onClick={handleDistributeQuizOpen}
                        >
                            <Tooltip hasArrow label={'Share quiz'} aria-label={'Share quiz'}>
                                <span><Icon highlightOnHover icon='share-from-square' /></span>
                            </Tooltip>
                        </Box>
                    </Flex>
                </Td>
            </Tr>
        </>
    );
};
