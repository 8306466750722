import { combineReducers } from '@reduxjs/toolkit';
import { quiz } from './quizSlice';
import { quizLibrary } from './quizLibrary';
import { quizAdmin } from './quizAdmin';
import { tags } from './tag/tagSlice';
import { quizCustomer } from "./quizCustomer";
import { quizUser } from "./quizUser";

export const quizzes = combineReducers({
    quiz,
    tags,
    quizLibrary,
    quizAdmin,
    quizCustomer,
    quizUser
});