import Icon from '@/components/Icon';
import React, { useCallback, useEffect, ChangeEvent } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/redux/store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Table,
    Tbody,
    Td,
    Thead,
    Tr,
    Spinner as SpinnerChakra,
    Button,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { HiX } from 'react-icons/hi';
import * as yup from 'yup';
import { debounce } from '@/utils/helpers';
import { Pagination } from '@/components/Pagination';
import { ThEl } from '@/features/Quiz/components/ArticlesQuiz';
import { Link } from 'react-router-dom';
import {
    getAllQuizzesAdmin,
    resetQuizAdminPages,
    selectQuizAdminActivePage,
    selectQuizAdminLoading,
    selectQuizAdminPage,
    selectQuizAdminTotalPages,
    setQuizAdminActivePage,
} from '@/redux/reducers/quizzes/quizAdmin';
import { AdminQuizItem } from '@/features/Quiz/components/AdminQuizItem';
import { useAuth } from '@/context/AuthContext';

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

type SearchFormData = {
    keyword: string;
};

export const AdminQuiz = () => {
    const dispatch = useDispatch<AppDispatch>();

    const activePage = useSelector(selectQuizAdminActivePage, shallowEqual);
    const totalPages = useSelector(selectQuizAdminTotalPages, shallowEqual);
    const quizzes = useSelector((state: RootState) => selectQuizAdminPage(state, activePage), shallowEqual);
    const isLoading = useSelector(selectQuizAdminLoading, shallowEqual);
    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
        watch,
        getValues,
        setValue,
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        },
    });

    const values = getValues();

    const onSearchKeywordSubmit = useCallback(
        (values: SearchFormData) => {
            dispatch(resetQuizAdminPages());
            dispatch(setQuizAdminActivePage(1));

            dispatch(
                getAllQuizzesAdmin({
                    activePage: 1,
                    activeSearchKeyword: values.keyword,
                })
            );
        },
        [dispatch]
    );

    const onSearchKeywordChange = debounce(
        useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                e.preventDefault();

                setValue('keyword', e.target.value);

                dispatch(resetQuizAdminPages());
                dispatch(setQuizAdminActivePage(1));

                dispatch(
                    getAllQuizzesAdmin({
                        activePage: 1,
                        activeSearchKeyword: e.target.value ?? '',
                    })
                );
            },
            [dispatch]
        ),
        300
    );

    const handleReset = useCallback(() => {
        reset();

        dispatch(
            getAllQuizzesAdmin({
                activePage: 1,
                activeSearchKeyword: '',
            })
        );
    }, []);

    const handlePaginationChange = useCallback(
        (page: number) => {
            dispatch(setQuizAdminActivePage(page));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(
            getAllQuizzesAdmin({
                activePage,
                activeSearchKeyword: values.keyword ?? '',
            })
        );
    }, [dispatch, activePage]);

    useEffect(
        () => () => {
            dispatch(setQuizAdminActivePage(1));
        },
        [dispatch]
    );

    return (
        <Flex w="100%" flexDir="column" mb={'10px'}>
            <Flex gap="20px" sx={{ '.btn-half-width': { flex: '1 0 45%' } }} w="100%">
                <Flex justifyContent={'space-between'} alignItems={'center'} mb={3} w="100%">
                    <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                        <InputGroup minW={'400px'} className="input-left-wrap">
                            <InputLeftElement
                                className="InputLeft"
                                pointerEvents={isSubmitting ? 'none' : 'auto'}
                                onClick={handleSubmit(onSearchKeywordSubmit)}>
                                {isSubmitting || isLoading ? <SpinnerChakra size="20px" /> : <FaSearch size="20px" />}
                            </InputLeftElement>
                            <Input
                                placeholder="Search Admin Quiz by Title"
                                bg={'#ffffff'}
                                id={'keyword'}
                                type={'text'}
                                borderRadius="1000px"
                                {...register('keyword')}
                                onChange={onSearchKeywordChange}
                            />
                            {watch('keyword') && (
                                <InputRightElement cursor="pointer" onClick={handleReset}>
                                    <HiX />
                                </InputRightElement>
                            )}
                        </InputGroup>
                    </form>
                    {isSuperAdmin && (
                        <Button
                            _focus={{ outline: 0 }}
                            _hover={{}}
                            _active={{}}
                            as={Link}
                            to={'/quiz/admin-quiz/create'}
                            variant="brand"
                            cursor="pointer">
                            <Icon icon="plus" /> &nbsp; Create a New Quiz
                        </Button>
                    )}
                </Flex>
            </Flex>
            <Flex flexDir={'column'}>
                <Table bgColor="#fff">
                    {quizzes.length > 0 && (
                        <Thead borderBottom="1px solid #e5ebf3">
                            <Tr w="100%">
                                <ThEl align="start" w="65%">
                                    Title
                                </ThEl>
                                <ThEl w="10%" align="start">
                                    Subjects
                                </ThEl>
                                <ThEl w="15%">Questions</ThEl>
                                <ThEl w="15%">Deadline</ThEl>
                                <ThEl w="15%">Actions</ThEl>
                            </Tr>
                        </Thead>
                    )}
                    <Tbody>
                        {quizzes.length > 0 ? (
                            quizzes.map((quiz) => <AdminQuizItem key={quiz.id} quiz={quiz} isAdminQuiz />)
                        ) : (
                            <>
                                {!isLoading && (
                                    <Tr>
                                        <Td>
                                            <Box>No list items</Box>
                                        </Td>
                                    </Tr>
                                )}
                            </>
                        )}
                    </Tbody>
                </Table>

                {totalPages > 1 && (
                    <Flex justifyContent={'end'} mt={'20px'}>
                        <Pagination currentPage={activePage} totalPages={totalPages} handlePagination={handlePaginationChange} />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};
