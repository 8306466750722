import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { isNotNull } from '@/utils/helpers';
import { RootState } from '../../store';
import {
    createQuizLibrary,
    getQuizLibrary,
    getQuizLibraryById,
    removeQuizLibraryById,
    updateQuizLibrary
} from '@/features/Quiz/api/getQuizzes';
import {
    CreateQuizLibraryData,
    QuizLibrary,
    QuizLibraryResponse, SaveQuizError,
    UpdateQuizLibraryData,
} from '@/features/Quiz/types';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

export type QuizLibraryType = QuizLibrary;

export const buildQuizLibraryParams = (
    activePage: number,
    activeSearchKeyword?: string | number,
    isTextbook?: boolean,
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword ? [
                    {
                        bool_operator: 'and',
                        condition: {
                            property: 'title',
                            operator: 'contains',
                            value: activeSearchKeyword ?? 0
                        }
                    },
                ] : []
                ),
                ...(isTextbook ? [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: 'section_id',
                                operator: 'is_null',
                            }
                        }
                    ] : [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: 'section_id',
                                operator: 'is_not_null',
                            }
                        }
                    ]
                ),
            ]
        },
        sort: [
            [
                'title',
                'asc'
            ]
        ]
    }
}

export const getAllQuizzesLibrary = createAsyncThunk<
    QuizLibraryResponse,
    {
        activePage: number;
        activeSearchKeyword?: string | number;
        isTextbook?: boolean;
    },
    {
        rejectValue: string;
    }
>(
'quizzes/getAllQuizzesLibrary',
async ({ activePage, activeSearchKeyword, isTextbook }) => {
    const data = buildQuizLibraryParams(activePage,  activeSearchKeyword, isTextbook);

    return await getQuizLibrary(data);
});

export const getLibraryQuizById = createAsyncThunk('quizzes/getQuizLibraryById', (id: number) => getQuizLibraryById(id));

export const removeQuizLibrary = createAsyncThunk(
'quizzes/removeQuizLibrary',
(id: number) => removeQuizLibraryById(id)
);


export const createNewQuizLibrary = createAsyncThunk<
    QuizLibrary,
    CreateQuizLibraryData,
    {
        rejectValue: SaveQuizError;
    }
    >('quizzes/createNewQuizLibrary', async (data, { rejectWithValue }) => {
    return await createQuizLibrary(data).catch((res) => rejectWithValue(res));
});

export const editQuizLibrary = createAsyncThunk<
    QuizLibrary,
    {
        id: number;
        data: UpdateQuizLibraryData;
    },
    {
        rejectValue: SaveQuizError;
    }
    >('quizzes/editQuizLibrary', async ({ id, data }, { rejectWithValue }) => {
    return await updateQuizLibrary(id, data).catch((res) => rejectWithValue(res));
});

const quizLibraryAdapter = createEntityAdapter<QuizLibraryType>();

type State = {
    pages: Record<number, QuizLibraryType['id'][]>;
    activePage: number;
    total: number;
    totalPages: number;
    isLoading: boolean;
};

const quizLibrarySlice = createSlice({
    name: 'quizLibrary',
    initialState: quizLibraryAdapter.getInitialState<State>({
        pages: {},
        activePage: 1,
        totalPages: 1,
        total: 0,
        isLoading: false,
    }),
    reducers: {
        setQuizLibraryActivePage: (state, action: PayloadAction<number>) => {
            state.activePage = action.payload;
        },
        resetQuizLibraryPages: (state) => {
            state.pages = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllQuizzesLibrary.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllQuizzesLibrary.fulfilled, (state, action) => {
            quizLibraryAdapter.upsertMany(state, action.payload.result);
            state.pages[action.payload.navigation.page ?? 1] = action.payload.result.map((item) => item.id);
            state.total = action.payload.navigation.total_items;
            state.activePage = action.payload.navigation.page;
            state.totalPages = action.payload.navigation.total_pages;
            state.isLoading = false;
        });
        builder.addCase(getAllQuizzesLibrary.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getLibraryQuizById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getLibraryQuizById.fulfilled, (state, action) => {
            quizLibraryAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        });
        builder.addCase(getLibraryQuizById.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(removeQuizLibrary.fulfilled, (state, action) => {
            quizLibraryAdapter.removeOne(state, action.meta.arg);
        });
        builder.addCase(createNewQuizLibrary.fulfilled, (state, action) => {
            quizLibraryAdapter.upsertOne(state, action.payload);
        });

        builder.addCase(editQuizLibrary.fulfilled, (state, action) => {
            quizLibraryAdapter.upsertOne(state, action.payload);
        });
    },
});

export const quizLibrary = quizLibrarySlice.reducer;
export const { resetQuizLibraryPages, setQuizLibraryActivePage } = quizLibrarySlice.actions;

const selectState = (state: RootState) => state.quizzes.quizLibrary;

export const { selectById, selectIds } = quizLibraryAdapter.getSelectors(selectState);

const selectRootState = (state: RootState) => state;

export const selectQuizLibraryById = createSelector(
    [(state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
    (entity) => entity
);

const selectIdsByPage = createSelector(
    [selectState, (_: RootState, page: number) => page],
    (state, page) => state.pages[page] ?? []
);

export const selectQuizLibraryPage = createSelector(
    [selectRootState, (state: RootState, page: number) => selectIdsByPage(state, page)],
    (state, ids) => ids.map((id) => selectQuizLibraryById(state, id)).filter(isNotNull)
);

export const selectQuizLibraryActivePage = createSelector([selectState], (state) => state.activePage);
export const selectQuizLibraryTotalPages = createSelector([selectState], (state) => state.totalPages);
export const selectQuizLibraryTotal = createSelector([selectState], (state) => state.total);
export const selectQuizLibraryLoading = createSelector([selectState], (state) => state.isLoading);
