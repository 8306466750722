import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Select,
} from '@chakra-ui/react';
import { Activity } from '../../types';

type Props = {
    sections: Activity[];
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    onSubmit: (sectionId: number) => void;
}

export const MoveComponentModal: FC<Props> = ({ sections, isOpen, isLoading, onClose, onSubmit }: Props) => {
    const [id, setId] = useState<number | null>(null);

    const handleSubmit = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (id) {
            await onSubmit(id);
        }
        onClose();
    }, [onSubmit, onClose, id]);

    const handleSectionChange = useCallback((e:  React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();

        e.target.value && setId(Number(e.target.value));
    }, []);

    useEffect(() => {
        !isOpen && setId(null);
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='sm'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Move Component</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Select
                        placeholder='Select an Activity'
                        onChange={handleSectionChange}
                        variant='outline'
                        w='100%'
                        mb='10px'
                    >
                        {sections.map((item, index) => (
                            <option key={index} value={item.sortIndex}>
                                {item.title ?? ''}
                            </option>
                        ))}
                    </Select>
                </ModalBody>
                <ModalFooter alignItems={'center'} justifyContent={'center'}>
                    <Button
                        type={'submit'}
                        variant='authBtn'
                        w={'100%'}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Move Item
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
