import React, { MouseEvent, useCallback } from 'react';
import {
    Button,
    Box,
    FormErrorMessage,
    FormControl,
    Flex,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Activity, QuizFormData } from '../../types';
import Icon from '@/components/Icon';

export const LeftActivitiesFieldArray = () => {
    const { formState: { errors }, control, watch } = useFormContext<QuizFormData>();

    const { fields, append, replace } = useFieldArray<QuizFormData, 'activities'>({
        control,
        name: "activities"
    });

    const activities = watch("activities");

    const handleAddActivities = useCallback((e: MouseEvent) => {
        e.preventDefault();

        append({
            originId: null,
            title: `Activity ${fields.length + 1}`,
            sortIndex: fields.length + 1,
            isSelect: false,
            items: [],
        });
    }, [append, fields.length]);

    const handleRemoveActivities = useCallback((index: number) => (e: MouseEvent) => {
        e.preventDefault();

        const list = activities.filter((_, key) => key !== index);

        if (list.some((item) => item.isSelect)) {
            const newList = list.map((item, key) => ({ ...item, sortIndex: key + 1 }));

            replace(newList);
        } else {
            const newList = list.map((item, key) => {
                if (key === 0) {
                    return {
                        ...item,
                        sortIndex: key + 1,
                        isSelect: true
                    };
                }
                return { ...item, sortIndex: key + 1 };
            });

            replace(newList);
        }
    }, [replace, activities]);

    const handleSelect = useCallback((index: number, item: Activity) => (e: MouseEvent) => {
        e.preventDefault();

        if (!item.isSelect) {
            replace(
                activities.map((activity) => {
                    if (activity.sortIndex === index) {
                        return { ...activity, isSelect: true};
                    }

                    if (activity.isSelect) {
                        return { ...activity, isSelect: false};
                    }
                    return activity;
                })
            );
        }
    }, [replace, activities]);

    return (
        <>
            <Flex
                justify="space-between"
                flexDir="row"
                mt="30px"
                mb="20px"
                pb="7px"
                fontSize="15px"
                fontWeight="500"
                color="primary.greyText"
                lineHeight="15px"
                borderBottomStyle="solid"
                borderBottomWidth="1px"
                borderBottomColor="primary.greyLine"
            >
                <Box
                    lineHeight="15px"
                    h="auto"
                >
                    Activities
                </Box>
                <Button
                    h="auto"
                    display="block"
                    color="primary.blue"
                    p="0"
                    fontSize="12px"
                    lineHeight="12px"
                    fontWeight="normal"
                    bg="primary.grey"
                    _hover={{}}
                    _focus={{ outline: 0 }}
                    _active={{}}
                    onClick={handleAddActivities}
                >
                    <Icon
                        icon='circle-plus'
                        fontSize="20px"
                    />
                </Button>
            </Flex>
            <Flex
                flexDir="column"
                mb="36px"
                fontSize="15px"
                fontWeight="500"
                color="primary.greyText"
                lineHeight="15px"
                borderBottomStyle="dashed"
                borderBottomWidth="1px"
                borderBottomColor="primary.greyLine"
            >
                <Droppable droppableId="activities" type="ACTIVITIES">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                        >
                            {fields.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                >
                                    {(provided) => (
                                        <Flex
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            pb="20px"
                                            justify="space-between"
                                        >
                                            <Box {...provided.dragHandleProps}>
                                                <Icon
                                                    icon="grip-lines"
                                                    fontSize="15px"
                                                />
                                            </Box>

                                            <FormControl
                                                isInvalid={errors.hasOwnProperty(`activities.${index}.title`)}
                                            >
                                                <Button
                                                    h="auto"
                                                    w="100%"
                                                    display="block"
                                                    textAlign="center"
                                                    bg='primary.grey'
                                                    p="0"
                                                    fontWeight="normal"
                                                    lineHeight="25px"
                                                    color={item.isSelect ? "primary.blue" : "primary.greyText"}
                                                    _hover={{}}
                                                    _focus={{ outline: 0 }}
                                                    _active={{}}
                                                    onClick={handleSelect(index + 1, item)}
                                                >
                                                    {watch(`activities.${index}.title`) ?? ''}
                                                </Button>
                                                <FormErrorMessage>
                                                    {errors.activities && errors.activities[index]?.title?.message}
                                                </FormErrorMessage>
                                            </FormControl>

                                            {fields.length > 1 &&
                                                <Button
                                                    h="auto"
                                                    display="block"
                                                    color="red"
                                                    p="0"
                                                    fontSize="12px"
                                                    lineHeight="12px"
                                                    fontWeight="normal"
                                                    bg="primary.grey"
                                                    _hover={{}}
                                                    _focus={{ outline: 0 }}
                                                    _active={{}}
                                                    onClick={handleRemoveActivities(index)}
                                                >
                                                    <Icon
                                                        icon='trash-can'
                                                        fontSize="20px"
                                                    />
                                                </Button>
                                            }
                                        </Flex>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Flex>
        </>
    );
}
