import React from 'react';
import {Alert, AlertIcon, Text} from '@chakra-ui/react';

export const ErrorBlock = ({error, textOnly = false}: { error: string, textOnly: boolean }) => {
    return (
        <>
            {error &&
            textOnly ?
                <Text color={'red'}>{error}</Text>
                :
                <Alert status="error" borderRadius="3px">
                    <AlertIcon/>
                    {error}
                </Alert>
            }
        </>
    );
};

