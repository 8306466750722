import { axios } from '@/lib/axios';
import { API_URL } from "@/config";
import {
    ArticleCtx,
    QuizzesArticlesResponse,
} from '@/types';

export const getArticle = (id: number): Promise<ArticleCtx> => {
    return axios.get(`${API_URL}/api/free/article/en/${id}`)
};

type FilterCollection = {
    bool_operator?: string;
    condition?: {
        property?: string;
        operator: string;
        value: string | number | boolean;
    };
};

type GetArticleListBodyStructure = {
    navigation: {
        page: number;
        itemsOnPage: number;
    };
    bySection?: null | boolean;
    contentType?: 'science' | 'journal';
    totalCount: boolean;
    filter: {
        collection: FilterCollection[] | null;
    };
    sort?: string[][];
};

export const buildQuizzesArticleListParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    itemsOnPage = 20,
    bySection: null | boolean,
    contentType?: 'science' | 'journal' | null,
    sectionId?: number
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage,
        },
        ...(contentType ? { contentType: contentType } : null),
        bySection: bySection || null,
        withTemplate: activeSearchKeyword ? null : true,
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword
                    ? [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: Number(activeSearchKeyword) ? 'domain_id' : 'title',
                                operator: Number(activeSearchKeyword) ? 'equals' : 'contains',
                                value: Number(activeSearchKeyword) ? Number(activeSearchKeyword) : activeSearchKeyword || '',
                            },
                        },
                    ]
                    : []),
                ...(sectionId
                    ? [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: 'id',
                                operator: 'equals',
                                value: sectionId,
                            },
                        },
                    ]
                    : []),
            ],
        },
    };
};

export const postQuizzesArticles = (data: GetArticleListBodyStructure): Promise<QuizzesArticlesResponse> => {
    return axios.post(`${API_URL}/api/admin/quiz/article_list`, data);
};
