import { axios } from '@/lib/axios';
import { useMutation, useQueryClient } from 'react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Sponsor } from "@/features/Sponsors/types";
import { useNavigate } from "react-router-dom";
import { SponsorDetailFormData } from "@/features/Sponsors/Components/SponsorForm";

type AddSponsorData = {
    data: SponsorDetailFormData;
};

export const addSponsor = ({ data }: AddSponsorData): Promise<Sponsor> => {
    return axios.post(`/api/admin/sponsor`, data);
};

export const useAddSponsor = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    return useMutation({
        onMutate: async (updatingCollection: any) => {
            await queryClient.cancelQueries(['sponsor', updatingCollection?.id]);
        },
        onError: (_, __, context: any) => {
            addNotification({
                type: 'error',
                title: 'Unable to update Sponsor',
            });
        },
        onSuccess: (res) => {
            queryClient.refetchQueries(['sponsor', res.id]);
            addNotification({
                type: 'success',
                title: 'Sponsor successfully updated',
            });
            navigate(`/sponsors/edit/${res.id}`)
        },

        mutationFn: addSponsor,
    });
};
