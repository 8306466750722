import React from 'react';
import { useNotificationStore } from '@/stores/notifications';
import {Flex, VStack} from '@chakra-ui/react';

import { Notification } from './Notification';

export const Notifications = () => {
  const { notifications } = useNotificationStore();

  return (
      <Flex
          zIndex={2000}
          pos={'fixed'}
          mx={'0 auto'}
          mt={6}
          aria-live={'assertive'}
          pointerEvents={'none'}
          justifyContent={'center'}
          w={'full'}
      >
          <Flex
              w={'full'}
              ml={{ base: 0, md: 60}}
              justifyContent={'center'}
              px={4}
          >
              <VStack
                  spacing={3}
                  maxW={'600px'}
                  w={'full'}
                  pos={'relative'}
              >
                  {notifications.map((notification) => (
                      <Notification
                          key={notification.id}
                          notification={notification}
                      />
                  ))}
              </VStack>
          </Flex>
      </Flex>

  );
};