import Icon from '@/components/Icon';
import { Button, Spinner, Tooltip } from '@chakra-ui/react';
import { IconName } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';

type ActionButtonProps = {
    linkTo?: string;
    isInProcess: boolean;
    tooltipTitle: string;
    handleClick?: () => void;
    isLoading?: boolean;
    icon: IconName;
};

export const ActionButton = ({ linkTo, isInProcess, tooltipTitle, icon, handleClick, isLoading }: ActionButtonProps) => {
    return (
        <Button
            as={linkTo ? Link : Button}
            to={!isInProcess && linkTo ? linkTo : ''}
            variant="playlistSmBtn"
            onClick={handleClick}
            isDisabled={isInProcess}
            bgColor="transparent"
            _hover={{ bgColor: 'transparent' }}
            _active={{ bgColor: 'transparent' }}
            _focus={{ bgColor: 'transparent' }}
            p="0"
            w="auto"
            minW="auto">
            <Tooltip hasArrow label={isInProcess ? '' : tooltipTitle} aria-label={isInProcess ? '' : tooltipTitle}>
                {isLoading ? (
                    <Spinner size="xs" />
                ) : (
                    <span>
                        <Icon highlightOnHover icon={icon} cursor={isInProcess ? 'not-allowed' : 'pointer'} />
                    </span>
                )}
            </Tooltip>
        </Button>
    );
};
