// import './style.css';

import { Box, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from "moment";
import { format } from "date-fns";
import Icon from "@/components/Icon";
import { IconName } from "@fortawesome/pro-solid-svg-icons";


type Props = {
    icon: IconName;
    title: string;
    value: string | number;
    onChange?: (date: Date) => void;
    source?: string;
    loading?: boolean;
    startDate?: string | null;
    isDisabled?: boolean;
};

export const roundToNearest30 = (date: Date) => {
    const minutes = 30;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.ceil((date.getTime() + 60000) / ms) * ms);
};

export const CustomerQuizHeaderCard = ({
    icon,
    title,
    value,
    onChange,
    source,
    loading,
    startDate,
    isDisabled
}: Props) => {
    const [open, setOpen] = useState(false);
    const [dateValue, setDateValue] = useState('MM/DD/YYYY HH:MM');
    const [updateTime, setUpdateTime] = useState(false);

    const handleCalendarOpen = () => {
        setOpen(true);
    };

    const validateEndDateOnChange = (date: Date) => {
        const currentDate = new Date(startDate ? (moment(startDate).isAfter(new Date()) ? startDate : new Date()) : new Date());
        if (currentDate.getTime() < date.getTime()) {
            onChange && onChange(date);
        } else {
            setDateValue(format(roundToNearest30(currentDate), 'MM/dd/yyyy hh:mm a'));
            onChange && onChange(new Date(roundToNearest30(currentDate)));
        }
    };

    useEffect(() => {
        setDateValue(value.toString());
    }, [value]);

    useEffect(() => {
        if (updateTime) {
            setOpen(false);
            dateValue && validateEndDateOnChange(new Date(dateValue));
            setUpdateTime(false);
        }
    }, [updateTime]);

    return (
        <Flex direction="column" gap="5px">
            <Box display="flex" fontSize="14px" fontWeight="500" color="#617180" gap="5px">
                <Flex gap="5px" alignItems="center" w="100%">
                    <Box minW="16px">
                        <Icon icon={icon} />
                    </Box>
                    <Flex gap="2px" alignItems={'center'}>
                        {title}
                        {title === 'Deadline' && (
                            <Tooltip
                                label={'Deadlines cannot be set beyond trial or subscription end date for the user.'}
                                fontWeight="400"
                                fontSize="12px"
                                lineHeight="14.06px"
                                bgColor="#2183ED"
                                color="white"
                                placement="auto-start">
                                <Text mt="3px">
                                    <Icon icon='circle-info' />
                                </Text>
                            </Tooltip>
                        )}
                    </Flex>
                </Flex>
            </Box>
            {title === 'Deadline' ? (
                <Flex border={open ? '1px solid #B1B7BD' : 'none'} borderRadius="4px" px="9px" py="5px">
                    <DatePicker
                        autoComplete="off"
                        dateFormat="MM/dd/yyyy hh:mm a"
                        showTimeSelect={true}
                        value={dateValue}
                        selected={dateValue === 'MM/DD/YYYY HH:MM' ? null : new Date(dateValue)}
                        id="deadline"
                        onChange={(date: Date, event) => {
                            setDateValue(format(date, 'MM/dd/yyyy hh:mm a'));
                            if (event == undefined || source === 'Result') {
                                setUpdateTime(true);
                            } else {
                                setUpdateTime(false);
                            }
                        }}
                        disabledKeyboardNavigation
                        readOnly
                        minDate={moment(startDate).isAfter(new Date()) ? new Date(startDate ?? new Date()) : new Date()}
                        onCalendarOpen={handleCalendarOpen}
                        onCalendarClose={() => alert('handle close called')}
                        open={open}
                        onClickOutside={() => setOpen(false)}
                        popperPlacement="bottom"
                    />
                    {loading ? (
                        <Spinner />
                    ) : (
                        <Tooltip label={'You cannot perform this action as your trial period has expired'} hide={!isDisabled}>
                            <Box minW="16px" h="100%" pt="1px">
                                <Icon
                                    icon='pencil'
                                    _hover={{ cursor: isDisabled ? 'no-drop' : 'pointer' }}
                                    opacity={isDisabled ? '0.5' : '1'}
                                    onClick={() => !isDisabled && setOpen((prevState) => !prevState)}
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Flex>
            ) : (
                <Text fontSize="16px" fontWeight="500" color="#202020" display={'flex'} alignItems="center">
                    {value}
                </Text>
            )}
        </Flex>
    );
};
