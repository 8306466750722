import React from 'react';

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from '@chakra-ui/react';
import { SponsorsAllArticlesList } from '@/features/Sponsors/Components/SponsorAllArticlesList';
import { ArticleList } from '@/features/Sponsors/types';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    sponsorId: number;
    articlesList?: ArticleList[];
};

export const SponsorsAssignArticlesModal = ({ isOpen, onClose, sponsorId, articlesList }: Props) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Assign Articles to Collection</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <SponsorsAllArticlesList sponsorId={sponsorId} articlesList={articlesList} />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
