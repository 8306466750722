import { axios } from '@/lib/axios';
import { Banner } from '../types';

type UpdateBannerData = {
    data: {
        id: number;
        type?: number;
        message?: string;
        published?: number;
    };
};

export const updateBanner = ({ data }: UpdateBannerData): Promise<Banner> => {
    return axios.patch(`/api/admin/banner/update`, data);
};
