import { axios } from '@/lib/axios';
import { useMutation, useQueryClient } from 'react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Translation } from '@/features/Translations/types';
import { useNavigate } from 'react-router-dom';
import { ILanguage } from '@/types';

export type FormattedTranslation_FormData = {
    groupId: number;
    key: string;
    translations: {
        language: ILanguage;
        value: string;
    }[];
};

type AddTranslationData = {
    data: FormattedTranslation_FormData;
};

export const addTranslation = ({ data }: AddTranslationData): Promise<Translation> => {
    return axios.post(`/api/admin/site_translation/add_by_key`, data);
};

export const useAddTranslation = (onSuccessCallback?: () => void) => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    return useMutation({
        onMutate: async (updatingCollection: any) => {
            await queryClient.cancelQueries(['translations', updatingCollection?.id]);
        },
        onError: (_, __, context: any) => {
            addNotification({
                type: 'error',
                title: 'Unable to update translation',
            });
        },
        onSuccess: () => {
            queryClient.refetchQueries(['translation']);
            addNotification({
                type: 'success',
                title: 'translation successfully updated',
            });
            navigate(`/translations/`);
            if (onSuccessCallback) {
                onSuccessCallback();
            }
        },

        mutationFn: addTranslation,
    });
};
