import { API_URL } from "@/config";
import { axios } from '@/lib/axios';
import { PlaylistArticlesResponse,
    GetListBodyStructure,
    PlaylistLabelResponse,
    playlistLabelProp,
    PlaylistSectionSubject,
    addArticleToPlaylistResponse,
    buildArticleParams,
    PlaylistsResponse
} from '../types';
export const getPlaylistArticles = (data: GetListBodyStructure): Promise<PlaylistArticlesResponse> => {
    return axios.post(`${API_URL}/api/free/playlist/article_list`, data);
};

export const deletePlaylistArticle = (id: string): Promise<PlaylistLabelResponse> => {
    return axios.delete(`${API_URL}/api/admin/playlist/${id}`);
};

export const createPlaylistLabel = ({ id, data }: playlistLabelProp): Promise<PlaylistLabelResponse> => {
    return axios.post(`${API_URL}/api/admin/playlist/${id}`, data);
};

export const editPlaylistLabel = ({ id, data }: playlistLabelProp): Promise<PlaylistLabelResponse> => {
    return axios.patch(`${API_URL}/api/admin/playlist/${id}`, data);
};

export const getPlaylistSectionMenu = (): Promise<PlaylistSectionSubject> => {
    return axios.get(`${API_URL}/api/free/playlist/section_menu`);
};

export const addArticleToPlaylists = ({ playlistId, dataCheckbox }: buildArticleParams): Promise<addArticleToPlaylistResponse> => {
    return axios.post(`${API_URL}/api/admin/playlist/add_article/${playlistId}`, dataCheckbox);
};

export const deletePlaylist = ({ playlistId }: { playlistId: number }): Promise<null> => {
    return axios.delete(`${API_URL}/api/admin/playlist/${playlistId}`);
};

export const getPlaylists = (data: GetListBodyStructure): Promise<PlaylistsResponse> => {
    return axios.post(`${API_URL}/api/admin/playlist/my_list`, data);
};
