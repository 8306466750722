import { ILanguages } from '@/types';

export const languages: { key: ILanguages; name: string }[] = [
    { key: 'en', name: 'EN - English' },
    { key: 'cn', name: 'CN - 中文' },
    { key: 'de', name: 'DE - Deutsch' },
    { key: 'es', name: 'ES - Español' },
    { key: 'kr', name: 'KR - 한국어' },
    { key: 'it', name: 'IT - Italiano' },
    { key: 'fr', name: 'FR - Français' },
    { key: 'pt', name: 'PT - Português' },
];
