import { QuizLibrary, QuizLibraryResponse, QuizSharedByMeType } from '@/features/Quiz';
import { SharedQuizItem } from '@/features/Quiz/components/SharedQuizItem';
import React, { useCallback, useEffect, ChangeEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Table,
    Tbody, Td,
    Thead,
    Tr,
    Spinner as SpinnerChakra,
    Button,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { HiX } from 'react-icons/hi';
import * as yup from 'yup';
import { debounce } from '@/utils/helpers';
import { Pagination } from "@/components/Pagination";
import { ThEl } from "@/features/Quiz/components/ArticlesQuiz";
import { Link } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import {
    getSharedQuizzes,
    buildQuizzesParams,
    getSharedUserQuizzes,
} from '@/features/Quiz/api/getQuizzes';
import Icon from "@/components/Icon";

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

type SearchFormData = {
    keyword: string;
};

export const SharedQuiz = () => {
    const [activePage, setActivePage] = useState(1);
    const [activeSearchKeyword, setActiveSearchKeyword] = useState('');
    const [quizzes, setQuizzes] = useState<QuizLibrary[]>([]);

    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const { data: dataForSuperAdmin, isLoading: isLoadingSuperAdminData, isError: isErrorSuperAdminData, error: errorSuperAdminData } = useQuery(
        ['sharedQuizForSuperAdmin', user, activePage, activeSearchKeyword],
        () => isSuperAdmin ? getSharedQuizzes(buildQuizzesParams(activePage, activeSearchKeyword, false, 20)) : null,
        {
            useErrorBoundary: (error: any) => error.response?.status >= 400,
        },
    );

    const { data, isLoading, isError, error } = useQuery(['sharedQuizCSUser', user, activePage, activeSearchKeyword],
        () => !isSuperAdmin ? getSharedUserQuizzes() : null,
        {
            useErrorBoundary: (error: any) => error.response?.status >= 400,
        },
    );

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
        watch,
        setValue,
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        },
    });

    const onSearchKeywordSubmit = useCallback(
        (values: SearchFormData) => {
            setActivePage(1);
            setActiveSearchKeyword(values.keyword ?? '');
        },
        [activePage, activeSearchKeyword]
    );

    const onSearchKeywordChange = debounce(useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            setValue('keyword', e.target.value);
            setActivePage(1);
            setActiveSearchKeyword(e.target.value ?? '');

        },
        [activePage, activeSearchKeyword]
    ), 300);

    const handleReset = useCallback(() => {
        reset();
        setActivePage(1);
        setActiveSearchKeyword('');
    }, []);

    const handlePaginationChange = useCallback(
        (page: number) => {
            setActivePage(page);
        },
        []
    );

    useEffect(() => () => {
        setActivePage(1);
    }, [activeSearchKeyword]);

    useEffect(() => {
        isSuperAdmin && dataForSuperAdmin ? setQuizzes(dataForSuperAdmin.result) : data ? setQuizzes(data) : [];
    }, [data, dataForSuperAdmin, isSuperAdmin]);

    if (isError && error?.message.toLowerCase() === 'access denied') {
        return (
            <Alert status='error'>
                <AlertIcon />
                You have no access to quizzes based on your subscription level
            </Alert>
        );
    }

    return (
        <Flex w="100%" flexDir="column" mb={'10px'}>
            <Flex gap="20px" sx={{ '.btn-half-width': { flex: '1 0 45%' } }} w="100%">
                <Flex justifyContent={'space-between'} alignItems={'center'} mb={3} w="100%">
                    {dataForSuperAdmin &&
                        <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                            <InputGroup minW={'400px'} className="input-left-wrap">
                                <InputLeftElement
                                    className="InputLeft"
                                    pointerEvents={isSubmitting ? 'none' : 'auto'}
                                    onClick={handleSubmit(onSearchKeywordSubmit)}
                                >
                                    {isSubmitting || isLoadingSuperAdminData
                                        ? <SpinnerChakra size="20px" />
                                        : <FaSearch size="20px" />
                                    }
                                </InputLeftElement>
                                <Input
                                    placeholder="Search Admin Quiz by Title"
                                    bg={'#ffffff'}
                                    id={'keyword'}
                                    type={'text'}
                                    borderRadius="1000px"
                                    {...register('keyword')}
                                    onChange={onSearchKeywordChange}
                                />
                                {watch('keyword') && (
                                    <InputRightElement cursor="pointer" onClick={handleReset}>
                                        <HiX />
                                    </InputRightElement>
                                )}
                            </InputGroup>
                        </form>
                    }
                    {isSuperAdmin &&
                        <Button
                            as={Link}
                            to={'/quiz/admin-quiz/create'}
                            variant={'brand'}
                        >
                            <Icon icon='plus' />&nbsp; Create a New Quiz
                        </Button>
                    }
                </Flex>
            </Flex>
            <Flex flexDir={'column'}>
                <Table bgColor={'#ffffff'}>
                    {quizzes?.length > 0 && (
                        <Thead borderBottom="1px solid #e5ebf3">
                            <Tr w="100%">
                                <ThEl align="start" w="45%">
                                    Title
                                </ThEl>
                                <ThEl align="start" w="45%">
                                    Shared with
                                </ThEl>
                                <ThEl w="10%" align="start">
                                    Date Created
                                </ThEl>
                                <ThEl w="15%">Questions</ThEl>
                                <ThEl w="15%">Deadline</ThEl>
                                <ThEl w="15%">Actions</ThEl>
                            </Tr>
                        </Thead>
                    )}
                    <Tbody>
                        {quizzes?.length > 0 ? (
                            quizzes.map((quiz, index) =>
                                <SharedQuizItem key={index} quiz={quiz} isAdminQuiz={quiz?.quizType ? quiz.quizType === 'adminQuiz' : Boolean(isSuperAdmin)}  />)
                        ) : (
                            <>
                                {!isLoading && (
                                    <Tr>
                                        <Td>
                                            <Box>No list items</Box>
                                        </Td>
                                    </Tr>
                                )}
                            </>
                        )}
                    </Tbody>
                </Table>

                {isSuperAdmin && dataForSuperAdmin?.navigation && dataForSuperAdmin.navigation.total_pages > 1 && (
                    <Flex justifyContent={'end'} mt={'20px'}>
                        <Pagination currentPage={activePage} totalPages={dataForSuperAdmin.navigation.total_pages} handlePagination={handlePaginationChange} />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};