import React from 'react';
import { Button, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';

import Icon from '@/components/Icon';
import dayjs from 'dayjs';
import { ArticleList } from '@/features/Sponsors/types';
import { RemoveSponsorArticleModal } from '@/features/Sponsors/Components/RemoveSponsorArticleModal';

interface Props {
    assignedArticle: ArticleList;
    sponsorId: number;
}

export const SponsorAssignedArticlesListItem = ({ assignedArticle, sponsorId }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <RemoveSponsorArticleModal isOpen={isOpen} onClose={onClose} assignedArticle={assignedArticle} sponsorId={sponsorId} />
            <Tr>
                <Td>{assignedArticle.id}</Td>
                <Td>
                    <Text noOfLines={2}>{assignedArticle?.title}</Text>
                </Td>
                <Td>{assignedArticle.createdAt && dayjs(assignedArticle.createdAt).format('MM/DD/YYYY')}</Td>
                <Td>{assignedArticle.isActive && <Icon icon={'check'} />}</Td>

                <Td>
                    <Button colorScheme="red" size="sm" onClick={onOpen}>
                        Remove
                    </Button>
                </Td>
            </Tr>
        </>
    );
};
