import React, { createContext, useState, useContext, useEffect, PropsWithChildren, useCallback } from 'react';

interface LeavePageConfirmationContextType {
    onUpdateLeavingState: (value: boolean) => void;
    shouldPreventLeaving: boolean;
}

const LeavePageConfirmationContext = createContext<LeavePageConfirmationContextType>({
    onUpdateLeavingState: () => { },
    shouldPreventLeaving: false,
});

export const LeavePageConfirmationProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [shouldPreventLeaving, setShouldPreventLeaving] = useState(false);
    const onUpdateLeavingState = useCallback(
        (state: boolean) =>
            setShouldPreventLeaving((prev) => {
                if (state === prev) return prev;
                return state;
            }),
        []
    );

    return (
        <LeavePageConfirmationContext.Provider value={{ shouldPreventLeaving, onUpdateLeavingState }}>
            {children}
        </LeavePageConfirmationContext.Provider>
    );
};

export const useLeavePageConfirmation = (
    shouldPreventLeavingEntry?: boolean,
) => {
    const { onUpdateLeavingState, shouldPreventLeaving } = useContext(LeavePageConfirmationContext);

    useEffect(() => {
        if (typeof shouldPreventLeavingEntry !== 'undefined') {
            onUpdateLeavingState(shouldPreventLeavingEntry);
        }
        return () => onUpdateLeavingState(false);
    }, [shouldPreventLeavingEntry]);


    return { shouldPreventLeaving };
};
