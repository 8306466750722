import { API_URL } from "@/config";
import { axios } from '@/lib/axios';
import { generateLSKey, getLSitem } from '@/utils/cacheLS';
import { ListContextById, SectionsType, ListContext } from '@/types';

export const fetchSearch = (query: string | null, contentType: string, page: number, from: string | null, to: string | null): Promise<any> => {
    return axios.get(`${API_URL}/api/free/search/?query=${query}&content_type=${contentType}&page=${page}&from=${from}&to=${to}`);
};

export const editEditorCollectionRank = (collectionId: number, id: number, data: { rank: number }) => {
    return axios.patch(`${API_URL}/api/admin/collections/${collectionId}/editor/${id}`, data);
};

export const fetchArticleByDomainId = (language: string, domainId: number | string): Promise<any> => {
    return axios.get(`${API_URL}/api/free/article/${language}/${domainId}`);
};

export const fetchSectionListById = (language: string, id: number | string, page?: number, itemsPerPage?: number): Promise<ListContextById> => {
    const searchStr = `${page || itemsPerPage ? '?' : ''}${page ? `page=${page}&` : ''}${itemsPerPage ? `items_per_page=${itemsPerPage}` : ''}`;
    const lsKey = generateLSKey(language, id, 'list-' + searchStr);
    return getLSitem(lsKey) || axios.get(`${API_URL}/api/free/section/list/${language}/${id}${searchStr}`);
};

export const getSectionForSubscription = (): Promise<SectionsType> => {
    return axios.get(`${API_URL}/api/section_subscription`);
};

export const fetchSectionList = (language: string): Promise<ListContext> => {
    const lsKey = generateLSKey(language, 0, 'list');
    return getLSitem(lsKey) || axios.get(`${API_URL}/api/free/section/list/${language}`);
};

export const fetchAdminSectionListById = (id: number | string, page?: number, itemsPerPage?: number): Promise<ListContextById> => {
    const searchStr = `${page || itemsPerPage ? '?' : ''}${page ? `page=${page}&` : ''}${itemsPerPage ? `items_per_page=${itemsPerPage}` : ''}`;
    return axios.get(`/api/admin/section/list/${id}${searchStr}`);
};
