import { modelShaper } from '@/utils/helpers';
import React from 'react';
import { useQuery } from 'react-query';
import { getCustomerUserResults } from '@/features/Quiz/api/getQuizzes';
import { Box, Flex, Text, Button, Spinner } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Icon from '@/components/Icon';

export const CustomerUserResults = () => {
    const { assignId } = useParams();
    const navigate = useNavigate();

    const { data: userResultsData, isLoading: resultsIsLoading } = useQuery(
        ['user-results', assignId],
        () => (assignId ? getCustomerUserResults(assignId) : null),
        {
            useErrorBoundary: (error: any) => error.response?.status >= 400,
        }
    );

    const shapedData = modelShaper(userResultsData?.questions || []);

    if (resultsIsLoading) return <Spinner size="lg" />;

    return (
        <>
            <Flex w="100%" flexDir="column" pb={'30px'}>
                <Box
                    m={'20px 5px 0 5px'}
                    bg={'#fff'}
                    w={'99.5%'}
                    h={'auto'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    borderRadius={'6px'}>
                    <Flex
                        p={'25px 15px'}
                        bg={'#fff'}
                        color={'#000'}
                        w={'100%'}
                        h={'auto'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        borderTopLeftRadius={'6px'}
                        borderTopRightRadius={'6px'}
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight="15px">
                        <Flex alignItems={'center'} fontSize={'18px'}>
                            <Button variant="back" onClick={() => navigate(-1)} leftIcon={<Icon icon="chevron-left" />} />
                            <Box>Quiz Results - {userResultsData?.assignedUser}</Box>
                        </Flex>
                    </Flex>
                    <Box bg={'#fff'} color={'#000'}>
                        <Box fontSize="14px" lineHeight="15px" fontWeight="500" borderBottom={'1px solid #e5ebf3'} p={'15px'}>
                            {userResultsData?.title ?? ''}
                        </Box>
                        <Flex p={'15px'}>
                            <Box w={'25%'} borderRight={'1px solid #e5ebf3'} pr={'15px'}>
                                <Box>Date Completed</Box>
                                <Box fontSize={'16px'} fontWeight={'bold'}>
                                    {userResultsData?.dateSubmitted ? format(new Date(userResultsData.dateSubmitted), 'MMMM do, yyyy') : '-'}
                                </Box>
                            </Box>
                            <Box w={'15%'} borderRight={'1px solid'} borderRightColor="#e5ebf3" p={'0 15px'}>
                                <Box>Score</Box>
                                <Box
                                    fontSize={'16px'}
                                    color={userResultsData && userResultsData?.score < 60 ? '#d90000' : '#45d4a1'}
                                    fontWeight={'bold'}>
                                    {userResultsData && userResultsData?.dateSubmitted && userResultsData?.score !== null
                                        ? `${userResultsData?.score}%`
                                        : '-'}
                                </Box>
                            </Box>
                            <Box w={'60%'} p={'0 15px'}>
                                <Box>Correct Answers</Box>
                                <Box fontSize={'16px'} fontWeight={'bold'}>
                                    {userResultsData?.rightAnswers}
                                </Box>
                            </Box>
                        </Flex>
                    </Box>

                    {shapedData.map((question, key) => (
                        <Box
                            key={key}
                            p={'21px 35px 19px 16px'}
                            borderRadius={'6px'}
                            border={'1px solid'}
                            borderColor="#e5ebf3"
                            boxShadow={'0px 3px 10px 2px rgb(0 0 0 / 12%)'}
                            m={'15px 20px'}
                            bg={'white'}
                            fontWeight={'bold'}>
                            <Box color={'#F7B500'} fontSize={'11px'} pb={'7px'} fontWeight="700" lineHeight="13px" textTransform={'uppercase'}>
                                Question {question.questionOrder}
                            </Box>
                            <Box
                                fontSize={'20px'}
                                fontWeight="500"
                                lineHeight="23px"
                                color={'#202020'}
                                dangerouslySetInnerHTML={{ __html: question?.title }}
                            />
                            {userResultsData?.isGraded ? (
                                <Box color={question?.isCorrectAnswer ? '#3AD5A0' : '#F93F5E'} pt={'15px'} fontSize={'18px'}>
                                    <Flex alignItems={'stretch'}>
                                        <Icon
                                            color={question?.isCorrectAnswer ? '#3AD5A0' : '#F93F5E'}
                                            icon={question?.isCorrectAnswer ? 'circle-check' : 'circle-xmark'}
                                        />
                                        <Box pl={'15px'}>{question?.isCorrectAnswer ? 'Correct' : 'Incorrect'}</Box>
                                    </Flex>
                                </Box>
                            ) : (
                                <>
                                    {!question?.isCorrectAnswer && (
                                        <Box color="#F93F5E" pt={'15px'} fontSize={'18px'}>
                                            <Flex alignItems={'center'}>
                                                <Icon color="#F93F5E" icon="circle-xmark" />
                                                <Flex
                                                    ml={'10px'}
                                                    justifyContent="space-between"
                                                    w="100%"
                                                    px="13px"
                                                    borderRadius="6px"
                                                    alignItems="center">
                                                    <Text
                                                        fontSize="14px"
                                                        fontWeight="400"
                                                        lineHeight="19px"
                                                        py="10.5px"
                                                        color="#202020"
                                                        display="flex"
                                                        alignItems={question?.userAnswer?.includes('<img') ? 'center' : 'baseline'}>
                                                        {question?.userIndexAnswer}
                                                        {'. '}
                                                        <Box
                                                            dangerouslySetInnerHTML={{
                                                                __html: question?.userAnswer,
                                                            }}
                                                        />
                                                    </Text>
                                                    <Text fontSize="14px" fontWeight="500" lineHeight="16px" color="#E02020">
                                                        Your Answer
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Box>
                                    )}
                                    <Box color="#3AD5A0" pt={'15px'} fontSize="18px">
                                        <Flex alignItems={'center'}>
                                            <Icon icon="circle-check" color="#3AD5A0" />
                                            <Flex
                                                ml={'10px'}
                                                justifyContent="space-between"
                                                w="100%"
                                                bg="#ace8da80"
                                                px="13px"
                                                borderRadius="6px"
                                                alignItems={'center'}>
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="400"
                                                    lineHeight="19px"
                                                    py="10.5px"
                                                    color="#202020"
                                                    display="flex"
                                                    alignItems={question?.correctAnswer?.includes('<img') ? 'center' : 'baseline'}>
                                                    {question?.correctIndexAnswer}
                                                    {'. '}

                                                    <Box
                                                        mixBlendMode="multiply"
                                                        dangerouslySetInnerHTML={{
                                                            __html: question?.correctAnswer,
                                                        }}
                                                    />
                                                </Text>
                                                <Text fontSize="14px" fontWeight="500" lineHeight="16px" color="#0A9B8D">
                                                    Correct Answer
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Box>
                                </>
                            )}
                        </Box>
                    ))}
                </Box>
            </Flex>
        </>
    );
};
