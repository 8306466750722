import { useMutation, useQueryClient } from 'react-query';

import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { CollectionsDetail } from '@/features/MethodsCollections';

export const removeSponsorArticle = ({ sponsorId, params }: {
    sponsorId: number;
    params: { articles: number[] }
}): Promise<CollectionsDetail> => {
    return axios.post(`api/admin/sponsor/${sponsorId}/article/remove`, params);
};

export const useRemoveSponsorArticle = (closeModalFunction: () => void) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();

    return useMutation({
        onError: (_, __, context: any) => {
            addNotification({
                type: 'error',
                title: 'Unable to remove article',
            });
            closeModalFunction();
        },
        onSuccess: (res, variables) => {
            queryClient.refetchQueries(['sponsor']);
            closeModalFunction();
            addNotification({
                type: 'info',
                title: 'Article successfully removed',
            });
        },
        mutationFn: removeSponsorArticle,
    });
};
