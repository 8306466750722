import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

type Auth = {
    tabIndexQuiz: null | number;
    isHasUnsavedChanges: boolean;
};

const initialState: Auth = {
    tabIndexQuiz: null,
    isHasUnsavedChanges: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTabIndexQuiz: (state: Auth, { payload: tab }: PayloadAction<null | number>) => {
            state.tabIndexQuiz = tab;
        },
        setIsHasUnsavedChanges: (state: Auth, { payload: isHasUnsavedChanges }: PayloadAction<boolean>) => {
            state.isHasUnsavedChanges = isHasUnsavedChanges;
        },
    },
});

export const { setTabIndexQuiz, setIsHasUnsavedChanges } = authSlice.actions;

export default authSlice.reducer;

const selectState = (state: RootState) => state.auth;

export const getTabIndexQuiz = createSelector([selectState], (state) => state.tabIndexQuiz);
export const getIsHasUnsavedChanges = createSelector([selectState], (state) => state.isHasUnsavedChanges);
