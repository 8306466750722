import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon as ChakraIcon, IconProps } from "@chakra-ui/react"
import { IconName } from '@fortawesome/fontawesome-svg-core'
import * as baseIcons from '@fortawesome/pro-regular-svg-icons';

interface Props {
    icon: IconName;
    highlightOnHover?: boolean;
    forDelete?: boolean;
    color?: string;
    fontSize?: string;
    style?: CSSProperties;
}

const Icon = ({ icon, highlightOnHover, forDelete, color, fontSize, style, ...rest }: Props & IconProps) => {
    const output = icon.replace(/-(.)/g, (_, letter) => letter.toUpperCase());
    const iconName = `fa${output.charAt(0).toUpperCase()}${output.slice(1)}`;
    return (
        <ChakraIcon
            as={FontAwesomeIcon}
            height={'100%'}
            cursor="pointer"
            color={color}
            fontSize={fontSize}
            style={style}
            _hover={highlightOnHover ? {
                color: forDelete ? 'red.500' : 'blue.500',
            } : undefined}
            {...rest}
            icon={(baseIcons as any)[iconName] ?? baseIcons.faInfoCircle}
        />
    );
};

export default Icon;
