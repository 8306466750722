import { parseText } from '@/utils/helpers';
import React, { MouseEvent, ChangeEvent, FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import { Quiz, QuizFormData } from '../../types';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Collapse,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Textarea,
} from '@chakra-ui/react';
import { QuizCheckboxIcon } from '../QuizCheckboxIcon';
import FileUpload from '@/components/Form/FileUpload';
import { MoveComponentModal } from '../MoveComponentModal';
import { ViewQuizVideoContainer } from '../ViewQuizVideoContainer';
import TextEditor from '@/components/TextEditor/TextEditor';
import Icon from '@/components/Icon';

type Props = {
    indexActivity: number;
    indexItem: number;
    templateQuiz?: Quiz | null;
};

export const RightQuizItem: FC<Props> = ({ indexActivity, indexItem, children }) => {
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { pathname } = useLocation();

    const isQuizTemplate = !pathname.match(/library|admin-quiz/gi);

    const {
        watch,
        formState: { errors },
        register,
        control,
        setValue,
        setError,
    } = useFormContext<QuizFormData>();

    const { replace: replaceItem } = useFieldArray<QuizFormData, `activities.${number}.items`>({
        control,
        name: `activities.${indexActivity}.items`,
    });
    const { remove, append } = useFieldArray<QuizFormData, `activities.${number}.items.${number}.textOptions`>({
        control,
        name: `activities.${indexActivity}.items.${indexItem}.textOptions`,
    });

    const activities = watch(`activities`);
    const showCaption = watch(`showCaption`);
    const activityItems = watch(`activities.${indexActivity}.items`);
    const activityItem = watch(`activities.${indexActivity}.items.${indexItem}`);
    const errorActivityItems = errors.activities && errors.activities[indexActivity] && errors.activities?.[indexActivity]?.items;

    const handleMoveOpen = useCallback((e: MouseEvent) => {
        e.preventDefault();

        setOpen(true);
    }, []);

    const handleMoveClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleMoveSubmit = useCallback(
        async (activeIndex: number) => {
            setLoading(true);

            const listItems = activityItems
                .filter((_, key) => key !== indexItem)
                .map((item, key) => {
                    return { ...item, sortIndex: key + 1 };
                });

            const newActivities = activities.map((data, key) => {
                if (key === indexActivity) {
                    return {
                        ...data,
                        items: listItems,
                    };
                }

                if (data.sortIndex === activeIndex) {
                    const moveItems = [...data.items, { ...activityItem, originId: null, id: null }].map((item, key) => {
                        return { ...item, sortIndex: key + 1 };
                    });

                    return {
                        ...data,
                        items: moveItems,
                    };
                }

                return data;
            });

            await setValue('activities', newActivities);

            setLoading(false);
        },
        [activityItems, indexItem, setValue, activities, activityItem]
    );

    const handleRemoveTextOption = useCallback(
        (id: number, index: number) => (e: MouseEvent) => {
            e.preventDefault();

            if (activityItem.textOptions.length > 1) {
                if (activityItem.rightAnswer.includes(id)) {
                    if (activityItem.rightAnswer.length > 1) {
                        setValue(
                            `activities.${indexActivity}.items.${indexItem}.rightAnswer`,
                            activityItem.rightAnswer.filter((customId) => customId !== id)
                        );
                    }

                    if (activityItem.rightAnswer.length === 1) {
                        setValue(`activities.${indexActivity}.items.${indexItem}.rightAnswer`, [
                            activityItem.textOptions.filter((item) => item.originId !== id)[0].originId,
                        ]);
                    }
                }

                remove(index);
            }
        },
        [remove, activityItem.textOptions.length, activityItem.rightAnswer, setValue, indexActivity, indexItem]
    );

    const handleAddTextOptionClick = useCallback(
        (originId: number) => (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            if (activityItem.isMultiple) {
                if (activityItem.rightAnswer.includes(originId)) {
                    activityItem.rightAnswer.length > 1 &&
                        setValue(
                            `activities.${indexActivity}.items.${indexItem}.rightAnswer`,
                            activityItem.rightAnswer.filter((id) => id !== originId)
                        );
                } else {
                    setValue(`activities.${indexActivity}.items.${indexItem}.rightAnswer`, [...activityItem.rightAnswer, originId]);
                }
            } else {
                !activityItem.rightAnswer.includes(originId) && setValue(`activities.${indexActivity}.items.${indexItem}.rightAnswer`, [originId]);
            }
        },
        [setValue, indexActivity, indexItem, activityItem.rightAnswer, activityItem.isMultiple]
    );

    const handleMultipleAnswersClick = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            if (!e.target.checked && activityItem.rightAnswer.length > 1) {
                setValue(`activities.${indexActivity}.items.${indexItem}.rightAnswer`, [activityItem.textOptions[0].originId]);
            }
        },
        [setValue, indexActivity, indexItem, activityItem.rightAnswer, activityItem.textOptions]
    );

    const handleToggle = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();

            setValue(`activities.${indexActivity}.items.${indexItem}.isSelect`, !activityItem.isSelect);
        },
        [setValue, indexActivity, indexItem, activityItem.isSelect]
    );

    const handleAddQuestionClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();

            append({
                originId: activityItem.textOptions.length + 1,
                text: '',
            });
        },
        [append, activityItem.textOptions.length]
    );

    const handleRemoveItemClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();

            const list = activityItems
                .filter((_, key) => key !== indexItem)
                .map((item, key) => {
                    if (key === 0) {
                        return {
                            ...item,
                            sortIndex: key + 1,
                        };
                    }
                    return { ...item, sortIndex: key + 1 };
                });

            replaceItem(list);
        },
        [replaceItem, indexItem, activityItems]
    );

    const handleChangeImage = useCallback(
        (url: string) => {
            setValue(`activities.${indexActivity}.items.${indexItem}.image`, url);
        },
        [setValue, indexActivity, indexItem]
    );

    const handleErrorImage = useCallback(
        (err: string) => {
            setError(`activities.${indexActivity}.items.${indexItem}.image`, { type: 'server', message: err });
        },
        [setError, indexActivity, indexItem]
    );

    return (
        <Box px={'18px'}>
            <Box
                w={'100%'}
                margin={'0 auto'}
                bg={'white'}
                p={'18px'}
                border={'1px solid #EDEDED'}
                boxShadow={'0px 3px 10px 2px rgb(0 0 0 / 12%)'}
                pos={'relative'}
                borderRadius={'6px'}
                mx={0}
                mb={'18px'}>
                <Flex pb="5px" justify="space-between">
                    <Flex justify="space-between">
                        {children}

                        <Box>
                            <Box
                                fontWeight={'bold'}
                                color={activityItem.type === 2 ? '#F7B500' : '#6236FF'}
                                textTransform={'uppercase'}
                                fontSize={'12px'}>
                                {activityItem.type === 2 ? `Question ${activityItem?.questionOrder || ''}` : 'Video'}
                            </Box>
                            {activityItem.type === 1 && (
                                <Box
                                    fontWeight="500"
                                    fontSize="16px"
                                    lineHeight="24px"
                                    pb="9px"
                                    dangerouslySetInnerHTML={{ __html: activityItem.title }}
                                />
                            )}
                        </Box>

                        {activities.length > 1 && (
                            <>
                                <Button
                                    color={'primary.blue'}
                                    bg={'#d8eaff'}
                                    fontSize={'12px'}
                                    borderRadius="3px"
                                    lineHeight={'12px'}
                                    padding={'4px'}
                                    cursor="pointer"
                                    height={'20px'}
                                    ml={'12px'}
                                    fontWeight={'400'}
                                    _hover={{}}
                                    _focus={{ outline: 0 }}
                                    _active={{}}
                                    onClick={handleMoveOpen}>
                                    Move
                                </Button>
                                <MoveComponentModal
                                    sections={activities.filter((_, key) => key !== indexActivity)}
                                    isOpen={isOpen}
                                    isLoading={isLoading}
                                    onClose={handleMoveClose}
                                    onSubmit={handleMoveSubmit}
                                />
                            </>
                        )}
                    </Flex>

                    <Flex>
                        {activityItem.type === 1 && (
                            <Button
                                color={'red'}
                                bg={'#FBE8E8'}
                                fontSize={'12px'}
                                borderRadius="3px"
                                lineHeight={'12px'}
                                padding={'4px'}
                                cursor="pointer"
                                height={'20px'}
                                mx={'12px'}
                                fontWeight={'400'}
                                onClick={handleRemoveItemClick}
                                _hover={{}}
                                _focus={{ outline: 0 }}
                                _active={{}}>
                                Remove
                            </Button>
                        )}

                        <Box color={'primary.blue'} cursor="pointer" onClick={handleToggle}>
                            <Icon icon={activityItem.isSelect ? 'circle-chevron-up' : 'circle-chevron-down'} fontSize="21px" />
                        </Box>
                    </Flex>
                </Flex>

                {!activityItem.isSelect && activityItem.type === 2 && (
                    <Box borderBottom={'1px solid #e1e7ef'} fontWeight="500" fontSize="16px" lineHeight="24px" pb="9px">
                        {parseText(activityItem.title)}
                    </Box>
                )}

                {activityItem.type === 1 && <Box borderBottom={'1px solid #e1e7ef'} w={'100%'} />}

                <Collapse startingHeight={0} in={activityItem.isSelect} animateOpacity>
                    {activityItem.type === 1 ? (
                        <>
                            {activityItem.article?.videoData ||
                            activityItem.article?.videoData.videoUrl ||
                            activityItem.article?.videoData.videoHash ? (
                                <Box w={'100%'} minH="min-content" position={'relative'} bgColor={'#F6F7FB'}>
                                    <ViewQuizVideoContainer videoData={activityItem.article.videoData} showCaption={showCaption} />
                                </Box>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <Flex w="100%" flexDirection={'column'}>
                                <FormControl isInvalid={errors.hasOwnProperty(`activities.${indexActivity}.items.${indexItem}.title`)}>
                                    {isQuizTemplate ? (
                                        <Controller
                                            control={control}
                                            name={`activities.${indexActivity}.items.${indexItem}.title`}
                                            defaultValue={activityItem.title}
                                            render={({ field: { value, onChange } }) => (
                                                <TextEditor value={activityItem.title} onChange={onChange} placeholder="Question Text" />
                                            )}
                                        />
                                    ) : (
                                        <Textarea
                                            {...register(`activities.${indexActivity}.items.${indexItem}.title`, { required: true })}
                                            placeholder="Question Text"
                                            p="6px"
                                            value={parseText(activityItem.title) ?? activityItem.title}
                                        />
                                    )}
                                    <FormErrorMessage>{errorActivityItems && errorActivityItems?.[indexItem]?.title?.message}</FormErrorMessage>
                                </FormControl>
                                <Box borderBottom={'1px solid #e1e7ef'} py={'10px'}>
                                    Be sure to make your question clear and understandable
                                </Box>
                            </Flex>

                            <Heading as={'h3'} color={'primary.black'} fontSize={'17px'} py={'10px'} fontWeight={'400'}>
                                Upload Image
                            </Heading>

                            <Flex w="100%" flexDirection={'row'} mb="16px">
                                <FileUpload
                                    value={activityItem.image}
                                    invalid={errors.hasOwnProperty(`activities.${indexActivity}.items.${indexItem}.image`)}
                                    isRequired={false}
                                    onChangeImage={handleChangeImage}
                                    errImageFn={handleErrorImage}
                                />
                            </Flex>

                            <Flex
                                w="100%"
                                flexDirection={'column'}
                                mb="16px"
                                sx={{
                                    '.chakra-checkbox__control': {
                                        borderRadius: '100%',
                                    },
                                }}>
                                <FormControl
                                    w="auto"
                                    minW="35px"
                                    display={'flex'}
                                    isInvalid={errors.hasOwnProperty(`activities.${indexActivity}.items.${indexItem}.isMultiple`)}>
                                    <Checkbox
                                        id={`activities.${indexActivity}.items.${indexItem}.isMultiple`}
                                        {...register(`activities.${indexActivity}.items.${indexItem}.isMultiple`, {
                                            onChange: handleMultipleAnswersClick,
                                        })}
                                        isChecked={activityItem.isMultiple}
                                        icon={<QuizCheckboxIcon />}
                                        _hover={{}}
                                        _focus={{ outline: 0 }}
                                        _active={{}}
                                    />
                                    <FormLabel
                                        htmlFor={`activities.${indexActivity}.items.${indexItem}.isMultiple`}
                                        fontSize="14px"
                                        fontWeight="500"
                                        color="primary.greyText"
                                        lineHeight="36px"
                                        m="0"
                                        ml="10px">
                                        Add multiple answers
                                    </FormLabel>
                                </FormControl>
                            </Flex>

                            <Flex w="100%" flexDirection={'column'} mb="16px">
                                <CheckboxGroup colorScheme="green" value={activityItem.rightAnswer}>
                                    {activityItem.textOptions.map((textOption, index) => {
                                        const errorsTextOption = errorActivityItems && errorActivityItems?.[indexItem]?.textOptions;

                                        return (
                                            <Flex
                                                key={index}
                                                justify="space-between"
                                                alignItems="center"
                                                mb={'10px'}
                                                sx={{
                                                    '.chakra-checkbox__control': {
                                                        borderRadius: '100%',
                                                    },
                                                }}>
                                                <FormControl
                                                    w="auto"
                                                    minW="35px"
                                                    isInvalid={errors.hasOwnProperty(`activities.${indexActivity}.items.${indexItem}.rightAnswer`)}>
                                                    <Checkbox
                                                        icon={<QuizCheckboxIcon />}
                                                        value={textOption.originId}
                                                        onChange={handleAddTextOptionClick(textOption.originId)}
                                                        _hover={{}}
                                                        _focus={{ outline: 0 }}
                                                        _active={{}}
                                                    />
                                                </FormControl>

                                                <FormControl
                                                    w={'100%'}
                                                    isInvalid={errors.hasOwnProperty(
                                                        `activities.${indexActivity}.items.${indexItem}.textOptions.${index}.text`
                                                    )}>
                                                    {isQuizTemplate ? (
                                                        <Controller
                                                            control={control}
                                                            name={`activities.${indexActivity}.items.${indexItem}.textOptions.${index}.text`}
                                                            defaultValue={activityItem.title}
                                                            render={({ field: { value, onChange } }) => (
                                                                <TextEditor
                                                                    value={textOption.text}
                                                                    onChange={onChange}
                                                                    placeholder={`Option ${index + 1}`}
                                                                    withImages
                                                                />
                                                            )}
                                                        />
                                                    ) : (
                                                        <Input
                                                            {...register(`activities.${indexActivity}.items.${indexItem}.textOptions.${index}.text`, {
                                                                required: true,
                                                            })}
                                                            placeholder={`Option ${index + 1}`}
                                                            value={parseText(textOption.text) ?? textOption.text}
                                                            p="6px"
                                                        />
                                                    )}
                                                    <FormErrorMessage>
                                                        {errorsTextOption && errorsTextOption?.[index]?.text?.message}
                                                    </FormErrorMessage>
                                                </FormControl>

                                                {activityItem.textOptions.length > 1 && (
                                                    <Button
                                                        h="auto"
                                                        display="block"
                                                        color="red"
                                                        p="0"
                                                        fontSize="12px"
                                                        lineHeight="12px"
                                                        fontWeight="normal"
                                                        bg="white"
                                                        _hover={{}}
                                                        _focus={{ outline: 0 }}
                                                        _active={{}}
                                                        onClick={handleRemoveTextOption(textOption.originId, index)}>
                                                        <Icon icon="trash-can" fontSize="20px" />
                                                    </Button>
                                                )}
                                            </Flex>
                                        );
                                    })}
                                </CheckboxGroup>
                            </Flex>
                            <Flex w="100%" flexDirection={'column'}>
                                <Button
                                    variant={'dashedOutline'}
                                    borderColor="#EAE7E7"
                                    color="#51B3FD"
                                    onClick={handleAddQuestionClick}
                                    borderRadius="100px"
                                    fontSize="16px"
                                    fontWeight="bold"
                                    size="md"
                                    cursor="pointer"
                                    w="100%"
                                    textAlign="center"
                                    _hover={{}}
                                    _focus={{ outline: 0 }}
                                    _active={{}}>
                                    Add Option
                                </Button>
                                <Button
                                    bg="white"
                                    color="#F93F5E"
                                    onClick={handleRemoveItemClick}
                                    fontSize="16px"
                                    fontWeight="bold"
                                    size="md"
                                    cursor="pointer"
                                    w="100%"
                                    textAlign="center"
                                    my="16px"
                                    _hover={{}}
                                    _focus={{ outline: 0 }}
                                    _active={{}}>
                                    Remove Question
                                </Button>
                            </Flex>
                        </>
                    )}
                </Collapse>
            </Box>
        </Box>
    );
};
