// @ts-nocheck
import * as React from 'react';
import ErrorBoundary from '@/components/ErrorBoundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spinner, ChakraProvider } from '@chakra-ui/react';

import { Provider } from 'react-redux';
import store from '../redux/store';
import theme from '@/theme';
import { AuthProvider } from '@/context/AuthContext';
import { Notifications } from '@/components/Notifications';
import { LeavePageConfirmationProvider } from '@/hooks/useLeavePageConfirmation';

type AppProviderProps = {
    children: React.ReactNode;
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

export const AppProvider = ({ children }: AppProviderProps) => {
    const baseName = window.__CONFIG__.REACT_APP_SERVE_ROUTE_PREFIX || "/admin";

    return (
        <React.Suspense
            fallback={
                <div className="flex items-center justify-center w-screen h-screen">
                    <Spinner size="lg" />
                </div>
            }
        >
            <Provider store={store}>
                <ChakraProvider theme={theme}>
                    <ErrorBoundary>
                        <QueryClientProvider client={queryClient}>
                            <Notifications />
                            <AuthProvider>
                                <Router basename={baseName}>
                                    <LeavePageConfirmationProvider>
                                        {children}
                                    </LeavePageConfirmationProvider>
                                </Router>
                            </AuthProvider>
                        </QueryClientProvider>
                    </ErrorBoundary>
                </ChakraProvider>
            </Provider>
        </React.Suspense>
    );
};
