import { API_URL } from "@/config";
import { ClassDocument, ClassPatchDocumentData, ClassUploadDocumentData } from '@/features/Classes/types';
import { axios } from "@/lib/axios";
import { AxiosResponse } from 'axios';

export const getDocumentsList = (classId: number): Promise<ClassDocument[]> => {
    return axios.get(`${API_URL}/api/admin/class/${classId}/document`);
};

export const deleteDocumentById = (classId: number, documentId: number, isSoft?: 1 | 0): Promise<void> => {
    return axios.delete(`${API_URL}/api/admin/class/${classId}/document/${documentId}?isSoft=${isSoft ?? 1}`);
};

export const restoreDocumentById = (classId: number, documentId: number): Promise<ClassDocument> => {
    return axios.post(`${API_URL}/api/admin/class/${classId}/document/${documentId}`);
};

export const uploadDocument = (
    classId: number,
    data: ClassUploadDocumentData | FormData,
    ProgressHandler: (e: any) => void,
    signal?: AbortSignal
): Promise<ClassDocument | AxiosResponse | void> => {
    return axios
    .post(`${API_URL}/api/admin/class/${classId}/document`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...(signal ? { signal: signal } : {}),
        onUploadProgress: (e) => ProgressHandler(e),
    })
    .then((res) => res)
    .catch((err) => {
        throw err;
    });
};

export const updateDocument = (classId: number, documentId: number, data: ClassPatchDocumentData): Promise<ClassDocument> => {
    return axios.patch(`${API_URL}/api/admin/class/${classId}/document/${documentId}`, data);
};

export const getDocumentTokenForProfessor = (classId: number, documentId: number): Promise<{ token: string }> => {
    return axios.get(`${API_URL}/api/admin/class/${classId}/document/${documentId}/token`);
};

export const downloadDocumentByIdForProfessor = (classId: number, documentId: number): Promise<any> => {
    return axios.get(`${API_URL}/api/admin/class/${classId}/document/${documentId}/file`, { responseType: 'blob' }).then((response) => {
        return response;
    });
};