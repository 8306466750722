import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Translations } from '../Components/Translations/Translations';
import { AddTranslation } from '../Components/AddTranslation/AddTranslation';
import { TranslationGroups } from '../Components/TranslationGroups/TranslationGroups';
import { AddTranslationGroup } from '../Components/TranslationGroups/Components/AddTranslationGroup/AddTranslationGroup';
import { UpdateTranslationGroup } from '../Components/TranslationGroups/Components/UpdateTranslationGroup/UpdateTranslationGroup';

export const TranslationsRoutes = () => {
    const { adminTranslations } = useFlags();

    if (!adminTranslations) {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<Translations />} />
            <Route path="/add" element={<AddTranslation />} />
            <Route path="/groups" element={<TranslationGroups />} />
            <Route path="/groups/add" element={<AddTranslationGroup />} />
            <Route path="/groups/edit/:groupId" element={<UpdateTranslationGroup />} />
        </Routes>
    );
};
