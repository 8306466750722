import { axios } from '@/lib/axios';
import { useMutation, useQueryClient } from 'react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Translation } from '@/features/Translations/types';
import { useNavigate } from 'react-router-dom';

type UpdateTranslationGroupData = {
    data: { id: number; key: string; title: string; isActive: boolean };
};

export const updateTranslationGroup = ({ data }: UpdateTranslationGroupData): Promise<Translation> => {
    const { id: groupId, ...rest } = data;

    return axios.patch(`/api/admin/site_translation/group/edit/${groupId}`, rest);
};

export const useUpdateTranslationGroup = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    return useMutation({
        onMutate: async (updatingCollection: any) => {
            await queryClient.cancelQueries(['group']);
        },
        onError: (_, __, context: any) => {
            addNotification({
                type: 'error',
                title: 'Unable to update translation group',
            });
        },
        onSuccess: (res) => {
            queryClient.refetchQueries(['group']);
            addNotification({
                type: 'success',
                title: 'Group successfully updated',
            });
            navigate(`/translations/groups`);
        },

        mutationFn: updateTranslationGroup,
    });
};
