import React, { Fragment } from 'react';
import { Flex, Heading, Text, Stack } from '@chakra-ui/react';
import { formatString } from '@/utils/helpers';
import { Link } from 'react-router-dom';
import {SearchArticles} from "@/types";

type Props = {
    authorsObject?: SearchArticles['authors'];
};

export const AuthorsInfo = ({ authorsObject }: Props) => {
    const authors = authorsObject?.authors ?? [];
    const institutions = authorsObject?.institutions ?? [];

    return (
        <Stack color="white" p="20px" pt="30px" bg="#2C3035" borderRadius="10px" shadow="dark-lg">
            {authors.length > 0 && (
                <>
                    <Heading fontSize={'12px'} color={'gray'}>
                        Authors
                    </Heading>
                    <Flex>
                        <Text>
                            {authors.map(
                                (author, i) => (
                                    <Link
                                        to={`/author/${author.name}`.replace(/\s/g, '_')}
                                        rel='author'
                                        key={i}
                                    >
                                        <Text
                                            as={'span'}
                                            fontSize="12px"
                                            lineHeight="21px"
                                            letterSpacing="0.27px"
                                        >
                                            {formatString(`${author.name}`)}
                                        </Text>
                                        {author?.institutionIndexes !== null && (
                                            <span>
                                                {author?.institutionIndexes.map((institution, index) => (
                                                    <sup key={index}>
                                                        {institution}
                                                        {index < author.institutionIndexes.length - 1 && ', '}
                                                    </sup>
                                                ))}
                                            </span>
                                        )}
                                        {i < authors.length - 1 && ', '}
                                    </Link>
                                )
                            )}
                        </Text>
                    </Flex>
                </>
            )}
            {institutions.length > 0 && (
                <>
                    <Heading fontSize={'12px'} color={'gray'}>
                        Institution
                    </Heading>
                    <Flex>
                        <Text>
                            {institutions.map((institution, i, arr) => (
                                <Fragment key={i}>
                                    <sup>{i + 1}</sup>
                                    <Text
                                        as={'span'}
                                        fontSize="12px"
                                        lineHeight="21px"
                                        letterSpacing="0.27px"
                                    >
                                        {`${institution.departmentTitle} `}
                                        <Text as={'span'} fontWeight={'bold'}>
                                            {institution.institutionTitle}
                                        </Text>
                                    </Text>
                                    {i < arr.length - 1 && ', '}
                                </Fragment>
                            ))}
                        </Text>
                    </Flex>
                </>
            )}
        </Stack>
    );
}
