import React from 'react';
import { Button, Td, Text, Tr } from '@chakra-ui/react';

import Icon from '@/components/Icon';
import { ArticleList } from '../../types';
import { useAddSponsorArticle } from '@/features/Sponsors/api/addSponsorArticle';

interface Props {
    articleDomainId: number;
    articleId: number;
    articleType: string;
    title: string;
    isSponsored: boolean;
    articlesList?: ArticleList[];
    sponsorId: number;
}

export const SponsorAllArticlesListItem = ({ articleDomainId, articleId, articleType, title, isSponsored, articlesList, sponsorId }: Props) => {
    const assignSponsorArticleMutation = useAddSponsorArticle(sponsorId);

    const handleAssign = async () => {
        const params = {
            articles: [articleDomainId],
        };
        assignSponsorArticleMutation.mutate({ sponsorId, params });
    };

    const isAssigned = articlesList?.some((el) => el.id === articleDomainId);

    return (
        <>
            <Tr>
                <Td>{articleDomainId}</Td>
                <Td>
                    <Text noOfLines={2} dangerouslySetInnerHTML={{ __html: title }} />
                </Td>
                <Td textAlign={'center'}>{isSponsored && <Icon icon={'check'} />}</Td>
                <Td>{articleType}</Td>
                <Td>
                    {isAssigned ? (
                        <Button
                            colorScheme="brand"
                            color="currentColor"
                            border="1px solid lightgrey"
                            size="sm"
                            leftIcon={<Icon icon={'check'} />}
                            disabled>
                            Assigned
                        </Button>
                    ) : (
                        <Button
                            colorScheme="brand"
                            color="currentColor"
                            border="1px solid lightgrey"
                            w="107px"
                            size="sm"
                            onClick={handleAssign}
                            isLoading={assignSponsorArticleMutation.isLoading}>
                            Assign
                        </Button>
                    )}
                </Td>
            </Tr>
        </>
    );
};
