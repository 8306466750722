import React, { FC } from 'react';
import { Modal, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { UpdateTranslationDetailFormData, UpdateTranslationForm } from './Components/UpdateTranslationForm';
import { useUpdateTranslation } from '../../../../api/updateTranslation';
import { SubmitHandler } from 'react-hook-form';
import { useAddTranslation } from '@/features/Translations/api/addTranslation';

type Props = Omit<UpdateTranslationDetailFormData, 'value' | 'key'> & {
    isOpen: boolean;
    onClose: () => void;
    name: string;
    translationKey: string;
    translationEnglishValue: string;
    previousValue: string;
    activePage: number;
    activeSearchKeyword: string | number;
    activeSortDirection: string;
    activeSortProperty: string;
    activeLanguage: string;
};

export const UpdateTranslationModal: FC<Props> = (props) => {
    const {
        activePage,
        activeSearchKeyword,
        activeSortDirection,
        activeSortProperty,
        activeLanguage,
        translationId,
        translationKey,
        groupId,
        language,
        isOpen,
        onClose,
        translationEnglishValue,
        previousValue,
    } = props;

    const addTranslationMutation = useAddTranslation(onClose);

    const updateTranslationMutation = useUpdateTranslation(
        onClose,
        activePage,
        activeSearchKeyword,
        activeSortDirection,
        activeSortProperty,
        activeLanguage
    );

    const onSubmit: SubmitHandler<UpdateTranslationDetailFormData> = async (formData) => {
        const { groupId, key, language, value } = formData;

        if (translationId) {
            await updateTranslationMutation.mutate({ translationId, data: { groupId: Number(groupId), key, language, value } });
        } else {
            await addTranslationMutation.mutate({ data: { groupId: Number(groupId), key, translations: [{ language: language, value }] } });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Update translation</ModalHeader>

                <ModalCloseButton />

                <ModalBody>
                    <UpdateTranslationForm
                        translationEnglishValue={translationEnglishValue}
                        translationKey={translationKey}
                        groupId={groupId}
                        language={language}
                        onSubmit={onSubmit}
                        onClose={onClose}
                        previousValue={previousValue}
                        translationId={translationId}
                        activePage={activePage}
                        activeSearchKeyword={activeSearchKeyword}
                        activeSortDirection={activeSortDirection}
                        activeSortProperty={activeSortProperty}
                        activeLanguage={activeLanguage}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
