import React from 'react';
import { Modal, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { useDeleteTranslation } from '@/features/Translations/api/deleteTranslation';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    keysToDelete: number[];
    name: string;
};

export const DeleteTranslationModal = ({ isOpen, onClose, keysToDelete, name }: Props) => {
    const deleteTranslationMutation = useDeleteTranslation(onClose);

    const onSubmit = async () => {
        deleteTranslationMutation.mutate(keysToDelete);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Remove translation</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{`Are you sure you want to remove ${name}?`}</ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme="red" onClick={onSubmit} isLoading={deleteTranslationMutation.isLoading}>
                        Remove
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
