import React from 'react';
import {Flex, Spinner} from '@chakra-ui/react';

interface Props {
    isFullScreen?: boolean
}

export const LoadingSpinner = ({ isFullScreen = false }: Props) => {
    return isFullScreen ? (
            <Flex p={4} flex={1} h={'full'} w={'full'} justifyContent={'center'} alignItems={'center'}>
                <Spinner size={'lg'}/>
            </Flex>
        )
        : <Spinner/>
};

export const OverlayLoadingSpinner = () => {
    return (
        <Flex
            bg="whiteAlpha.500"
            zIndex="spinner"
            pos="fixed"
            overflow="hidden"
            top="0"
            left="0"
            justifyContent="center"
            alignItems="center"
            h="100vh"
            w="100vw"
        >
            <Spinner />
        </Flex>
    );
}

