import { API_URL } from "@/config";
import { axios } from '@/lib/axios';

import { TokenResponse, AuthProfile } from '@/types';

export type LoginCredentialsDTO = {
    email: string;
    password: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<TokenResponse> => {
    return axios.post(`${API_URL}/api/admin/security/get_access_token`, data);
};

export const refreshAccessToken = (refreshToken: string): Promise<TokenResponse> => {
    return axios.post(`${API_URL}/api/admin/security/refresh_access_token`, {refreshToken});
};

export const getProfile = (): Promise<AuthProfile> => {
    return axios.get(`${API_URL}/api/admin/profile`);
};
