import {extendTheme} from '@chakra-ui/react';

const theme = extendTheme({
    fonts: {
        heading: 'Roboto',
        body: 'Roboto',
    },
    colors: {
        brand: {
            blue: '#4193f4',
        },
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: '3px',
                minWidth: '120px',
                // height: '41px',
                // fontSize: '14px',
                // letterSpacing: '0.39px',
                // lineHeight: '39px',
                // textAlign: 'center',
                // minWidth: '109px',
            },
            variants: {
                brand: {
                    bgColor: '#4193F4',
                    color: '#ffffff',
                    fontSize: '14px',
                    // boxShadow: '0 9px 18px 0 rgb(65 147 244 / 39%)',
                    _hover: {
                        bgColor: '#inherit',
                        _disabled: {
                            bgColor: '#4193F4',
                            color: '#ffffff'
                        }
                    },
                },
                dashedOutline: {
                    border: '1px dashed #E3DEDE',
                    borderRadius: '2px',
                    lineHeight: '34px',
                    fontSize: '14px',
                    color: '#4193F4',
                },
                playlistSmPreviewBtn: {
                    backgroundColor: '#4193F4',
                    fontSize: '.875rem',
                    color: '#fff',
                    fontWeight: 600,
                    border: '1px solid #ddd',
                    cursor: 'pointer',
                    borderRadius: '3px',
                    height: 'auto',
                    padding: '.25rem .5rem',
                },
                addVideoModalFilter: {
                    fontWeight: 500,
                    lineHeight: '0.875rem',
                    fontSize: '0.875rem',
                    border: '1px solid #DCDCDC',
                    borderRadius: '20px',
                    padding: '0.4375rem 1.6875rem',
                    color: '#353535',
                    height: '30px',
                },
            },
        },
        Tabs: {
            variants: {
                line: {
                    tab: {
                        fontSize: '20px',
                        fontWeight: '500',
                        color: '#2d3748',
                        borderColor: '#e2e8f0',
                        _selected: {
                            color: 'brand.blue',
                            boxShadow: 'none',
                        },
                        _focus: {
                            boxShadow: 'none'
                        },
                        _active: {
                            background: 'transparent',
                        }
                    },
                },
            },
        },
        // Tab: {
        //     variants: {
        //         pageTab: {
        //             _focus: {
        //                 boxShadow: 'none'
        //             },
        //             fontSize: '20px'
        //         }
        //     }
        // }
    }
});

export default theme;
