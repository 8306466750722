import React, { FC } from 'react';
import { Group } from '@/features/Translations/types';
import { Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Checkbox } from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import 'react-image-crop/dist/ReactCrop.css';
import { useAddTranslationGroup } from '@/features/Translations/api/addTranslationGroup';
import { useUpdateTranslationGroup } from '@/features/Translations/api/updateTranslationGroup';

export type TranslationGroupFormData = {
    key: string;
    title: string;
    isActive: boolean;
    id: number;
};

const translationGroupSchema = yup.object().shape({
    key: yup.string().required('This field is required'),
    title: yup.string().required('This field is required'),
});

type Props = {
    groupData?: Group;
    onSubmit: (values: TranslationGroupFormData) => Promise<void>;
};

export const TranslationGroupForm: FC<Props> = (props) => {
    const { onSubmit, groupData } = props;
    const addTranslationGroupMutation = useAddTranslationGroup();
    const updateTranslationGroupMutation = useUpdateTranslationGroup();

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<TranslationGroupFormData>({
        resolver: yupResolver(translationGroupSchema),
        defaultValues: {
            title: groupData?.title,
            key: groupData?.key,
            isActive: groupData?.isActive ?? true,
            id: groupData?.id,
        },
    });

    const formIsLoading = groupData ? updateTranslationGroupMutation.isLoading : addTranslationGroupMutation.isLoading;
    const fieldsAreDisabled = formIsLoading;

    return (
        <>
            <Heading size="md" mb={3}>
                Add translation group
            </Heading>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack mb={2} spacing={4}>
                    <FormControl isInvalid={errors.hasOwnProperty('key')} isDisabled={fieldsAreDisabled}>
                        <FormLabel htmlFor="key">Write Key</FormLabel>

                        <Input id="key" placeholder="Enter translation key" {...register('key')} />

                        <FormErrorMessage>{errors.key?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.hasOwnProperty('key')} isDisabled={fieldsAreDisabled}>
                        <FormLabel htmlFor="key">Title</FormLabel>

                        <Input id="key" placeholder="Enter translation group title" {...register('title')} />

                        <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.hasOwnProperty('isActive')}>
                        <Checkbox isChecked={watch('isActive')} {...register('isActive')} size="md" colorScheme="green">
                            Is Active?
                        </Checkbox>
                        <FormErrorMessage>{errors.isActive && errors.isActive.message}</FormErrorMessage>
                    </FormControl>
                </Stack>

                <Button mt={4} variant={'brand'} isLoading={formIsLoading} type={'submit'}>
                    Save Changes
                </Button>
            </form>
        </>
    );
};
