import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import {
    Box,
    Checkbox,
    CheckboxGroup,
    Flex,
    Image,
    ModalCloseButton,
    Modal,
    ModalBody,
    ModalContent,
    Stack, ModalHeader, Button, ModalFooter,
} from '@chakra-ui/react';
import { ViewQuizVideoContainer } from '../ViewQuizVideoContainer';
import { QuizCheckboxIcon } from '../QuizCheckboxIcon';
import { useAuth } from "@/context/AuthContext";
import parse from 'html-react-parser';
import { QuizFormData } from '@/features/Quiz';
import Icon from '@/components/Icon';

type Props = {
    onClose: () => void;
    isOpen: boolean;
    data?:  QuizFormData;
}

export const PreviewQuizModal = ({ onClose, isOpen, data }: Props) => {
    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const { pathname } = useLocation();
    const { quizLibraryId, adminQuizId, userQuizId } = useParams();

    const [tabActivity, setTabActivity] = useState<number | null>(null);
    const [textOptions, setTextOptions] = useState<{
        activityId: number;
        items: {
            itemId: number;
            value: string[];
        }[]
    }[]>(
        data?.activities?.sort((a, b) => a.sortIndex - b.sortIndex).map((activitItem, index) => {
            return {
                activityId: activitItem.originId || index || 0,
                items: activitItem.items?.filter((item) => item.type === 2).map((item, index) => {
                    return {
                        itemId: item.originId || index || 0,
                        value: [],
                    };
                }) ?? [],
            };
        }) ?? []);

    const timeLimit = useCallback(() => {
        if (data && data.duration) {
            const hours = Math.floor(data.duration / 60);
            const minutes = data.duration % 60;
            return `${hours ? `${hours}:` : ''}${minutes ? `${Math.floor(minutes)}` : '00'}:00`;
        }
    }, []);

    const handleTextOptionsChange = useCallback(
        (activityId: number, activityItemId: number, isMultiple: boolean = false) =>
            (value: string[]) => {

                setTextOptions((state) => {
                    const activitiesSelect = state.find((item) => item.activityId === activityId);
                    const otherActivities = state.filter((item) => item.activityId !== activityId);

                    if (activitiesSelect) {
                        return ([
                            ...otherActivities,
                            {
                                activityId: activitiesSelect.activityId,
                                items: activitiesSelect.items.map((item) => {
                                    if (item.itemId === activityItemId) {
                                        return {
                                            itemId: item.itemId,
                                            value: isMultiple ?
                                                value :
                                                value.filter((dataValue) => !item.value.includes(dataValue)),
                                        };
                                    }
                                    return item;
                                }),
                            },
                        ]);
                    }

                    return otherActivities;
                });
            }, []);

    useEffect(() => {
        if (data) {
            setTextOptions(data.activities?.sort((a, b) => a.sortIndex - b.sortIndex).map((activitItem) => {
                return {
                    activityId: activitItem.originId ?? 0,
                    items: activitItem.items?.filter((item) => item.type === 2).map((item) => {
                        return {
                            itemId: item.originId ?? 0,
                            value: [],
                        };
                    }) ?? [],
                };
            }) ?? []);

            data.activities && setTabActivity(data.activities[0].originId);
        }
    }, [data]);

    if (!isSuperAdmin && !quizLibraryId && !adminQuizId && !userQuizId) {
        return <Navigate to="/"/>
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered size="4xl">
            <ModalContent marginLeft="300px" bgColor="#f8f8f8" position="relative">
                <ModalCloseButton
                    top="30px"
                    transform="translate(-10px, -50%)"
                    position="absolute"
                    colorScheme='blue'
                    fontSize="16px"
                    fontWeight="700"
                />
                <ModalHeader>Quiz Preview</ModalHeader>
                <ModalBody px="16" py="12" overflowY='auto' maxH='80vh'>
                    {data &&
                        <Flex w='100%' maxW='1440px' m='0 auto' justify='space-between' flexDir='row' rowGap='20px'
                              className='quiz-columns'>
                            <Flex className='left-quiz-column' p='18px 30px 0 0' flex={'1 0 33%'} w='100%' bg='primary.grey'
                                  flexDirection='column'>
                                <>
                                    <Box
                                        my='20px'
                                        letterSpacing='0.4px'
                                        fontSize='16px'
                                        fontWeight='bold'
                                        lineHeight='20px'
                                        mt='10px'
                                        mb='20px'
                                        pb='7px'
                                        borderBottom='5px solid'
                                        borderBottomColor='primary.greyLine'
                                        dangerouslySetInnerHTML={{__html: data.title}}
                                    />

                                    {data.duration && (
                                        <>
                                            <Box mt='10px' pb='5px' fontSize='15px' fontWeight='500' color='primary.greyText'
                                                 lineHeight='20px'>
                                                Time Limit
                                            </Box>
                                            <Box color='red'>{timeLimit()}</Box>
                                        </>
                                    )}

                                    {data.endDate && (
                                        <>
                                            <Box mt='10px' pb='5px' fontSize='15px' fontWeight='500' color='primary.greyText'
                                                 lineHeight='20px'>
                                                Deadline
                                            </Box>
                                            <Box color='red'>{new Date(data.endDate).toLocaleString()}</Box>
                                        </>
                                    )}

                                    <Flex
                                        justify='start'
                                        flexDir='row'
                                        mt='30px'
                                        mb='10px'
                                        pb='7px'
                                        fontSize='15px'
                                        fontWeight='500'
                                        color='primary.greyText'
                                        lineHeight='15px'
                                        borderBottomStyle='solid'
                                        borderBottomWidth='1px'
                                        borderBottomColor='primary.greyLine'
                                    >
                                        <Box lineHeight='15px' h='auto'>
                                            Activities
                                        </Box>
                                    </Flex>

                                    {data.activities?.map((activity, i) => (
                                        <Flex
                                            w={'100%'}
                                            cursor={'pointer'}
                                            color='primary.blue'
                                            pb='10px'
                                            mb='10px'
                                            borderBottom='1px solid'
                                            borderBottomColor='primary.greyLine'
                                            onClick={() => setTabActivity(activity.originId)}
                                            key={i}
                                        >
                                            <Icon icon='file-lines' fontSize='20px' />
                                            <Box fontSize='14px' w={'100%'} textAlign={'center'}>
                                                {activity.title ?? ''}
                                            </Box>
                                        </Flex>
                                    ))}
                                </>
                            </Flex>

                            <Flex className='right-quiz-column' w='100%' flex={'1 0 67%'} bg='white' flexDirection='column'>
                                <Flex flexDir='row' minH='100%' className='right-column-quiz'>
                                    <Flex flexDir='column' w='100%' p='18px' bg='primary.grey'>

                                        <Box borderLeft={'3px solid #6236FF'} marginLeft={'-17px'} p={'10px 0'}
                                             fontSize={'14px'}>
                                            <Box marginLeft={'15px'} fontWeight={'bold'} color={'#6236FF'}>
                                                Activity 1
                                                of {(data?.activities && data.activities.length > 0) ? data.activities.length : '1'}
                                            </Box>
                                            <Box marginLeft={'15px'} fontWeight={'bold'}>
                                                {data.activities?.find((item) => item.originId === tabActivity)?.title ?? ''}
                                            </Box>
                                        </Box>

                                        <Flex w='100%' mb='18px' flexDir='column'>
                                            {data.activities?.filter((item) => item.originId === tabActivity).map((activity, i) => (
                                                <Fragment key={i}>
                                                    {activity.items?.sort(
                                                        (a, b) => a.sortIndex - b.sortIndex,
                                                    ).map((activityItem, i) => (
                                                        <Box key={i} px={'18px'}>
                                                            <Box
                                                                w={'100%'}
                                                                margin={'0 auto'}
                                                                bg={'white'}
                                                                p={'18px'}
                                                                border={'1px solid #EDEDED'}
                                                                boxShadow={'0px 3px 10px 2px rgb(0 0 0 / 12%)'}
                                                                pos={'relative'}
                                                                borderRadius={'6px'}
                                                                mx={0}
                                                                mb={'18px'}
                                                            >
                                                                <Flex justify='space-between' w={'100%'}>
                                                                    {activityItem?.type === 1 ? (
                                                                        <Box w={'100%'}>
                                                                            <Box fontWeight='500' fontSize='16px'
                                                                                 lineHeight='24px'
                                                                                 pb='9px'
                                                                                 dangerouslySetInnerHTML={{__html: activityItem.title}}
                                                                            />
                                                                            <Box
                                                                                w={'100%'}
                                                                                minH={'400px'}
                                                                                position={'relative'}
                                                                                bgColor={'#F6F7FB'}
                                                                            >
                                                                                <ViewQuizVideoContainer
                                                                                    videoData={activityItem.article?.videoData ?? null}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    ) : (
                                                                        <Box w={'100%'}>
                                                                            <Box
                                                                                fontWeight={'bold'}
                                                                                color={'#F7B500'}
                                                                                textTransform={'uppercase'}
                                                                                fontSize={'12px'}
                                                                                pb={'7px'}
                                                                            >
                                                                                Question
                                                                            </Box>
                                                                            <Box
                                                                                fontWeight='500'
                                                                                fontSize='16px'
                                                                                lineHeight='24px'
                                                                                pb='9px'
                                                                                dangerouslySetInnerHTML={{ __html: activityItem.title }}
                                                                            />

                                                                            {activityItem.image && (
                                                                                <Box
                                                                                    width={'100%'}
                                                                                    pb='20px'
                                                                                    m={0}
                                                                                >
                                                                                    <Image src={activityItem.image}
                                                                                           width={'100%'} />
                                                                                </Box>
                                                                            )}

                                                                            <CheckboxGroup
                                                                                onChange={handleTextOptionsChange(activity.originId ?? 0, activityItem.originId ?? 0, activityItem.isMultiple)}
                                                                                value={
                                                                                    textOptions
                                                                                    .find((data) => data.activityId === activity?.originId)?.items
                                                                                    .find((data) => data.itemId === activityItem.originId)?.value ?? []
                                                                                }
                                                                            >
                                                                                <Stack spacing={5}>
                                                                                    {activityItem.textOptions && activityItem.textOptions.map(
                                                                                        (textOption, i) => {
                                                                                            const textOptionsSelected = textOptions
                                                                                            .find((data, i) => data.activityId === activity?.originId || i === activity?.sortIndex)?.items
                                                                                            .find((data, i) => data.itemId === activityItem.originId || i === activityItem?.sortIndex)?.value ?? [];
                                                                                            return (
                                                                                                <Flex
                                                                                                    key={i}
                                                                                                    sx={{
                                                                                                        '.chakra-checkbox__control': {
                                                                                                            borderRadius: '100%',
                                                                                                        },
                                                                                                        '.textOptionsGood:after': {
                                                                                                            content: '"Correct Answer"',
                                                                                                            color: 'green',
                                                                                                            fontWeight: 500,
                                                                                                            fontSize: '18px',
                                                                                                            paddingLeft: '20px',
                                                                                                        },
                                                                                                        '.textOptionsFail:after': {
                                                                                                            content: '"Incorrect Answer"',
                                                                                                            color: 'red',
                                                                                                            fontWeight: 500,
                                                                                                            fontSize: '18px',
                                                                                                            paddingLeft: '20px',
                                                                                                        },
                                                                                                    }}
                                                                                                >
                                                                                                    <Checkbox
                                                                                                        colorScheme='green'
                                                                                                        value={`${textOption.originId}`}
                                                                                                        icon={
                                                                                                            <QuizCheckboxIcon />}
                                                                                                        fontSize={'18px'}
                                                                                                        className={
                                                                                                            !data.isGraded && textOptionsSelected.includes(`${textOption.originId}`) ?
                                                                                                                (activityItem.rightAnswer ?? []).includes(textOption.originId) ?
                                                                                                                    'textOptionsGood' : 'textOptionsFail' :
                                                                                                                pathname.includes('create') ? (activityItem.rightAnswer?.toString() ?? []).includes(`${textOption.originId}`) ?
                                                                                                                    'textOptionsGood' : 'textOptionsFail' : ''
                                                                                                        }
                                                                                                    >
                                                                                                        {parse(textOption.text)}
                                                                                                    </Checkbox>
                                                                                                </Flex>
                                                                                            );
                                                                                        })}
                                                                                </Stack>
                                                                            </CheckboxGroup>
                                                                        </Box>
                                                                    )}
                                                                </Flex>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Fragment>
                                            ))}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
