import { ILanguage } from '@/types';
import { FormattedTranslation_FormData } from '../../../../api/addTranslation';
import { TranslationDetailFormData } from './TranslationForm';

type Format_TranslationFormData_To_BulkUpload = (values: TranslationDetailFormData) => FormattedTranslation_FormData;

export const format_TranslationFormData_To_BulkUpload: Format_TranslationFormData_To_BulkUpload = (values) => {
    const { groupId, key, ...translations } = values;

    return {
        groupId: Number(groupId),
        key,
        translations: (Object.entries(translations) as [ILanguage, string][]).reduce(
            (acc, [key, value]) => {
                if (value) {
                    acc.push({ language: key, value });
                }
                return acc;
            },
            [] as { language: ILanguage; value: string }[]
        ),
    };
};
