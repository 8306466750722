import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from '@chakra-ui/react'
import {ArticlesList} from "@/features/Quiz/components/ArticlesList";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSelectArticle: (id: number) => void;
};

export const ArticlesModal = ({isOpen, onClose, onSelectArticle}: Props) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Articles</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <ArticlesList onSelectArticle={onSelectArticle}/>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
