import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { isNotNull } from '@/utils/helpers';
import { RootState } from '../../store';
import {
    createUserQuiz,
    deleteAdminQuizById,
    getUserQuizById,
    getMyQuizzes,
    updateUserQuiz,
} from '@/features/Quiz/api/getQuizzes';
import {
    CreateQuizLibraryData,
    QuizLibrary,
    QuizLibraryResponse, SaveQuizError,
    UpdateQuizLibraryData,
} from '@/features/Quiz/types';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

export type QuizUserType = QuizLibrary;

export const buildQuizLibraryParams = (activePage: number,  activeSearchKeyword?: string | number) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword ? [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: 'title',
                                operator: 'contains',
                                value: activeSearchKeyword ?? 0
                            }
                        }
                    ] : []
                ),
            ]
        },
        sort: [
            [
                'title',
                'asc'
            ]
        ]
    }
}

export const getAllQuizzesUser = createAsyncThunk<
    QuizLibraryResponse,
    {
        activePage: number;
        activeSearchKeyword?: string | number;
    },
    {
        rejectValue: string;
    }
>(
    'quizzes/getAllQuizzesUser',
    async ({activePage,  activeSearchKeyword}) => {
        const data = buildQuizLibraryParams(activePage,  activeSearchKeyword);

        return await getMyQuizzes(data);
    });

export const getQuizUserById = createAsyncThunk(
    'quizzes/getQuizUserById',
    (id: number) => getUserQuizById(id)
);

export const removeQuizUser = createAsyncThunk(
    'quizzes/removeQuizUser',
    (id: number) => deleteAdminQuizById(id)
);


export const createQuizUser = createAsyncThunk<
    QuizLibrary,
    CreateQuizLibraryData,
    {
        rejectValue: SaveQuizError;
    }
>(
    'quizzes/createQuizUser',
    async (data, { rejectWithValue }) => {
        return await createUserQuiz(data).catch((res) => rejectWithValue(res));
    }
);

export const editQuizUser = createAsyncThunk<
    QuizLibrary,
    {
        id: number;
        data: UpdateQuizLibraryData;
    },
    {
        rejectValue: SaveQuizError;
    }
>('quizzes/editQuizUser', async ({ id, data }, { rejectWithValue }) => {
    return await updateUserQuiz(id, data).catch((res) => rejectWithValue(res));
});

const quizUserAdapter = createEntityAdapter<QuizUserType>();

type State = {
    pages: Record<number, QuizUserType['id'][]>;
    activePage: number;
    total: number;
    totalPages: number;
    isLoading: boolean;
};

const quizUserSlice = createSlice({
    name: 'quizUser',
    initialState: quizUserAdapter.getInitialState<State>({
        pages: {},
        activePage: 1,
        totalPages: 1,
        total: 0,
        isLoading: false,
    }),
    reducers: {
        setQuizUserActivePage: (state, action: PayloadAction<number>) => {
            state.activePage = action.payload;
        },
        resetQuizUserPages: (state) => {
            state.pages = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllQuizzesUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllQuizzesUser.fulfilled, (state, action) => {
            quizUserAdapter.upsertMany(state, action.payload.result);
            state.pages[action.payload.navigation.page ?? 1] = action.payload.result.map((item) => item.id);
            state.total = action.payload.navigation.total_items;
            state.activePage = action.payload.navigation.page;
            state.totalPages = action.payload.navigation.total_pages;
            state.isLoading = false;
        });
        builder.addCase(getAllQuizzesUser.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getQuizUserById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQuizUserById.fulfilled, (state, action) => {
            quizUserAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        });
        builder.addCase(getQuizUserById.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(removeQuizUser.fulfilled, (state, action) => {
            quizUserAdapter.removeOne(state, action.meta.arg);
        });
        builder.addCase(createQuizUser.fulfilled, (state, action) => {
            quizUserAdapter.upsertOne(state, action.payload);
        });

        builder.addCase(editQuizUser.fulfilled, (state, action) => {
            quizUserAdapter.upsertOne(state, action.payload);
        });
    },
});

export const quizUser = quizUserSlice.reducer;
export const { resetQuizUserPages, setQuizUserActivePage } = quizUserSlice.actions;

const selectState = (state: RootState) => state.quizzes.quizUser;

export const { selectById, selectIds } = quizUserAdapter.getSelectors(selectState);

const selectRootState = (state: RootState) => state;

export const selectQuizUserById = createSelector(
    [(state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
    (entity) => entity
);

const selectIdsByPage = createSelector(
    [selectState, (_: RootState, page: number) => page],
    (state, page) => state.pages[page] ?? []
);

export const selectQuizUserPage = createSelector(
    [selectRootState, (state: RootState, page: number) => selectIdsByPage(state, page)],
    (state, ids) => ids.map((id) => selectQuizUserById(state, id)).filter(isNotNull)
);

export const selectQuizUserActivePage = createSelector([selectState], (state) => state.activePage);
export const selectQuizUserTotalPages = createSelector([selectState], (state) => state.totalPages);
export const selectQuizUserTotal = createSelector([selectState], (state) => state.total);
export const selectQuizUserLoading = createSelector([selectState], (state) => state.isLoading);
