import React from 'react';
import {
    Alert,
    AlertIcon,
} from '@chakra-ui/react';

export type Props = {
    notification: {
        id: string;
        type: 'info' | 'warning' | 'success' | 'error';
        title: string;
        message?: string;
    }
};

export const Notification = ({notification: {type, title}}: Props) => {
    return (
        <Alert
            status={type}
            variant={'solid'}
            transition={'.2s ease-in-out'}
            w={'full'}
            borderRadius={4}
            bgColor={type === 'success' ? 'brand.blue' : ''}
        >
            <AlertIcon/>
            {title}
        </Alert>
    );
};

