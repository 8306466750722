import { API_URL } from "@/config";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation, useQueryClient } from "react-query";
import { ClassItem, ClassUpdateData } from "../../types";

type UpdateData = {
    classId: number;
    data: ClassUpdateData;
}

export const updateClass = ({ classId, data }: UpdateData): Promise<ClassItem> => {
    return axios.patch(`${API_URL}/api/admin/class/${classId}`, data);
}

export const useUpdateClass = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation({
        onMutate: async ({ classId }: UpdateData) => {
            await queryClient.cancelQueries(['getClass', classId]);
        },
        onError: (error: any) => {
            const title = error.message || 'Unable to update Class'
            addNotification({
                type: 'error',
                title
            });
        },
        onSuccess: (classItem) => {
            queryClient.refetchQueries(['getClass', classItem.id]);
            queryClient.refetchQueries(['classes']);
        },
        mutationFn: updateClass,
    })
}