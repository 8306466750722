import { createSlice, createEntityAdapter, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import {addArticleToPlaylists } from '../../../../features/Playlists/api';
import { addArticleToPlaylistResponse, buildArticleParams } from '../../../../features/Playlists/types';
import { RootState } from '../../../store';
import { isNotNull } from '../../../../utils/helpers';

type ArticleToPlaylistState = {
    isLoading: boolean;
    isError: boolean;
}

type ArticleToPlaylistAdapterType = {
    id: number;
    value: number;
};

const ArticleToPlaylistAdapter = createEntityAdapter<ArticleToPlaylistAdapterType>();

export const addArticleToPlaylist = createAsyncThunk<addArticleToPlaylistResponse,
    buildArticleParams,
    { rejectValue: string }>(
    'playlist/articles/addArticleToPlaylist',
    async ({ playlistId, dataCheckbox }, { rejectWithValue }) => {
        try {
            return await addArticleToPlaylists({ playlistId, dataCheckbox });
        } catch (error) {
            let message = '';
            if (error instanceof Error) {
                message = error.message ||
                    error.toString();
            }

            return rejectWithValue(message);
        }
    });

const articleToPlaylistSlice = createSlice({
    name: 'article in playlists',
    initialState: ArticleToPlaylistAdapter.getInitialState<ArticleToPlaylistState>({
        isLoading: false,
        isError: false,
    }),
    reducers: {
        resetArticleToPlaylist: state => {ArticleToPlaylistAdapter.removeAll(state)},
        setArticleToPlaylist: (state, action: PayloadAction<number[]>) => {
            ArticleToPlaylistAdapter.setAll(state, action.payload.map((item) => ({
                id: item,
                value: item
            })));
        },
        getArticleToPlaylist: (state, action: PayloadAction<number>) => {
            ArticleToPlaylistAdapter.upsertOne(state,{id: action.payload, value: action.payload});
        }
    },
    extraReducers: {
        [addArticleToPlaylist.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [addArticleToPlaylist.fulfilled.type]: (state, action: PayloadAction<addArticleToPlaylistResponse>) => {
            ArticleToPlaylistAdapter.setAll(state, action.payload.in_user_playlist.map((item) => ({
                id: item,
                value: item
            })));
            state.isLoading = false;
            state.isError = false;
        },
        [addArticleToPlaylist.rejected.type]: (state) => {
            state.isLoading = false;
            state.isError = true;
        },
    },
});

export const articleToPlaylist = articleToPlaylistSlice.reducer;

export const { resetArticleToPlaylist, setArticleToPlaylist, getArticleToPlaylist } = articleToPlaylistSlice.actions;

const selectState = (state: RootState) => state.playlistReducer.articleToPlaylist;
export const { selectById, selectIds } = ArticleToPlaylistAdapter.getSelectors(selectState);
const selectRootState = (state: RootState) => state;

export const selectArticleToPlaylistsById = createSelector(
    [selectRootState, (state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
    (_, entity) => entity?.value ?? null,
);

export const selectAllArticleToPlaylist = createSelector([selectIds, selectRootState], (ids, state) =>
    ids.map((id) => selectArticleToPlaylistsById(state, Number(id))).filter(isNotNull),
);
export const selectPlaylistWithArticleLoading = createSelector([selectState], (state) => state.isLoading);
export const selectPlaylistWithArticleError = createSelector([selectState], (state) => state.isError);
