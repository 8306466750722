import { useAuth } from '@/context/AuthContext';
import React, { MouseEvent, useCallback } from 'react';
import {
    Button,
    FormLabel,
    Input,
    FormErrorMessage,
    FormControl,
    Flex, useDisclosure,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { QuizFormData } from '@/features/Quiz/types';
import { RightQuizItems } from '../RightQuizItems';
import { PreviewQuizModal } from '@/features/Quiz/components/PreviewQuizModal';
import Icon from '@/components/Icon';

type Props = {
    isLibrary?: boolean;
}

export const RightQuiz = ({ isLibrary = false }: Props) => {
    const { register, formState: { errors }, watch, control, getValues } = useFormContext<QuizFormData>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const values = getValues();
    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const indexActivity = (watch('activities')?.find((item) => item.isSelect)?.sortIndex ?? 1) - 1

    const { replace } = useFieldArray<QuizFormData, `activities.${number}.items`>({
        control,
        name: `activities.${indexActivity}.items`
    });

    const items = watch(`activities.${indexActivity}.items`);

    const handleCollapseAllClick = useCallback((e: MouseEvent) => {
        e.preventDefault();

        const itemsList = items.map((item) => ({ ...item, isSelect: false }));

        replace(itemsList);
    }, [replace, items]);

    const handleExpandAllClick = useCallback((e: MouseEvent) => {
        e.preventDefault();

        const itemsList = items.map((item) => ({ ...item, isSelect: true }));

        replace(itemsList);
    }, [replace, items]);

    return (
        <>
            {isSuperAdmin && <PreviewQuizModal data={values} isOpen={isOpen} onClose={onClose} />}
            <Flex
                flexDir="row"
                mx="20px"
                minH="100%"
                className='right-column-quiz'
            >
                <Flex
                    flexDir="column"
                    w="100%"
                    p="18px"
                    bg='primary.grey'
                >
                    <Flex w="100%" mb="28px">
                        <Button
                            variant={'authBtn'}
                            onClick={handleCollapseAllClick}
                            borderRadius="100px"
                            size="sm"
                            cursor="pointer"
                            mr="5px"
                        >
                            Collapse All
                        </Button>
                        <Button
                            variant={'authBtn'}
                            onClick={handleExpandAllClick}
                            borderRadius="100px"
                            size="sm"
                            cursor="pointer"
                        >
                            Expand All
                        </Button>
                    </Flex>

                    {isLibrary &&
                        <Flex w="100%" mb="72px">
                            <FormControl
                                isInvalid={errors.hasOwnProperty(`activities.${indexActivity}.title`)}
                            >
                                <FormLabel
                                    htmlFor={`activities-${indexActivity}-title`}
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="primary.greyText"
                                    lineHeight="36px"
                                    m="0"
                                >
                                    Activity Title *
                                </FormLabel>
                                <Input
                                    {...register(`activities.${indexActivity}.title`, { required: true })}
                                    id={`activities-${indexActivity}-title`}
                                    placeholder=''
                                    p="6px"
                                    value={watch(`activities.${indexActivity}.title`)}
                                />
                                <FormErrorMessage>
                                    {errors.activities && errors.activities?.[indexActivity]?.title?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                    }

                    <Flex w="100%" mb="18px" flexDir="column">
                        <RightQuizItems indexActivity={indexActivity} isLibrary={isLibrary} />
                    </Flex>

                    {isSuperAdmin && values?.activities?.length && values.activities.map(i => i?.items)?.length && values.activities.map(i => i?.items)[0]?.length ?
                        <Button onClick={onOpen} w="fit-content" m="0 auto">
                            <Icon icon='file-magnifying-glass' style={{ marginRight: '7px', color: '#4193f4' }}/>
                            Preview
                        </Button>
                    : null}

                </Flex>
            </Flex>
        </>
    );
}
