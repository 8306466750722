import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { createAdminQuiz, deleteQuiz, getAdminQuizById, updateUserQuiz } from '@/features/Quiz/api/getQuizzes';
import { CreateQuizLibraryData, QuizLibrary, QuizLibraryResponse, SaveQuizError, UpdateQuizLibraryData } from '@/features/Quiz';
import { isNotNull } from '@/utils/helpers';
import { axios } from '@/lib/axios';
import { API_URL } from '@/config';
import { format } from 'date-fns';

type FilterCollection = {
    bool_operator?: string;
    condition?: {
        property: string;
        operator: string;
        value: string | number | boolean;
    };
};

type GetListBodyStructure = {
    navigation: {
        page: number;
        itemsOnPage: number;
    };
    totalCount: boolean;
    filter: {
        collection: FilterCollection[] | null;
    };
    sort: string[][];
};

export type GetListParams = {
    activePage: number;
    activeSortProperty: string;
    activeSortDirection: string;
    activeSearchKeyword: string | number;
    isShowExpired: boolean;
    isNumber?: boolean;
    isApproved?: boolean;
    itemsOnPage?: number;
};

export const getCustomerQuizzes = (data: GetListBodyStructure): Promise<QuizLibraryResponse> => {
    return axios.post(`${API_URL}/api/admin/quiz/list`, data);
};

export const buildQuizLibraryParams = (activePage: number, activeSearchKeyword: string | number, isShowExpired: boolean) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20,
        },
        totalCount: true,
        filter: {
            collection: [
                ...(!isShowExpired
                    ? [
                          {
                              bool_operator: 'and',
                              condition: {
                                  property: 'end_date',
                                  operator: 'greater_than',
                                  value: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                              },
                          },
                          {
                              bool_operator: 'or',
                              condition: {
                                  property: 'end_date',
                                  operator: 'is_null',
                                  value: true,
                              },
                          },
                      ]
                    : []),
                {
                    bool_operator: 'and',
                    collection: [
                        ...(activeSearchKeyword
                            ? [
                                  {
                                      bool_operator: 'or',
                                      condition: {
                                          property: 'user_first_name',
                                          operator: 'contains',
                                          value: activeSearchKeyword ?? 0,
                                      },
                                  },
                              ]
                            : []),
                        ...(activeSearchKeyword
                            ? [
                                  {
                                      bool_operator: 'or',
                                      condition: {
                                          property: 'user_last_name',
                                          operator: 'contains',
                                          value: activeSearchKeyword ?? 0,
                                      },
                                  },
                              ]
                            : []),
                        ...(activeSearchKeyword
                            ? [
                                  {
                                      bool_operator: 'or',
                                      condition: {
                                          property: 'user_email',
                                          operator: 'contains',
                                          value: activeSearchKeyword ?? 0,
                                      },
                                  },
                              ]
                            : []),
                        ...(activeSearchKeyword
                            ? [
                                  {
                                      bool_operator: 'or',
                                      condition: {
                                          property: 'title',
                                          operator: 'contains',
                                          value: activeSearchKeyword ?? 0,
                                      },
                                  },
                              ]
                            : []),
                    ],
                },
            ],
        },
        sort: [['start_date', 'desc']],
    };
};

export const getAllCustomerQuizzes = createAsyncThunk<
    QuizLibraryResponse,
    {
        activePage: number;
        activeSearchKeyword: string | number;
        isShowExpired: boolean;
    },
    {
        rejectValue: string;
    }
>('quizzes/getAllCustomerQuizzes', async ({ activePage, activeSearchKeyword, isShowExpired }) => {
    const data = buildQuizLibraryParams(activePage, activeSearchKeyword, isShowExpired);

    return await getCustomerQuizzes(data);
});

export const getQuizCustomerById = createAsyncThunk('quizzes/getQuizCustomerById', (id: number) => getAdminQuizById(id));

export const removeQuizCustomer = createAsyncThunk('quizzes/removeQuizCustomer', (id: number) => deleteQuiz(id));

export const createQuizCustomer = createAsyncThunk<
    QuizLibrary,
    CreateQuizLibraryData,
    {
        rejectValue: SaveQuizError;
    }
>('quizzes/createQuizCustomer', async (data, { rejectWithValue }) => {
    return await createAdminQuiz(data).catch((res) => rejectWithValue(res));
});

export const editQuizCustomer = createAsyncThunk<
    QuizLibrary,
    {
        id: number;
        data: UpdateQuizLibraryData;
    },
    {
        rejectValue: SaveQuizError;
    }
>('quizzes/editUserQuiz', async ({ id, data }, { rejectWithValue }) => {
    return await updateUserQuiz(id, data).catch((res) => rejectWithValue(res));
});

const quizCustomerAdapter = createEntityAdapter<QuizLibrary>();

type State = {
    pages: Record<number, QuizLibrary['id'][]>;
    activePage: number;
    total: number;
    totalPages: number;
    isLoading: boolean;
};

const quizCustomerSlice = createSlice({
    name: 'quizCustomer',
    initialState: quizCustomerAdapter.getInitialState<State>({
        pages: {},
        activePage: 1,
        totalPages: 1,
        total: 0,
        isLoading: false,
    }),
    reducers: {
        setQuizCustomerActivePage: (state, action: PayloadAction<number>) => {
            state.activePage = action.payload;
        },
        resetQuizCustomerPages: (state) => {
            state.pages = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCustomerQuizzes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllCustomerQuizzes.fulfilled, (state, action) => {
            quizCustomerAdapter.upsertMany(state, action.payload.result);
            state.pages[action.payload.navigation.page ?? 1] = action.payload.result.map((item) => item.id);
            state.total = action.payload.navigation.total_items;
            state.activePage = action.payload.navigation.page;
            state.totalPages = action.payload.navigation.total_pages;
            state.isLoading = false;
        });
        builder.addCase(getAllCustomerQuizzes.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getQuizCustomerById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQuizCustomerById.fulfilled, (state, action) => {
            quizCustomerAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        });
        builder.addCase(getQuizCustomerById.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(removeQuizCustomer.fulfilled, (state, action) => {
            quizCustomerAdapter.removeOne(state, action.meta.arg);
        });
        builder.addCase(createQuizCustomer.fulfilled, (state, action) => {
            quizCustomerAdapter.upsertOne(state, action.payload);
        });

        builder.addCase(editQuizCustomer.fulfilled, (state, action) => {
            quizCustomerAdapter.upsertOne(state, action.payload);
        });
    },
});

export const quizCustomer = quizCustomerSlice.reducer;
export const { resetQuizCustomerPages, setQuizCustomerActivePage } = quizCustomerSlice.actions;

const selectState = (state: RootState) => state.quizzes.quizCustomer;

export const { selectById, selectIds } = quizCustomerAdapter.getSelectors(selectState);

const selectRootState = (state: RootState) => state;

export const selectQuizCustomerById = createSelector(
    [(state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
    (entity) => entity
);

const selectIdsByPage = createSelector([selectState, (_: RootState, page: number) => page], (state, page) => state.pages[page] ?? []);

export const selectQuizCustomerPage = createSelector(
    [selectRootState, (state: RootState, page: number) => selectIdsByPage(state, page)],
    (state, ids) => ids.map((id) => selectQuizCustomerById(state, id)).filter(isNotNull)
);

export const selectQuizCustomerActivePage = createSelector([selectState], (state) => state.activePage);
export const selectQuizCustomerTotalPages = createSelector([selectState], (state) => state.totalPages);
export const selectQuizCustomerLoading = createSelector([selectState], (state) => state.isLoading);
