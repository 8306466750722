import { rebuildSectionQuizLibrary } from '@/features/Quiz/api/getQuizzes';
import { useNotificationStore } from '@/stores/notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    useDisclosure,
    Select,
    Radio,
    RadioGroup,
    FormControl,
    FormErrorMessage,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    type?: number;
    isAllUpdate?: boolean;
};

const rebuildSchema = yup.object().shape({
    type: yup.string().typeError('This field is required').required('This field is required'),
    isAllUpdate: yup.string().required('This field is required'),
});

const RebuildQuizModal = ({ isOpen, onClose }: Props) => {
    const { isOpen: isOpenConfirmModal, onOpen: onOpenConfirmModal, onClose: onCloseConfirmModal } = useDisclosure();
    const [type, setType] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState('');
    const [isAllUpdate, setIsAllUpdate] = useState(false);
    const options = [
        { type: 1, title: 'Entire Quiz Library' },
        { type: 2, title: 'Core' },
        { type: 3, title: 'Science Education' },
        { type: 4, title: 'Lab Manual' },
        { type: 5, title: 'Business' },
    ];

    const methods = useForm<{ type: string | undefined; isAllUpdate: string }>({
        mode: 'onChange',
        resolver: yupResolver(rebuildSchema),
        defaultValues: {
            type: undefined,
            isAllUpdate: '0',
        },
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = methods;

    const handleRebuild = () => {
        const type = watch('type');
        if (!type) {
            return setError('type', { message: 'This field is required' });
        }
        clearErrors();
        onOpenConfirmModal();
        setType(type ?? undefined);
        setTitle(options[Number(type) - 1].title);
        setIsAllUpdate(isAllUpdate);
        onClose();
        reset();
    };

    useEffect(() => {
        if (isOpen) {
            reset();
            setIsAllUpdate(false);
            setTitle('');
        }
    }, [isOpen]);

    return (
        <>
            <ConfirmModal isOpen={isOpenConfirmModal} onClose={onCloseConfirmModal} type={Number(type)} title={title} isAllUpdate={isAllUpdate} />
            <form onSubmit={handleSubmit(handleRebuild)}>
                <FormProvider {...methods}>
                    <Modal isOpen={isOpen} onClose={onClose} size="xl">
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Select the section to rebuild</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody mx={4}>
                                <FormControl isInvalid={errors.hasOwnProperty('type')}>
                                    <Select id="type" placeholder="Select the section to Rebuild" mb="10px" {...register('type')}>
                                        {options.map((option, index) => (
                                            <option key={index} value={option.type}>
                                                {option.title}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{errors.type && errors.type.message}</FormErrorMessage>
                                </FormControl>

                                {!!watch('type') && (
                                    <RadioGroup
                                        value={watch('isAllUpdate')}
                                        onChange={(e) => {
                                            setIsAllUpdate(e === '1');
                                            setValue('isAllUpdate', e);
                                        }}
                                        defaultValue="0">
                                        <Radio value="0" color="inherit" colorScheme="blue" mr="15px">
                                            Add Only Missing Quizzes
                                        </Radio>
                                        <Radio value="1" color="inherit" colorScheme="blue">
                                            Rebuild All Quizzes
                                        </Radio>
                                    </RadioGroup>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <Flex>
                                    <Button mr={3} onClick={onClose}>
                                        Close
                                    </Button>
                                    <Button colorScheme="blue" type="submit" onClick={handleRebuild}>
                                        Rebuild Quiz Library
                                    </Button>
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </FormProvider>
            </form>
        </>
    );
};

export default RebuildQuizModal;

export const ConfirmModal = ({ isOpen, onClose, title, type, isAllUpdate }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotificationStore();

    const handleRebuildQuiz = async () => {
        setIsLoading(true);

        try {
            await rebuildSectionQuizLibrary({ type: type as 1 | 2 | 3 | 4 | 5, isAllUpdate: isAllUpdate as boolean });

            onClose();
            addNotification({
                type: 'info',
                title: 'Rebuilding process has been added to the queue',
            });
        } catch (e: any) {
            addNotification({
                type: 'error',
                title: e?.message,
            });
        }

        setIsLoading(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Rebuild Quiz</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure you want to {isAllUpdate ? 'rebuild All Quizzes' : 'add only Missing Quizzes'} for{' '}
                    {type === 1 ? (
                        <b>all sections</b>
                    ) : (
                        <>
                            <b>{title}</b> Quiz Library
                        </>
                    )}
                    ?
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme="blue" onClick={handleRebuildQuiz} isLoading={isLoading}>
                        Rebuild
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
