import { Box, HStack, Td, Tr, useDisclosure } from '@chakra-ui/react';
import Icon from '@/components/Icon';
import React, { FC, useEffect, useState } from 'react';
import { Translation } from '@/features/Translations/types';
import { DeleteTranslationModal } from '../DeleteTranslationModal/DeleteTranslationModal';
import { UpdateTranslationModal } from '../UpdateTranslationModal/UpdateTranslationModal';
import { languages } from '../../../../Utils/languages';
import { ILanguages } from '@/types';
import { UpdateTranslationDetailFormData } from '../UpdateTranslationModal/Components/UpdateTranslationForm';

export type LanguageStrings = {
    [K in (typeof languages)[number]['key']]: string;
};

type Props = {
    activePage: number;
    activeSearchKeyword: string | number;
    activeSortDirection: string;
    activeSortProperty: string;
    activeLanguage: string;
    translation: Pick<Translation, 'key' | 'groupTitle' | 'groupId'> &
        LanguageStrings & { keysToDelete: number[]; translationIdsByLanguage: Record<string, number> };
};

export const TranslationItem: FC<Props> = (props) => {
    const { translation, activePage, activeSearchKeyword, activeSortDirection, activeSortProperty, activeLanguage } = props;
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

    const [selectedLanguageHovered, setSelectedLanguageHovered] = useState<string>();
    const [selectedLanguage, setSelectedLanguage] = useState<string>();
    const [selectedTranslationId, setSelectedTranslationId] = useState<number>();
    const [selectedGroupId, setSelectedGroupId] = useState<string>();
    const [selectedKey, setSelectedKey] = useState<string>();

    const onTranslationHover = (data?: Omit<UpdateTranslationDetailFormData, 'value'>) => {
        if (data) {
            setSelectedLanguageHovered(data.language);
            setSelectedTranslationId(data.translationId);
            setSelectedLanguage(data.language);
            setSelectedGroupId(data.groupId);
            setSelectedKey(data.key);
        } else {
            setSelectedLanguageHovered(undefined);
        }
    };

    return (
        <>
            <DeleteTranslationModal
                keysToDelete={[selectedTranslationId] as number[]}
                name={selectedLanguage ? translation[selectedLanguage as ILanguages] : translation.key}
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
            />

            {selectedLanguage && selectedGroupId && selectedKey && (
                <UpdateTranslationModal
                    activePage={activePage}
                    activeSearchKeyword={activeSearchKeyword}
                    activeSortDirection={activeSortDirection}
                    activeSortProperty={activeSortProperty}
                    activeLanguage={activeLanguage}
                    language={selectedLanguage}
                    groupId={selectedGroupId}
                    translationKey={selectedKey}
                    name={translation.key}
                    isOpen={isUpdateModalOpen}
                    onClose={() => setIsUpdateModalOpen(false)}
                    translationEnglishValue={translation.en}
                    previousValue={translation[selectedLanguageHovered as ILanguages]}
                    translationId={selectedTranslationId}
                />
            )}

            <Tr>
                <Td>{translation.key}</Td>

                <Td textAlign={'center'}>{translation.groupTitle}</Td>

                {languages.map((language) => (
                    <Td
                        key={language.key}
                        textAlign={'center'}
                        onMouseEnter={() => {
                            onTranslationHover({
                                groupId: String(translation.groupId),
                                key: translation.key,
                                language: language.key,
                                translationId: translation.translationIdsByLanguage[language.key],
                                translationEnglishValue: translation.en,
                            });
                        }}
                        onMouseLeave={() => onTranslationHover()}
                        cursor={selectedLanguageHovered === language.key ? 'pointer' : undefined}>
                        {translation[language.key]}
                        {selectedLanguageHovered === language.key && (
                            <Box ml="5px" display="inline-block">
                                <Icon
                                    highlightOnHover
                                    icon={'pencil'}
                                    onClick={() => {
                                        setIsUpdateModalOpen(true);
                                    }}
                                />
                                {translation[language.key] && <Icon ml="5px" highlightOnHover icon={'trash'} onClick={onDeleteOpen} />}
                            </Box>
                        )}
                    </Td>
                ))}
            </Tr>
        </>
    );
};
