import { Box, HStack, Td, Tr, useDisclosure } from '@chakra-ui/react';
import Icon from '@/components/Icon';
import { Link } from 'react-router-dom';
import React from 'react';
import { Sponsor } from '@/features/Sponsors/types';
import { DeleteSponsorModal } from '@/features/Sponsors/Components/DeleteSponsorModal';

type Props = {
    sponsor: Sponsor;
};

export const SponsorItem = ({ sponsor }: Props) => {
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    return (
        <>
            <DeleteSponsorModal id={sponsor.id} name={sponsor.companyName} isOpen={isDeleteOpen} onClose={onDeleteClose} />
            <Tr key={sponsor.id}>
                <Td maxW="200px" dangerouslySetInnerHTML={{ __html: sponsor.companyName }} />
                <Td maxW="200px">{sponsor.companyUrl}</Td>
                <Td maxW="100px" textAlign="center">
                    {sponsor.logoUrl ? <Icon icon="check" /> : <Icon icon="xmark" />}
                </Td>
                <Td maxW="100px" textAlign={'center'}>
                    {sponsor.videoUrl ? <Icon icon="check" /> : <Icon icon="xmark" />}
                </Td>

                <Td maxW="200px" textAlign="center">
                    {sponsor.countOfArticles}
                </Td>
                <Td>
                    <HStack color="gray.600" spacing={4}>
                        <Box as={Link} to={`/sponsors/edit/${sponsor.id}`}>
                            <Icon highlightOnHover icon="pencil" />
                        </Box>
                        <Box onClick={onDeleteOpen}>
                            <Icon highlightOnHover forDelete icon="trash" />
                        </Box>
                    </HStack>
                </Td>
            </Tr>
        </>
    );
};
