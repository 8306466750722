import React from 'react';
import { Modal, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';

import { ArticleList } from '@/features/Sponsors/types';
import { useRemoveSponsorArticle } from '@/features/Sponsors/api/removeSponsorArticle';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    assignedArticle: ArticleList;
    sponsorId: number;
};

export const RemoveSponsorArticleModal = ({ isOpen, onClose, assignedArticle, sponsorId }: Props) => {
    const removeArticleMutation = useRemoveSponsorArticle(onClose);

    const onSubmit = async () => {
        const params = {
            articles: [assignedArticle.id],
        };
        removeArticleMutation.mutate({ sponsorId, params });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Remove Article</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Are you sure you want to remove this article from sponsor?</Text>
                    <Text>
                        <strong>
                            {assignedArticle.id} - {assignedArticle.title}
                        </strong>
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme="red" onClick={onSubmit} isLoading={removeArticleMutation.isLoading}>
                        Remove
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
