import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';
import Icon from '@/components/Icon';
import { TranslationGroupForm, TranslationGroupFormData } from '../../Components/AddTranslationGroup/Components/TranslationForm/TranslationGroupForm';
import { getGroup } from '@/features/Translations/api/getTranslationGroup';
import { useUpdateTranslationGroup } from '@/features/Translations/api/updateTranslationGroup';

export const UpdateTranslationGroup: FC = () => {
    const navigate = useNavigate();
    const { groupId } = useParams();

    const queryClient = useQueryClient();
    const entities = queryClient.getQueryData(['group']);
    const updateTranslationMutation = useUpdateTranslationGroup();

    const { data: group } = useQuery(['group', groupId], () => (groupId ? getGroup(groupId) : null), {
        enabled: !entities,
        useErrorBoundary: (error: any) => error.response?.status >= 400,
    });

    const onSubmit = async (data: TranslationGroupFormData) => {
        await updateTranslationMutation.mutate({ data });
    };

    return (
        <Flex flexDir={'column'} margin={'0 auto'} maxW={'800px'}>
            <Flex mb={3} alignItems={'center'} justifyContent={'space-between'}>
                <Heading size="lg">Add New translation group</Heading>

                <Button variant="back" onClick={() => navigate(`/translations/groups`)} leftIcon={<Icon icon={'chevron-left'} />}>
                    Back
                </Button>
            </Flex>

            {group && (
                <Box bgColor="#ffffff" p={4}>
                    <TranslationGroupForm groupData={group} onSubmit={onSubmit} />
                </Box>
            )}
        </Flex>
    );
};
