import React, { FC } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { fetchArticleByDomainId } from '@/utils/api';
import {ErrorBlock} from "@/components/ErrorBlock";
import {VideoContainer} from "@/components/video-container/video-container";

type Props = {
    videoId: number | null;
    isOpen: boolean;
    onClose: () => void;
    subtitles?: Record<string, string> | null;
}

export const VideoModal: FC<Props> = ({ videoId, isOpen = false, onClose, subtitles }: Props) => {
    const { isLoading, data, isError } = useQuery(
        `fetchVideo-${videoId}`,
        () => fetchArticleByDomainId('en', Number(videoId)),
        {
            enabled: isOpen && videoId !== null,
        }
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='5xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader dangerouslySetInnerHTML={{ __html: data?.domain.title ?? '' }} />
                <ModalCloseButton />
                <ModalBody>
                    {!isError && !isLoading && data && (
                        <VideoContainer videoSubtitles={subtitles} isQuizzes article={data} />
                    )}
                    {isError && <ErrorBlock error="Error" textOnly />}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
