import { useMutation, useQueryClient } from 'react-query';

import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { SponsorAddArticlesResponse } from "@/features/Sponsors/types";

export const addSponsorArticle = ({ sponsorId, params }: {
    sponsorId: number;
    params: { articles: number[] }
}): Promise<SponsorAddArticlesResponse> => {
    return axios.post(`api/admin/sponsor/${sponsorId}/article/add`, params);
};

export const useAddSponsorArticle = (sponsorId: number) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();

    return useMutation({
        onError: () => {
            addNotification({
                type: 'error',
                title: 'Unable to add article',
            });
        },
        onSuccess: (res) => {
            if (res.error) {
                addNotification({
                    type: 'error',
                    title: Object.values(res.error)[0],
                });
            } else {
                queryClient.refetchQueries(['sponsor', String(sponsorId)]);
                addNotification({
                    type: 'info',
                    title: 'Article successfully assigned',
                });
            }
        },
        mutationFn: addSponsorArticle,
    });
};
