import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Banner } from '../components/Banner';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const BannerRoutes = () => {
    const { adminBanner } = useFlags();

    if (!adminBanner) {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<Banner />} />
        </Routes>
    );
};
