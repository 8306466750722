import { API_URL } from "@/config";
import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ClassItem } from '../../types';

export const getAllClasses = (): Promise<ClassItem[]> => {
    return axios.get(`${API_URL}/api/admin/class/`);
};

export const useAllClasses = (userId?: { userId: number | undefined }) => {
    return useQuery<ClassItem[], Error>({
        queryKey: ['classes', userId],
        queryFn: () => getAllClasses(),
        keepPreviousData: true,
    });
};
