import React, { useCallback, useEffect, ChangeEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Table,
    Tbody, Td,
    Thead,
    Tr,
    Spinner as SpinnerChakra,
    Button,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { HiX } from 'react-icons/hi';
import * as yup from 'yup';
import { debounce } from '@/utils/helpers';
import { Pagination } from "@/components/Pagination";
import { ThEl } from "@/features/Quiz/components/ArticlesQuiz";
import { Link } from "react-router-dom";
import { AdminQuizItem } from "@/features/Quiz/components/AdminQuizItem";
import { useAuth } from '@/context/AuthContext';
import { buildQuizzesParams, getMyQuizzes } from '@/features/Quiz/api/getQuizzes';
import Icon from "@/components/Icon";

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

type SearchFormData = {
    keyword: string;
};

export const MyQuiz = () => {
    const [activePage, setActivePage] = useState(1);
    const [activeSearchKeyword, setActiveSearchKeyword] = useState('');

    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const { data, isLoading, isError, error } = useQuery(
        ['myQuiz', user, activePage, activeSearchKeyword],
        () => getMyQuizzes(buildQuizzesParams(activePage, activeSearchKeyword, null, 20)),
        {
            useErrorBoundary: (error: any) => error.response?.status >= 400,
        }
    );

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
        watch,
        setValue,
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        },
    });

    const onSearchKeywordSubmit = useCallback(
        (values: SearchFormData) => {
            setActivePage(1);
            setActiveSearchKeyword(values.keyword ?? '');
        },
        [activePage, activeSearchKeyword]
    );

    const onSearchKeywordChange = debounce(useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            setValue('keyword', e.target.value);
            setActivePage(1);
            setActiveSearchKeyword(e.target.value ?? '');

        },
        [activePage, activeSearchKeyword]
    ), 300);

    const handleReset = useCallback(() => {
        reset();
        setActivePage(1);
        setActiveSearchKeyword('');
    }, []);

    const handlePaginationChange = useCallback(
        (page: number) => {
            setActivePage(page);
        },
        []
    );

    useEffect(() => () => {
        setActivePage(1);
    }, [activeSearchKeyword]);

    if (isError && error?.message.toLowerCase() === 'access denied') {
        return (
            <Alert status='error'>
                <AlertIcon />
                You have no access to quizzes based on your subscription level
            </Alert>
        );
    }

    return (
        <Flex w="100%" flexDir="column" mb={'10px'}>
            <Flex gap="20px" sx={{ '.btn-half-width': { flex: '1 0 45%' } }} w="100%">
                <Flex justifyContent={'space-between'} alignItems={'center'} mb={3} w="100%">
                    <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                        <InputGroup minW={'400px'} className="input-left-wrap">
                            <InputLeftElement
                                className="InputLeft"
                                pointerEvents={isSubmitting ? 'none' : 'auto'}
                                onClick={handleSubmit(onSearchKeywordSubmit)}
                            >
                                {isSubmitting || isLoading ? <SpinnerChakra size="20px" /> : <FaSearch size="20px" />}
                            </InputLeftElement>
                            <Input
                                placeholder="Search Admin Quiz by Title"
                                bg={'#ffffff'}
                                id={'keyword'}
                                type={'text'}
                                borderRadius="1000px"
                                {...register('keyword')}
                                onChange={onSearchKeywordChange}
                            />
                            {watch('keyword') && (
                                <InputRightElement cursor="pointer" onClick={handleReset}>
                                    <HiX />
                                </InputRightElement>
                            )}
                        </InputGroup>
                    </form>
                    <Button
                        as={Link}
                        variant={'brand'}
                        to={isSuperAdmin ? '/quiz/admin-quiz/create' : '/quiz/my-quiz/create'}
                    >
                        <Icon icon='plus'/>&nbsp; Create a New Quiz
                    </Button>
                </Flex>
            </Flex>
            <Flex flexDir={'column'}>
                <Table variant="striped" colorScheme="gray">
                    {data && data.result?.length > 0 && (
                        <Thead borderBottom="1px solid #e5ebf3">
                            <Tr w="100%">
                                <ThEl align="start" w="65%">
                                    Title
                                </ThEl>
                                <ThEl w="15%">Access</ThEl>
                                <ThEl w="10%" align="start">
                                    Subjects
                                </ThEl>
                                <ThEl w="15%">Questions</ThEl>
                                <ThEl w="15%">Deadline</ThEl>
                                <ThEl w="15%">Actions</ThEl>
                            </Tr>
                        </Thead>
                    )}
                    <Tbody>
                        { data && data.result?.length > 0 ? (
                            data.result?.map((quiz) => (
                                <AdminQuizItem key={quiz.id} quiz={quiz} isAdminQuiz={quiz?.quizType ? quiz.quizType === 'adminQuiz' : Boolean(isSuperAdmin)} />)
                            )
                        ) : (
                            <>
                                {!isLoading && (
                                    <Tr>
                                        <Td>
                                            <Box>No list items</Box>
                                        </Td>
                                    </Tr>
                                )}
                            </>
                        )}
                    </Tbody>
                </Table>

                {data && data?.navigation?.total_pages > 1 && (
                    <Flex justifyContent={'end'} mt={'20px'}>
                        <Pagination currentPage={activePage} totalPages={data.navigation.total_pages} handlePagination={handlePaginationChange} />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};