import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { isNotNull } from '@/utils/helpers';
import { getTags, Tag } from '@/features/Quiz';
import { RootState } from '../../../store';

export type TagType = Tag;

export const getQuizTags = createAsyncThunk('quiz/tag/getQuizTags', () => getTags());

const tagAdapter = createEntityAdapter<TagType>();

type State = {
  total: number;
  isLoading: boolean;
};

const tagSlice = createSlice({
  name: 'tags',
  initialState: tagAdapter.getInitialState<State>({
    total: 0,
    isLoading: false,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuizTags.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getQuizTags.fulfilled, (state, action) => {
      tagAdapter.upsertMany(state, action.payload);
      state.total = action.payload.length;
      state.isLoading = false;
    });
    builder.addCase(getQuizTags.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const tags = tagSlice.reducer;

const selectState = (state: RootState) => state.quizzes.tags;

export const { selectById, selectIds } = tagAdapter.getSelectors(selectState);

const selectRootState = (state: RootState) => state;

export const selectTagById = createSelector(
  [(state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
  (entity) => entity
);
export const selectAllTags = createSelector([selectIds, selectRootState], (ids, state) =>
  ids.map((id) => selectTagById(state, Number(id))).filter(isNotNull)
);
export const selectTagsTotal = createSelector([selectState], (state) => state.total);
export const selectTagsLoading = createSelector([selectState], (state) => state.isLoading);
