import { DocRenderer } from '@cyntler/react-doc-viewer';
import React from 'react';

const DocViewerOfficePlugin: DocRenderer = ({ mainState: { currentDocument } }) => {
    if (!currentDocument) return null;

    return <iframe width="100%" height="100%" src={`https://docs.google.com/gview?url=${encodeURIComponent(currentDocument.uri)}&embedded=true`} />;
};

DocViewerOfficePlugin.fileTypes = [
    'docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'doc',
    'application/msword',
    'ppt',
    'application/vnd.ms-powerpoint',
    'pptx',
    'applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation',
    'xls',
    'application/vnd.ms-excel',
    'xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'odt',
    // 'application/vnd.oasis.opendocument.text',
];
DocViewerOfficePlugin.weight = 1;

export default DocViewerOfficePlugin;
