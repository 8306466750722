import { API_URL } from "@/config";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation, useQueryClient } from "react-query";
import { ClassItem } from "../../types";

type AddData = {
    classId: number;
    quizzesIds: number[];
}

const addQuizToClass = (data: AddData): Promise<ClassItem> => {
    return axios.post(`${API_URL}/api/admin/class/quiz`, { quizzesIds: data.quizzesIds, classIds: [data.classId] });
}

export const useAddQuizToClass = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation({
        onMutate: async ({ classId }) => {
            await queryClient.cancelQueries(['getClass', classId]);
        },
        onError: (e: any) => {
            addNotification({
                type: 'error',
                title: e.message,
            });
        },
        onSuccess: async (_,{ classId } ) => {
            await queryClient.refetchQueries(['getClass', classId]);
            addNotification({
                type: 'success',
                title: 'Quiz successfully added',
            });
        },
        mutationFn: addQuizToClass,
    })
}