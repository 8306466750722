import React, { ReactNode, useState } from 'react';
import { Tr, Td, Text, useDisclosure, Flex, Box, Tooltip, Spinner } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { QuizType } from '@/redux/reducers/quizzes/quizSlice';
import { DeleteQuizModal } from '../DeleteQuizModal';
import { format } from 'date-fns';
import Icon from "@/components/Icon";
import { useQti } from "@/api/qtiApi";
import InstitutionsModal from "@/features/Playlists/components/InstitutionsModal";
import { usePlaylistCC } from "@/api/ccApi";
import { InstitutionModalTypes } from "@/features/Playlists";
import { usePlaylistScorm } from "@/api/scormApi";

type Props = {
    quiz: QuizType;
};

const TextEl = ({ children }: { children: ReactNode }) => (
    <Text color="black" lineHeight={'21px'} textAlign="center"
          display="inline-block" p="0 10px" w={'auto'}>
        {children}
    </Text>
);

export const QuizListItem = ({ quiz }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isInstitutionsOpen, onOpen: onInstitutionsOpen, onClose: onInstitutionsClose } = useDisclosure();
    const [type, setType] = useState<InstitutionModalTypes>(null);

    const downloadType = "from_quiz_template"

    const { isLoading: isQtiLoading, handleQtiDownload } = useQti({
        qtiType: downloadType,
        qtiId: quiz?.id ?? 0,
        title: quiz?.title ?? ''
    });

    const { isLoading: isCCLoading, handleCCDownload } = usePlaylistCC({
        ccType: downloadType, ccId: quiz?.id ?? 0, title: quiz?.title ?? ''
    });

    const { isLoading: isScormLoading, handleScormDownload } = usePlaylistScorm({
        scormType: downloadType, scormId: quiz?.id ?? 0, playlistTitle: quiz?.title ?? ''
    });

    const handleOpenInstitutionsQti = () => {
        setType('qti');
        onInstitutionsOpen();
    };

    const handleOpenInstitutionsCC = () => {
        setType('cc');
        onInstitutionsOpen();
    };

    const handleOpenInstitutionsScorm = () => {
        setType('scorm');
        onInstitutionsOpen();
    };

    return (
        <>
            <InstitutionsModal
                isOpen={isInstitutionsOpen}
                onClose={onInstitutionsClose}
                handleQtiDownload={handleQtiDownload}
                handleScormDownload={handleScormDownload}
                handleCCDownload={handleCCDownload}
                isLoading={isScormLoading || isCCLoading || isQtiLoading}
                type={type}
            />
            <DeleteQuizModal isOpen={isOpen} onClose={onClose} quiz={quiz} quizType={'template'} />
            <Tr>
                <Td w={'65%'} py={'10px'}>
                    <Flex as={Link} py={'10px'}
                          to={`/quiz/view/${quiz.id}`}>
                        <Text color={'black'} lineHeight={'20px'}
                              dangerouslySetInnerHTML={{ __html: quiz.title }} />
                    </Flex>
                </Td>
                <Td textAlign="center" w="10%">
                    <TextEl>
                        {quiz.createdAt ? format(new Date(quiz.createdAt), 'MM/dd/yyyy') : '-'}
                    </TextEl>
                </Td>
                <Td textAlign="center" w="15%">
                    <TextEl>
                        {quiz.countOfQuestions} Questions
                    </TextEl>
                </Td>

                <Td w="auto" maxWidth={'260px'}>
                    <Flex columnGap={'10px'} align="center" justifyContent={'space-around'}>
                        <Box
                            as={Link}
                            to={`/quiz/edit/${quiz.id}`}
                            borderRadius={'16px'}
                            height={'28px'}
                        >
                            <Tooltip hasArrow label={'Edit quiz'} aria-label={'Edit quiz'}>
                                <span><Icon highlightOnHover icon='pencil' /></span>
                            </Tooltip>
                        </Box>

                        <Box
                            as={Link}
                            to={`/quiz/copy/${quiz.id}`}
                            borderRadius={'16px'}
                            height={'28px'}
                        >
                            <Tooltip hasArrow label={'Copy quiz'} aria-label={'Copy quiz'}>
                                <span><Icon highlightOnHover icon='clone' /></span>
                            </Tooltip>
                        </Box>
                        <Box
                            onClick={handleOpenInstitutionsScorm}
                        >
                            <Tooltip hasArrow label={'Download SCORM'} aria-label={'Download SCORM'}>
                                {isScormLoading ?
                                    <Spinner size={'xs'} />
                                    :
                                    <span><Icon highlightOnHover icon='file-zipper' /></span>
                                }
                            </Tooltip>
                        </Box>
                        <Box
                            onClick={handleOpenInstitutionsQti}
                        >
                            <Tooltip hasArrow label={'Download QTI'} aria-label={'Download QTI'}>
                                <span><Icon fontSize={'20px'} highlightOnHover icon='scroll' /></span>
                            </Tooltip>
                        </Box>
                        <Box
                            onClick={handleOpenInstitutionsCC}
                        >
                            <Tooltip hasArrow label={'Download CC'} aria-label={'Download CC'}>
                                {isCCLoading ?
                                    <Spinner size={'xs'} />
                                    :
                                    <span><Icon fontSize={'20px'} highlightOnHover icon='closed-captioning'/></span>
                                }
                            </Tooltip>
                        </Box>
                        <Box
                            onClick={onOpen}
                            fontSize={'0.875rem'}
                            color={'#cc0000'}
                            fontWeight={500}
                            cursor={'pointer'}
                        >
                            <Tooltip hasArrow label={'Delete quiz'} aria-label={'Delete quiz'}>
                                <span><Icon highlightOnHover icon='trash' /></span>
                            </Tooltip>
                        </Box>
                    </Flex>
                </Td>
            </Tr>
        </>
    );
};
