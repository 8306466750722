import { axios } from "@/lib/axios";
import { Quiz } from "@/types";
import { useQuery } from "react-query";

export type GetQuizzesData = {
    navigation: {
        total_pages: number;
        items_per_page: number,
        page: number,
        more: boolean,
        items_on_page: number
    },
    result: Quiz[]
}

type FilterCollection = {
    bool_operator?: string;
    condition?: {
        property: string;
        operator: string;
        value: string | number;
    };
};

type GetListBodyStructure = {
    navigation: {
        page: number;
        itemsOnPage: number;
    };
    totalCount: boolean;
    filter: {
        collection: FilterCollection[] | null;
    };
    sort: string[][];
};

const getQuizzesList = (): Promise<GetQuizzesData> => {
    return axios.get('/api/admin/quiz/list');
}

const postQuizzesList = (data: GetListBodyStructure): Promise<GetQuizzesData> => {
    return axios.post('/api/admin/class/quiz_list', data);
};

type Params = {
    activePage: number;
    activeSearchKeyword: string | number;
    itemsOnPage: number;
    isModalOpen: boolean;
}

export const buildQuizzesParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    itemsOnPage = 20,
    withAssign?: null,
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage,
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword
                    ? [
                        {
                            bool_operator: 'or',
                            condition: {
                                property: Number.isInteger(activeSearchKeyword) ? 'id' : 'title',
                                operator: Number.isInteger(activeSearchKeyword) ? 'equals' : 'contains',
                                value: Number.isInteger(activeSearchKeyword) ? activeSearchKeyword : activeSearchKeyword || '',
                            },
                        },
                    ]
                    : []),
            ],
        },
        sort: [['id', 'desc']],
        withAssign,
    };
};

export const useGetQuizzesList = () => {
    return useQuery({
        queryKey: ['quizzesList'],
        queryFn: () => getQuizzesList(),
    })
}

export const usePostQuizzesList = ({ activePage, activeSearchKeyword, itemsOnPage, isModalOpen }: Params) => {
    return useQuery({
        queryKey: ['postQuizzesList', activePage, activeSearchKeyword, itemsOnPage, isModalOpen],
        queryFn: () => isModalOpen ? postQuizzesList(buildQuizzesParams(activePage, activeSearchKeyword, itemsOnPage)) : null,
        useErrorBoundary: (error: any) => error.response?.status >= 400,
    })
}