import React, {FC, SetStateAction, Dispatch, useState} from 'react';
import {
    Box,
    Button,
    Divider,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text,
    VStack,
    MenuItem
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import Icon from '@/components/Icon';

type Props = {
    title: string,
    variant: string,
    dateFrom?: Date,
    dateTo?: Date,
    setDateFrom?: Dispatch<SetStateAction<Date | undefined>>,
    setDateTo?: Dispatch<SetStateAction<Date | undefined>>,
    setValue?: Dispatch<SetStateAction<string>>
}

const ModalFilter: FC<Props> = ({title, variant, setValue, dateFrom, dateTo, setDateFrom , setDateTo}) => {
    const [inputText, setInputText] = useState('');
    const [from, setFrom] = React.useState<Date>(new Date('01/01/2000'));
    const [until, setUntil] = React.useState<Date>(new Date());

    const clear = () => {
        if (setValue && variant !== 'datePicker') {
            setInputText('');
            setValue('');
        } else if (setDateFrom && setDateTo && variant === 'datePicker') {
            setDateFrom(new Date('01/01/2000'));
            setDateTo(new Date())
        }
    }

    const handleSaveFilter = () => {
        if (setValue) {
            setValue(inputText);
        } else if (setDateFrom && setDateTo && variant === 'datePicker') {
            setDateFrom(from);
            setDateTo(until)
        }
    }
    return (
        <Stack sx={{ '.datePicker': { bg: '#EDEDED', px: '8px', py: '5px' } }} spacing={3}>
            <Box as={'form'}>
                <Text size="xs" fontWeight="bold" color="GrayText" px="20px">
                    {variant === 'datePicker' ? title : `Search ${title}`}
                </Text>
                {variant === 'input' && <Flex align="center" justify="space-between" p="10px 20px">
                    <InputGroup size="sm">
                        <Input
                            value={inputText}
                            placeholder="Search"
                            borderRadius="full"
                            bg="#EDEDED"
                            color="black"
                            _placeholder={{ color: 'GrayText' }}
                            onChange={(e) => setInputText(e.target.value)}
                        />
                        <InputRightElement color='brand.blue'>
                            <Icon icon='magnifying-glass' fontSize="12px" />
                        </InputRightElement>
                    </InputGroup>
                </Flex>}

                {variant === 'datePicker' && <Flex gap='2' p="10px 20px">
                    <VStack alignItems={'flex-start'}>
                        <Text size="xs" fontWeight="bold" color="GrayText">
                            From:
                        </Text>
                        <DatePicker className="datePicker" selected={dateFrom !== undefined ? dateFrom : from} onChange={(date: Date) => setFrom(date)} />
                    </VStack>
                    <VStack alignItems={'flex-start'}>
                        <Text size="xs" fontWeight="bold" color="GrayText" align="start">
                            Until:
                        </Text>
                        <DatePicker className="datePicker" selected={dateTo !== undefined ? dateTo : until} onChange={(date: Date) => setUntil(date)} />
                    </VStack>
                </Flex>}

                <Divider pt="5px" />
                <Box px="20px" pt="10px">
                    <Flex align="center" justify="space-between" h="max-content">
                        <MenuItem w={'56px'}>
                            <Button
                                _focus={{}}
                                _active={{}}
                                _hover={{}}
                                color="GrayText"
                                variant="ghost"
                                fontWeight="normal"
                                fontSize="sm"
                                textDecor="underline"
                                size="sm"
                                onClick={clear}
                            >
                                Clear
                            </Button>
                        </MenuItem>
                        <MenuItem w={'76px'}>
                            <Button onClick={handleSaveFilter} bg="primary.blue" colorScheme="blue" fontWeight="normal" fontSize="sm" size="sm">
                                Save
                            </Button>
                        </MenuItem>
                    </Flex>
                </Box>
            </Box>
        </Stack>
    );
}

export default ModalFilter
