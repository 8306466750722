import React, {MouseEvent, useCallback, useState} from 'react';
import {Button, Flex, Tooltip, useDisclosure} from '@chakra-ui/react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {ActivityItem, Quiz, QuizFormData} from '@/features/Quiz/types';
import ImportPlaylist from '../ImportPlaylist';
import {AddVideoModal} from '@/features/Playlists/components/PlaylistForm/AddVideoModal';
import {getQuizTemplateByArticleId} from '@/features/Quiz/api/getQuizzes';
import Icon from "@/components/Icon";

type Props = {
    indexActivity: number;
    isLibrary?: boolean;
};

export const RightQuizAddButton = ({indexActivity, isLibrary}: Props) => {
    const {
        isOpen: isImportPlaylistOpen,
        onOpen: onImportPlaylistOpen,
        onClose: onImportPlaylistClose
    } = useDisclosure();
    const [isOpen, setOpen] = useState(false);
    const [importQuestion, setImportQuestion] = useState(true);

    const {watch, control} = useFormContext<QuizFormData>();
    const {append, replace} = useFieldArray<QuizFormData, `activities.${number}.items`>({
        control,
        name: `activities.${indexActivity}.items`,
    });
    const activityItems = watch(`activities.${indexActivity}.items`);

    const {isOpen: isAddVideoOpen, onOpen: onAddVideoOpen, onClose: onAddVideoClose} = useDisclosure();

    const handleAddVideo = useCallback(
        async (videoId: number | number[]) => {
            setOpen(false);
            const action = await getQuizTemplateByArticleId(Number(videoId));

            let data: ActivityItem[] = [];

            if (action) {
                action.activities &&
                action.activities.forEach((activity) => {
                    activity.items?.sort((a, b) => a.sortIndex - b.sortIndex).forEach((item) => {
                        const {id: customItemId, ...dataItem} = item;

                        if (item.article == null && importQuestion) {
                            data.push({
                                ...dataItem,
                                originId: null,
                                rightAnswer: dataItem.rightAnswer ?? [1],
                                textOptions: dataItem.textOptions?.map((textOption) => ({
                                    originId: textOption.id,
                                    text: textOption.text,
                                })) ?? [
                                    {
                                        originId: 1,
                                        text: '',
                                    },
                                    {
                                        originId: 2,
                                        text: '',
                                    },
                                    {
                                        originId: 3,
                                        text: '',
                                    },
                                    {
                                        originId: 4,
                                        text: '',
                                    },
                                ],
                                isSelect: true,
                            });
                        } else if (item.article != null) {
                            data.push({
                                ...dataItem,
                                originId: null,
                                isSelect: true,
                                rightAnswer: [],
                                textOptions: [],
                            });
                        }
                    });
                });
            }

            replace([
                ...activityItems.sort((a, b) => a.sortIndex - b.sortIndex),
                ...data,
            ].map((item, indexItem) => ({
                ...item,
                sortIndex: indexItem + 1,
            })));
        },
        [replace, activityItems, importQuestion],
    );

    const handleAddItemClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            setOpen(false);

            append({
                article: null,
                originId: null,
                title: '',
                type: 2,
                image: '',
                isSelect: true,
                isMultiple: false,
                sortIndex: activityItems.length + 1,
                rightAnswer: [1],
                textOptions: [
                    {
                        originId: 1,
                        text: '',
                    },
                    {
                        originId: 2,
                        text: '',
                    },
                    {
                        originId: 3,
                        text: '',
                    },
                    {
                        originId: 4,
                        text: '',
                    },
                ],
            });
        },
        [append, activityItems]
    );

    const handleAddPlayListClick = useCallback((e: MouseEvent) => {
        e.preventDefault();
        onImportPlaylistOpen();
    }, []);

    const handleOpenAddItemClick = useCallback((e: MouseEvent) => {
        e.preventDefault();
        setOpen((state) => !state);
    }, []);

    const handleImportCheckbox = useCallback(() => {
        setImportQuestion((preState) => !preState);
    }, [importQuestion]);

    return (
        <>
            <Flex justify="center" flexDir="row" mb="1px" fontSize="15px" fontWeight="500" color="primary.greyText"
                  lineHeight="15px">
                {isLibrary && (
                    <>
                        <Button
                            bg="primary.blue"
                            borderColor="primary.black"
                            borderWidth="2px"
                            borderRadius="0"
                            onClick={handleAddPlayListClick}
                            size="md"
                            cursor="pointer"
                            p="25px 20px"
                            fontSize="17px"
                            lineHeight="36px"
                            color="primary.black"
                            _hover={{
                                color: 'primary.blue',
                                bg: 'white',
                            }}
                            _focus={{outline: 0}}
                            _active={{}}
                        >
                            <Icon icon='list-music' fontSize="20px"/>
                        </Button>
                        <Button
                            bg="primary.blue"
                            borderColor="primary.black"
                            borderWidth="2px"
                            borderRadius="0"
                            onClick={onAddVideoOpen}
                            size="md"
                            cursor="pointer"
                            p="25px 20px"
                            fontSize="17px"
                            lineHeight="36px"
                            color="primary.black"
                            _hover={{
                                color: 'primary.blue',
                                bg: 'white',
                            }}
                            _focus={{outline: 0}}
                            _active={{}}
                        >
                            <Icon icon='video-plus' fontSize="20px"/>
                        </Button>
                    </>
                )}
            </Flex>
            {isLibrary && (
                <>
                    <ImportPlaylist isOpen={isImportPlaylistOpen} onClose={onImportPlaylistClose}
                                    indexActivity={indexActivity}/>
                    <AddVideoModal
                        isOpen={isAddVideoOpen}
                        onClose={onAddVideoClose}
                        onSelect={handleAddVideo}
                        setImportQuestion={handleImportCheckbox}
                    />
                </>
            )}
            <Button
                variant={'dashedOutline'}
                borderColor="primary.blue"
                onClick={handleAddItemClick}
                borderRadius="100px"
                size="md"
                cursor="pointer"
                w="100%"
                _hover={{}}
                _focus={{outline: 0}}
                _active={{}}
            >
                <Tooltip hasArrow label={'Add question'}
                         aria-label={'Add Questions'}>
                    <span>
                        <Icon icon='circle-plus' fontSize="20px"/>
                    </span>
                </Tooltip>
            </Button>
        </>
    );
};
