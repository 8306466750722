import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import Icon from '@/components/Icon';
import React from 'react';
import { TranslationGroupForm, TranslationGroupFormData } from './Components/TranslationForm/TranslationGroupForm';
import { useAddTranslationGroup } from '@/features/Translations/api/addTranslationGroup';

export const AddTranslationGroup = () => {
    const navigate = useNavigate();

    const addTranslationMutation = useAddTranslationGroup();

    const onSubmit = async (data: TranslationGroupFormData) => {
        await addTranslationMutation.mutate({ data });
    };

    return (
        <Flex flexDir={'column'} margin={'0 auto'} maxW={'800px'}>
            <Flex mb={3} alignItems={'center'} justifyContent={'space-between'}>
                <Heading size="lg">Add New translation group</Heading>

                <Button variant="back" onClick={() => navigate(`/translations/groups`)} leftIcon={<Icon icon={'chevron-left'} />}>
                    Back
                </Button>
            </Flex>

            <Box bgColor={'#ffffff'} p={4}>
                <TranslationGroupForm onSubmit={onSubmit} />
            </Box>
        </Flex>
    );
};
