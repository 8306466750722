import React from 'react';
import {
    Th,
    Flex,
    Text,
    Box
} from '@chakra-ui/react';

import Icon from '@/components/Icon';

type Props = {
    value: string,
    activeSortProperty: string,
    activeSortDirection: string,
    handleSortSelect: (value: string) => void,
    children: React.ReactNode;
}

const SortableTableHeader = (props : Props) => {
    const {value, activeSortDirection, activeSortProperty, handleSortSelect, children} = props;
    const isActive = activeSortProperty.toLowerCase() === value.toLowerCase();
    return (
        <Th
            cursor="pointer"
            onClick={() => handleSortSelect(value)}
        >
            <Flex
                alignItems="center"
                border="none"
                className="d-flex align-items-center"
                maxWidth="200px"
                color={isActive ? "blue.300" : '#4a5568'}
            >
              <Box paddingRight={'8px'}>
                  {children}
              </Box>
                <Icon
                    style={{ paddingLeft: '5px' }}
                    icon={(isActive && activeSortDirection === 'asc') ? 'caret-up' : 'caret-down'}
                />
            </Flex>
        </Th>
    );
};

export default SortableTableHeader;
