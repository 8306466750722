import {Box, Button, Icon} from '@chakra-ui/react';
import DocViewer from '@cyntler/react-doc-viewer';
import { IDocument } from '@cyntler/react-doc-viewer/dist/esm/models';
import BMPRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/bmp';
import CSVRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/csv';
import HTMLRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/html';
import ImageProxyRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/image';
import JPGRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/jpg';
import PDFRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/pdf';
import PNGRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/png';
import TIFFRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/tiff';
import TXTRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/txt';
import { IMainState } from '@cyntler/react-doc-viewer/dist/esm/store/mainStateReducer';
import DocViewerOfficePlugin from '@/components/DocViewerPlugin';
import { API_URL } from "@/config";

import {
    downloadDocumentByIdForProfessor,
    getDocumentTokenForProfessor,
} from '@/features/Classes/api/documents';
import FileDownload from 'js-file-download';
import React, { memo, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {ErrorBlock} from "@/components/ErrorBlock";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {FaFileDownload, FaTrashAlt} from "react-icons/fa";

const getFileType = (extension: string = '') => {
    if (extension === 'bmp') return 'image/bmp';
    if (extension === 'csv') return 'text/csv';
    if (extension === 'odt') return 'application/vnd.oasis.opendocument.text';
    if (extension === 'doc') return 'application/msword';
    if (extension === 'docx') return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (extension === 'gif') return 'image/gif';
    if (extension === 'htm') return 'text/htm';
    if (extension === 'html') return 'text/html';
    if (extension === 'jpg') return 'image/jpg';
    if (extension === 'jpeg') return 'image/jpeg';
    if (extension === 'pdf') return 'application/pdf';
    if (extension === 'png') return 'image/png';
    if (extension === 'ppt') return 'application/vnd.ms-powerpoint';
    if (extension === 'pptx') return 'applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation';
    if (extension === 'tiff') return 'image/tiff';
    if (extension === 'xls') return 'application/vnd.ms-excel';
    if (extension === 'xlsx') return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return 'text/plain';
};

const blobToBase64 = (blob: Blob): Promise<ArrayBuffer | string> => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result || '');
        reader.readAsDataURL(blob);
    });
};

const MyNoRenderer = () => {
    return <ErrorBlock error={'Error'} textOnly={false} />;
};

const MyLoading = () => {
    return <LoadingSpinner isFullScreen />;
};

const OverrideComponent: React.FunctionComponent<IMainState> = (state) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const data = state as Omit<IMainState, 'currentDocument'> & { currentDocument?: IDocument & { data: Blob } };

    const handleDownloadClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();

            if (data.currentDocument) {
                FileDownload(data.currentDocument.data, data.currentDocument?.fileName ?? '');
            }
        },
        [data.currentDocument]
    );

    useEffect(() => {
        if (ref.current && data.currentDocument?.fileType === 'application/pdf') {
            const childrens = [...ref.current.children];

            const hasElementPdfZoomIn = childrens.find((item) => item.id === 'pdf-zoom-in');
            const hasElementPdfZoomOut = childrens.find((item) => item.id === 'pdf-zoom-out');

            if (!hasElementPdfZoomIn) {
                const elementPdfZoomIn = document.getElementById('pdf-zoom-in');
                if (elementPdfZoomIn) {
                    ref.current.appendChild(elementPdfZoomIn);
                }
            } else {
                ref.current.removeChild(hasElementPdfZoomIn);
                const elementPdfZoomIn = document.getElementById('pdf-zoom-in');
                if (elementPdfZoomIn) {
                    ref.current.appendChild(elementPdfZoomIn);
                }
            }

            if (!hasElementPdfZoomOut) {
                const elementPdfZoomOut = document.getElementById('pdf-zoom-out');
                if (elementPdfZoomOut) {
                    ref.current.appendChild(elementPdfZoomOut);
                }
            } else {
                ref.current.removeChild(hasElementPdfZoomOut);
                const elementPdfZoomOut = document.getElementById('pdf-zoom-out');
                if (elementPdfZoomOut) {
                    ref.current.appendChild(elementPdfZoomOut);
                }
            }
        }
    }, [data]);

    if (data.documentLoading) {
        return <></>;
    }

    return (
        <Box ref={ref} width="100%" minHeight="40px" display="flex" justifyContent="center" p="10px">
            <Button
                color="#2183ED"
                fontWeight="500"
                leftIcon={<Icon as={FaFileDownload} />}
                boxShadow="0px 4px 4px rgba(33, 131, 237, 0.15)"
                border="1px solid #2183ED"
                borderRadius="20px"
                backgroundColor="#E3F2FE"
                onClick={handleDownloadClick}>
                Download
            </Button>
        </Box>
    );
};

export const ClassDocumentView = memo(() => {
    const { id, documentId } = useParams();

    const [isError, setIsError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [documents, setDocuments] = useState<{
        uri: string;
        fileData: ArrayBuffer | string;
        fileType: string;
        fileName: string;
        data: Blob;
    } | null>(null);

    useEffect(() => {
        const getDocument = async () => {
            try {
                setLoading(true);
                const getTokenResponse = await getDocumentTokenForProfessor(Number(id), Number(documentId));

                const response = await downloadDocumentByIdForProfessor(Number(id), Number(documentId));

                const headerLine = response.request.getResponseHeader('Content-Disposition');
                const filename = headerLine.substring(headerLine.indexOf('=') + 1, headerLine.lastIndexOf('')).replaceAll('"', '');
                const extension = filename.substring(filename.lastIndexOf('.') + 1);

                const fileData = await blobToBase64(response.data);

                setDocuments({
                    uri: `${API_URL}/file/class/${id}/document/${documentId}/${getTokenResponse.token}`,
                    fileData,
                    fileType: getFileType(extension),
                    fileName: filename,
                    data: response.data,
                });

                setIsError(false);
                setLoading(false);
            } catch (e) {
                setIsError(true);
                setLoading(false);
                setDocuments(null);
            }
        };

        getDocument();
    }, [documentId, id]);

    if (!id || !documentId || isError) {
        return <ErrorBlock error={'Error'} textOnly={false} />;
    }

    if (isLoading || !documents) {
        return <MyLoading />;
    }

    return (
        <>
            <DocViewer
                style={{
                    width: '100%',
                    minHeight: '100%',
                }}
                pluginRenderers={[
                    TXTRenderer,
                    TIFFRenderer,
                    PNGRenderer,
                    PDFRenderer,
                    JPGRenderer,
                    ImageProxyRenderer,
                    HTMLRenderer,
                    CSVRenderer,
                    BMPRenderer,
                    DocViewerOfficePlugin,
                ]}
                documents={[documents]}
                config={{
                    loadingRenderer: {
                        overrideComponent: MyLoading,
                        showLoadingTimeout: false,
                    },
                    noRenderer: {
                        overrideComponent: MyNoRenderer,
                    },
                    header: {
                        disableFileName: true,
                        overrideComponent: OverrideComponent,
                    },
                    pdfVerticalScrollByDefault: true,
                }}
                initialActiveDocument={documents}
                activeDocument={documents}
            />
        </>
    );
});
