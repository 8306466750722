import { combineReducers } from '@reduxjs/toolkit';
import dataReducer from './data.reducer';
import { playlistReducer } from './playlist/playlistReducers';
import { quizzes } from './quizzes/quizzesReducer';
import { classesReducer } from './classes/classesSlice';
import auth from './auth.reducer';

export const rootReducer = combineReducers({
    data: dataReducer,
    playlistReducer,
    quizzes,
    auth,
    classes: classesReducer,
});
