import { createClass } from '@/features/Classes/api/classes/createClass';
import { getAllClasses as getClasses } from '@/features/Classes/api/classes/getAllClasses';
import { getClass } from '@/features/Classes/api/classes/getClass';
import { updateClass } from '@/features/Classes/api/classes/updateClass';
import { getDocumentsList } from '@/features/Classes/api/documents';
import { getClassPlaylists } from '@/features/Classes/api/playlists/getPlaylistsList';
import { ClassQuizzes, getQuizzes } from '@/features/Classes/api/quizzes/getQuizzes';
import {
    ClassCreateData,
    ClassDocument,
    ClassItem,
    ClassItemResponse,
    ClassPlaylistsResponse,
    ClassUpdateData,
    PlaylistListItemData,
} from '@/features/Classes/types';

import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    isFulfilled,
    isPending,
    isRejected,
    PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { copyClass } from '@/features/Classes/api/classes/copyClass';

type State = {
    deletedClass: null | number;
    currentClass: ClassItem | null;
    classPlaylists: {
        playlist: PlaylistListItemData;
        addedById: number;
        originId: number | null;
        playlistId: number;
    }[];
    playlistVideosCount: {
        [key: number]: number;
    }[];
    classQuizzes: ClassQuizzes | null;
    isError: boolean;
    isLoading: boolean;
    isLoadingClass: boolean;
    classDocuments: ClassDocument[] | null | void;
    deletedDocument: null | number;
    shareDocument: null | { url: string; email: string };
};

const getAllClasses = createAsyncThunk<
    ClassItem[],
    void,
    {
        state: RootState;
        rejectWithValue: string;
    }
>('classes/getAllClasses', async (_, { rejectWithValue }) => {
    try {
        return await getClasses();
    } catch (error: any) {
        let message = '';
        if (error instanceof Error) {
            message = error.message || error.toString();
        }

        return rejectWithValue(message);
    }
});

const getClassById = createAsyncThunk<ClassItemResponse, number, { state: RootState; rejectWithValue: string }>(
    'classes/getClassById',
    async (id, { rejectWithValue }) => {
        try {
            return await getClass(id);
        } catch (error: any) {
            let message = '';
            if (error instanceof Error) {
                message = error.message || error.toString();
            }

            return rejectWithValue(message);
        }
    }
);

const storeNewClass = createAsyncThunk('classes/storeClass', (data: ClassCreateData) => createClass(data));

const copyClassById = createAsyncThunk('classes/copyClass', (id: number) => copyClass(id));

const editClass = createAsyncThunk('classes/updateClass', ({ id, data }: { id: number; data: ClassUpdateData }) =>
    updateClass({ classId: id, data: data })
);

export const getPlaylists = createAsyncThunk<
    ClassPlaylistsResponse | null | void,
    number,
    {
        state: RootState;
        rejectWithValue: string;
    }
>('classes/getClassPlaylists', async (classId, { getState, rejectWithValue }) => {
    const { currentClass } = getState().classes;
    if (currentClass) {
        try {
            return await getClassPlaylists(classId);
        } catch (error) {
            let message = '';
            if (error instanceof Error) {
                message = error.message || error.toString();
            }
            return rejectWithValue(message);
        }
    }
});

export const getClassQuizzes = createAsyncThunk<ClassQuizzes | null, number>('classes/getQuizzes', async (classId, { rejectWithValue }) => {
    try {
        return await getQuizzes(classId);
    } catch (error) {
        let message = '';
        if (error instanceof Error) {
            message = error.message || error.toString();
        }
        return rejectWithValue(message);
    }
});

export const getDocuments = createAsyncThunk<
    ClassDocument[] | null | void,
    number,
    {
        state: RootState;
        rejectWithValue: string;
    }
>('classes/getDocuments', async (classId, { rejectWithValue }) => {
    try {
        return await getDocumentsList(classId);
    } catch (error) {
        let message = '';
        if (error instanceof Error) {
            message = error.message || error.toString();
        }
        return rejectWithValue(message);
    }
});

const classesAdapter = createEntityAdapter<ClassItem>({
        sortComparer: (a, b) => b.id - a.id,
});

const classesSlice = createSlice({
    name: 'classesSlice',
    initialState: classesAdapter.getInitialState<State>({
        deletedClass: null,
        currentClass: null,
        isLoadingClass: false,
        classPlaylists: [],
        playlistVideosCount: [],
        classQuizzes: null,
        isError: false,
        isLoading: false,
        classDocuments: [],
        deletedDocument: null,
        shareDocument: null,
    }),
    reducers: {
        deleteClassById: (state, action: PayloadAction<{ id: number; }>) => {
            classesAdapter.removeOne(state, action.payload.id);
            state.deletedClass = action.payload.id;
        },
        resetDeletedClass: (state) => {
            state.deletedClass = null;
        },
        deleteProfessorDocument: (state, action) => {
            state.classDocuments = state.classDocuments?.filter((item) => item.id !== action.payload);
            state.deletedDocument = action.payload;
        },
        deleteCanceledDocument: (state, action: PayloadAction<number>) => {
            state.classDocuments = state.classDocuments?.filter((item) => item.id !== action.payload);
        },
        resetDeletedDocument: (state) => {
            state.deletedDocument = null;
        },
        setShareDocument: (state, action: PayloadAction<{ url: string; email: string }>) => {
            state.shareDocument = action.payload;
        },
        resetShareDocument: (state) => {
            state.shareDocument = null;
        },
        deleteClassPlaylist: (state, action) => {
            state.classPlaylists = state.classPlaylists?.filter((item) => item.playlist.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllClasses.fulfilled, (state, action) => {
                classesAdapter.setAll(state, action.payload);
                state.isLoading = false;
            })
            .addCase(storeNewClass.fulfilled, (state, action) => {
                classesAdapter.addOne(state, action.payload);
                state.isLoading = false;
            })
            .addCase(editClass.fulfilled, (state, { payload }) => {
                if (payload.id === state.currentClass?.id) {
                    state.currentClass = {
                        ...state.currentClass,
                        ...payload,
                    };
                }
                classesAdapter.updateOne(state, { id: payload.id, changes: payload });
                state.isLoading = false;
            })
            .addCase(getClassById.pending, (state) => {
                state.isLoadingClass = true;
            })
            .addCase(getClassById.fulfilled, (state, action) => {
                state.currentClass = action.payload.class;
                state.isLoadingClass = false;
            })
            .addCase(getClassById.rejected, (state) => {
                state.isLoadingClass = false;
            })
            .addCase(getPlaylists.fulfilled, (state, action) => {
                state.classPlaylists = action.payload?.playlists ?? [];
                state.playlistVideosCount = action.payload?.playlistVideosCount ?? [];
                state.isLoading = false;
            })
            .addCase(getClassQuizzes.fulfilled, (state, action) => {
                state.classQuizzes = action.payload;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(getClassQuizzes.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getDocuments.fulfilled, (state, action) => {
                state.classDocuments = action.payload;
                state.isLoading = false;
            })
            .addMatcher(isFulfilled, (state) => {
                state.isLoading = false;
            })
            .addMatcher(isPending, (state) => {
                state.isLoading = true;
            })
            .addMatcher(isRejected, (state) => {
                state.isLoading = false;
            });
    },
});

// selectors
const sliceState = (state: RootState) => state.classes;
const { selectAll, selectById } = classesAdapter.getSelectors(sliceState);

const selectClasses = createSelector(selectAll, (classes) => classes.filter((classItem) => !classItem.professor));
const selectSharedClasses = createSelector(selectAll, (classes) => classes.filter((classItem) => !!classItem.professor));
const selectCurrentClass = createSelector(sliceState, (state) => state.currentClass);
const selectPlaylistVideosCount = createSelector(sliceState, (state) => state.playlistVideosCount);
const selectStudentInCurrentClass = createSelector(sliceState, (state) => state.currentClass?.students);
const selectLoading = createSelector(sliceState, (state) => state.isLoading);
const selectLoadingClass = createSelector(sliceState, (state) => state.isLoadingClass);
const selectError = createSelector(sliceState, (state) => state.isError);
const selectClassPlaylists = createSelector(sliceState, (state) => state.classPlaylists);
const selectClassQuizzes = createSelector(sliceState, (state) => state.classQuizzes);
const selectClassDocuments = createSelector(sliceState, (state) => state.classDocuments);
const selectDeletedDocument = createSelector(sliceState, (state) => state.deletedDocument);

const selectDeletedClass = createSelector(sliceState, (state) => state.deletedClass);
const selectShareDocument = createSelector(sliceState, (state) => state.shareDocument);

export const classesReducer = classesSlice.reducer;
export const {
    deleteClassById,
    resetDeletedClass,
    deleteClassPlaylist,
    deleteProfessorDocument,
    deleteCanceledDocument,
    resetDeletedDocument,
    setShareDocument,
    resetShareDocument,
} = classesSlice.actions;

export {
    editClass,
    getAllClasses,
    getClassById,
    copyClassById,
    selectClasses,
    selectClassPlaylists,
    selectSharedClasses,
    selectCurrentClass,
    selectPlaylistVideosCount,
    selectStudentInCurrentClass,
    selectLoading,
    selectLoadingClass,
    selectError,
    selectClassQuizzes,
    selectClassDocuments,
    selectDeletedDocument,
    selectDeletedClass,
    selectShareDocument,
    storeNewClass,
};
