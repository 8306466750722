import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { getUserAreaDomain } from '@/utils/helpers';
import FileDownload from "js-file-download";
import { useState } from "react";
import { API_URL } from "@/config";
import { PackageDownloadTypes } from "@/types";

const getPlaylistCC = (ccType: PackageDownloadTypes, ccId: number, title: string, institutionId: number) => {
    return axios
        .get(`${API_URL}/api/admin/quiz/${ccType}/${ccId}/cc?videoLink=${getUserAreaDomain(window.location.origin)}/embed/player&institutionId=${institutionId}`,
            { responseType: 'blob' })
        .then((response) => {
            const file = new Blob([response.data]);
            FileDownload(file, `${title.toLowerCase().replace(/ /g, '-')}-cc-export.imscc`)
        });
};

export const usePlaylistCC = ({
                                  ccType,
                                  ccId,
                                  title
                              }: { ccType: PackageDownloadTypes, ccId: number; title: string; }) => {
    const { addNotification } = useNotificationStore();
    const [isLoading, setIsLoading] = useState(false);

    const handleCCDownload = async (institutionId: number) => {
        if (ccId && title) {
            try {
                setIsLoading(true);
                await getPlaylistCC(ccType, ccId, title, Number(institutionId));
                setIsLoading(false);
            } catch (error: any) {
                const responseObj = JSON.parse(await error.response.data.text());
                addNotification({
                    type: 'error',
                    title: responseObj.content.message,
                });
                setIsLoading(false);
            }
        }
    };
    return { isLoading, handleCCDownload }
};
