import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Spinner,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    Box,
    Td,
    Checkbox,
    FormControl,
    Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { HiX } from 'react-icons/hi';

import { debounce } from '@/utils/helpers';
import { Pagination } from '@/components/Pagination';
import Icon from '@/components/Icon';
import { useSponsorArticles } from '@/features/Sponsors/api/getSponsorArticles';
import { SponsorAllArticlesListItem } from '@/features/Sponsors/Components/SponsorAllArticlesListItem';
import { ArticleList, SponsorArticles } from '@/features/Sponsors/types';

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
    isSponsored: yup.boolean(),
    isEditorial: yup.boolean(),
});

export type SearchFormData = {
    keyword: string;
    isSponsored: boolean;
    isEditorial: boolean;
};

type Props = {
    sponsorId: number;
    articlesList?: ArticleList[];
};

export const SponsorsAllArticlesList = ({ sponsorId, articlesList }: Props) => {
    const [activePage, setActivePage] = useState<number>(1);
    const [activeSearchKeyword, setActiveSearchKeyword] = useState<string | number>('');

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { isSubmitting },
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
            isSponsored: false,
        },
    });

    const {
        isLoading,
        isFetching,
        data: articlesData,
    } = useSponsorArticles({
        activePage,
        activeSearchKeyword,
        activeSortDirection: 'asc',
        activeSortProperty: 'id',
        isSponsored: watch('isSponsored'),
    });

    useEffect(() => {
        setActivePage(1);
    }, [watch('isSponsored')]);

    const onSearchKeywordSubmit = useCallback((values: SearchFormData) => {
        setActivePage(1);
        setActiveSearchKeyword(values.keyword);
    }, []);

    const onSearchKeywordChange = debounce(
        useCallback((e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            setActivePage(1);
            setActiveSearchKeyword(e.target.value ?? '');
        }, []),
        300
    );

    const handleReset = useCallback(() => {
        reset();

        setActivePage(1);
        setActiveSearchKeyword('');
    }, []);

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <Box>
                    <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                        <Flex>
                            <InputGroup minW={'150px'} w={'200px'}>
                                <InputLeftElement pointerEvents={isSubmitting ? 'none' : 'auto'} onClick={handleSubmit(onSearchKeywordSubmit)}>
                                    {isSubmitting || isLoading || isFetching ? <Icon icon={'loader'} /> : <Icon icon={'loader'} />}
                                </InputLeftElement>
                                <Input
                                    placeholder="Search by ID"
                                    bg={'#ffffff'}
                                    id={'keyword'}
                                    type={'number'}
                                    w={'200px'}
                                    {...register('keyword')}
                                    onChange={onSearchKeywordChange}
                                />
                                {activeSearchKeyword && (
                                    <InputRightElement cursor="pointer" onClick={handleReset}>
                                        <HiX />
                                    </InputRightElement>
                                )}
                            </InputGroup>
                            <FormControl alignSelf={'center'} maxW={'100px'}>
                                <Checkbox
                                    {...register('isSponsored')}
                                    defaultChecked={watch('isSponsored')}
                                    ml="20px"
                                    maxW={'100px'}
                                    borderColor={'black'}
                                    isChecked={watch('isSponsored')}>
                                    Sponsored
                                </Checkbox>
                            </FormControl>
                        </Flex>
                    </form>
                    <Table bgColor={'#ffffff'}>
                        <Thead>
                            <Tr>
                                <Th>ID</Th>
                                <Th>Title</Th>
                                <Th textAlign={'center'}>Is Sponsored</Th>
                                <Th>Type</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {articlesData?.result?.length ? (
                                articlesData.result
                                    .reduce((result: SponsorArticles[], item) => {
                                        return result.map((itemDomain) => itemDomain.domain.id).includes(item.domain.id) ? result : [...result, item];
                                    }, [])
                                    .map((article) => (
                                        <SponsorAllArticlesListItem
                                            key={article.id}
                                            articleDomainId={article.domain.id}
                                            articleId={article.id}
                                            articleType={article.article_type}
                                            title={article.domain.title}
                                            isSponsored={!!article.sponsor_data}
                                            articlesList={articlesList}
                                            sponsorId={sponsorId}
                                        />
                                    ))
                            ) : (
                                <Tr>
                                    <Td>No articles found</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </Box>
            )}
            {articlesData && articlesData?.navigation?.total_pages > 1 && (
                <Pagination
                    currentPage={activePage}
                    totalPages={articlesData.navigation.total_pages}
                    handlePagination={(page: number) => setActivePage(page)}
                />
            )}
        </>
    );
};
