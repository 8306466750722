import React, { ChangeEvent, useCallback, useState, useEffect, } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Spinner,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    Box,
    Td,
    Checkbox,
    Text,
    Flex,
} from '@chakra-ui/react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { HiX } from "react-icons/hi";

import { debounce } from "@/utils/helpers";
import { Pagination } from '@/components/Pagination';
import Icon from "@/components/Icon";
import { useInstitutions } from "@/features/Institutions/api/getInstitutions";
import { InstitutionsListItem } from './InstitutionsListItem';
import { InstitutionModalTypes } from "@/features/Playlists";

let searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

export type SearchFormData = {
    keyword: string;
};

type Props = {
    handleScormDownload?: (id: number) => void;
    handleCCDownload?: (id: number) => void;
    handleQtiDownload?: (id: number) => void;
    handleProviderInstitutionId?: (id: number, title?: string) => void;
    handleAssignInstitutionId?: (id: number, title: string) => void;
    type: InstitutionModalTypes;
    isLoading?: boolean;
};

export const InstitutionsList = ({
    handleScormDownload,
    handleCCDownload,
    handleQtiDownload,
    handleProviderInstitutionId,
    handleAssignInstitutionId,
    isLoading,
    type
}: Props) => {
    const [activePage, setActivePage] = useState<number>(1);
    const [activeSearchKeyword, setActiveSearchKeyword] = useState<string | number>('');
    const [activeSortDirection,] = useState<string>('asc');
    const [activeSortProperty,] = useState<string>('title');
    const [isEducationSubscription, setIsEducationSubscription] = useState(true);
    const [isUnlimitedSubscription, setIsUnlimitedSubscription] = useState(true);

    const { isLoading: isLoadingInstitutions, data: institutionsData, isFetching } = useInstitutions({
        activePage,
        activeSearchKeyword: activeSearchKeyword,
        activeSortDirection,
        activeSortProperty,
        isApproved: false,
        isNumber: !!Number(activeSearchKeyword),
        subscription: (isEducationSubscription && isUnlimitedSubscription)
            ? 'education'
            : isEducationSubscription
                ? 'education-not-unlimited'
                : isUnlimitedSubscription
                    ? 'unlimited'
                    : null,
    });

    const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        }
    });

    const onSearchKeywordSubmit = useCallback(
        (values: SearchFormData) => {
            setActivePage(1);
            setActiveSearchKeyword(values.keyword.trim());
        },
        []
    );

    const onSearchKeywordChange = debounce(useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            setActivePage(1);
            setActiveSearchKeyword(e.target.value.trim() ?? '');
        },
        []
    ), 300);

    const handleReset = useCallback(() => {
        reset();

        setActivePage(1);
        setActiveSearchKeyword('');
    }, []);

    useEffect(() => {
        setActivePage(1);
    }, [isEducationSubscription, isUnlimitedSubscription]);

    return (
        <>
            {isLoadingInstitutions ?
                <Spinner />
                :
                <Box>
                    <form
                        onSubmit={handleSubmit(onSearchKeywordSubmit)}
                    >
                        <InputGroup minW="400px">
                            <InputLeftElement
                                pointerEvents={isSubmitting ? 'none' : 'auto'}
                                onClick={handleSubmit(onSearchKeywordSubmit)}
                            >
                                {(isSubmitting || isLoadingInstitutions || isFetching)
                                    ? <Icon icon='loader' />
                                    : <Icon icon='search' />
                                }
                            </InputLeftElement>
                            <Input
                                placeholder="Search by Name or ID"
                                bg="#ffffff"
                                id="keyword"
                                type="text"
                                isInvalid={false}
                                borderColor="inherit"
                                {...register('keyword')}
                                onChange={onSearchKeywordChange}
                            />
                            {activeSearchKeyword &&
                                <InputRightElement cursor="pointer" onClick={handleReset}>
                                    <HiX />
                                </InputRightElement>
                            }
                        </InputGroup>
                         <Flex mt={5}>
                            <Text fontWeight='500'>Subscription:</Text>
                            <Checkbox
                                ml={7}
                                mb={3}
                                isChecked={isEducationSubscription}
                                onChange={() => setIsEducationSubscription(!isEducationSubscription)}
                            >
                                Education
                            </Checkbox>
                            <Checkbox
                                ml={7}
                                mb={3}
                                isChecked={isUnlimitedSubscription}
                                onChange={() => setIsUnlimitedSubscription(!isUnlimitedSubscription)}
                            >
                                Unlimited
                            </Checkbox>
                            <Checkbox
                                ml={7}
                                mb={3}
                                isChecked={isUnlimitedSubscription && isEducationSubscription}
                                onChange={() => {
                                    setIsEducationSubscription(!(isUnlimitedSubscription && isEducationSubscription));
                                    setIsUnlimitedSubscription(!(isUnlimitedSubscription && isEducationSubscription));
                                }}
                            >
                                Education + Unlimited
                            </Checkbox>
                        </Flex>
                    </form>
                    <Table bgColor={'#ffffff'}>
                        <Thead>
                            <Tr>
                                <Th>ID</Th>
                                <Th>Title</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {institutionsData?.result?.length ?
                                institutionsData.result.map((institution) =>
                                    <InstitutionsListItem
                                        key={institution.id}
                                        institution={institution}
                                        handleScormDownload={handleScormDownload}
                                        handleCCDownload={handleCCDownload}
                                        handleQtiDownload={handleQtiDownload}
                                        handleProviderInstitutionId={handleProviderInstitutionId}
                                        handleAssignInstitutionId={handleAssignInstitutionId}
                                        isLoading={isLoading}
                                        type={type}
                                    />
                                ) : (
                                    <Tr>
                                        <Td>No institutions found</Td>
                                    </Tr>
                                )
                            }
                        </Tbody>
                    </Table>
                </Box>
            }
            {institutionsData && institutionsData?.navigation?.total_pages > 1 &&
                <Pagination
                    currentPage={activePage}
                    totalPages={institutionsData.navigation.total_pages}
                    handlePagination={(page: number) => setActivePage(page)}
                />
            }
        </>
    );
};
