import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { useMutation, useQueryClient } from 'react-query';
import { Banner } from '../types';

type CreateBannerData = {
    data: {
        type: number;
        message: string;
        published: number;
    };
};

export const createBanner = ({ data }: CreateBannerData): Promise<Banner> => {
    return axios.post(`/api/admin/banner/create`, data);
};

export const useCreateBanner = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation({
        onError: (_, __, context: any) => {
            addNotification({
                type: 'error',
                title: 'Unable to update translation',
            });
        },
        onSuccess: (res) => {
            addNotification({
                type: 'success',
                title: 'translation successfully updated',
            });
        },

        mutationFn: createBanner,
    });
};
