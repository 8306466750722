import React, { ComponentType } from 'react';
import { AppProvider } from '@/providers/appProvider';
import { AppRoutes } from '@/routes';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

function App() {
    return (
        <AppProvider>
            <AppRoutes />
        </AppProvider>
    );
}

export default withLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENTSIDE_ID || window.__CONFIG__.REACT_APP_LD_CLIENTSIDE_ID || '',
})(App as ComponentType<{}>);
