import { Box, HStack, Td, Tr, useDisclosure } from '@chakra-ui/react';
import Icon from '@/components/Icon';
import React, { FC } from 'react';
import { Group } from '@/features/Translations/types';
import { DeleteTranslationGroupModal } from '../DeleteTranslationGroupModal/DeleteTranslationGroupModal';
import { languages } from '../../../../Utils/languages';
import { useNavigate } from 'react-router';

export type LanguageStrings = {
    [K in (typeof languages)[number]['key']]: string;
};

type Props = {
    activePage: number;
    activeSearchKeyword: string | number;
    activeSortDirection: string;
    activeSortProperty: string;
    group: Group;
};

export const TranslationGroupItem: FC<Props> = (props) => {
    const { group } = props;
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const navigate = useNavigate();

    return (
        <>
            <DeleteTranslationGroupModal groupId={group.id} name={group.key} isOpen={isDeleteOpen} onClose={onDeleteClose} />

            <Tr>
                <Td>{group.key}</Td>

                <Td textAlign={'center'}>{group.title}</Td>

                <Td textAlign={'center'}>{group.isActive ? 'Yes' : 'No'}</Td>

                <Td>
                    <HStack color={'gray.600'} spacing={4}>
                        <Box onClick={() => navigate(`edit/${group.id}`)}>
                            <Icon highlightOnHover icon={'pencil'} />
                        </Box>

                        <Box onClick={onDeleteOpen}>
                            <Icon highlightOnHover forDelete icon={'trash'} />
                        </Box>
                    </HStack>
                </Td>
            </Tr>
        </>
    );
};
