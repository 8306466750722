import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { isNotNull } from '@/utils/helpers';
import { RootState } from '../../store';
import {
    createAdminQuiz,
    deleteAdminQuizById,
    getAdminQuizById,
    getAdminQuizzes,
    updateAdminQuiz, updateUserQuiz,
} from '@/features/Quiz/api/getQuizzes';
import {
    CreateQuizLibraryData,
    QuizLibrary,
    QuizLibraryResponse, SaveQuizError,
    UpdateQuizLibraryData,
} from '@/features/Quiz/types';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

export type QuizAdminType = QuizLibrary;

export const buildQuizLibraryParams = (activePage: number,  activeSearchKeyword?: string | number) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword ? [
                    {
                        bool_operator: 'and',
                        condition: {
                            property: 'title',
                            operator: 'contains',
                            value: activeSearchKeyword ?? 0
                        }
                    }
                ] : []
                ),
            ]
        },
        sort: [
            [
                'title',
                'asc'
            ]
        ]
    }
}

export const getAllQuizzesAdmin = createAsyncThunk<
    QuizLibraryResponse,
    {
        activePage: number;
        activeSearchKeyword?: string | number;
    },
    {
        rejectValue: string;
    }
>(
'quizzes/getAllQuizzesAdmin',
async ({activePage,  activeSearchKeyword}) => {
    const data = buildQuizLibraryParams(activePage,  activeSearchKeyword);

    return await getAdminQuizzes(data);
});

export const getQuizAdminById = createAsyncThunk(
'quizzes/getQuizAdminById',
(id: number) => getAdminQuizById(id)
);

export const removeQuizAdmin = createAsyncThunk(
'quizzes/removeQuizAdmin',
(id: number) => deleteAdminQuizById(id)
);


export const createQuizAdmin = createAsyncThunk<
    QuizLibrary,
    CreateQuizLibraryData,
    {
        rejectValue: SaveQuizError;
    }
>(
    'quizzes/createQuizAdmin',
async (data, { rejectWithValue }) => {
    return await createAdminQuiz(data).catch((res) => rejectWithValue(res));
}
);

export const editQuizAdmin = createAsyncThunk<
    QuizLibrary,
    {
        id: number;
        data: UpdateQuizLibraryData;
    },
    {
        rejectValue: SaveQuizError;
    }
    >('quizzes/editQuizAdmin', async ({ id, data }, { rejectWithValue }) => {
    return await updateAdminQuiz(id, data).catch((res) => rejectWithValue(res));
});

export const editUserQuiz = createAsyncThunk<
    QuizLibrary,
    {
        id: number;
        data: UpdateQuizLibraryData;
    },
    {
        rejectValue: SaveQuizError;
    }
>('quizzes/editUserQuiz', async ({ id, data }, { rejectWithValue }) => {
    return await updateUserQuiz(id, data).catch((res) => rejectWithValue(res));
});

const quizAdminAdapter = createEntityAdapter<QuizAdminType>();

type State = {
    pages: Record<number, QuizAdminType['id'][]>;
    activePage: number;
    total: number;
    totalPages: number;
    isLoading: boolean;
};

const quizAdminSlice = createSlice({
    name: 'quizAdmin',
    initialState: quizAdminAdapter.getInitialState<State>({
        pages: {},
        activePage: 1,
        totalPages: 1,
        total: 0,
        isLoading: false,
    }),
    reducers: {
        setQuizAdminActivePage: (state, action: PayloadAction<number>) => {
            state.activePage = action.payload;
        },
        resetQuizAdminPages: (state) => {
            state.pages = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllQuizzesAdmin.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllQuizzesAdmin.fulfilled, (state, action) => {
            quizAdminAdapter.upsertMany(state, action.payload.result);
            state.pages[action.payload.navigation.page ?? 1] = action.payload.result.map((item) => item.id);
            state.total = action.payload.navigation.total_items;
            state.activePage = action.payload.navigation.page;
            state.totalPages = action.payload.navigation.total_pages;
            state.isLoading = false;
        });
        builder.addCase(getAllQuizzesAdmin.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getQuizAdminById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQuizAdminById.fulfilled, (state, action) => {
            quizAdminAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        });
        builder.addCase(getQuizAdminById.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(removeQuizAdmin.fulfilled, (state, action) => {
            quizAdminAdapter.removeOne(state, action.meta.arg);
        });
        builder.addCase(createQuizAdmin.fulfilled, (state, action) => {
            quizAdminAdapter.upsertOne(state, action.payload);
        });

        builder.addCase(editQuizAdmin.fulfilled, (state, action) => {
            quizAdminAdapter.upsertOne(state, action.payload);
        });

        builder.addCase(editUserQuiz.fulfilled, (state, action) => {
            quizAdminAdapter.upsertOne(state, action.payload);
        });
    },
});

export const quizAdmin = quizAdminSlice.reducer;
export const { resetQuizAdminPages, setQuizAdminActivePage } = quizAdminSlice.actions;

const selectState = (state: RootState) => state.quizzes.quizAdmin;

export const { selectById, selectIds } = quizAdminAdapter.getSelectors(selectState);

const selectRootState = (state: RootState) => state;

export const selectQuizAdminById = createSelector(
    [(state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
    (entity) => entity
);

const selectIdsByPage = createSelector(
    [selectState, (_: RootState, page: number) => page],
    (state, page) => state.pages[page] ?? []
);

export const selectQuizAdminPage = createSelector(
    [selectRootState, (state: RootState, page: number) => selectIdsByPage(state, page)],
    (state, ids) => ids.map((id) => selectQuizAdminById(state, id)).filter(isNotNull)
);

export const selectQuizAdminActivePage = createSelector([selectState], (state) => state.activePage);
export const selectQuizAdminTotalPages = createSelector([selectState], (state) => state.totalPages);
export const selectQuizAdminTotal = createSelector([selectState], (state) => state.total);
export const selectQuizAdminLoading = createSelector([selectState], (state) => state.isLoading);
