import { useMutation, useQueryClient } from "react-query";
import { deleteQuiz } from "@/features/Quiz/api/getQuizzes";


export const useDeleteQuiz = (closeModalFunction: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        onError: (_, __, context: any) => {
            closeModalFunction();
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['myQuiz']);
            closeModalFunction();
        },
        mutationFn: deleteQuiz,
    });
};