import React, { useEffect, useCallback, memo } from 'react';
import { Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { LeftCreateQuiz } from '../LeftCreateQuiz';
import { RightQuiz } from '../RightQuiz';
import { Quiz, QuizFormData } from '../../types';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Reorder } from '@/utils/helpers';
import { createNewQuiz, editQuiz, QuizType } from '@/redux/reducers/quizzes/quizSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/redux/store';
import { isFulfilled, isRejected } from '@reduxjs/toolkit';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNotificationStore } from '@/stores/notifications';
import { createNewQuizLibrary, editQuizLibrary } from '@/redux/reducers/quizzes/quizLibrary';
import { createQuizAdmin, editQuizAdmin } from '@/redux/reducers/quizzes/quizAdmin';
import { createQuizUser, editQuizUser } from '@/redux/reducers/quizzes/quizUser';

const searchInputSchema = (isLibrary: boolean) => {
    if (isLibrary) {
        return yup.object().shape({
            title: yup.string(),
        });
    }

    return yup.object().shape({
        title: yup.string(),
        isGraded: yup.string(),
    });
};

type Props = {
  quiz?: QuizType | null;
  isLibrary?: boolean;
  isAdminQuiz?: boolean;
  isEdit?: boolean;
  isUserQuiz?: boolean;
};

export const FormQuiz = memo(({ quiz, isLibrary = false, isAdminQuiz = false, isEdit = false, isUserQuiz = false }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { pathname } = useLocation();
    const isCustomerQuiz = pathname.includes("customer-quiz");

    const { addNotification } = useNotificationStore();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const initFormValues = useCallback(
      (data: (Omit<Quiz, "isGraded"> & { isGraded?: boolean }) | null) => {
        return {
            article: data?.article || null,
            title: data?.title ?? '',
            tags: data?.tags?.map((tag) => tag.id) ?? [],
            ...(isAdminQuiz || isLibrary || isUserQuiz
                ? {
                      isGraded: typeof data?.isGraded === 'boolean' ? (data?.isGraded ? 'graded' : 'ungraded') : '',
                      startDate: data?.startDate ? new Date(data.startDate) : new Date(),
                      endDate: data?.endDate ? new Date(data.endDate) : null,
                      duration: data?.duration ?? 0,
                      showCaption: data ? data.showCaption : true,
                  }
                : {}),
            ...(isUserQuiz
                ? {
                      restrictNonInstitutional: data?.restrictNonInstitutional ?? false,
                  }
                : {}),
            activities:
                data?.activities && data.activities.length > 0
                    ? [...data.activities]
                          .sort((a, b) => a.sortIndex - b.sortIndex)
                          .map((activity, index) => {
                              const { id: customId, items, ...dataActivity } = activity;

                      return {
                        ...dataActivity,
                        originId: customId,
                        isSelect: index === 0,
                        sortIndex: index + 1,
                        items:
                          items && items.length > 0
                            ? [...items]
                                  .sort((a, b) => a.sortIndex - b.sortIndex)
                                  .map((item, indexItem) => {
                                    const { id: customItemId, ...dataItem } = item;

                                                    return {
                                                        ...dataItem,
                                                        originId: customItemId,
                                                        isSelect: true,
                                                        sortIndex: indexItem + 1,
                                                        isMultiple: dataItem.isMultiple ?? false,
                                                        rightAnswer: dataItem.rightAnswer ?? [1],
                                                        textOptions: dataItem.textOptions
                                                            ? dataItem.textOptions.map((textOption) => {
                                                                  const { id: customTextOptionId, ...dataTextOption } = textOption;
                                                                  return {
                                                                      ...dataTextOption,
                                                                      originId: customTextOptionId,
                                                                  };
                                                              })
                                                            : [
                                                                  {
                                                                      originId: 1,
                                                                      text: '',
                                                                  },
                                                                  {
                                                                      originId: 2,
                                                                      text: '',
                                                                  },
                                                                  {
                                                                      originId: 3,
                                                                      text: '',
                                                                  },
                                                                  {
                                                                      originId: 4,
                                                                      text: '',
                                                                  },
                                                              ],
                                                    };
                                                })
                                          : [],
                              };
                          })
                    : [
                          {
                              originId: null,
                              title: 'Activity 1',
                              sortIndex: 1,
                              isSelect: true,
                              items: [],
                          },
                      ],
        };
      },
      []
    );

    const { handleSubmit, ...methods } = useForm<QuizFormData>({
      mode: "onSubmit",
      resolver: yupResolver(
        searchInputSchema(isLibrary || isAdminQuiz || isUserQuiz)
      ),
      defaultValues: initFormValues(quiz ?? null),
    });

    const { replace } = useFieldArray<QuizFormData, "activities">({
      control: methods.control,
      name: "activities",
    });

    const activities = methods.watch("activities");
    const indexActivity =
      (activities?.find((item) => item.isSelect)?.sortIndex ?? 1) - 1;
    const items = activities[indexActivity].items;

    const { replace: replaceItems } = useFieldArray<
      QuizFormData,
      `activities.${number}.items`
    >({
      control: methods.control,
      name: `activities.${indexActivity}.items`,
    });

    useEffect(() => {
        quiz?.title && methods.setValue('title', quiz.title);
        quiz?.activities &&
            methods.setValue(
                'activities',
                quiz?.activities && quiz.activities.length > 0
                    ? [...quiz.activities]
                          .sort((a, b) => a.sortIndex - b.sortIndex)
                          .map((activity, index) => {
                              const { id: customId, items, ...dataActivity } = activity;

                              return {
                                  ...dataActivity,
                                  originId: customId,
                                  isSelect: index === 0,
                                  sortIndex: index + 1,
                                  items:
                                      items && items.length > 0
                                          ? [...items]
                                                .sort((a, b) => a.sortIndex - b.sortIndex)
                                                .map((item, indexItem) => {
                                                    const { id: customItemId, ...dataItem } = item;

                                                    return {
                                                        ...dataItem,
                                                        originId: customItemId,
                                                        isSelect: true,
                                                        sortIndex: indexItem + 1,
                                                        isMultiple: dataItem.isMultiple ?? false,
                                                        rightAnswer: dataItem.rightAnswer ?? [1],
                                                        textOptions: dataItem.textOptions
                                                            ? dataItem.textOptions.map((textOption) => {
                                                                  const { id: customTextOptionId, ...dataTextOption } = textOption;
                                                                  return {
                                                                      ...dataTextOption,
                                                                      originId: customTextOptionId,
                                                                  };
                                                              })
                                                            : [
                                                                  {
                                                                      originId: 1,
                                                                      text: '',
                                                                  },
                                                                  {
                                                                      originId: 2,
                                                                      text: '',
                                                                  },
                                                                  {
                                                                      originId: 3,
                                                                      text: '',
                                                                  },
                                                                  {
                                                                      originId: 4,
                                                                      text: '',
                                                                  },
                                                              ],
                                                    };
                                                })
                                          : [],
                              };
                          })
                    : []
            );
    }, [quiz?.title, quiz?.activities]);

    const onDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) {
          return;
        }

        if (result.type === "ACTIVITIES") {
          const list = Reorder(
            activities,
            result.source.index,
            result.destination.index
          );

          replace(list);
        } else {
          const list = Reorder(
            items,
            result.source.index,
            result.destination.index
          );

          replaceItems(list);
        }
      },
      [replace, replaceItems, activities, items]
    );

    const onSubmit = useCallback(async () => {
        const { isGraded, activities, startDate, endDate, duration, showCaption, restrictNonInstitutional, ...rest } = methods.getValues();
        const { title } = methods.getValues();

        let alertMessage = '';

        if (isEdit) {
            for (const activity of activities) {
                for (const item of activity.items) {
                    const arr: string[] = [];

                    if (item.article === null) {
                        for (const options of item.textOptions) {
                            arr.push(options.text);
                            const hasDuplicates = () => new Set(arr).size !== arr.length;
                            if (alertMessage) break;

                            if (!options.text) {
                                alertMessage = alertMessage + 'Options should not be empty';
                                break;
                            } else if (hasDuplicates()) {
                                alertMessage = alertMessage + 'Options should not be duplicated';
                                break;
                            }
                        }
                    }
                }
            }
        }

        if (title.trim().length === 0) {
            alertMessage = alertMessage + 'No Title\n';
        } else if (title.trim().length < 3) {
            alertMessage = alertMessage + 'Title is too short. It should have 3 characters or more\n';
        }

        if ((isLibrary || isAdminQuiz || isUserQuiz) && isGraded.length === 0) {
            alertMessage = alertMessage + 'No type selected\n';
        }

        if (!isEdit) {
            for (const activity of activities) {
                if (activity.items.length === 0) {
                    alertMessage =
                        alertMessage + (isLibrary ? 'Each activity should have at least one question\n' : 'There should be at least one question\n');
                    break;
                } else {
                    for (const item of activity.items) {
                        if (item.title.trim().length === 0) {
                            alertMessage = alertMessage + 'Question title should not be empty\n';
                            break;
                        } else if (item.title.trim().length < 3) {
                            alertMessage = alertMessage + 'Question title is too short. It should have 3 characters or more\n';
                            break;
                        }
                    }
                }
            }
        }

        if (alertMessage.length > 0) {
            addNotification({
                type: 'error',
                title: alertMessage,
            });

            return;
        }

        setIsSubmitting(true);

        if (isLibrary) {
            if (quiz && isEdit) {
                const action = await dispatch(
                    editQuizLibrary({
                        id: quiz.id,
                        data: {
                            ...rest,
                            startDate: startDate?.toISOString() ?? '',
                            endDate: endDate?.toISOString() ?? null,
                            isGraded: isGraded === 'graded',
                            duration: duration === 0 ? null : duration,
                            activities:
                                activities?.map((activity) => {
                                    const { title, sortIndex, items, originId } = activity;

                                    return {
                                        id: originId,
                                        title,
                                        sortIndex,
                                        items: items?.map((item) => {
                                            const {
                                                article,
                                                sortIndex: sortIndexItem,
                                                title: titleItem,
                                                textOptions,
                                                type,
                                                isMultiple,
                                                rightAnswer,
                                                image,
                                                originId: originIdItem,
                                            } = item;

                                            if (type === 1) {
                                                return {
                                                    id: originIdItem,
                                                    article: article?.videoData.articleId ?? null,
                                                    sortIndex: sortIndexItem,
                                                    title: titleItem,
                                                    type,
                                                    isMultiple,
                                                    image,
                                                };
                                            }

                                            return {
                                                id: originIdItem,
                                                article: article?.videoData.articleId ?? null,
                                                sortIndex: sortIndexItem,
                                                title: titleItem,
                                                textOptions: textOptions.map((textOption) => ({
                                                    id: textOption.originId,
                                                    text: textOption.text,
                                                })),
                                                type,
                                                isMultiple,
                                                rightAnswer,
                                                image,
                                            };
                                        }),
                                    };
                                }) ?? [],
                        },
                    })
                );

                if (isFulfilled(action)) {
                    setIsSubmitting(false);
                    navigate(`/quiz/library`, {
                        replace: true,
                    });
                }

                if (isRejected(action) && action.payload) {
                    setIsSubmitting(false);
                    if (action.payload.content.errors) {
                        Object.values(action.payload.content.errors).forEach(
                            (errorItem) =>
                                errorItem?.forEach((error) => {
                                    addNotification({
                                        type: 'error',
                                        title: error.message,
                                    });
                                })
                        );
                    }
                }
            } else {
                const action = await dispatch(
                    createNewQuizLibrary({
                        ...rest,
                        startDate: startDate?.toISOString() ?? '',
                        endDate: endDate?.toISOString() ?? '',
                        isGraded: isGraded === 'graded',
                        duration: duration === 0 ? null : duration,
                        activities: activities.map((activity) => {
                            const { title, sortIndex, items } = activity;

                            return {
                                title,
                                sortIndex,
                                items: items.map((item) => {
                                    const {
                                        article,
                                        sortIndex: sortIndexItem,
                                        title: titleItem,
                                        textOptions,
                                        type,
                                        isMultiple,
                                        rightAnswer,
                                        image,
                                    } = item;

                                    if (type === 1) {
                                        return {
                                            article: article?.videoData.articleId ?? null,
                                            sortIndex: sortIndexItem,
                                            title: titleItem,
                                            textOptions: [],
                                            type,
                                            isMultiple,
                                            rightAnswer: [],
                                            image,
                                        };
                                    }

                                    return {
                                        article: article?.videoData.articleId ?? null,
                                        sortIndex: sortIndexItem,
                                        title: titleItem,
                                        textOptions: textOptions.map((textOption) => ({
                                            id: textOption.originId,
                                            text: textOption.text,
                                        })),
                                        type,
                                        isMultiple,
                                        rightAnswer,
                                        image,
                                    };
                                }),
                            };
                        }),
                    })
                );

                if (isFulfilled(action) && action.payload) {
                    setIsSubmitting(false);
                    navigate(`/quiz/library`, {
                        replace: true,
                    });
                }
                if (isRejected(action) && action.payload) {
                    setIsSubmitting(false);
                    if (action.payload.content.errors) {
                        Object.values(action.payload.content.errors).forEach(
                            (errorItem) =>
                                errorItem?.forEach((error) => {
                                    addNotification({
                                        type: 'error',
                                        title: error.message,
                                    });
                                })
                        );
                    }
                }
            }
        } else if (isAdminQuiz) {
            if (quiz && isEdit) {
                const action = await dispatch(
                    editQuizAdmin({
                        id: quiz.id,
                        data: {
                            ...rest,
                            startDate: startDate?.toISOString() ?? '',
                            endDate: endDate?.toISOString() ?? null,
                            isGraded: isGraded === 'graded',
                            duration: duration === 0 ? null : duration,
                            activities:
                                activities?.map((activity) => {
                                    const { title, sortIndex, items, originId } = activity;

                                    return {
                                        id: originId,
                                        title,
                                        sortIndex,
                                        items: items?.map((item) => {
                                            const {
                                                article,
                                                sortIndex: sortIndexItem,
                                                title: titleItem,
                                                textOptions,
                                                type,
                                                isMultiple,
                                                rightAnswer,
                                                image,
                                                originId: originIdItem,
                                            } = item;

                                            if (type === 1) {
                                                return {
                                                    id: originIdItem,
                                                    article: article?.videoData.articleId ?? null,
                                                    sortIndex: sortIndexItem,
                                                    title: titleItem,
                                                    type,
                                                    isMultiple,
                                                    image,
                                                };
                                            }

                                            return {
                                                id: originIdItem,
                                                article: article?.videoData.articleId ?? null,
                                                sortIndex: sortIndexItem,
                                                title: titleItem,
                                                textOptions: textOptions.map((textOption) => ({
                                                    id: textOption.originId,
                                                    text: textOption.text,
                                                })),
                                                type,
                                                isMultiple,
                                                rightAnswer,
                                                image,
                                            };
                                        }),
                                    };
                                }) ?? [],
                        },
                    })
                );

                if (isFulfilled(action)) {
                    setIsSubmitting(false);
                    navigate(`/quiz/admin-quiz`, {
                        replace: true,
                    });
                }

                if (isRejected(action) && action.payload) {
                    setIsSubmitting(false);
                    if (action.payload.content.errors) {
                        Object.values(action.payload.content.errors).forEach(
                            (errorItem) =>
                                errorItem?.forEach((error) => {
                                    addNotification({
                                        type: 'error',
                                        title: error.message,
                                    });
                                })
                        );
                    }
                }
            } else {
                const action = await dispatch(
                    createQuizAdmin({
                        ...rest,
                        startDate: startDate?.toISOString() ?? '',
                        endDate: endDate?.toISOString() ?? '',
                        isGraded: isGraded === 'graded',
                        duration: duration === 0 ? null : duration,
                        activities: activities.map((activity) => {
                            const { title, sortIndex, items } = activity;

                            return {
                                title,
                                sortIndex,
                                items: items.map((item) => {
                                    const {
                                        article,
                                        sortIndex: sortIndexItem,
                                        title: titleItem,
                                        textOptions,
                                        type,
                                        isMultiple,
                                        rightAnswer,
                                        image,
                                    } = item;

                                    if (type === 1) {
                                        return {
                                            article: article?.videoData.articleId ?? null,
                                            sortIndex: sortIndexItem,
                                            title: titleItem,
                                            textOptions: [],
                                            type,
                                            isMultiple,
                                            rightAnswer: [],
                                            image,
                                        };
                                    }

                                    return {
                                        article: article?.videoData.articleId ?? null,
                                        sortIndex: sortIndexItem,
                                        title: titleItem,
                                        textOptions: textOptions.map((textOption) => ({
                                            id: textOption.originId,
                                            text: textOption.text,
                                        })),
                                        type,
                                        isMultiple,
                                        rightAnswer,
                                        image,
                                    };
                                }),
                            };
                        }),
                    })
                );

                if (isFulfilled(action) && action.payload) {
                    setIsSubmitting(false);
                    navigate(`/quiz/admin-quiz`, {
                        replace: true,
                    });
                }

                if (isRejected(action) && action.payload) {
                    setIsSubmitting(false);
                    if (action.payload.content.errors) {
                        Object.values(action.payload.content.errors).forEach(
                            (errorItem) =>
                                errorItem?.forEach((error) => {
                                    addNotification({
                                        type: 'error',
                                        title: error.message,
                                    });
                                })
                        );
                    }
                }
            }
        } else if (isUserQuiz) {
            if (quiz && isEdit) {
                const action = await dispatch(
                    editQuizUser({
                        id: quiz.id,
                        data: {
                            ...rest,
                            startDate: startDate?.toISOString() ?? '',
                            endDate: endDate?.toISOString() ?? null,
                            isGraded: isGraded === 'graded',
                            duration: duration === 0 ? null : duration,
                            showCaption: Boolean(showCaption),
                            restrictNonInstitutional: restrictNonInstitutional ?? false,
                            activities:
                                activities?.map((activity) => {
                                    const { title, sortIndex, items, originId } = activity;

                                    return {
                                        id: originId,
                                        title,
                                        sortIndex,
                                        items: items?.map((item) => {
                                            const {
                                                article,
                                                sortIndex: sortIndexItem,
                                                title: titleItem,
                                                textOptions,
                                                type,
                                                isMultiple,
                                                rightAnswer,
                                                image,
                                                originId: originIdItem,
                                            } = item;

                                            if (type === 1) {
                                                return {
                                                    id: originIdItem,
                                                    article: article?.videoData.articleId ?? null,
                                                    sortIndex: sortIndexItem,
                                                    title: titleItem,
                                                    type,
                                                    isMultiple,
                                                    image,
                                                };
                                            }

                                            return {
                                                id: originIdItem,
                                                article: article?.videoData.articleId ?? null,
                                                sortIndex: sortIndexItem,
                                                title: titleItem,
                                                textOptions: textOptions.map((textOption) => ({
                                                    id: textOption.originId,
                                                    text: textOption.text,
                                                })),
                                                type,
                                                isMultiple,
                                                rightAnswer,
                                                image,
                                            };
                                        }),
                                    };
                                }) ?? [],
                        },
                    })
                );

                if (isFulfilled(action)) {
                    setIsSubmitting(false);
                    navigate(isCustomerQuiz ? `/quiz/customer-quiz` : `/quiz`, {
                        replace: true,
                    });
                }
                if (isRejected(action) && action.payload) {
                    setIsSubmitting(false);
                    if (action.payload.content && action.payload.content.errors) {
                        Object.values(action.payload.content.errors).forEach(
                            (errorItem) =>
                                errorItem?.forEach((error) => {
                                    addNotification({
                                        type: 'error',
                                        title: error.message,
                                    });
                                })
                        );
                    } else if (action.payload.message) {
                        addNotification({
                            type: 'error',
                            title: action.payload.message,
                        });
                    }
                }
            } else {
                const action = await dispatch(
                    createQuizUser({
                        ...rest,
                        startDate: startDate?.toISOString() ?? '',
                        endDate: endDate?.toISOString() ?? '',
                        isGraded: isGraded === 'graded',
                        duration: duration === 0 ? null : duration,
                        restrictNonInstitutional: false,
                        activities: activities.map((activity) => {
                            const { title, sortIndex, items } = activity;

                            return {
                                title,
                                sortIndex,
                                items: items.map((item) => {
                                    const {
                                        article,
                                        sortIndex: sortIndexItem,
                                        title: titleItem,
                                        textOptions,
                                        type,
                                        isMultiple,
                                        rightAnswer,
                                        image,
                                    } = item;

                                    if (type === 1) {
                                        return {
                                            article: article?.videoData.articleId ?? null,
                                            sortIndex: sortIndexItem,
                                            title: titleItem,
                                            textOptions: [],
                                            type,
                                            isMultiple,
                                            rightAnswer: [],
                                            image,
                                        };
                                    }

                                    return {
                                        article: article?.videoData.articleId ?? null,
                                        sortIndex: sortIndexItem,
                                        title: titleItem,
                                        textOptions: textOptions.map((textOption) => ({
                                            id: textOption.originId,
                                            text: textOption.text,
                                        })),
                                        type,
                                        isMultiple,
                                        rightAnswer,
                                        image,
                                    };
                                }),
                            };
                        }),
                    })
                );

                if (isFulfilled(action) && action.payload) {
                    setIsSubmitting(false);
                    navigate(isCustomerQuiz ? `/quiz/customer-quiz` : `/quiz/my-quiz`, {
                        replace: true,
                    });
                }

                if (isRejected(action) && action.payload) {
                    setIsSubmitting(false);
                    if (action.payload.content.errors) {
                        Object.values(action.payload.content.errors).forEach(
                            (errorItem) =>
                                errorItem?.forEach((error) => {
                                    addNotification({
                                        type: 'error',
                                        title: error.message,
                                    });
                                })
                        );
                    }
                }
            }
        } else {
            if (quiz && isEdit) {
                const action = await dispatch(
                    editQuiz({
                        id: quiz.id,
                        data: {
                            ...rest,
                            activities:
                                activities?.map((activity) => {
                                    const { title, sortIndex, items, originId } = activity;

                                    return {
                                        id: originId,
                                        title,
                                        sortIndex,
                                        items: items?.map((item) => {
                                            const {
                                                article,
                                                sortIndex: sortIndexItem,
                                                title: titleItem,
                                                textOptions,
                                                type,
                                                isMultiple,
                                                rightAnswer,
                                                image,
                                                originId: originIdItem,
                                            } = item;

                                            if (type === 1) {
                                                return {
                                                    id: originIdItem,
                                                    article: article?.videoData.articleId ?? null,
                                                    sortIndex: sortIndexItem,
                                                    title: titleItem,
                                                    type,
                                                    isMultiple,
                                                    image,
                                                };
                                            }

                                            return {
                                                id: originIdItem,
                                                article: article?.videoData.articleId ?? null,
                                                sortIndex: sortIndexItem,
                                                title: titleItem,
                                                textOptions: textOptions.map((textOption) => ({
                                                    id: textOption.originId,
                                                    text: textOption.text,
                                                })),
                                                type,
                                                isMultiple,
                                                rightAnswer,
                                                image,
                                            };
                                        }),
                                    };
                                }) ?? [],
                        },
                    })
                );

                if (isFulfilled(action)) {
                    setIsSubmitting(false);
                    navigate(`/quiz`, { replace: true });
                }
                if (isRejected(action) && action.payload) {
                    setIsSubmitting(false);
                    if (action.payload.content && action.payload.content.errors) {
                        Object.values(action.payload.content.errors).forEach(
                            (errorItem) =>
                                errorItem?.forEach((error) => {
                                    addNotification({
                                        type: 'error',
                                        title: error.message,
                                    });
                                })
                        );
                    } else if (action.payload.message) {
                        addNotification({
                            type: 'error',
                            title: action.payload.message,
                        });
                    }
                }
            } else {
                const action = await dispatch(
                    createNewQuiz({
                        ...rest,
                        article: rest.article ?? 0,
                        activities: activities.map((activity) => {
                            const { title, sortIndex, items } = activity;

                            return {
                                title,
                                sortIndex,
                                items: items.map((item) => {
                                    const {
                                        article,
                                        sortIndex: sortIndexItem,
                                        title: titleItem,
                                        textOptions,
                                        type,
                                        isMultiple,
                                        rightAnswer,
                                        image,
                                    } = item;

                                    if (type === 1) {
                                        return {
                                            article: article?.videoData.articleId ?? null,
                                            sortIndex: sortIndexItem,
                                            title: titleItem,
                                            textOptions: [],
                                            type,
                                            isMultiple,
                                            rightAnswer: [],
                                            image,
                                        };
                                    }

                                    return {
                                        article: article?.videoData.articleId ?? null,
                                        sortIndex: sortIndexItem,
                                        title: titleItem,
                                        textOptions: textOptions.map((textOption) => ({
                                            id: textOption.originId,
                                            text: textOption.text,
                                        })),
                                        type,
                                        isMultiple,
                                        rightAnswer,
                                        image,
                                    };
                                }),
                            };
                        }),
                    })
                );

                if (isFulfilled(action) && action.payload) {
                    setIsSubmitting(false);
                    navigate(`/quiz`, {
                        replace: true,
                    });
                }

                if (isRejected(action) && action.payload) {
                    setIsSubmitting(false);
                    if (action?.payload?.content?.errors) {
                        Object.values(action.payload.content.errors).forEach(
                            (errorItem) =>
                                errorItem?.forEach((error) => {
                                    addNotification({
                                        type: 'error',
                                        title: error.message,
                                    });
                                })
                        );
                    }
                }
            }
        }
    }, [dispatch, quiz, isLibrary, isAdminQuiz, isEdit, isUserQuiz]);

    return (
        <FormProvider handleSubmit={handleSubmit} {...methods}>
            <form
                onSubmit={(event) => {
                    event?.preventDefault();
                }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Flex w="100%" maxW="1440px" m="0 auto" justify="space-between" flexDir="row" rowGap="20px" minH="95vh" className="quiz-columns">
                        <Flex className="left-quiz-column" p="18px 50px 18px 16px" flex={'1 0 33%'} w="100%" bg="primary.grey" flexDirection="column">
                            <LeftCreateQuiz
                                isLibrary={isLibrary || isAdminQuiz || isUserQuiz}
                                isUserQuiz={isUserQuiz}
                                isCustomerQuiz={isCustomerQuiz}
                                isEdit={isEdit}
                                onSubmit={onSubmit}
                                isFormSubmitting={isSubmitting}
                                isAssignSomeone={quiz?.isAssignSomeone ?? false}
                            />
                        </Flex>
                        <Flex className="right-quiz-column" w="100%" flex={'1 0 67%'} bg="white" flexDirection="column">
                            <RightQuiz isLibrary={isLibrary || isAdminQuiz || isUserQuiz} />
                        </Flex>
                    </Flex>
                </DragDropContext>
            </form>
        </FormProvider>
    );
  }
);
