import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import Icon from '@/components/Icon';
import { SponsorForm } from '@/features/Sponsors/Components/SponsorForm';
import React from 'react';

export const AddSponsor = () => {
    const navigate = useNavigate();

    return (
        <Flex flexDir="column" margin="0 auto" maxW="800px">
            <Flex mb={3} alignItems="center" justifyContent="space-between">
                <Heading size="lg">Add New Sponsor</Heading>
                <Button variant="back" onClick={() => navigate(`/sponsors`)} leftIcon={<Icon icon="chevron-left" />}>
                    Back
                </Button>
            </Flex>
            <Box bgColor="#ffffff" p={4}>
                <SponsorForm isCreate />
            </Box>
        </Flex>
    );
};
