import React, {useState} from 'react';
import {Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, VStack} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

import {AuthCard} from '../components/AuthCard';
import {AuthLayout} from '../components/Layout';
import {useAuth} from '@/context/AuthContext';
import {ErrorBlock} from '@/components/ErrorBlock';

type AuthInputs = {
    email: string,
    password: string,
};

let authSchema = yup.object().shape({
    email: yup.string().required('This field is required'),
    password: yup.string().required('This field is required'),
});

export const SignIn = () => {
    const {register, handleSubmit, watch, formState: {errors, isSubmitting}} = useForm<AuthInputs>({
        resolver: yupResolver(authSchema)
    });
    const {handleLogin} = useAuth();

    const [error, setError] = useState()

    const handleSignIn = async (values: AuthInputs) => {
        try {
            await handleLogin(values);
        } catch (e: any) {
            console.log(e)
            e?.message && setError(e.message);
        }

        // try {
        //     setSubmitting(true);
        //     await handleLogin(email, password);
        //     setSubmitting(false);
        //     navigate('/dashboard');
        // } catch (e: any) {
        //     e?.message && setError(e.message);
        //     setSubmitting(false);
        // }
    }

    return (
        <AuthLayout>
            <AuthCard>
                <Heading
                    color={'#5D697C'}
                    fontSize={'33px'}
                >Admin Sign In </Heading>
                <Heading
                    color={'#C8C8C8'}
                    fontSize={'20px'}
                    fontWeight={500}
                    letterSpacing={'-.12px'}
                    lineHeight={'40px'}
                    mx={0}
                    mb={3}
                >Enter your details below</Heading>
                <form onSubmit={handleSubmit(handleSignIn)}>
                    <VStack spacing={4} mb={4}>
                        <FormControl isInvalid={errors.hasOwnProperty('email')}>
                            <FormLabel color={'#AFAFAF'} htmlFor='email'>Email address</FormLabel>
                            <Input
                                id='email'
                                type='email'
                                {...register('email')}
                            />
                            <FormErrorMessage>
                                {errors.email?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.hasOwnProperty('password')}>
                            <FormLabel color={'#AFAFAF'} htmlFor='password'>Password</FormLabel>
                            <Input
                                id='password'
                                type='password'
                                {...register('password')}
                            />
                            <FormErrorMessage>
                                {errors.password?.message}
                            </FormErrorMessage>
                        </FormControl>
                    </VStack>
                    {error && <ErrorBlock textOnly error={error}/>}
                    <Button
                        w={'full'}
                        variant={'brand'}
                        type={'submit'}
                        isLoading={isSubmitting}
                        mt={3}
                    >Sign In</Button>
                </form>
            </AuthCard>
        </AuthLayout>
    );
};
