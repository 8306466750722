import { axios } from "@/lib/axios";
import { useMutation, useQueryClient } from "react-query";
import { useNotificationStore } from "@/stores/notifications";

export const deleteSponsor = (id: number): Promise<void> => {
    return axios.delete(`/api/admin/sponsor/${id}`);
};

export const useDeleteSponsor = (closeModalFunction: () => void) => {
    const queryClient = useQueryClient();
    const {addNotification} = useNotificationStore();

    return useMutation({
        onError: (_, __, context: any) => {
            addNotification({
                type: 'error',
                title: 'Unable to delete Sponsor',
            });
            closeModalFunction();
        },
        onSuccess: () => {
            queryClient.refetchQueries(['sponsor']);
            queryClient.invalidateQueries(['sponsor']);
            closeModalFunction();
            addNotification({
                type: 'info',
                title: 'Sponsor successfully deleted',
            });
        },
        mutationFn: deleteSponsor,
    });
};