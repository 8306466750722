import { createAsyncThunk, createSlice, createEntityAdapter, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { PlaylistLabelResponse } from '../../../../features/Playlists';
import { createPlaylistLabel, editPlaylistLabel } from '../../../../features/Playlists/api';
import { playlistLabelProp } from '../../../../features/Playlists/types';
import { RootState } from '../../../store';
import { isNotNull } from '../../../../utils/helpers';
import { SectionPath } from '../../../../features/Playlists/types';

export type sectionsList = {
    domain_id: string;
    domain_title: string;
    domain_type: string;
    section_id: string;
};

type LabelData = {
    id: number;
    sortNumber: number;
    path?: string;
    locale?: string;
    domainType?: string;
    sectionsList?: sectionsList[];
    sectionpath: SectionPath[];
    domain: {
        id: number;
        uid: string;
        title: string;
        titleDescription: null | string;
        titleImage: null | string;
        headerDescription: null | string;
        headerImage: null | string;
        accessType: number;
        isActive: boolean;
        type: string;
        professorName?: string;
        courseName?: string;
        institutionName?: string;
        dateEnd: null | string;
        isEnabled: boolean;
        articleType?: string;
        status: number;
        images: {
            short?: string | null;
            large?: string | null;
        }
    }
}

type lastLabelAdapter = LabelData & {
    children: [];
}

export type childrenLabelAdapter = LabelData & {
    children: lastLabelAdapter[];
    title?: string;
    headerImage?: string;
    id: number;
}

export type LabelAdapter = LabelData & {
    children: childrenLabelAdapter[];
}

type LabelState = {
    isLoading: boolean;
    isError: boolean;
}

const labelAdapter = createEntityAdapter<LabelAdapter>();

export const createLabel = createAsyncThunk<PlaylistLabelResponse,
    playlistLabelProp,
    { rejectValue: string }>(
    'playlist/articles/createPlaylistLabel',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await createPlaylistLabel({ id, data });
        } catch (error) {
            let message = '';
            if (error instanceof Error) {
                message = error.message ||
                    error.toString();
            }

            return rejectWithValue(message);
        }
    });

export const editLabel = createAsyncThunk<PlaylistLabelResponse,
    playlistLabelProp,
    { rejectValue: string }>(
    'playlist/articles/editPlaylistLabel',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await editPlaylistLabel({ id, data });
        } catch (error) {
            let message = '';
            if (error instanceof Error) {
                message = error.message ||
                    error.toString();
            }

            return rejectWithValue(message);
        }
    });

const labelSlice = createSlice({
    name: 'label',
    initialState: labelAdapter.getInitialState<LabelState>({
        isLoading: false,
        isError: false,
    }),
    reducers: {
        resetLabels: (state) => {
            labelAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [createLabel.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [createLabel.fulfilled.type]: (state, action: PayloadAction<LabelAdapter>) => {
            labelAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
            state.isError = false;
        },
        [createLabel.rejected.type]: (state) => {
            state.isLoading = false;
            state.isError = true;
        },
        [editLabel.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [editLabel.fulfilled.type]: (state, action: PayloadAction<LabelAdapter>) => {
            labelAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
            state.isError = false;
        },
        [editLabel.rejected.type]: (state) => {
            state.isLoading = false;
            state.isError = true;
        },
    },
});

export const label = labelSlice.reducer;

export const { resetLabels } = labelSlice.actions;

const selectState = (state: RootState) => state.playlistReducer.label;
export const { selectById, selectIds } = labelAdapter.getSelectors(selectState);
const selectRootState = (state: RootState) => state;

export const selectLabelsById = createSelector(
    [selectRootState, (state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
    (_, entity) => entity,
);

export const selectAllLabels = createSelector([selectIds, selectRootState], (ids, state) =>
    ids.map((id) => selectLabelsById(state, Number(id))).filter(isNotNull),
);
