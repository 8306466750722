import React, {useEffect, useState} from 'react';
import {Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, VStack} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

import {AuthCard} from '../components/AuthCard';
import {AuthLayout} from '../components/Layout';
import {ErrorBlock} from '@/components/ErrorBlock';
import {confirmAdmin} from "@/features/Admins/api/confirmAdmin";
import {ConfirmAdminData} from "@/features/Admins/types";
import {LoadingSpinner} from '@/components/LoadingSpinner';
import {useAuth} from "@/context/AuthContext";

let authSchema = yup.object().shape({
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    plainPassword: yup.object().shape({
        first: yup.string().required('This field is required').min(6, 'Password is too short - should be 6 chars minimum.'),
        second: yup.string().oneOf([yup.ref('first'), null], 'Passwords do not match'),
    })
});

export const SetPassword = () => {
    const {register, handleSubmit, formState: {errors, isSubmitting}} = useForm<ConfirmAdminData>({
        resolver: yupResolver(authSchema)
    });
    const [token, setToken] = useState<string>('');
    const [tokenChecked, setTokenChecked] = useState<boolean>(false);
    const [error, setError] = useState()
    const {handleSetPasswordLogin} = useAuth();

    const handleSignIn = async (values: ConfirmAdminData) => {
        values.token = token
        try {
            const res = await confirmAdmin(values);
            handleSetPasswordLogin(res.token)
        } catch (e: any) {
            e?.message && setError(e.message);
        }
    }

    useEffect(() => {
        handleCheckToken();
    }, []);

    const handleCheckToken = async () => {
        try {
            let searchParams = location?.search;
            if (!searchParams || searchParams.length <= 7) {
                setTokenChecked(true);
            }
            let tokenStr = searchParams.substr(7, searchParams.length);
            setTokenChecked(true);
            setToken(tokenStr);
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <AuthLayout>
            {!tokenChecked ? (
                <Flex h={'full'} w={'full'} justify={'center'} align={'center'}>
                    <LoadingSpinner isFullScreen/>
                </Flex>
            ) : (
                <AuthCard>
                    <Heading
                        color={'#5D697C'}
                        fontSize={'33px'}
                    >Confirm email</Heading>
                    <Heading
                        color={'#C8C8C8'}
                        fontSize={'20px'}
                        fontWeight={500}
                        letterSpacing={'-.12px'}
                        lineHeight={'40px'}
                        mx={0}
                        mb={3}
                    ></Heading>
                    <form onSubmit={handleSubmit(handleSignIn)}>
                        <VStack spacing={4} mb={4}>
                            <FormControl isInvalid={errors.hasOwnProperty('name')}>
                                <FormLabel color={'#AFAFAF'} htmlFor='name'>First name</FormLabel>
                                <Input
                                    id='firstName'
                                    {...register('firstName')}
                                />
                                <FormErrorMessage>
                                    {errors.firstName?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.hasOwnProperty('name')}>
                                <FormLabel color={'#AFAFAF'} htmlFor='name'>Last name</FormLabel>
                                <Input
                                    id='lastName'
                                    {...register('lastName')}
                                />
                                <FormErrorMessage>
                                    {errors.lastName?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.hasOwnProperty('plainPassword.first')}>
                                <FormLabel color={'#AFAFAF'} htmlFor='password'>Password</FormLabel>
                                <Input
                                    id='password'
                                    type='password'
                                    {...register('plainPassword.first')}
                                />
                                <FormErrorMessage>
                                    {errors.plainPassword?.first?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.hasOwnProperty('plainPassword.second')}>
                                <FormLabel color={'#AFAFAF'} htmlFor='password'>Password</FormLabel>
                                <Input
                                    id='password'
                                    type='password'
                                    {...register('plainPassword.second')}
                                />
                                <FormErrorMessage>
                                    {errors.plainPassword?.first?.message}
                                </FormErrorMessage>
                            </FormControl>

                        </VStack>
                        {error && <ErrorBlock textOnly error={error}/>}
                        <Button
                            w={'full'}
                            variant={'brand'}
                            type={'submit'}
                            isLoading={isSubmitting}
                            mt={3}
                        >Sign In</Button>
                    </form>
                </AuthCard>
            )}
        </AuthLayout>
    );
};
