import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslations } from '@/features/Translations/api/getTranslationsList';
import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Skeleton,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Select,
    Tooltip,
} from '@chakra-ui/react';
import Icon from '@/components/Icon';
import { Link } from 'react-router-dom';
import { Pagination } from '@/components/Pagination';
import { TranslationItem } from './Components/TranslationItem/TranslationItem';
import { languages } from '../../Utils/languages';
import SortableTableHeader from '@/components/Table/SortableTableHeader';
import { useGroups } from '../../api/getGroupsList';

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

export const Translations = () => {
    const [activePage, setActivePage] = useState<number>(1);
    const [activeSearchKeyword, setActiveSearchKeyword] = useState<string>('');
    const [activeSortDirection, setActiveSortDirection] = useState<string>('desc');
    const [activeSortProperty, setActiveSortProperty] = useState<string>('key');
    const [activeLanguage, setActiveLanguage] = useState<string>('');

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
        watch,
    } = useForm({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        },
        mode: 'onChange',
    });

    const { data, isLoading } = useTranslations({
        activePage,
        activeSearchKeyword,
        activeSortDirection,
        activeSortProperty,
        activeLanguage,
    });

    const { data: groups } = useGroups({
        activePage: 1,
        activeSearchKeyword: '',
        activeSortDirection: '',
        activeSortProperty: 'key',
    });

    const groupedData = data?.result.reduce<
        Record<
            string,
            {
                values: Record<string, string>;
                ids: number[];
                translationIdsByLanguage: Record<string, number>;
                groupId: number;
                groupTitle: string;
            }
        >
    >((acc, item) => {
        const { id, key, language, value, groupId, groupTitle } = item;

        if (!acc[key]) {
            acc[key] = {
                values: {},
                ids: [],
                translationIdsByLanguage: {},
                groupId,
                groupTitle,
            };
        }

        acc[key].values[language] = value;
        acc[key].ids.push(id);
        acc[key].translationIdsByLanguage[language] = id;

        return acc;
    }, {});

    useEffect(() => {
        setActivePage(1);
    }, [activeSearchKeyword]);

    const onSearchKeywordSubmit = (values: { keyword: string }) => {
        if (values.keyword && values.keyword.trim() === '') {
            return;
        }
        setActiveSearchKeyword(values.keyword.trim());
    };

    const handleReset = () => {
        setActiveSearchKeyword('');
        setActiveLanguage('');
        reset();
    };

    const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setActiveLanguage(e.target.value);
    };

    const handleSortSelect = (sortVal: string) => {
        if (activeSortProperty === sortVal) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(sortVal);
            setActiveSortDirection('asc');
        }
    };

    const handleKeywordBlur = () => {
        if (watch('keyword') === '') {
            setActiveSearchKeyword('');
        }
    };

    const thereAreGroups = groups && groups.result?.length > 0;
    const emptyGroupsTooltipMessage = 'Groups must be created before adding translations';

    return (
        <>
            <Flex mb={3} alignItems={'center'} justifyContent={'space-between'}>
                <Heading size="lg">Translations</Heading>
            </Flex>

            <Flex justifyContent={'space-between'} alignItems={'center'} mb={3}>
                <Flex>
                    <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                        <InputGroup minW={'800px'}>
                            <InputLeftElement pointerEvents={isSubmitting ? 'none' : 'auto'} onClick={handleSubmit(onSearchKeywordSubmit)}>
                                {isSubmitting ? <Icon icon={'loader'} /> : <Icon icon={'search'} />}
                            </InputLeftElement>

                            <Input
                                placeholder="Search for keys, translations, languages, groups..."
                                bg={'#ffffff'}
                                id={'keyword'}
                                {...register('keyword')}
                                onBlur={handleKeywordBlur}
                            />
                            {activeSearchKeyword && (
                                <Button onClick={handleReset} marginLeft={'15px'}>
                                    <Icon icon={'times'} />
                                </Button>
                            )}

                            <Select placeholder="All Languages" bg="white" marginLeft={'15px'} onChange={handleLanguageChange}>
                                {languages.map((lang) => (
                                    <option key={lang.key} value={lang.key}>
                                        {lang.name}
                                    </option>
                                ))}
                            </Select>
                        </InputGroup>
                    </form>

                    <Button as={Link} marginLeft="15px" to={'/translations/groups'} variant={'brand'}>
                        Manage Groups
                    </Button>
                </Flex>

                <Box>
                    <Tooltip
                        hasArrow
                        label={!thereAreGroups ? emptyGroupsTooltipMessage : ''}
                        aria-label={!thereAreGroups ? emptyGroupsTooltipMessage : ''}>
                        <Button as={Link} to={'/translations/add'} variant={'brand'} disabled={!groups || groups.result?.length <= 0}>
                            Add
                        </Button>
                    </Tooltip>
                </Box>
            </Flex>

            {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i: number) => (
                    <Box display={'block'} key={i} w={'100%'}>
                        <Flex>
                            <Skeleton m={'5px'} height="50px" w={'30%'}></Skeleton>

                            <Skeleton m={'5px'} height="50px" w={'100%'}></Skeleton>

                            <Skeleton m={'5px'} height="50px" w={'30%'}></Skeleton>
                        </Flex>
                    </Box>
                ))
            ) : (
                <Flex my={4}>
                    <Table bgColor={'#ffffff'}>
                        <Thead>
                            <Tr>
                                <SortableTableHeader
                                    value="key"
                                    activeSortDirection={activeSortDirection}
                                    activeSortProperty={activeSortProperty}
                                    handleSortSelect={(val) => handleSortSelect(val)}>
                                    Key
                                </SortableTableHeader>

                                <Th textAlign={'center'}>Group</Th>

                                {languages.map((lang) => (
                                    <Th key={lang.key} textAlign={'center'}>
                                        {lang.name}
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {groupedData && Object.keys(groupedData).length > 0 ? (
                                Object.keys(groupedData).map((key) => {
                                    return (
                                        <TranslationItem
                                            key={key}
                                            activePage={activePage}
                                            activeSearchKeyword={activeSearchKeyword}
                                            activeSortDirection={activeSortDirection}
                                            activeSortProperty={activeSortProperty}
                                            activeLanguage={activeLanguage}
                                            translation={{
                                                key,
                                                groupId: groupedData[key].groupId,
                                                groupTitle: groupedData[key].groupTitle,
                                                en: groupedData[key].values.en,
                                                cn: groupedData[key].values.cn,
                                                de: groupedData[key].values.de,
                                                es: groupedData[key].values.es,
                                                kr: groupedData[key].values.kr,
                                                it: groupedData[key].values.it,
                                                fr: groupedData[key].values.fr,
                                                pt: groupedData[key].values.pt,
                                                keysToDelete: groupedData[key].ids,
                                                translationIdsByLanguage: groupedData[key].translationIdsByLanguage,
                                            }}
                                        />
                                    );
                                })
                            ) : (
                                <Tr>
                                    <Td colSpan={12}>
                                        {activeSearchKeyword ? (
                                            <>
                                                Your search{' '}
                                                <b>
                                                    <i>{activeSearchKeyword}</i>
                                                </b>{' '}
                                                did not match any results. Make sure all words are spelled correctly or try different keywords
                                            </>
                                        ) : (
                                            'No translations found'
                                        )}
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </Flex>
            )}

            {data && data?.navigation?.total_pages > 1 && (
                <Pagination
                    currentPage={activePage}
                    totalPages={data.navigation.total_pages}
                    handlePagination={(page: number) => setActivePage(page)}
                />
            )}
        </>
    );
};
