import React from 'react';
import {
    Flex,
    FlexProps,
    Box,
    Image,
    HStack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
} from '@chakra-ui/react';

import Icon from '@/components/Icon';
import Logo from '@/images/jovelogo.svg';
import { useAuth } from '@/context/AuthContext';
import { useLeavePageConfirmation } from '@/hooks/useLeavePageConfirmation';

interface MobileProps extends FlexProps {
    onOpen: () => void;
}

export const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    const { handleLogout, user } = useAuth();
    const { shouldPreventLeaving } = useLeavePageConfirmation()
    const onLogout = () => {
        if (shouldPreventLeaving) return
        handleLogout()
    };
    return (
        <>
            <Flex
                px={4}
                height="16"
                alignItems="center"
                bg={'gray.900'}
                borderBottomWidth="1px"
                borderBottomColor={'gray.200'}
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                {...rest}
            >
                <Box
                    onClick={onOpen}
                    aria-label="open menu"
                    color={'#ffffff'}
                    display={{ base: 'flex', md: 'none' }}
                >
                    <Icon icon='bars' />
                </Box>

                <Image
                    display={{ base: 'flex', md: 'none' }}
                    src={Logo}
                    alt="jove logo"
                    height={'44px'}
                />

                <HStack spacing={{ base: '0', md: '6' }}>
                    <Flex alignItems={'center'}>
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                color={'#ffffff'}
                                _focus={{ boxShadow: 'none' }}
                            >
                                <HStack cursor="pointer">
                                    <Text>{user?.firstName} {user?.lastName}</Text>

                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <Icon icon='chevron-down' />
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList
                                bg={'#ffffff'}
                                borderColor={'#ffffff'}
                            >
                                <MenuItem onClick={onLogout}>Sign out</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            </Flex>
        </>

    );
};