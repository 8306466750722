import { API_URL } from "@/config";
import {useQuery} from 'react-query';
import { axios } from "@/lib/axios";
import { ClassItemResponse } from '../../types';

export const getClass = (classId: number): Promise<ClassItemResponse> => {
    return axios.get(`${API_URL}/api/admin/class/${classId}`);
};

export const useClass = (classId: number, tabIndex?: number) => {
    return useQuery<ClassItemResponse | null, Error>({
        queryKey: ['getClass', classId, { tabIndex: 0 }],
        queryFn: () => getClass(classId),
        keepPreviousData: true,
    });
};
