import React, { useRef, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import { saveQuizImage } from "@/features/Quiz/api/getQuizzes";
import { useNotificationStore } from "@/stores/notifications";

let Font = Quill.import("attributors/style/font");
let Size = Quill.import("attributors/style/size");
Size.whitelist = [ "11px", "12px", "13px", "14px", "15px", "16px", "17px", "18px", "19px", "20px", "22px", "24px", "26px"];
Font.whitelist = [ "Roboto", "serif", "monospace", "Ubuntu Mono", "Raleway",];

Quill.register(Font, true);
Quill.register(Size, true);

const toolbarOptions = [
    [{ 'font': Font.whitelist }, 'bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'indent': '-1'}, {'indent': '+1'}],
    ["link", "formula"],
    [{'color': []}, {'background': []}],
    [{'align': []}],
    ['clean', 'code'],
    [{ 'size': Size.whitelist }],
];

const toolbarOptionsWithImages = [
    [{ 'font': Font.whitelist }, 'bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'indent': '-1'}, {'indent': '+1'}],
    ["link", "image", "formula"],
    [{'color': []}, {'background': []}],
    [{'align': []}],
    ['clean', 'code'],
    [{ 'size': Size.whitelist }],
];

const formats = [
    'header', 'font', 'background', 'color', 'code', 'size', 'height',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'float',
    'list', 'bullet', 'indent', 'script', 'align', 'direction',
    'link', 'code-block', 'formula', 'width',
    'style', 'size', 'code',
];

const formatsWithImages = [
    'header', 'font', 'background', 'color', 'code', 'size', 'height',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'float',
    'list', 'bullet', 'indent', 'script', 'align', 'direction',
    'link', 'code-block', 'formula', 'width',
    'style', 'size', 'image', 'code',
];

type EditorProps = {
    value: string;
    onChange: (value: (((prevState: string) => string) | string)) => void;
    placeholder: string;
    withImages?: boolean;
}

const TextEditor = ({ value, onChange, placeholder, withImages = false }: EditorProps) => {
    const quillRef = useRef<any>();
    const ref = useRef<any>();
    const { addNotification } = useNotificationStore();

    const imageHandler = (e: any) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input?.files && input.files[0];
            if (file && /^image\//.test(file.type)) {
                const formData = new FormData();
                formData.append("file", file);

                const res = await saveQuizImage(formData);

                editor.insertEmbed(editor.getSelection().index, "image", res?.url);
            } else {
                addNotification({
                    type: "error",
                    title: "You could only upload images."
                });
            }
        };
    };

    const codeHandler = () => {
        ref.current?.querySelectorAll('.output-html')[0]?.classList.toggle('hide');
    }

    const modules = useMemo(() => ({
        toolbar: {
            container: toolbarOptions,
            handlers: {
                code: codeHandler,
            }
        }
    }), []);

    const modulesWithImages = useMemo(() => ({
        toolbar: {
            container: toolbarOptionsWithImages,
            handlers: {
                image: imageHandler,
                code: codeHandler,
            },
        },
    }), []);

    return (
        <div className="text-editor" ref={ref}>
            <ReactQuill
                theme="snow"
                value={value ?? ''}
                onChange={onChange}
                placeholder={value ? value : placeholder}
                modules={withImages ? modulesWithImages : modules}
                formats={withImages ? formatsWithImages : formats}
                ref={quillRef}
            />
            <div className='hide output-html'>
                <code className="code">
                    {ref.current?.querySelector('.ql-editor')?.innerHTML}
                </code>
            </div>
        </div>
    );
};

export default TextEditor;
