import { axios } from "@/lib/axios";
import { API_URL } from "@/config";

export const uploadSponsorLogo = (id: number,data: FormData): Promise<{ url: string }> => {
    return axios.post(`${API_URL}/api/admin/sponsor/${id}/add_file/logo`, data);
};

export const uploadSponsorVideo = (id: number,data: FormData): Promise<{ url: string }> => {
    return axios.post(`${API_URL}/api/admin/sponsor/${id}/add_file/video`, data);
};
