import Icon from '@/components/Icon';
import InstitutionsModal from '@/features/Playlists/components/InstitutionsModal';
import RebuildQuizModal from '@/features/Quiz/components/RebuildQuizModal';
import React, { useCallback, useEffect, ChangeEvent, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch, RootState } from '@/redux/store';
import {
    getAllQuizzesLibrary,
    resetQuizLibraryPages,
    selectQuizLibraryActivePage,
    selectQuizLibraryPage,
    selectQuizLibraryLoading,
    selectQuizLibraryTotalPages,
    setQuizLibraryActivePage,
} from '@/redux/reducers/quizzes/quizLibrary';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Table,
    Tbody,
    Td,
    Thead,
    Tr,
    Spinner as SpinnerChakra,
    Button,
    TabList,
    Tab,
    Tabs,
    useDisclosure,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { HiX } from 'react-icons/hi';
import * as yup from 'yup';
import { QuizLibraryItem } from '../QuizLibraryItem';
import { debounce } from '@/utils/helpers';
import { Pagination } from '@/components/Pagination';
import { ThEl } from '@/features/Quiz/components/ArticlesQuiz';

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

type SearchFormData = {
    keyword: string;
    keyword_section: string;
};

export const QuizLibrary = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const activePage = useSelector(selectQuizLibraryActivePage, shallowEqual);
    const totalPages = useSelector(selectQuizLibraryTotalPages, shallowEqual);
    const quizzes = useSelector((state: RootState) => selectQuizLibraryPage(state, activePage), shallowEqual);
    const isLoading = useSelector(selectQuizLibraryLoading, shallowEqual);
    const [isTextbook, setIsTextbook] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
        watch,
        getValues,
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
            keyword_section: '',
        },
    });

    const values = getValues();

    const onSearchKeywordSubmit = useCallback(
        (values: SearchFormData) => {
            dispatch(resetQuizLibraryPages());
            dispatch(setQuizLibraryActivePage(1));

            dispatch(
                getAllQuizzesLibrary({
                    activePage: 1,
                    activeSearchKeyword: values.keyword,
                    isTextbook: isTextbook,
                })
            );
        },
        [dispatch, isTextbook]
    );

    const onSearchKeywordChange = debounce(
        useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                e.preventDefault();

                dispatch(resetQuizLibraryPages());
                dispatch(setQuizLibraryActivePage(1));

                dispatch(
                    getAllQuizzesLibrary({
                        activePage: 1,
                        activeSearchKeyword: e.target.name === 'keyword' ? e.target.value ?? '' : values.keyword,
                        isTextbook: isTextbook,
                    })
                );
            },
            [dispatch, values.keyword, isTextbook]
        ),
        300
    );

    const handleReset = useCallback(() => {
        reset();

        dispatch(
            getAllQuizzesLibrary({
                activePage: 1,
                activeSearchKeyword: '',
                isTextbook: isTextbook,
            })
        );
    }, []);

    const handlePaginationChange = useCallback(
        (page: number) => {
            dispatch(setQuizLibraryActivePage(page));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(
            getAllQuizzesLibrary({
                activePage,
                activeSearchKeyword: values.keyword ?? '',
                isTextbook: isTextbook,
            })
        );
    }, [dispatch, activePage, isTextbook]);

    useEffect(
        () => () => {
            dispatch(setQuizLibraryActivePage(1));
        },
        [dispatch]
    );

    const handleTabsChange = (index: number) => {
        setTabIndex(index);
        setIsTextbook(index === 0);
        dispatch(setQuizLibraryActivePage(1));
    };

    return (
        <>
            <RebuildQuizModal isOpen={isOpen} onClose={onClose} />
            <Flex w="100%" flexDir="column" mb="10px">
                <Tabs index={tabIndex} onChange={handleTabsChange} mb={5}>
                    <TabList>
                        <Tab fontSize="20px" color="#777" fontWeight="500">
                            Textbooks
                        </Tab>
                        <Tab fontSize="20px" color="#777" fontWeight="500">
                            Sections
                        </Tab>
                    </TabList>
                </Tabs>
                <Flex gap="20px" sx={{ '.btn-half-width': { flex: '1 0 45%' } }} w="100%">
                    <Flex justifyContent="space-between" alignItems="center" mb={3} w="100%">
                        <Flex>
                            <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                                <InputGroup minW="200px" className="input-left-wrap">
                                    <InputLeftElement
                                        className="InputLeft"
                                        pointerEvents={isSubmitting ? 'none' : 'auto'}
                                        onClick={handleSubmit(onSearchKeywordSubmit)}>
                                        {isSubmitting || isLoading ? <SpinnerChakra size="20px" /> : <FaSearch size="20px" />}
                                    </InputLeftElement>
                                    <Input
                                        placeholder="Search JoVE Library"
                                        bg="#ffffff"
                                        id="keyword"
                                        type="text"
                                        borderRadius="1000px"
                                        {...register('keyword')}
                                        onChange={onSearchKeywordChange}
                                    />
                                    {watch('keyword') && (
                                        <InputRightElement cursor="pointer" onClick={handleReset}>
                                            <HiX />
                                        </InputRightElement>
                                    )}
                                </InputGroup>
                            </form>
                        </Flex>

                        {tabIndex === 1 && (
                            <Button _focus={{ outline: 0 }} _hover={{}} _active={{}} onClick={onOpen} variant="brand" cursor="pointer">
                                <Icon icon="trowel-bricks" /> &nbsp; Rebuild a sections Quiz library
                            </Button>
                        )}

                        <Button
                            _focus={{ outline: 0 }}
                            _hover={{}}
                            _active={{}}
                            as={Link}
                            to={'/quiz/library/create'}
                            variant="brand"
                            cursor="pointer">
                            <Icon icon="plus" /> &nbsp; Create a New Quiz
                        </Button>
                    </Flex>
                </Flex>
                <Flex flexDir="column">
                    <Table bgColor="#ffffff">
                        {quizzes.length > 0 && (
                            <Thead borderBottom="1px solid #e5ebf3">
                                <Tr w="100%">
                                    <ThEl align="start" w="65%">
                                        Title
                                    </ThEl>
                                    <ThEl w="10%" align="start">
                                        Subjects
                                    </ThEl>
                                    <ThEl w="15%">Questions</ThEl>
                                    <ThEl w="15%">Actions</ThEl>
                                </Tr>
                            </Thead>
                        )}
                        <Tbody>
                            {quizzes.length > 0 ? (
                                quizzes.map((quiz) => <QuizLibraryItem key={quiz.id} quiz={quiz} isSectionType={tabIndex === 1} />)
                            ) : (
                                <>
                                    {!isLoading && (
                                        <Tr>
                                            <Td>
                                                <Box>No list items</Box>
                                            </Td>
                                        </Tr>
                                    )}
                                </>
                            )}
                        </Tbody>
                    </Table>

                    {totalPages > 1 && (
                        <Flex justifyContent="end" mt="20px">
                            <Pagination currentPage={activePage} totalPages={totalPages} handlePagination={handlePaginationChange} />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </>
    );
};
