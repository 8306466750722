import React from 'react';
import {SignIn} from '@/features/Authentication/routes/SignIn';
import {Navigate, Route} from 'react-router-dom';
import {SetPassword} from "@/features/Authentication/routes/SetPassword";

export const publicRoutes = [
    {
        path: '/signin',
        element: <SignIn/>,
    },
    {
        path: '/set-password',
        element: <SetPassword/>,
    },
    {path: '/*', element: <Navigate to="/signin"/>},
];
