import { API_URL } from "@/config";
import { axios } from "@/lib/axios";
import { useAppDispatch } from '@/redux/hooks';
import { getAllClasses, getClassById } from '@/redux/reducers/classes/classesSlice';
import { useNotificationStore } from "@/stores/notifications";
import { useMutation, useQueryClient } from "react-query";
import { ClassShareData } from "../../types";

type ShareClassData = {
    classId: number;
    data: ClassShareData;
}

const shareClass = ({classId, data}: ShareClassData) => {
    return axios.patch(`${API_URL}/api/admin/class/${classId}/share`, data)
}

export const useShareClass = (closeModalFunction: () => void) => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    return useMutation({
        onError: (error: any) => {
            const title = error.message || 'Unable to share Class'
            addNotification({
                type: 'error',
                title
            });
        },
        onSuccess: (_, { classId }) => {
            queryClient.refetchQueries(['classes']);
            queryClient.refetchQueries(['getClass', classId]);
            addNotification({
                type: 'success',
                title: 'Class successfully shared',
            });
            dispatch(getAllClasses());
            dispatch(getClassById(classId));
            closeModalFunction();
        },
        mutationFn: shareClass,
    })
}

