import { RootState } from '@/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListContext, Ctx, LandingPagesType } from '@/types';

interface Data {
    researchId?: number;
    educationId?: number;
    joveCoreId?: number;
    joveScienceId?: number;
    landingPagesId?: number;
    joveJournalId?: number;
    encyclopediaId?: number;
    joveManualId?: number;
    syllabusId?: number;
    textBooksId?: number;
    traditionalResourcesId?: number;
    openEducationsResourcesId?: number;
    facultyResourcesId?: number;
    ltiId?: number;
    joveVideoTextbookId?: number;

    listOfContent: ListContext['children'];
    joveCoreList: ListContext;
    joveCoreTree: Ctx;
    joveScienceEducationList: ListContext;
    joveLabManualList: ListContext;
    joveLabManualTree: Ctx;
    joveEncyclopediaOfExperimentsList: ListContext;
    researchSubjectsList: ListContext;
    landingPagesList: LandingPagesType;
    educationSubjectsList: ListContext;
    ltiSubjectsList: ListContext;
}

const initialState: Data = {
    researchId: -1,
    educationId: -1,
    joveCoreId: -1,
    joveScienceId: -1,
    joveJournalId: -1,
    encyclopediaId: -1,
    joveManualId: -1,
    syllabusId: 0,
    textBooksId: 0,
    traditionalResourcesId: 0,
    openEducationsResourcesId: 0,
    facultyResourcesId: 0,
    landingPagesId: -1,
    ltiId: -1,
    joveVideoTextbookId: -1,

    listOfContent: [] as ListContext['children'],
    joveCoreList: {} as ListContext,
    joveCoreTree: {} as Ctx,
    joveScienceEducationList: {} as ListContext,
    joveLabManualList: {} as ListContext,
    joveLabManualTree: {} as Ctx,
    joveEncyclopediaOfExperimentsList: {} as ListContext,
    researchSubjectsList: {} as ListContext,
    landingPagesList: {} as LandingPagesType,
    educationSubjectsList: {} as ListContext,
    ltiSubjectsList: {} as ListContext,
};

export const DataSlice = createSlice({
    name: 'Data',
    initialState,
    reducers: {
        initIds(
            state: Data,
            action: PayloadAction<{
                joveCoreId?: number;
                joveScienceId?: number;
                joveJournalId?: number;
                encyclopediaId?: number;
                joveManualId?: number;
                syllabusId?: number;
                textBooksId?: number;
                facultyResourcesId?: number;
                traditionalResourcesId?: number;
                openEducationsResourcesId?: number;
                landingPagesId?: number;
                ltiId?: number;
                joveVideoTextbookId?: number;
            }>
        ) {

            state.joveCoreId = action.payload.joveCoreId;
            state.joveScienceId = action.payload.joveScienceId;
            state.joveJournalId = action.payload.joveJournalId;
            state.encyclopediaId = action.payload.encyclopediaId;
            state.joveManualId = action.payload.joveManualId;
            state.syllabusId = action.payload.syllabusId;
            state.textBooksId = action.payload.textBooksId;
            state.traditionalResourcesId = action.payload.traditionalResourcesId,
            state.openEducationsResourcesId = action.payload.openEducationsResourcesId,
            state.facultyResourcesId = action.payload.facultyResourcesId;
            state.landingPagesId = action.payload.landingPagesId;
            state.ltiId = action.payload.ltiId;
            state.joveVideoTextbookId = action.payload.joveVideoTextbookId;

        },
        initContentList(state: Data, action: PayloadAction<ListContext>) {
            state.listOfContent = action.payload.children;
        },
        // JoveCore
        initJoveCore(state: Data, action: PayloadAction<ListContext>) {
            state.joveCoreList = action.payload;
        },
        initJoveCoreTree(state: Data, action: PayloadAction<Ctx>) {
            state.joveCoreTree = action.payload;
        },
        // ScienceEducation
        initScienceEducation(state: Data, action: PayloadAction<ListContext>) {
            state.joveScienceEducationList = action.payload;
        },
        // Lab Manual
        initJoveLabManual(state: Data, action: PayloadAction<ListContext>) {
            state.joveLabManualList = action.payload;
        },
        initJoveLabManualTree(state: Data, action: PayloadAction<Ctx>) {
            state.joveLabManualTree = action.payload;
        },
        // JoveEncyclopediaOfExperiments
        initJoveEncyclopediaOfExperiments(state: Data, action: PayloadAction<ListContext>) {
            state.joveEncyclopediaOfExperimentsList = action.payload;
        },
        initResearchSubjects(state: Data, action: PayloadAction<ListContext>) {
            state.researchSubjectsList = action.payload;
        },
        initLandingPages(state: Data, action: PayloadAction<LandingPagesType>) {
            state.landingPagesList = action.payload;
        },
        // misc
        initEducationSubjects(state: Data, action: PayloadAction<ListContext>) {
            state.educationSubjectsList = action.payload;
        },
        initLtiSubjects(state: Data, action: PayloadAction<ListContext>) {
            state.ltiSubjectsList = action.payload;
        },
    },
});
export const {
    initIds,
    initContentList,
    initJoveCore,
    initJoveCoreTree,
    initScienceEducation,
    initJoveLabManual,
    initJoveLabManualTree,
    initJoveEncyclopediaOfExperiments,
    initResearchSubjects,
    initLandingPages,
    initEducationSubjects,
    initLtiSubjects
} = DataSlice.actions;
export default DataSlice.reducer;
