import Axios, { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';

import { API_URL } from '@/config';
import storage from '@/utils/storage';
import {refreshAccessToken} from '@/api/authApi/authApi';
// import {useNotificationStore} from '@/stores/notifications';

type TokenRes = {
    token: string
}

function authRequestInterceptor(config: AxiosRequestConfig) {
    const token = storage.getToken();

    if (config.headers === undefined) {
        config.headers = {};
    }
    if (token?.accessToken) {
        config.headers.authorization = `Bearer ${token.accessToken}`;
    }
    config.headers.Accept = 'application/json';
    return config;
}

export const axios = Axios.create({
    baseURL: API_URL,
});

let authTokenRequest: Promise<TokenRes> | null

function getAuthToken(refreshToken: string) {
    if (!authTokenRequest) {
        authTokenRequest = refreshAccessToken(refreshToken);
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }

    return authTokenRequest;
}

function resetAuthTokenRequest() {
    authTokenRequest = null;
}

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
        if (response.request.responseURL.includes('api/admin/user/create_admin')){
            return response
        } else
        // return response.data;
        return response.data?.content ?? response;
    },
    (error) => {
        const originalRequest = error.config;
        if(error.response?.status === 401 && !originalRequest._retry && originalRequest?.headers.hasOwnProperty('authorization')) {
            const token = storage.getToken();
            if(!token) return;
            if(token?.refreshToken && dayjs().isBefore(dayjs(token.refreshTokenExpiredAt))) {
                return getAuthToken(token.refreshToken).then((response:TokenRes) => {
                    storage.setToken(response.token);
                    originalRequest._retry = true;
                    return axios(originalRequest);
                });
            } else {
                storage.clearToken();
                window.location.reload();
            }
        }
        const message = error.response?.data?.content?.message || error.message;
        // useNotificationStore.getState().addNotification({
        //     type: 'error',
        //     title: message,
        // });
        return Promise.reject(error.response?.data?.content ?? error);
        // return Promise.reject(error);
    }
);
