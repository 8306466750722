import React, { useState } from 'react';
import { Button, Heading, Flex, Table, Thead, Tr, Th, Tbody, Box, useDisclosure, Td } from '@chakra-ui/react';

import Icon from '@/components/Icon';

import { Pagination } from '@/components/Pagination';
import { ArticleList } from '@/features/Sponsors/types';
import { SponsorAssignedArticlesListItem } from '@/features/Sponsors/Components/SponsorAssignedArticlesListItem';
import { SponsorsAssignArticlesModal } from '@/features/Sponsors/Components/SponsorAssignArticlesModal';

type Props = {
    sponsorId: number;
    articlesList?: ArticleList[];
};

export const SponsorsAssignedArticlesList = ({ sponsorId, articlesList }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activePage, setActivePage] = useState(1);

    const handlePageChange = (val: number) => {
        setActivePage(val);
    };

    return (
        <>
            <SponsorsAssignArticlesModal isOpen={isOpen} onClose={onClose} sponsorId={sponsorId} articlesList={articlesList} />
            <Flex justifyContent={'space-between'} mb={3}>
                <Heading size="md">Articles</Heading>
                <Button leftIcon={<Icon icon={'plus'} />} onClick={onOpen} variant="brand" size="sm">
                    Assign Articles
                </Button>
            </Flex>
            <Table>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Title</Th>
                        <Th>Added On</Th>
                        <Th>Is Active</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {articlesList && articlesList?.length > 0 ? (
                        articlesList
                            ?.slice(activePage * 10 - 10, activePage * 10)
                            .map((assignedArticle) => (
                                <SponsorAssignedArticlesListItem key={assignedArticle.id} assignedArticle={assignedArticle} sponsorId={sponsorId} />
                            ))
                    ) : (
                        <Tr py={4} px={6}>
                            <Td>No articles have been assigned to this sponsor</Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            {articlesList && articlesList?.length > 10 && (
                <Box ml={2} mt={3}>
                    <Pagination totalPages={Math.ceil(articlesList.length / 10)} currentPage={activePage} handlePagination={handlePageChange} />
                </Box>
            )}
        </>
    );
};
