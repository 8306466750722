import React, { useState, useEffect, useContext, createContext } from 'react';
import { getProfile, loginWithEmailAndPassword } from '@/api/authApi/authApi';
import storage from "@/utils/storage";
import { User } from "@/types";

interface AuthContextInterface {
    user: User | null,
    authChecked: boolean,
    handleLogin: ({email, password}: { email: string, password: string }) => void
    handleLogout: () => void
    handleSetPasswordLogin: (token:string) => void
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

type contextProps = {
    children: React.ReactNode
}

function AuthProvider({children}: contextProps) {
    const [user, setUser] = useState<User | null>(null);
    const [authChecked, setAuthChecked] = useState<boolean>(false);

    useEffect(() => {
        handleAuthCheck();
    }, []);

    const handleAuthCheck = async () => {
        let token = storage.getToken();
        if (token === 'empty') {
            await setUser(null);
            storage.clearToken();
            setAuthChecked(true);
        } else {
            getUser();
        }
    }

    const handleLogin = async ({email, password}: { email: string, password: string }) => {
        const res = await loginWithEmailAndPassword({email, password});
        storage.setToken(res.token)
        await getUser()
        return user;
    };

    const handleSetPasswordLogin = async (token:string ) => {
        storage.setToken(token)
        await getUser()
        return user;
    };

    const handleLogout = () => {
        storage.clearToken();
        setUser(null)
    }

    const getUser = async () => {
        try {
            if (storage.getToken()) {
                let userRes = await getProfile();
                setUser(userRes)
            } else {
                storage.clearToken();
            }
            setAuthChecked(true);
            return null;
        } catch (err) {
            throw err;
        }
    };


    return (
        <AuthContext.Provider value={{user, authChecked, handleLogin, handleLogout, handleSetPasswordLogin}}>
            {authChecked && children}
        </AuthContext.Provider>
    );
}

function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return context;
}

export {AuthProvider, useAuth};
