import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Spinner, Flex } from '@chakra-ui/react';

import { protectedRoutes, protectedRoutesForSuperAdmin, protectedRoutesForCSUser, protectedRoutesForPublishUser } from './protected';
import { publicRoutes } from './public';
import { useAuth } from '@/context/AuthContext';

export const AppRoutes = () => {

    const { user, authChecked } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');
    const isCSUser = user?.roles.includes('ROLE_CS_USER');
    const isPublishUser = user?.roles.includes('ROLE_PUBLISH');
    const routes = user
        ? (isSuperAdmin && protectedRoutesForSuperAdmin) || (isCSUser && protectedRoutesForCSUser) || (isPublishUser && protectedRoutesForPublishUser) || protectedRoutes
        : publicRoutes;

    const element = useRoutes([...routes]);

    if(!authChecked) {
        return (
            <Flex
                height={'full'}
                w={'full'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Spinner size="xl" />
            </Flex>
        )
    }

    return <>{element}</>;
};
