import React, { useCallback } from 'react';
import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button
} from '@chakra-ui/react';
import { deleteTemplateQuiz, QuizType, selectQuizLoading } from '@/redux/reducers/quizzes/quizSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/redux/store';
import { QuizLibrary } from "@/features/Quiz";
import { removeQuizLibrary } from "@/redux/reducers/quizzes/quizLibrary";
import { removeQuizAdmin } from "@/redux/reducers/quizzes/quizAdmin";
import { useDeleteQuiz } from "@/features/Quiz/api/deleteRegularQuiz";
import { removeQuizCustomer } from "@/redux/reducers/quizzes/quizCustomer";
import {useNotificationStore} from "@/stores/notifications";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    quiz: QuizType | QuizLibrary;
    quizType: 'regular' | 'admin' | 'template' | 'library' | 'customer'
};

export const DeleteQuizModal = ({ isOpen, onClose, quiz, quizType }: Props) => {
    const deleteQuizMutation = useDeleteQuiz(onClose);
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector(selectQuizLoading, shallowEqual);
    const {addNotification} = useNotificationStore();

    const handleDelete = useCallback(async () => {
        if (quizType === 'library') {
            await dispatch(removeQuizLibrary(quiz.id))
        }
        if (quizType === 'admin') {
            await dispatch(removeQuizAdmin(quiz.id))
        }
        if (quizType === 'template') {
            await dispatch(deleteTemplateQuiz(quiz.id));
        }
        if (quizType === 'customer') {
            await dispatch(removeQuizCustomer(quiz.id));
        }
        if (quizType === 'regular') {
           await deleteQuizMutation.mutate(quiz.id);
        }
        addNotification({
            type: 'success',
            title: 'Quiz deleted successfully',
        });
    }, [dispatch, quiz.id, quizType]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Quiz</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure you want to delete Quiz: <b>{quiz.title}</b> ?
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme="red" onClick={handleDelete} isLoading={isLoading || deleteQuizMutation.isLoading}>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
