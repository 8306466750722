import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Sponsors } from '@/features/Sponsors/routes/Sponsors';
import { EditSponsor } from '@/features/Sponsors/Components/EditSponsor';
import { AddSponsor } from '@/features/Sponsors/Components/AddSponsor';

export const SponsorsRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Sponsors />} />
            <Route path="/edit/:id" element={<EditSponsor />} />
            <Route path="/add" element={<AddSponsor />} />
        </Routes>
    );
};
