import { GetLinkItems } from '../types/getLinkItems';

export const getLinkItemsForCorporateAdmin: GetLinkItems = (featureFlags: Record<string, boolean>) => [
    { name: 'Dashboard', icon: 'table-columns', url: '/dashboard' },
    { name: 'Methods Collections', icon: 'books', url: '/methods-collections' },
    { name: 'Playlists', icon: 'play-circle', url: '/playlist' },
    { name: 'LTI', icon: 'list-check', url: '/lti' },
    { name: 'Classes', icon: 'graduation-cap', url: '/classes' },
    { name: 'Institutions', icon: 'building-columns', url: '/institutions/list' },
    { name: 'Quizzes', icon: 'arrows-up-to-line', url: '/quiz' },
    { name: 'Identity Providers', icon: 'passport', url: '/identity-providers' },
    { name: 'Sponsors', icon: 'circle-dollar', url: '/sponsors' },
    { name: 'Translations', icon: 'circle-dollar', url: '/translations', hide: !featureFlags.adminTranslations },
    { name: 'Publish', icon: 'newspaper', url: '/publish/auto-translation' },
];
