import { modelShaper } from '@/utils/helpers';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Checkbox, CheckboxGroup, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { getAdminQuizById, getQuiz, getQuizLibraryById, getUserQuizById } from '../../api/getQuizzes';
import { ViewQuizVideoContainer } from '../ViewQuizVideoContainer';
import { QuizCheckboxIcon } from '../QuizCheckboxIcon';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ErrorBlock } from '@/components/ErrorBlock';
import { useAuth } from '@/context/AuthContext';
import parse from 'html-react-parser';
import Icon from '@/components/Icon';

export const ViewQuiz = () => {
    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const navigate = useNavigate();
    const { id, quizLibraryId, adminQuizId, userQuizId, myQuizId, customerQuizId } = useParams();
    const { pathname } = useLocation();

    const isCustomerQuiz = pathname?.includes('customer-quiz');

    const quizQueryKey = useCallback(() => {
        if (userQuizId) {
            return `getUserQuiz-${userQuizId}`;
        }
        if (adminQuizId) {
            return `getAdminQuiz-${adminQuizId}`;
        }
        if (quizLibraryId) {
            return `getQuizLibrary-${quizLibraryId}`;
        }
        if (myQuizId) {
            return `myQuizId-${myQuizId}`;
        }
        if (customerQuizId) {
            return `customerQuizId-${customerQuizId}`;
        }
        return `getQuiz-${id}`;
    }, [userQuizId, adminQuizId, quizLibraryId, id, myQuizId, customerQuizId]);

    const { data, isLoading, isError, error } = useQuery(
        quizQueryKey(),
        async () => {
            if (id || quizLibraryId || adminQuizId || userQuizId || myQuizId || customerQuizId) {
                if (userQuizId) {
                    return await getUserQuizById(Number(userQuizId));
                }
                if (adminQuizId) {
                    return await getAdminQuizById(Number(adminQuizId));
                }
                if (quizLibraryId) {
                    return await getQuizLibraryById(Number(quizLibraryId));
                }
                if (myQuizId) {
                    return await getUserQuizById(Number(myQuizId));
                }
                return await getQuiz(Number(id));
            }

            return null;
        },
        {
            useErrorBoundary: (error: any) => error.response?.status >= 400,
        }
    );

    const [tabActivity, setTabActivity] = useState<number | null>(null);
    const [textOptions, setTextOptions] = useState<
        {
            activityId: number;
            items: {
                itemId: number;
                value: string[];
            }[];
        }[]
    >(
        data?.activities
            ?.sort((a, b) => a.sortIndex - b.sortIndex)
            .map((activityItem) => {
                return {
                    activityId: activityItem.id ?? 0,
                    items:
                        activityItem.items
                            ?.filter((item) => item.type === 2)
                            .map((item) => {
                                return {
                                    itemId: item.id ?? 0,
                                    value: [],
                                };
                            }) ?? [],
                };
            }) ?? []
    );

    const timeLimit = useCallback(() => {
        if (data && data.duration) {
            const hours = Math.floor(data.duration / 60);
            const minutes = data.duration % 60;
            return `${hours ? `${hours}:` : ''}${minutes ? `${Math.floor(minutes)}` : '00'}:00`;
        }
    }, []);

    const handleTextOptionsChange = useCallback(
        (activityId: number, activityItemId: number, isMultiple = false) =>
            (value: string[]) => {
                setTextOptions((state) => {
                    const activitiesSelect = state.find((item) => item.activityId === activityId);
                    const otherActivities = state.filter((item) => item.activityId !== activityId);

                    if (activitiesSelect) {
                        return [
                            ...otherActivities,
                            {
                                activityId: activitiesSelect.activityId,
                                items: activitiesSelect.items.map((item) => {
                                    if (item.itemId === activityItemId) {
                                        return {
                                            itemId: item.itemId,
                                            value: isMultiple ? value : value.filter((dataValue) => !item.value.includes(dataValue)),
                                        };
                                    }
                                    return item;
                                }),
                            },
                        ];
                    }

                    return otherActivities;
                });
            },
        []
    );

    const handleBackToQuizzesClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            navigate(`/quiz`, {
                replace: true,
                state: {
                    tabMyQuizzes: true,
                },
            });
        },
        [navigate]
    );

    useEffect(() => {
        if (data) {
            setTextOptions(
                data.activities
                    ?.sort((a, b) => a.sortIndex - b.sortIndex)
                    .map((activityItem) => {
                        return {
                            activityId: activityItem.id ?? 0,
                            items:
                                activityItem.items
                                    ?.filter((item) => item.type === 2)
                                    .map((item) => {
                                        return {
                                            itemId: item.id ?? 0,
                                            value: [],
                                        };
                                    }) ?? [],
                        };
                    }) ?? []
            );

            data.activities && setTabActivity(data.activities[0].id);
        }
    }, [data]);

    if (!isSuperAdmin && !quizLibraryId && !adminQuizId && !userQuizId && !myQuizId) {
        return <Navigate to="/" />;
    }

    if (isLoading) return <LoadingSpinner />;
    if (error?.statusCode === 404 || isError) return <ErrorBlock error={'Error'} textOnly />;

    return (
        <>
            {data && (
                <Flex w="100%" maxW="1440px" m="0 auto" justify="space-between" flexDir="row" rowGap="20px" minH="95vh" className="quiz-columns">
                    <Flex className="left-quiz-column" p="18px 40px" w="33%" bg="primary.grey" flexDirection="column">
                        <>
                            <Box
                                my="20px"
                                letterSpacing="0.4px"
                                fontSize="20px"
                                fontWeight="bold"
                                lineHeight="26px"
                                mt="10px"
                                mb="20px"
                                pb="7px"
                                borderBottom="5px solid"
                                borderBottomColor="primary.greyLine"
                                dangerouslySetInnerHTML={{ __html: data.title }}
                            />

                            {data.duration && (
                                <>
                                    <Box mt="10px" pb="5px" fontSize="15px" fontWeight="500" color="primary.greyText" lineHeight="20px">
                                        Time Limit
                                    </Box>
                                    <Box color="red">{timeLimit()}</Box>
                                </>
                            )}

                            {data.endDate && (
                                <>
                                    <Box mt="10px" pb="5px" fontSize="15px" fontWeight="500" color="primary.greyText" lineHeight="20px">
                                        Deadline
                                    </Box>
                                    <Box color="red">{new Date(data.endDate).toLocaleString()}</Box>
                                </>
                            )}

                            {isCustomerQuiz && (
                                <>
                                    <Box mt="10px" pb="5px" fontSize="15px" fontWeight="500" color="primary.greyText" lineHeight="20px">
                                        Type Of Quiz: <Text>{data?.isGraded ? 'Graded' : 'Ungraded'}</Text>
                                    </Box>
                                </>
                            )}

                            {!id && !isCustomerQuiz && (
                                <>
                                    <Flex
                                        justify="start"
                                        flexDir="row"
                                        mt="30px"
                                        mb="10px"
                                        pb="7px"
                                        fontSize="15px"
                                        fontWeight="500"
                                        color="primary.greyText"
                                        lineHeight="15px"
                                        borderBottomStyle="solid"
                                        borderBottomWidth="1px"
                                        borderBottomColor="primary.greyLine">
                                        <Box lineHeight="15px" h="auto">
                                            Activities
                                        </Box>
                                    </Flex>

                                    {data.activities?.map((activity, i) => (
                                        <Flex
                                            w={'100%'}
                                            cursor={'pointer'}
                                            color="primary.blue"
                                            pb="10px"
                                            mb="10px"
                                            borderBottom="1px solid"
                                            borderBottomColor="primary.greyLine"
                                            onClick={() => setTabActivity(activity.id)}
                                            key={i}>
                                            <Icon icon="file-lines" fontSize="20px" />
                                            <Box fontSize="14px" w={'100%'} textAlign={'center'}>
                                                {activity.title ?? ''}
                                            </Box>
                                        </Flex>
                                    ))}
                                </>
                            )}

                            {userQuizId && (
                                <Flex alignItems="start" height="min-content" fontSize="16px" mt="2rem" width="100%">
                                    <Box flexBasis="110px">
                                        <Text w="110px" fontWeight="500" fontSize="16px" color="primary.greyText">
                                            Access Type:
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Box
                                            outline="1px solid transparent"
                                            h="2rem"
                                            bgColor="transparent"
                                            fontWeight="500"
                                            _hover={{ backgroundColor: 'transparent' }}
                                            _active={{ backgroundColor: 'transparent' }}
                                            _focus={{ backgroundColor: 'transparent' }}>
                                            {!data?.restrictNonInstitutional ? 'Open' : 'Restricted'}
                                        </Box>
                                        <Box color="#717171">
                                            {data?.restrictNonInstitutional
                                                ? 'Only students from your institute can take this quiz using their institutional email'
                                                : 'Students can take quiz using any email address'}
                                        </Box>
                                    </Box>
                                </Flex>
                            )}
                        </>
                    </Flex>

                    <Flex className="right-quiz-column" w="67%" bg="white" flexDirection="column">
                        <Flex flexDir="row" mx="72px" minH="100%" className="right-column-quiz">
                            <Flex flexDir="column" w="100%" p="18px" bg="primary.grey">
                                <Box borderLeft={'3px solid #6236FF'} marginLeft={'-17px'} p={'10px 0'} fontSize={'14px'}>
                                    <Box marginLeft={'15px'} fontWeight={'bold'} color={'#6236FF'}>
                                        Activity 1 of {data?.activities && data.activities.length > 0 ? data.activities.length : '1'}
                                    </Box>
                                    <Box marginLeft={'15px'} fontWeight={'bold'}>
                                        {data.activities?.find((item) => item.id === tabActivity)?.title ?? ''}
                                    </Box>
                                </Box>

                                <Flex w="100%" mb="18px" flexDir="column">
                                    {data.activities
                                        ?.filter((item) => item.id === tabActivity)
                                        .map((activity, i) => (
                                            <Fragment key={i}>
                                                {activity.items &&
                                                    modelShaper(activity.items)
                                                        ?.filter(
                                                            (item) =>
                                                                !['lab_manual_procedure', 'lab_manual_prep'].includes(item?.article?.articleType)
                                                        )
                                                        .map((activityItem, i: number) => (
                                                            <Box key={i} px={'18px'}>
                                                                <Box
                                                                    w={'100%'}
                                                                    margin={'0 auto'}
                                                                    bg={'white'}
                                                                    p={'18px'}
                                                                    border={'1px solid #EDEDED'}
                                                                    boxShadow={'0px 3px 10px 2px rgb(0 0 0 / 12%)'}
                                                                    pos={'relative'}
                                                                    borderRadius={'6px'}
                                                                    mx={0}
                                                                    mb={'18px'}>
                                                                    <Flex justify="space-between" w={'100%'}>
                                                                        {activityItem?.type === 1 ? (
                                                                            <Box w={'100%'}>
                                                                                <Box
                                                                                    fontWeight="500"
                                                                                    fontSize="16px"
                                                                                    lineHeight="24px"
                                                                                    pb="9px"
                                                                                    dangerouslySetInnerHTML={{ __html: activityItem.title }}
                                                                                />
                                                                                <Box
                                                                                    w={'100%'}
                                                                                    minH={'400px'}
                                                                                    position={'relative'}
                                                                                    bgColor={'#F6F7FB'}>
                                                                                    <ViewQuizVideoContainer
                                                                                        videoData={activityItem.article?.videoData ?? null}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        ) : (
                                                                            <Box w={'100%'}>
                                                                                <Box
                                                                                    fontWeight={'bold'}
                                                                                    color={'#F7B500'}
                                                                                    textTransform={'uppercase'}
                                                                                    fontSize={'12px'}
                                                                                    pb={'7px'}>
                                                                                    Question {activityItem.questionOrder}
                                                                                </Box>
                                                                                <Box
                                                                                    fontWeight="500"
                                                                                    fontSize="16px"
                                                                                    lineHeight="24px"
                                                                                    pb="9px"
                                                                                    dangerouslySetInnerHTML={{ __html: activityItem.title }}
                                                                                />

                                                                                {activityItem.image && (
                                                                                    <Box width={'100%'} pb="20px" m={0}>
                                                                                        <Image src={activityItem.image} width={'100%'} />
                                                                                    </Box>
                                                                                )}

                                                                                <CheckboxGroup
                                                                                    onChange={handleTextOptionsChange(
                                                                                        activity.id ?? 0,
                                                                                        activityItem.id ?? 0,
                                                                                        activityItem.isMultiple
                                                                                    )}
                                                                                    value={
                                                                                        textOptions
                                                                                            .find((data) => data.activityId === activity?.id)
                                                                                            ?.items.find((data) => data.itemId === activityItem.id)
                                                                                            ?.value ?? []
                                                                                    }>
                                                                                    <Stack spacing={5}>
                                                                                        {activityItem.textOptions &&
                                                                                            activityItem.textOptions.map(
                                                                                                (
                                                                                                    textOption: {
                                                                                                        id: number;
                                                                                                        text: string;
                                                                                                    },
                                                                                                    i: number
                                                                                                ) => {
                                                                                                    const textOptionsSelected =
                                                                                                        textOptions
                                                                                                            .find(
                                                                                                                (data) =>
                                                                                                                    data.activityId === activity?.id
                                                                                                            )
                                                                                                            ?.items.find(
                                                                                                                (data) =>
                                                                                                                    data.itemId === activityItem.id
                                                                                                            )?.value ?? [];
                                                                                                    return (
                                                                                                        <Flex
                                                                                                            key={i}
                                                                                                            sx={{
                                                                                                                '.chakra-checkbox__control': {
                                                                                                                    borderRadius: '100%',
                                                                                                                },
                                                                                                                '.textOptionsGood:after': {
                                                                                                                    content: '"Correct Answer"',
                                                                                                                    color: 'green',
                                                                                                                    fontWeight: 500,
                                                                                                                    fontSize: '18px',
                                                                                                                    paddingLeft: '20px',
                                                                                                                },
                                                                                                                '.textOptionsFail:after': {
                                                                                                                    content: '"Incorrect Answer"',
                                                                                                                    color: 'red',
                                                                                                                    fontWeight: 500,
                                                                                                                    fontSize: '18px',
                                                                                                                    paddingLeft: '20px',
                                                                                                                },
                                                                                                            }}>
                                                                                                            <Checkbox
                                                                                                                colorScheme="green"
                                                                                                                value={`${textOption.id}`}
                                                                                                                icon={<QuizCheckboxIcon />}
                                                                                                                fontSize={'18px'}
                                                                                                                className={
                                                                                                                    !data.isGraded &&
                                                                                                                    textOptionsSelected.includes(
                                                                                                                        `${textOption.id}`
                                                                                                                    )
                                                                                                                        ? (
                                                                                                                              activityItem.rightAnswer ??
                                                                                                                              []
                                                                                                                          ).includes(textOption.id)
                                                                                                                            ? 'textOptionsGood'
                                                                                                                            : 'textOptionsFail'
                                                                                                                        : ''
                                                                                                                }>
                                                                                                                {parse(textOption.text)}
                                                                                                            </Checkbox>
                                                                                                        </Flex>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </Stack>
                                                                                </CheckboxGroup>
                                                                            </Box>
                                                                        )}
                                                                    </Flex>
                                                                </Box>
                                                            </Box>
                                                        ))}
                                            </Fragment>
                                        ))}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>
    );
};
