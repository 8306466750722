import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '@/redux/hooks';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Flex,
    Input,
    Box,
    Text,
    Menu,
    MenuButton,
    MenuList,
    Divider,
    Spinner,
    Checkbox,
    Select,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Pagination } from '@/components/Pagination';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { VideoListItem} from './VideoListItem';
import { resetArticlesPages } from '@/redux/reducers/playlist/articles/articlesSlice';
import ModalFilter from './ModalFilter';
import { getSectionSubjectTree } from '@/redux/reducers/playlist/articles/sectionSlice';
import { SearchArticles } from '@/types';
import { PlaylistData } from '../../types';
import { FiInfo } from 'react-icons/fi';
import { fetchSearch } from '@/utils/api';
import { useQuery } from 'react-query';
import Icon from '@/components/Icon';

let searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

export type SearchFormData = {
    keyword: string;
    labels: string;
};

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (videoId: number, title: string, imageArr: string[], type: string) => void;
    articles?: PlaylistData[]
    handleLabelSelect?: (value: string) => void;
    setImportQuestion?: () => void;
    selectedLabel?: string;
};

export const buildEditorsParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    publicationDateStart?: string,
    publicationDateEnd?: string,
    authorName?: string,
    institutionName?: string,
    activeSubjects?: string[]
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 9,
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword
                    ? [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: 'title',
                                operator: 'contains',
                                value: activeSearchKeyword ?? 0,
                            },
                        },
                    ]
                    : []),
                ...(authorName
                    ? [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: 'full_name',
                                operator: 'contains',
                                value: authorName ?? 0,
                            },
                        },
                    ]
                    : []),
                ...(publicationDateStart || publicationDateEnd
                    ? [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: 'published_at',
                                operator: 'between',
                                value: [publicationDateStart, publicationDateEnd] ?? 0,
                            },
                        },
                    ]
                    : []),
                ...(institutionName
                    ? [
                        {
                            bool_operator: 'and',
                            condition: {
                                property: 'institution_name',
                                operator: 'contains',
                                value: institutionName ?? 0,
                            },
                        },
                    ]
                    : []),
                activeSubjects?.length && {
                    bool_operator: 'and',
                    condition: {
                        property: 'section_id',
                        operator: 'in',
                        value: activeSubjects.map(Number) ?? 0,
                    },
                },
            ],
        },
        sort: [['id']],
    };
};

export const AddVideoModal = ({
                                  isOpen,
                                  onClose,
                                  onSelect,
                                  articles,
                                  handleLabelSelect,
                                  setImportQuestion,
                                  selectedLabel,
                              }: Props) => {
    const location = useLocation().pathname;
    const editPlaylistSearchData = JSON.parse(window.sessionStorage.getItem('editPlaylistSearchData') as string);

    const ref = useRef<HTMLDivElement>(null);
    const [activePage, setActivePage] = useState<number>(editPlaylistSearchData?.activePage ?? 1);
    const [publicationDateStart, setPublicationDateStart] = useState<Date | undefined>();
    const [publicationDateEnd, setPublicationDateEnd] = useState<Date | undefined>();
    const [, setActiveSubjects] = useState<string[]>([]);
    const [checkAllArr1,] = useState<string[]>([]);
    const [checkAllArr2,] = useState<string[]>([]);
    const [openId, setOpenId] = useState<number>(-1);
    const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
    const [value, setValue] = useState(editPlaylistSearchData?.searchValue ?? '');
    const [pageCount, setPageCount] = useState<number>(0);
    const [from, setFrom] = useState<string | null>(null);
    const [to, setTo] = useState<string | null>(null);
    const [contentType, setContentType] = useState(editPlaylistSearchData?.contentType ?? '');
    const [totalPages, setTotalPages] = useState<number>();
    const [totalPagesJournalContent, setTotalPagesJournalContent] = useState<number>();
    const [totalPagesSciedContent, setTotalPagesSciedContent] = useState<number>();
    const [searchData, setSearchData] = useState({ searchValue: '', contentType: '', activePage: 1 });
    const [isError, setIsError] = useState(false);

    const journalContent = 'journal_content';
    const sciedContent = 'scied_content';
    const isAddVideo = location == '/quiz/create' || location.includes('/quiz/edit/');

    const dispatch = useAppDispatch();

    const {
        register,
        getValues,
        setValue: setKeyword,
        reset,
        formState: { isSubmitting },
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
            labels: '',
        },
    });

    useEffect(() => {
        publicationDateStart && setFrom(publicationDateStart.toISOString().slice(0, 10));
        publicationDateEnd && setTo(publicationDateEnd.toISOString().slice(0, 10));
    }, [publicationDateStart, publicationDateEnd]);

    const { isFetching, data: dataSearch } = useQuery(
        [`search`, value, activePage, from, to, contentType, isOpen],
        () => isOpen ? fetchSearch(value, contentType, activePage, from, to) : null,
    );

    const handleReset = useCallback(() => {
        setValue('');
        reset();
    }, []);

    const handleSearch = () => {
        setValue(getValues('keyword'));
        setActivePage(1);
    };

    const handlePagination = (page: number) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView();
        }
        setActivePage(page);
    };

    const handleResearch = () => {
        setContentType(journalContent);
        setActivePage(1);
    };

    const handleEducation = () => {
        setContentType(sciedContent);
        setActivePage(1);
    };

    const itemsLength = dataSearch?.navigation?.total_items;
    const start = 12 * (activePage - 1) + 1;
    const end = itemsLength && (12 * (activePage - 1) + 12 <= itemsLength ? 12 * (activePage - 1) + 12 : itemsLength);

    useEffect(() => {
        dataSearch && itemsLength ? setPageCount(Math.ceil(itemsLength / dataSearch?.navigation.items_on_page)) : setPageCount(0);
    }, [dataSearch]);

    useEffect(() => {
        setActivePage(1);
    }, [value, to, from]);

    useEffect(() => {
        setKeyword('keyword', value);
        setActivePage(editPlaylistSearchData?.activePage ?? 1);
    }, []);

    useEffect(() => {
        setSearchData({ searchValue: value, contentType: contentType, activePage: activePage });
    }, [value, contentType, activePage]);

    useEffect(() => {
        dispatch(getSectionSubjectTree());
    }, [dispatch]);

    useEffect(() => {
        setActiveSubjects([...checkAllArr1, ...checkAllArr2]);
    }, [checkAllArr1, checkAllArr2, setActiveSubjects]);

    useEffect(() => {
        return () => {
            dispatch(resetArticlesPages());
        };
    }, [dispatch]);

    useEffect(() => {
        if (totalPages) return;

        dataSearch?.navigation?.total_pages
        && !value
        && !contentType
        && setTotalPages(dataSearch?.navigation?.total_pages);
    }, [dataSearch]);

    useEffect(() => {
        if (totalPagesJournalContent) return;

        dataSearch?.navigation?.total_pages
        && !value
        && contentType === journalContent
        && setTotalPagesJournalContent(dataSearch?.navigation?.total_pages);
    }, [dataSearch]);

    useEffect(() => {
        if (totalPagesSciedContent) return;

        dataSearch?.navigation?.total_pages
        && !value
        && contentType === sciedContent
        && setTotalPagesSciedContent(dataSearch?.navigation?.total_pages);
    }, [dataSearch]);

    const pagination = {
        activePage,
        pageCount: (!value
                ? (contentType
                    ? (contentType === journalContent
                        ? totalPagesJournalContent
                        : totalPagesSciedContent)
                    : totalPages)
                : pageCount
        ),
        itemsOnPage: dataSearch?.navigation?.items_on_page,
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
            <ModalOverlay />
            <ModalContent>
                {isAddVideo ? (
                    <ModalHeader color="#5D697C" fontWeight={'400'} fontSize="17px">
                        Add Video
                    </ModalHeader>
                ) : (
                    <ModalHeader>Add Video</ModalHeader>
                )}
                <ModalCloseButton />
                <ModalBody p={0}>
                    <Box px={6}>
                        <Flex justifyContent={'space-between'} alignItems={'center'} flexDir={'column'} mb={3}>
                            <Box w='full' mb={3}>
                                {isAddVideo ? (
                                    <>
                                        <InputGroup
                                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                            w={'full'}
                                            display={'flex'}
                                            alignItems={'center'}
                                        >
                                            <InputLeftElement onClick={handleSearch}>
                                                <Icon
                                                    icon={isSubmitting || isFetching ? 'loader' : 'search'}
                                                    cursor='pointer'
                                                    color={'primary.blue'}
                                                />
                                            </InputLeftElement>
                                            <Input
                                                placeholder='Search JoVE articles...'
                                                bg={'#ffffff'}
                                                id={'keyword'}
                                                {...register('keyword')}
                                                bgColor={'#F2F2F2'}
                                                margin={'10px 0'}
                                                h={'36px'}
                                                borderRadius={'18px'}
                                                w={'full'}
                                                m={0}
                                            />

                                            {getValues('keyword')?.length > 0 && (
                                                <InputRightElement onClick={handleReset}>
                                                    <Icon
                                                        icon='times'
                                                        cursor='pointer'
                                                        color={'primary.blue'}
                                                    />
                                                </InputRightElement>
                                            )}
                                        </InputGroup>{' '}
                                        <Checkbox pt='1rem' size='sm' defaultChecked
                                                  onChange={() => setImportQuestion && setImportQuestion()}>
                                            Import Questions
                                        </Checkbox>
                                        <Flex background={'#F2E2B4'} color='#816C2F' mt='1rem' p='0.5rem' gap='0.5rem'>
                                            <Box pt='0.2rem'>
                                                <FiInfo fontSize={'18px'} />{' '}
                                            </Box>
                                            <Text fontSize={'15px'}> Only Education videos have questions that can be
                                                imported.</Text>
                                        </Flex>
                                    </>
                                ) : (
                                    <InputGroup
                                        onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                        w={'full'}
                                        display={'flex'}
                                        alignItems={'center'}
                                    >
                                        <InputLeftElement pointerEvents={isSubmitting ? 'none' : 'auto'}
                                                          onClick={handleSearch}>
                                            <Icon
                                                icon={isSubmitting || isFetching ? 'loader' : 'search'}
                                                cursor='pointer'
                                                color={'primary.blue'}
                                            />
                                        </InputLeftElement>
                                        <Input
                                            placeholder='Search JoVE articles...'
                                            bg={'#ffffff'}
                                            id={'keyword'}
                                            {...register('keyword')}
                                            bgColor={'#F2F2F2'}
                                            margin={'10px 0'}
                                            h={'36px'}
                                            borderRadius={'18px'}
                                            w={'full'}
                                            m={0}
                                        />
                                        {getValues('keyword')?.length > 0 && (
                                            <InputRightElement onClick={handleReset}>
                                                <Icon
                                                    icon='times'
                                                    cursor='pointer'
                                                    color={'primary.blue'}
                                                />
                                            </InputRightElement>
                                        )}
                                    </InputGroup>
                                )}
                            </Box>
                            <Text mb={2} lineHeight={'1.125rem'} fontSize={'0.9375rem'}>
                                To verify which videos you can play in full, please check which content is provided in
                                your institution&apos;s JoVE
                                subscription{' '}
                                <Text as={Link} to={'/access'} color={'primary.blue'}>
                                    here
                                </Text>
                                .
                            </Text>
                            <>
                                <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                                    <Menu>
                                        <MenuButton
                                            onClick={handleResearch}
                                            w="30%"
                                            variant={'addVideoModalFilter'}
                                            as={Button}
                                            backgroundColor="#4193F4"
                                            color={'white'}
                                            border={contentType === journalContent ? '1px solid black' : 'none'}
                                            boxShadow={contentType === journalContent ? '1px 5px 8px 1px rgba(34, 60, 80, 0.4)' : 'none'}
                                        >
                                            Research
                                        </MenuButton>
                                    </Menu>
                                    <Menu>
                                        <MenuButton
                                            onClick={handleEducation}
                                            w="30%"
                                            variant={'addVideoModalFilter'}
                                            as={Button}
                                            background={'#44D7B6'}
                                            color={'white'}
                                            border={contentType === sciedContent ? '1px solid black' : 'none'}
                                            boxShadow={contentType === sciedContent ? '1px 5px 8px 1px rgba(34, 60, 80, 0.4)' : 'none'}
                                        >
                                            Education
                                        </MenuButton>
                                    </Menu>
                                    <Menu>
                                        <MenuButton
                                            w="30%"
                                            as={Button}
                                            onClick={() => setOpenAdvancedFilter(!openAdvancedFilter)}
                                            fontWeight="400"
                                            background={'white'}
                                            borderRadius="20px"
                                            height="30px"
                                            border="1px solid #DCDCDC"
                                        >
                                            Advanced
                                        </MenuButton>
                                    </Menu>
                                </Flex>
                                {openAdvancedFilter ? (
                                    <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'}
                                          m={'1rem 0'}>
                                        <Menu>
                                            <MenuButton variant={'addVideoModalFilter'} as={Button}>
                                                Publication Date
                                            </MenuButton>
                                            <MenuList>
                                                <ModalFilter
                                                    title={'Publication Date'}
                                                    dateFrom={publicationDateStart}
                                                    dateTo={publicationDateEnd}
                                                    variant={'datePicker'}
                                                    setDateFrom={setPublicationDateStart}
                                                    setDateTo={setPublicationDateEnd}
                                                />
                                            </MenuList>
                                        </Menu>
                                    </Flex>
                                ) : (
                                    <></>
                                )}
                            </>
                            {isAddVideo && <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                                <Menu>
                                    <MenuButton variant={'addVideoModalFilter'} as={Button}>
                                        Publication Date
                                    </MenuButton>
                                    <MenuList>
                                        <ModalFilter
                                            title={'Publication Date'}
                                            dateFrom={publicationDateStart}
                                            dateTo={publicationDateEnd}
                                            variant={'datePicker'}
                                            setDateFrom={setPublicationDateStart}
                                            setDateTo={setPublicationDateEnd}
                                        />
                                    </MenuList>
                                </Menu>
                            </Flex>}
                        </Flex>

                        {!isAddVideo &&
                            <Select
                                placeholder={'Choose label'}
                                onChange={(e) => {
                                    handleLabelSelect && handleLabelSelect(e.target.value);
                                }}
                                defaultValue={selectedLabel}
                                variant="outline"
                                outline={isError && !selectedLabel ? '2px solid red' : '2px solid transparent'}
                                w="50%"
                                mb="20px"
                            >
                                {articles
                                    ?.filter((item) => item.type === 'topic')
                                    .map((label, index) => (
                                        <option key={index} value={label?.sortIndex}>
                                            {label?.title ?? ''}
                                        </option>
                                    ))}
                            </Select>
                        }

                    </Box>
                    <Divider />
                    {isFetching ? (
                        <Flex justifyContent={'center'} alignItems={'center'} my={'20px'}>
                            <Spinner />
                        </Flex>
                    ) : dataSearch?.result?.length ? (
                        <Flex flexDir={'column'} px={6} mb={3} mt={3}>
                            {value && (
                                <Text color={'#9c9c9c'} lineHeight={'1.3125rem'}>
                                    {dataSearch.result.length > 0
                                        ? `Showing ${start} - ${end} of ${itemsLength} results ${value ? `for "${value}"` : ''}`
                                        : `Showing 0 results ${value ? `for "${value}"` : ''}`}
                                </Text>
                            )}

                            {dataSearch &&
                                dataSearch.result?.map((video: SearchArticles) => (
                                    <VideoListItem
                                        key={video.id}
                                        video={video}
                                        onSelect={onSelect}
                                        selectedLabel={selectedLabel}
                                        openId={openId}
                                        setOpenId={setOpenId}
                                        closeModal={onClose}
                                        searchData={searchData}
                                        setIsError={setIsError}
                                    />
                                ))}

                            {pagination && pagination?.itemsOnPage && pagination.pageCount && pagination.itemsOnPage && (
                                <Flex w={'full'} justifyContent={'flex-end'}>
                                    <Pagination
                                        currentPage={pagination.activePage}
                                        totalPages={pagination.pageCount}
                                        handlePagination={handlePagination}
                                    />
                                </Flex>
                            )}
                        </Flex>
                    ) : (
                        <Text color={'#9c9c9c'} fontSize={30} m={30} fontWeight={'bold'} lineHeight={'1.3125rem'}>
                            No match found
                        </Text>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
