import { useNotificationStore } from '@/stores/notifications';
import parse from 'html-react-parser';
import React, { Dispatch, SetStateAction, useCallback, MouseEvent, useState, useEffect } from 'react';
import {
    Flex,
    Image,
    Heading,
    HStack,
    Text,
    Box,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { Path, SearchArticles } from "@/types";
import { AuthorsInfo } from "@/features/Playlists/components/PlaylistForm/AuthorsInfo";

type Props = {
    openId: number;
    setOpenId: Dispatch<SetStateAction<number>>;
    video: SearchArticles;
    onSelect: (videoId: number, title: string, imageArr: string[], type: string) => void
    closeModal: () => void;
    searchData?: {
        searchValue: string;
        contentType: string;
        activePage: number;
    };
    selectedLabel?: any;
    setIsError?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VideoListItem = ({
                                  video,
                                  onSelect,
                                  openId,
                                  setOpenId,
                                  closeModal,
                                  searchData,
                                  selectedLabel,
                                  setIsError
                              }: Props) => {
    const { id, title, header_image, authors, images, path, inCollection, article_type } = video;
    const { pathname } = useLocation();
    const isClientHub = pathname.includes('client-hub-playlist');
    const { addNotification } = useNotificationStore();

    const [image, setImage] = useState<string | null>();

    useEffect(() => {
        setImage(images?.short || images?.large || header_image || '/assets/VideoComingSoonTeaser.webp');
    }, [header_image, images.large, images.short]);

    const handleError = () => setImage('/assets/VideoComingSoonTeaser.webp');

    const education = ['jove_core', 'lab_manual_prep', 'lab_manual', 'lab_manual_procedure', 'science_education'];
    const labManual = ['lab_manual_prep', 'lab_manual', 'lab_manual_procedure'];

    const checkSection = () => {
        switch (article_type) {
            case 'journal':
                return 'Journal';
            case 'encyclopedia_of_experiments':
                return 'Encyclopedia of Experiments';
            case 'jove_core':
                return 'Core';
            case 'science_education':
                return 'Science education library';
            case 'lab_manual':
                return 'Lab manual';
            case 'lab_manual_prep':
                return 'Lab manual';
            case 'lab_manual_procedure':
                return 'Lab manual';
            default:
                return '';
        }
    };

    const section = checkSection();

    const labManualTitle = () => {
        switch (article_type) {
            case 'lab_manual':
                return 'Concept';
            case 'lab_manual_prep':
                return 'Prep Student';
            case 'lab_manual_procedure':
                return 'Student Protocol';
            default:
                return;
        }
    };

    const subject = (arr: Path[]) => {
        let subjectTitle;
        arr.forEach((item) => {
            if (item.type === 'subject') {
                subjectTitle = item.title;
            }
        });
        return subjectTitle;
    };

    const topic = (arr: Path[]) => {
        let topicTitle: string = '';
        arr.forEach((item) => {
            if (item.type === 'topic') {
                topicTitle = item.title;
            }
        });
        return topicTitle;
    };

    const handlerClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (isClientHub && !selectedLabel) {
            addNotification({
                title: 'Please choose a label',
                type: 'error'
            })
            setIsError && setIsError(true);
        } else {
            setIsError && setIsError(false);
            onSelect(video.id, title, [header_image ?? '', images?.short ?? '', images?.large ?? '', '/assets/VideoComingSoonTeaser.webp'], video.article_type);
            window.sessionStorage.setItem('editPlaylistSearchData', JSON.stringify(searchData));
        }
    }, [video.id, onSelect, closeModal, selectedLabel])

    return (
        <>
            <Flex
                to={''}
                margin={'0.8125rem 0'}
                p={'0.625rem 0.625rem 0.375rem 0.625rem'}
                onClick={handlerClick}
                cursor={'pointer'}
            >
                <Box
                    h={'80px'}
                    minW={'130px'}
                    mr={'0.625rem'}
                >
                    <Image
                        objectFit='cover'
                        src={image || '/assets/VideoComingSoonTeaser.webp'}
                        onError={handleError}
                        alt={title}
                        w={'full'}
                        h={'full'}
                        borderRadius={'3px'}
                    />
                </Box>
                <Flex
                    mx={'1.5625rem'}
                    flexDir={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                >
                    <Heading
                        fontWeight={500}
                        fontSize={'1.125rem'}
                        mb={'0.375rem'}
                    >
                        {labManual.includes(article_type) ? `${parse(title)} - ${labManualTitle()}` : parse(title)}
                    </Heading>

                    <HStack
                        spacing={6}
                        divider={<Text
                            margin={'0 0.5rem'}
                            fontSize={'0.75rem'}
                            opacity={'.5'}
                            color={'#333333'} mx={3}>|</Text>}
                        color={'#2E73C5'}
                        fontSize={'12px'}
                        letterSpacing={'.7px'}
                        pos={'relative'}
                    >

                        {openId === id && (
                            <Box pos='absolute' bottom='-150px' w='500px' zIndex={2} onClick={() => setOpenId(-1)}>
                                <AuthorsInfo authorsObject={authors} />
                            </Box>
                        )}

                        {title && (
                            <Text>
                                {education.includes(article_type)
                                    ? section.match(/Science education/gi)
                                        ? `Science Education (${subject(path)})`
                                        : `Education (${section}: ${subject(path)})`
                                    : `Journal  ${topic(path) ? `(${topic(path)})` : ''}`}
                            </Text>
                        )}

                        {inCollection &&
                            <Text as={Link} to={'/methods-collections'} onClick={(e) => e.stopPropagation()}>
                                Methods Collections
                            </Text>
                        }
                    </HStack>
                </Flex>
            </Flex>
        </>
    );
};
