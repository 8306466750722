import { axios } from "@/lib/axios";
import { useQuery } from "react-query";
import { ClassPlaylistsResponse, PlaylistListItemData } from '../../types';
import { GetListBodyStructure, GetListParams } from "@/types";
import { PlaylistsResponse } from "@/features/Playlists";

type GetPlaylistsData = {
    navigation: {
        items_per_page: number,
        page: number,
        more: boolean,
        items_on_page: number
    },
    result: PlaylistListItemData[]
}

export const getClassPlaylists = (classId: number): Promise<ClassPlaylistsResponse> => {
    return axios.get(`/api/admin/class/${classId}/playlist`);
};

const getPlaylistsList = (): Promise<GetPlaylistsData> => {
    return axios.post('/api/admin/playlist/list');
}

export const getPlaylists = (data: GetListBodyStructure): Promise<PlaylistsResponse> => {
    return axios.post('/api/admin/class/playlist_list', data);
};

export const getMyPlaylists = (data: GetListBodyStructure): Promise<PlaylistsResponse> => {
    return axios.post('/api/admin/class/my_playlist_list', data);
};

export const useGetPlaylistsList = () => {
    return useQuery({
        queryKey: ['playlistsList'],
        queryFn: () => getPlaylistsList(),
    })
}

const buildPlaylistParams = (totalItems: number, activeSearchKeyword: string | number) => {
    return {
        navigation: {
            page: 1,
            itemsOnPage: totalItems,
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword
                    ? [
                        {
                            bool_operator: 'or',
                            condition: {
                                property: 'title',
                                operator: 'contains',
                                value: activeSearchKeyword ?? 0,
                            },
                        },
                    ]
                    : []),
            ],
        },
        sort: [['created_at', 'desc']],
    };
};

export const usePlaylists = ({activePage, activeSearchKeyword, activeSortDirection, activeSortProperty} : GetListParams) => {
    return useQuery<PlaylistsResponse, Error>({
        queryKey: ['playlists', activePage, activeSearchKeyword, activeSortDirection, activeSortProperty],
        queryFn: () => {
            let params = buildPlaylistParams(activePage, activeSearchKeyword);
            return getPlaylists(params)
        },
        keepPreviousData: true
    });
};
