import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
    buildQuizzesParams,
    createQuiz,
    getQuiz,
    postQuizzes,
    removeTemplateQuiz,
    updateQuiz,
} from '@/features/Quiz/api/getQuizzes';
import {CreateQuizData, Quiz, QuizzesResponse, SaveQuizError, UpdateQuizData} from '@/features/Quiz/types';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {isNotNull} from "@/utils/helpers";

export type QuizType = Quiz;

export const getAllQuizzes = createAsyncThunk<
    QuizzesResponse,
    {
        activePage: number;
        activeSearchKeyword: string | number;
        withAssign?: boolean;
        itemsOnPage?: number;
    },
    {
        rejectValue: string;
    }
>('quizzes/quiz/getAllQuizzes', async ({ activePage, activeSearchKeyword, withAssign, itemsOnPage = 20 }) => {
    const data = buildQuizzesParams(activePage, activeSearchKeyword, withAssign, itemsOnPage);
    return await postQuizzes(data);
});

export const deleteTemplateQuiz = createAsyncThunk('quizzes/quiz/deleteTemplateQuiz', (id: number) => removeTemplateQuiz(id));

export const getQuizById = createAsyncThunk('quizzes/quiz/getQuizById', (id: number) => getQuiz(id));

export const createNewQuiz = createAsyncThunk<
    Quiz,
    CreateQuizData,
    {
        rejectValue: SaveQuizError;
    }
>('quizzes/quiz/createNewQuiz', async (data, { rejectWithValue }): Promise<any> => {
    return await createQuiz(data).catch((res) => rejectWithValue(res));
});

export const editQuiz = createAsyncThunk<
    Quiz,
    {
        id: number;
        data: UpdateQuizData;
    },
    {
        rejectValue: SaveQuizError;
    }
>('quizzes/quiz/editQuiz', async ({ id, data }, { rejectWithValue }): Promise<any> => {
    return await updateQuiz(id, data).catch((res) => rejectWithValue(res));
});

const quizAdapter = createEntityAdapter<QuizType>();

type State = {
    pages: Record<number, QuizType['id'][]>;
    activePage: number;
    total: number;
    totalPages: number;
    isLoading: boolean;
};

const quizSlice = createSlice({
    name: 'quiz',
    initialState: quizAdapter.getInitialState<State>({
        pages: {},
        activePage: 1,
        totalPages: 1,
        total: 0,
        isLoading: false,
    }),
    reducers: {
        setQuizActivePage: (state, action: PayloadAction<number>) => {
            state.activePage = action.payload;
        },
        resetQuizPages: (state) => {
            state.pages = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllQuizzes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllQuizzes.fulfilled, (state, action) => {
            quizAdapter.upsertMany(state, action.payload.result);
            state.pages[action.meta.arg.activePage] = action.payload.result.map((item) => item.id);
            state.total = action.payload.navigation.total_items;
            state.activePage = action.payload.navigation.page;
            state.totalPages = action.payload.navigation.total_pages;
            state.isLoading = false;
        });
        builder.addCase(getAllQuizzes.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(deleteTemplateQuiz.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteTemplateQuiz.fulfilled, (state, action) => {
            quizAdapter.removeOne(state, action.meta.arg);
            state.isLoading = false;
        });
        builder.addCase(deleteTemplateQuiz.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(getQuizById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQuizById.fulfilled, (state, action) => {
            quizAdapter.upsertOne(state, action.payload as Quiz);
            state.isLoading = false;
        });
        builder.addCase(getQuizById.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(createNewQuiz.fulfilled, (state, action) => {
            quizAdapter.upsertOne(state, action.payload);
        });

        builder.addCase(editQuiz.fulfilled, (state, action) => {
            quizAdapter.upsertOne(state, action.payload);
        });
    },
});

export const quiz = quizSlice.reducer;
export const { resetQuizPages, setQuizActivePage } = quizSlice.actions;

const selectState = (state: RootState) => state.quizzes.quiz;

export const { selectById, selectIds } = quizAdapter.getSelectors(selectState);

const selectRootState = (state: RootState) => state;

export const selectQuizById = createSelector(
    [(state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
    (entity) => entity
);

const selectIdsByPage = createSelector([selectState, (_: RootState, page: number) => page], (state, page) => state.pages[page] ?? []);

export const selectQuizByPage = createSelector([selectRootState, (state: RootState, page: number) => selectIdsByPage(state, page)], (state, ids) =>
    ids.map((id) => selectQuizById(state, id)).filter(isNotNull)
);

export const selectAllQuizzes = createSelector([selectIds, selectRootState], (ids, state) =>
    ids.map((id) => selectQuizById(state, Number(id))).filter(isNotNull)
);

export const selectQuizActivePage = createSelector([selectState], (state) => state.activePage);
export const selectQuizTotalPages = createSelector([selectState], (state) => state.totalPages);
export const selectQuizTotal = createSelector([selectState], (state) => state.total);
export const selectQuizLoading = createSelector([selectState], (state) => state.isLoading);
