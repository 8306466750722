import React, { FC } from 'react';
import { Translation } from '@/features/Translations/types';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Stack,
    Select,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import 'react-image-crop/dist/ReactCrop.css';
import { useAddTranslation } from '@/features/Translations/api/addTranslation';
import { useGroups } from '@/features/Translations/api/getGroupsList';
import { languages } from '../../../../Utils/languages';

export type TranslationDetailFormData = {
    key: string;
    groupId: string;
    en: string;
    cn: string;
    de: string;
    es: string;
    kr: string;
    it: string;
    fr: string;
    pt: string;
};

const translationDetailsSchema = yup.object().shape({
    key: yup.string().required('This field is required'),
    groupId: yup.string().required('This field is required'),
    en: yup.string().required('This field is required'),
});

type Props = {
    translationData?: Translation;
    onSubmit: (values: TranslationDetailFormData) => Promise<void>;
};

export const TranslationForm: FC<Props> = (props) => {
    const { onSubmit } = props;
    const addTranslationMutation = useAddTranslation();

    const { data: groups, isLoading: groupsAreLoading } = useGroups({
        activePage: 1,
        activeSearchKeyword: '',
        activeSortDirection: '',
        activeSortProperty: 'key',
    });

    const thereAreGroups = groups && groups.result?.length > 0;
    const emptyGroupsTooltipMessage = 'Groups must be created before adding translations';

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TranslationDetailFormData>({
        resolver: yupResolver(translationDetailsSchema),
    });

    const formIsLoading = addTranslationMutation.isLoading;
    const fieldsAreDisabled = groupsAreLoading || formIsLoading;

    return (
        <>
            <Heading size="md" mb={3}>
                Add translation
            </Heading>

            {thereAreGroups ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack mb={2} spacing={4}>
                        <FormControl isInvalid={errors.hasOwnProperty('group')} isDisabled={fieldsAreDisabled}>
                            <FormLabel htmlFor="group">Add group fot this translation</FormLabel>

                            {groups && (
                                <FormControl isInvalid={errors.hasOwnProperty('group')}>
                                    <Select {...register('groupId')} placeholder="All" bg="white">
                                        {groups.result
                                            .filter((group) => group.isActive)
                                            .map((group) => (
                                                <option key={group.key} value={group.id}>
                                                    {group.title}
                                                </option>
                                            ))}
                                    </Select>

                                    <FormErrorMessage>{errors.groupId && errors.groupId.message}</FormErrorMessage>
                                </FormControl>
                            )}
                        </FormControl>

                        <FormControl isInvalid={errors.hasOwnProperty('key')} isDisabled={fieldsAreDisabled}>
                            <FormLabel htmlFor="key">Write Key</FormLabel>

                            <Input id="key" placeholder="Enter translation key" {...register('key')} />

                            <FormErrorMessage>{errors.key?.message}</FormErrorMessage>
                        </FormControl>

                        {languages.map((language) => {
                            return (
                                <FormControl
                                    isDisabled={fieldsAreDisabled}
                                    key={`${language.key}-language-field`}
                                    isInvalid={errors.hasOwnProperty(language.key)}>
                                    <FormLabel htmlFor={language.key}>Add Default Value {language.name}</FormLabel>

                                    <Input
                                        maxLength={256}
                                        id={language.key}
                                        placeholder={`Write the new translation here`}
                                        {...register(language.key)}
                                    />

                                    <FormErrorMessage>{errors[language.key]?.message}</FormErrorMessage>
                                </FormControl>
                            );
                        })}
                    </Stack>

                    <Button mt={4} variant={'brand'} isLoading={addTranslationMutation.isLoading} type={'submit'} disabled={!thereAreGroups}>
                        Save Changes
                    </Button>
                </form>
            ) : (
                <Alert status="warning">
                    <AlertIcon />
                    <Box>
                        <AlertTitle>There are no groups</AlertTitle>

                        <AlertDescription>{emptyGroupsTooltipMessage} </AlertDescription>
                    </Box>
                </Alert>
            )}
        </>
    );
};
