import {
    FormControl,
    FormErrorMessage,
    Image, Box, Button, Flex,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useFileUpload } from 'use-file-upload';
import { saveQuizImage } from '@/features/Quiz/api/getQuizzes';
import Icon from '@/components/Icon';

type Props = {
    invalid: boolean;
    value?: string | null;
    isRequired?: boolean;
    onChangeImage: (url: string) => void;
    errImageFn: (err: string) => void;
}

const FileUpload: FC<Props> = (
    {
        invalid = false,
        value,
        isRequired= false,
        onChangeImage,
        errImageFn,
    }
) => {
    const [, selectFile] = useFileUpload();

    return (
        <FormControl isInvalid={invalid} isRequired={isRequired}>
            <Flex>
                {value ?
                    <Image
                        borderRadius="6px"
                        boxSize='38px'
                        border={'2px solid #e5ebf3'}
                        bg={'#f3f3f3'}
                        mr={'5px'}
                        src={value}
                    /> :
                    <Box
                        borderRadius="6px"
                        boxSize='38px'
                        border={'2px solid #e5ebf3'}
                        bg={'#f3f3f3'}
                        mr={'5px'}
                    />
                }

                <Button
                    color="black"
                    bg="white"
                    p={'10px 20px'}
                    fontSize="15px"
                    fontWeight={400}
                    border={'1px solid #e5ebf3'}
                    onClick={() => {
                        selectFile(
                            { accept: 'image/*', multiple: false },
                            async (props) => {
                                if (props && !Array.isArray(props)) {
                                    const formData = new FormData();

                                    formData.append("file", props.file);

                                    try {
                                        const action = await saveQuizImage(formData);
                                        onChangeImage(action.url);
                                    } catch (e) {
                                        errImageFn('Error add image');
                                    }
                                }
                            }
                        )
                    }}
                >
                    <Icon
                        icon='cloud-upload'
                        fontSize="20px"
                    />
                    Upload Image
                </Button>
            </Flex>
            <FormErrorMessage>
                Error add image
            </FormErrorMessage>
        </FormControl>
    );
}

export default FileUpload;