import { modelShaper } from '@/utils/helpers';
import React from 'react';
import { RightQuizAddButton } from '../RightQuizAddButton';
import { useFormContext } from 'react-hook-form';
import { QuizFormData } from '../../types';
import { RightQuizItem } from '../RightQuizItem';
import { Box } from '@chakra-ui/react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Icon from '@/components/Icon';

type Props = {
    indexActivity: number;
    isLibrary?: boolean;
};

export const RightQuizItems = ({ indexActivity, isLibrary }: Props) => {
    const { getValues } = useFormContext<QuizFormData>();

    const items = getValues(`activities.${indexActivity}.items`);

    const updatedItems = [...items];
    modelShaper(updatedItems);

    return (
        <>
            <Droppable droppableId={`activities.${indexActivity}.items`} type={`${indexActivity}`}>
                {(provided) => (
                    <div ref={provided.innerRef}>
                        {updatedItems?.map((_, index) => (
                            <Draggable
                                key={`activities_${indexActivity}_items_${index}`}
                                draggableId={`activities_${indexActivity}_items_${index}`}
                                index={index}>
                                {(provided) => (
                                    <div key={index} ref={provided.innerRef} {...provided.draggableProps}>
                                        <RightQuizItem indexActivity={indexActivity} indexItem={index}>
                                            <Box pr={'10px'} color={'primary.blue'} cursor="grab" {...provided.dragHandleProps}>
                                                <Icon icon="grip-lines" fontSize="21px" />
                                            </Box>
                                        </RightQuizItem>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

            <RightQuizAddButton indexActivity={indexActivity} isLibrary={isLibrary} />
        </>
    );
};
