import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Td, Text, Tr } from '@chakra-ui/react';

import { Institution } from "@/features/Institutions";
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { InstitutionModalTypes } from "@/features/Playlists";

interface Props {
    institution: Institution;
    handleScormDownload?: (id: number) => void;
    handleCCDownload?: (id: number) => void;
    handleQtiDownload?: (id: number) => void;
    handleProviderInstitutionId?: (id: number, title?: string) => void;
    handleAssignInstitutionId?: (id: number, title: string) => void;
    type: InstitutionModalTypes;
    isLoading?: boolean;
}

export const InstitutionsListItem = ({
    institution,
    handleScormDownload,
    handleCCDownload,
    handleQtiDownload,
    handleProviderInstitutionId,
    handleAssignInstitutionId,
    isLoading,
    type
}: Props) => {
    const { id, title } = institution;
    const [institutionId, setInstitutionId] = useState(0);
    const handleDownload = async () => {
        setInstitutionId(id);
        if (type === 'cc' && handleCCDownload) {
            await handleCCDownload(id);
            setInstitutionId(0);
        } else if (type === 'scorm' && handleScormDownload) {
            await handleScormDownload(id);
            setInstitutionId(0);
        } else if (type === 'qti' && handleQtiDownload) {
            await handleQtiDownload(id);
            setInstitutionId(0);
        } else if (type === 'provider' && handleProviderInstitutionId) {
            handleProviderInstitutionId(id, title);
            setInstitutionId(0);
        } else if (type === 'users-management' && handleAssignInstitutionId) {
            handleAssignInstitutionId(id, title);
            setInstitutionId(0);
        }
    };

    const typeLabel = () => {
        switch (type) {
            case 'cc':
                return 'Assign CC';
            case 'scorm':
                return 'Get SCORM';
            case 'qti':
                return 'Get QTI';
            case 'provider':
                return 'Get Id';
            case 'users-management':
                return 'Assign ID';
            default:
                return '';
        }
    };

    return (
        <>
            <Tr>
                <Td>{id}</Td>
                <Td>
                    <Text w={'550px'} noOfLines={2}>
                        {title}
                    </Text>
                </Td>
                <Td>
                    <Button
                        colorScheme='brand'
                        color='currentColor'
                        border='1px solid lightgrey'
                        w='107px'
                        size='sm'
                        cursor={'pointer'}
                        onClick={handleDownload}
                    >
                        {isLoading && (institutionId === id)
                            ? <LoadingSpinner />
                            : typeLabel()
                        }
                    </Button>
                </Td>
            </Tr>
        </>
    );
};
