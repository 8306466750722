import React from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '@/components/Icon';
import { getSponsor } from '@/features/Sponsors/api/getSponsor';
import { SponsorForm } from '@/features/Sponsors/Components/SponsorForm';

export const EditSponsor = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const queryClient = useQueryClient();
    const entities = queryClient.getQueryData(['sponsor', id]);

    const { data } = useQuery(['sponsor', id], () => (id ? getSponsor(id) : null), {
        enabled: !entities,
        useErrorBoundary: (error: any) => error.response?.status >= 400,
    });

    return (
        <Flex flexDir="column" margin="0 auto" maxW="800px">
            <Flex mb={3} alignItems="center" justifyContent="space-between">
                <Heading size="lg">Edit Sponsor</Heading>
                <Button variant="back" onClick={() => navigate(`/sponsors`)} leftIcon={<Icon icon="chevron-left" />}>
                    Back
                </Button>
            </Flex>
            {data && (
                <Box bgColor="#ffffff" p={4}>
                    <SponsorForm sponsorData={data} />
                </Box>
            )}
        </Flex>
    );
};
