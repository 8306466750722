import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    getCustomerResults,
    getQuizResultCSV, getUserQuizById,
    updateEndDate
} from "@/features/Quiz/api/getQuizzes";
import { Box, Button, Flex, Select, Spinner, Table, Tbody, Td, Text, Thead, Tr } from "@chakra-ui/react";
import { ThEl } from "@/features/Quiz/components/ArticlesQuiz";
import { format } from "date-fns";
import Icon from "@/components/Icon";
import { useGetQuizzesList } from "@/features/Classes/api";
import { CustomerQuizHeaderCard } from "@/features/Quiz/components/CustomerQuizHeaderCard";
import { useNotificationStore } from "@/stores/notifications";
import {isNotNull} from "@/utils/helpers";

export const CustomerResults = () => {
    const { userQuizId } = useParams();
    const navigate = useNavigate();
    const { addNotification } = useNotificationStore();

    const [downloading, setDownloading] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data: resultsData, isLoading: resultsIsLoading } = useQuery(['results', userQuizId],
        () => userQuizId ? getCustomerResults(userQuizId) : null, {
            useErrorBoundary: (error: any) => error.response
        }
    );

    const quizzesListData = useGetQuizzesList().data

    const showDownload = useMemo(() => resultsData?.assignList?.filter((user) => user.submitAt !== null), [resultsData?.assignList]);

    const handleLinkClick = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            e.preventDefault();
            if (e.target.value) {
                navigate(`/quiz/customer-quiz/results/${e.target.value}`)
            }
        },
        [navigate]
    );

    const handleDeadlineChange = useCallback(
        async (date: Date) => {
            try {
                setLoading(true);
                await updateEndDate(Number(userQuizId), {
                    end_date: date ? date.toISOString() : null,
                });
                await getUserQuizById(Number(userQuizId))
                setLoading(false);
                addNotification({
                    type: 'success',
                    title: 'Deadline updated',
                });

            } catch (e: any) {
                setLoading(false);
                addNotification({
                    type: 'error',
                    title: 'Failed to update Deadline',
                });
                throw e;
            }
        },
        [userQuizId, addNotification]
    );

    const handleDownload = useCallback(
        async () => {
            setDownloading(true);
            try {
                if (userQuizId) {
                    await getQuizResultCSV(userQuizId);
                }
            } catch (e: any) {
                setDownloading(false);
                throw e;
            }
            setDownloading(false);
        },
        [userQuizId]
    );

    return (
        <>
            <Box mb={'20px'} backgroundColor="#ffffff" p={'20px'}>
                <Flex alignItems={'center'} fontSize={'18px'}>
                    <Button variant="back" onClick={() => navigate(-1)} leftIcon={<Icon icon='chevron-left' />} />
                    <Text fontSize="20px" fontWeight="500" color="#202020" p="0">
                        Quiz Results
                    </Text>
                </Flex>

                <Select
                    h="50px"
                    w="366px"
                    border="1px solid #CDCDCD"
                    borderRadius="40px"
                    mt="20px"
                    fontSize="16px"
                    fontWeight="500"
                    icon={
                        <Icon style={{ marginLeft: '10px' }} icon='chevron-down' />
                    }
                    iconSize="14px 16px"
                    value={`${userQuizId ?? ''}`}
                    onChange={handleLinkClick}
                    className="quiz_select">
                    {[...(quizzesListData?.result || []), ...[resultsData]].filter(isNotNull).map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.title}
                        </option>
                    ))}
                </Select>
                <Flex py={'20px'} justifyContent="space-between" alignItems="flex-end">
                    <Flex gap="31px">
                        <CustomerQuizHeaderCard icon="question-square" title="Quiz Type"
                            value={resultsData?.isGraded ? 'Graded' : 'Ungraded'} />
                        <Box border="1px solid #EDEDED" />
                        <CustomerQuizHeaderCard
                            icon="clock"
                            title="Deadline"
                            value={resultsData?.endDate  ? format(new Date(resultsData.endDate), 'MM/dd/yyyy hh:mm a') : 'MM/DD/YYYY HH:MM'}
                            onChange={handleDeadlineChange}
                            source="Result"
                            loading={loading}
                            startDate={resultsData?.startDate}
                        />
                        <Box border="1px solid #EDEDED" />
                        <CustomerQuizHeaderCard icon="share-from-square" title="Distributed to"
                                                value={resultsIsLoading ? '-' : resultsData?.assignList?.length ?? '0'} />
                        <Box border="1px solid #EDEDED" />
                        <CustomerQuizHeaderCard
                            icon="check-circle"
                            title="Completed by"
                            value={resultsIsLoading ? '-' : resultsData?.assignList?.filter((user) => user.submitAt !== null).length ?? '0'}
                        />
                    </Flex>

                    <Button
                        variant={'brand'}
                        w="150px"
                        onClick={handleDownload}
                        disabled={showDownload ? showDownload.length < 1 : true}
                    > {downloading ? <Spinner /> : 'Download CSV'}
                    </Button>
                </Flex>
            </Box>
            {
                resultsIsLoading ? <Spinner size={'lg'} /> :
                    resultsData?.assignList && resultsData.assignList.length > 0 ?
                        <Table bgColor={'#ffffff'}>
                            <Thead>
                                <Tr>
                                    <ThEl align="start" w="65%">
                                        Student email
                                    </ThEl>
                                    <ThEl w="10%" align="start">
                                        Score
                                    </ThEl>
                                    <ThEl w="15%">Percentage</ThEl>
                                    <ThEl w="15%">Date Submitted</ThEl>
                                    <ThEl w="15%">Actions</ThEl>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {resultsData?.assignList?.map((item) => {
                                    return <Tr key={item.id}>
                                        <Td>{item.user.email}</Td>
                                        <Td> {item.submitAt && item.progress !== null
                                            ? `${Math.round((Number(resultsData.countOfQuestions) * Number(item.progress)) / 100)}/${resultsData.countOfQuestions}`
                                            : '-'}</Td>
                                        {item.progress ? <Td>{item.progress}%</Td> : <Td>-</Td>}
                                        <Td>{item.submitAt ? format(new Date(item.submitAt), 'MM/dd/yyyy  hh:mm  a') : '-'}</Td>
                                        <Td>{item.submitAt && (
                                            <Button
                                                variant="outline"
                                                color="quiz.primaryBlue"
                                                fontSize="14px"
                                                fontWeight="500"
                                                h="32px"
                                                p="8px 30px"
                                                borderRadius="40px"
                                                borderColor="quiz.primaryBlue"
                                                as={Link}
                                                to={`/quiz/customer-quiz/user-results/${item.id}`}>
                                                View Results in Detail
                                            </Button>
                                        )}</Td>

                                    </Tr>
                                })}
                            </Tbody>
                        </Table>
                        :
                        <Text fontSize="18px" bg="#edf2f7" textAlign="center" py="16px" mt="70px">
                            Distribute Quiz before viewing results
                        </Text>
            }
        </>
    )
}
