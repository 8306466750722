import React, { Fragment, useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Text,
    useMediaQuery,
} from '@chakra-ui/react';
import flowplayer from '@flowplayer/player';
import SubtitlePlugin from '@flowplayer/player/plugins/subtitles';
import HLSPlugin from '@flowplayer/player/plugins/hls';
import OVPPlugin from '@flowplayer/player/plugins/ovp';
import SpeedPlugin from '@flowplayer/player/plugins/speed';
import ThumbnailsPlugin from '@flowplayer/player/plugins/thumbnails';
import DashPlugin from '@flowplayer/player/plugins/dash';
import DRMPlugin from '@flowplayer/player/plugins/drm';
import KeyboardPlugin from '@flowplayer/player/plugins/keyboard';
import CuepointsPlugin from '@flowplayer/player/plugins/cuepoints';
import GoogleAnalyticsPlugin from '@flowplayer/player/plugins/google-analytics';
import { PAUSE, PLAYING } from '@flowplayer/player/core/events';
import './flowplayer.css';
import { useFlowplayer } from '@flowplayer/react-flowplayer';
import { FaPlay } from 'react-icons/fa';
import { allLanguages, flowplayerToken } from '@/utils/consts';
import { ArticleByDomainEoE, ArticleCtx, ArticleByDomain } from "@/types";
import Icon from '../Icon';

type Props = {
    article: ArticleCtx | ArticleByDomain | ArticleByDomainEoE;
    ratio?: number;
    isOpenAccess?: boolean;
    iconSize?: string;
    autoPlay?: boolean;
    videoSrc?: string;
    thumbnail?: string;
    currentTime?: number;
    isQuizzes?: boolean;
    isExperiment?: boolean | null;
    isEoE?: boolean;
    videoSubtitles?: Record<string, string> | null;
};

flowplayer(
    SpeedPlugin,
    SubtitlePlugin,
    ThumbnailsPlugin,
    HLSPlugin,
    OVPPlugin,
    DashPlugin,
    DRMPlugin,
    KeyboardPlugin,
    CuepointsPlugin,
    GoogleAnalyticsPlugin,
);

export const VideoContainer = ({
    article,
    ratio,
    iconSize,
    autoPlay,
    videoSrc,
    thumbnail,
    currentTime,
    isQuizzes,
    isExperiment,
    isEoE,
    videoSubtitles,
}: Props) => {
    if (article.domain.articleType == 'encyclopedia_of_experiments' && article.children[0] && isExperiment) {
        article = article.children[0].children[0];
    }
    const articleType = article?.domain?.articleType;

    const { api, Flowplayer } = useFlowplayer({ token: flowplayerToken });
    const [isSmThan750] = useMediaQuery(['(max-width: 750px)']);
    const [isThumbnailShowing, toggleThumbnail] = useState<boolean>(true);

    const image = videoSrc || thumbnail || article?.domain?.images?.large || article?.domain?.images?.short || article?.domain?.headerImage;

    const [error, setError] = useState<boolean | null>(null);
    const [isEducationPlayerPause, setIsEducationPlayerPause] = useState<boolean>();
    const articleVideos = article.domain.videos['en'];

    const subtitles = videoSubtitles ?
            typeof videoSubtitles === 'string' ? [videoSubtitles] : Object.values(videoSubtitles) : [];

    const loadingSubtitle = (url: string) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.send('');
        xhr.onload = () => setError(xhr.status !== 200);
        xhr.onprogress = (event) => event.lengthComputable && setError(false);
        xhr.onerror = () => setError(true);
    };

    useEffect(() => {
        if (!api || currentTime === undefined) return;
        api.currentTime = currentTime;
    }, [currentTime]);

    useEffect(() => {
        if (api && autoPlay) {
            toggleThumbnail(false);
            api.play();
        }
    }, [api, autoPlay]);

    useEffect(() => {
        if (subtitles.length > 0) {
            loadingSubtitle(subtitles[0]);
        }
        const video = document.querySelector('video');
        if (video) video.setAttribute('crossorigin', 'anonymous');
        if (!api) return;
        if (error !== null) {
            if (error) {
                api.setOpts({
                    subtitles: false,
                    thumbnails: { src: Object.values(article.domain.videos).find((el) => el.thumbnails)?.thumbnails },
                });
            } else if (articleVideos.subtitles) {
                api.setOpts({
                    subtitles: {
                        tracks: typeof articleVideos.subtitles === 'string' ? [
                            {
                                src: articleVideos.subtitles,
                                id: 0,
                                label: allLanguages.find((lang) => lang.key === 'en')?.name || 'en',
                                crossorigin: 'anonymous',
                            },
                        ] : Object.entries(articleVideos.subtitles).map(([key, val], index) => ({
                            src: val,
                            id: index,
                            label: allLanguages.find((lang) => lang.key === key)?.name || key,
                            crossorigin: 'anonymous',
                        })),
                    },
                    thumbnails: { src: Object.values(article.domain.videos).find((el) => el.thumbnails)?.thumbnails },
                });
            } else {
                api.setOpts({
                    subtitles: false,
                    thumbnails: { src: Object.values(article.domain.videos).find((el) => el.thumbnails)?.thumbnails },
                });
            }
        }
    }, [api, article, error]);

    useEffect(() => {
        if (!image && isThumbnailShowing) toggleThumbnail(false);
    }, [image]);

    const iconPlay: HTMLElement | null = document.querySelector('.fp-middle');
    const fpPlay: HTMLElement | null = document.querySelector('.fp-play');

    const thumbnailClicked = async () => {
        toggleThumbnail(false);
        iconPlay?.style.setProperty('opacity', '1');
        fpPlay?.style.setProperty('opacity', '1');

        if (api) {
            api.play();
        }
    };

    const togglePlay = () => api && api.togglePlay();

    useEffect(() => {
        if (!api) return;
        const education = ['jove_core', 'science_education'];

        const stateHandler = (ev: Event) => {
            if (education.includes(articleType) || isQuizzes) {
                if (ev.type === PAUSE) {
                    setIsEducationPlayerPause(true);
                    fpPlay?.style.setProperty('opacity', '0');
                }

                if (ev.type === PLAYING) {
                    setIsEducationPlayerPause(false);
                }
            } else {
                setIsEducationPlayerPause(undefined);
            }
        };

        api.on([PAUSE, PLAYING], stateHandler);

        return () => {
            if (!api) return;
            api.off(PAUSE, stateHandler);
            api.off(PLAYING, stateHandler);
        };
    }, [api]);

    const { colorOfHeaderText } = getHeaderTextAndColor(articleType);
    const color = colorOfHeaderText === 'primary.blue' ? '#4193F4' : colorOfHeaderText || '#fff';

    useEffect(() => {
        if (colorOfHeaderText) {
            document.documentElement.style.setProperty('--fp-brand-color', color);
        }
    }, [colorOfHeaderText]);

    const videoObjVideo = article?.domain?.videos && article.domain.videos['en'];


    const src = videoObjVideo?.cdnFile || videoObjVideo?.fpResourceId;
    const metaWidth = article?.domain?.videos['en']?.metaWidth;
    const metaHeight = article?.domain?.videos['en']?.metaHeight;

    useEffect(() => {
        if (isEoE && api) {
            api.pause();
        }
    }, [isEoE]);

    return (
        <Box w='100%' pos='relative' zIndex='0'>
            <Box pos='relative'>
                {isEducationPlayerPause &&
                    <Box pos='absolute' top='0' left='0' w='100%' bottom='0' bgColor='rgba(0,0,0,.5)' zIndex='1' />}

                {isEducationPlayerPause && (
                    <Flex justify='space-around' align='center' pos='absolute' top='0'
                          bottom={isSmThan750 ? '40%' : '0'} left='0' right='0'>
                        <Text
                            pos='absolute'
                            top={isSmThan750 ? '2%' : '15%'}
                            left='5%'
                            zIndex='1'
                            color='#fff'
                            fontSize='md'
                            dangerouslySetInnerHTML={{ __html: article?.domain?.title }}
                        />
                        <Flex h={iconSize ? iconSize : '150px'} width={iconSize ? iconSize : '150px'} align='center'
                              justify='center' zIndex='1'>
                            <Icon
                                icon='play'
                                color='#fff'
                                cursor='pointer'
                                fontSize={iconSize ? `${Math.round(Number(iconSize.split('px')[0]) / 4)}px` : '80px'}
                                onClick={togglePlay}
                            />
                        </Flex>
                    </Flex>
                )}
                <Box
                    pos='relative'
                    w='100%'
                    display="block"
                >
                    {isThumbnailShowing && (
                        <Fragment>
                            <Box
                                zIndex='100'
                                pos='absolute'
                                top='0'
                                left='0'
                                h='100%'
                                w='100%'
                                transition='all 0.2s'
                                overflow='hidden'
                                onClick={thumbnailClicked}
                                bgImage={image}
                                bgPos='center'
                                bgRepeat='no-repeat'
                                color='white'
                                bgSize='cover'
                                sx={{
                                    '&::after': {
                                        content: '""',
                                        pos: 'absolute',
                                        top: '0',
                                        left: '0',
                                        bg: 'rgba(0,0,0,0.2)',
                                        h: '100%',
                                        w: '100%',
                                        zIndex: '101',
                                    },
                                }}
                                _hover={{
                                    '&::after': {
                                        content: '""',
                                        pos: 'absolute',
                                        top: '0',
                                        left: '0',
                                        bg: 'rgba(0,0,0,0.5)',
                                        h: '100%',
                                        w: '100%',
                                        zIndex: '101',
                                    },
                                }}
                            >
                                <Flex align='center' justify='center' position='absolute' top='0' left='0' h='100%'
                                      w='100%' zIndex='102'>
                                    <Flex
                                        border={iconSize ? `${Math.round(Number(iconSize.split('px')[0]) / 10)}px solid white` : `14px solid white`}
                                        align='center'
                                        justify='center'
                                        borderRadius='50%'
                                        h={iconSize || '150px'}
                                        width={iconSize || '150px'}
                                    >
                                        <FaPlay
                                            size={iconSize ? `${Math.round(Number(iconSize.split('px')[0]) / 4)}px` : '40px'} />
                                    </Flex>
                                </Flex>
                            </Box>
                        </Fragment>
                    )}
                    <Flowplayer
                        muted={false}
                        ratio={ratio ? `${+ratio}:1` : metaWidth && metaHeight ? `${metaWidth / metaHeight}:1` : ''}
                        src={src}
                        plugins={['hls', 'dash', 'drm', 'ovp', 'subtitles', 'speed', 'thumbnails', 'keyboard', 'cuepoints', 'google-analytics']}
                        speed={{
                            options: [0.75, 1, 1.5, 2],
                            labels: ['slow', 'normal', 'fast', 'faster'],
                        }}
                        hls={{ native: true }}
                        style={{ zIndex: '1' }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

function getHeaderTextAndColor(type: string) {
    let headerText = '';
    let colorOfHeaderText = 'primary.blue';
    if (type === 'journal') {
        headerText = 'JoVE Journal';
    }
    if (type === 'jove_core') {
        headerText = 'JoVE Core';
        colorOfHeaderText = '#44D7B6';
    }
    if (type === 'encyclopedia_of_experiments') {
        headerText = 'JoVE Encyclopedia Of Experiments';
    }
    if (type === 'science_education') {
        headerText = 'JoVE Science Education';
        colorOfHeaderText = '#44D7B6';
    }
    if (type === 'lab_manual' || type === 'lab_manual_prep' || type === 'lab_manual_procedure') {
        headerText = 'JoVE Lab Manual';
        colorOfHeaderText = '#44D7B6';
    }
    return { headerText, colorOfHeaderText };
}
