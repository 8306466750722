import React from 'react';
import Icon from '@/components/Icon';

type Props = {
    isIndeterminate?: boolean;
    isChecked?: boolean;
};

export const QuizCheckboxIcon = ({ isIndeterminate, isChecked, ...rest }: Props) => {
    return (
        <Icon
            icon='circle-check'
            fontSize="21px"
            {...rest}
        />
    );
}
