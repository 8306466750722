/* eslint-disable react/jsx-no-duplicate-props */
import React, { FC } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { useGetLastBanner } from '../api/getLastBanner';
import { BannerForm } from './BannerForm';
import { BannerContainerProps } from '../types';

const BannerContainer: FC<BannerContainerProps> = (props) => {
    const { children } = props;
    return (
        <Box flexDir={'column'} margin={'0 auto'} maxW={'800px'} p={5} w={'100%'} bgColor={'#ffffff'}>
            {children}
        </Box>
    );
};

export const Banner = () => {
    const { data: banner, isLoading, isFetching } = useGetLastBanner();

    const isLoadingOrFetching = isLoading || isFetching;

    if (!banner) return null;

    if (isLoadingOrFetching)
        return (
            <BannerContainer>
                <Spinner size="xl" />
            </BannerContainer>
        );

    return (
        <BannerContainer>
            <BannerForm banner={banner} />
        </BannerContainer>
    );
};
