import React, { ChangeEvent, useEffect } from 'react';
import {
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    InputGroup,
    Input,
    InputRightElement,
    Flex,
    Image,
    Stack,
    Checkbox,
    Box,
    CheckboxGroup,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { getQuizDataByPlaylistId } from './../../api/getQuizzes';
import { getPlaylists } from '@/features/Playlists/api';
import { Playlist } from '@/features/Playlists/types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ImportPlaylistQuizItem, PlayListQuizItem, QuizFormData } from '@/features/Quiz/types';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer';
import Icon from '@/components/Icon';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    indexActivity: number;
};

type ListItemData = {
    id: number;
    title: string;
};

const sortFunction = (a: ListItemData, b: ListItemData) => {
    return b.id - a.id;
};
const ImportPlaylist = ({ isOpen, onClose, indexActivity }: Props) => {
    const [totalPlaylists, setTotalPlaylists] = useState(0);
    const [playlistsList, setPlaylistsList] = useState<Playlist[]>([]);
    const defaultPlaylistsList = playlistsList.map((listItem) => {
        return { id: listItem.id, title: listItem.domain.title };
    });
    const [importVideos, setImportVideos] = useState(true);
    const [importTemplateQuestions, setImportTemplateQuestions] = useState(true);
    const [leftList, setLeftList] = useState<ListItemData[]>([]);
    const [rightList, setRightList] = useState<ListItemData[]>([]);
    const leftCache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
        })
    );
    const rightCache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
        })
    );
    const { watch, control } = useFormContext<QuizFormData>();

    const { append, remove } = useFieldArray<QuizFormData, `activities.${number}.items`>({
        control,
        name: `activities.${indexActivity}.items`,
    });

    const activityItems = watch(`activities.${indexActivity}.items`);

    const buildPlaylistParams = useCallback((totalItems: number, activeSearchKeyword: string | number) => {
        return {
            navigation: {
                page: 1,
                itemsOnPage: totalItems,
            },
            totalCount: true,
            filter: {
                collection: [
                    ...(activeSearchKeyword
                        ? [
                              {
                                  bool_operator: 'or',
                                  condition: {
                                      property: 'title',
                                      operator: 'contains',
                                      value: activeSearchKeyword ?? 0,
                                  },
                              },
                          ]
                        : []),
                ],
            },
            sort: [['id', 'desc']],
        };
    }, []);

    const initFormValues = useCallback(
        (data: ImportPlaylistQuizItem[]) => {
            return data.map((item: ImportPlaylistQuizItem, index: number) => {
                return {
                    originId: null,
                    isSelect: true,
                    sortIndex: activityItems.length + index + 1,
                    type: item.type,
                    title: item.title,
                    image: item.image ?? null,
                    isMultiple: item.isMultiple ?? false,
                    rightAnswer: item.rightAnswer ?? [1],
                    article: item.article ?? null,
                    textOptions: item.textOptions
                        ? item.textOptions.map((textOption) => {
                              const { id: customTextOptionId, ...dataTextOption } = textOption;

                              return {
                                  ...dataTextOption,
                                  originId: customTextOptionId,
                              };
                          })
                        : [
                              {
                                  originId: 1,
                                  text: '',
                              },
                              {
                                  originId: 2,
                                  text: '',
                              },
                              {
                                  originId: 3,
                                  text: '',
                              },
                              {
                                  originId: 4,
                                  text: '',
                              },
                          ],
                };
            });
        },
        [activityItems]
    );
    const appendArrayOfItems = useCallback(
        async (items: PlayListQuizItem[], originId: number) => {
            let quizData: ImportPlaylistQuizItem[] = [];

            items.forEach((item) => {
                const { articleData, ...data } = item;

                if (data.type === 1) {
                    if (articleData) {
                        quizData.push({
                            ...data,
                            id: originId,
                            image: data?.image ?? null,
                            isMultiple: data.isMultiple ?? false,
                            article: {
                                videoData: articleData,
                            },
                        });
                    }
                } else {
                    quizData.push({
                        ...data,
                        id: originId,
                        image: data?.image ?? null,
                        isMultiple: data.isMultiple ?? false,
                        article: null,
                    });
                }
            });

            append(initFormValues(quizData));
            setRightList((prevState) => [...prevState, ...leftList.filter((item) => item.id === originId)].sort(sortFunction));
            setLeftList((prevState) => [...prevState.filter((item) => item.id !== originId)].sort(sortFunction));
        },
        [leftList, rightList]
    );

    const getQuizData = useCallback(
        async (id: number) => {
            const action = await getQuizDataByPlaylistId(id);
            let arr: PlayListQuizItem[] = [];

            action.items.forEach((item: PlayListQuizItem) => {
                if (item.type === 1) {
                    importVideos && arr.push(item);
                } else {
                    importTemplateQuestions && item.textOptions && arr.push(item);
                }
            });
            appendArrayOfItems(arr, id);
        },
        [importVideos, importTemplateQuestions, leftList, rightList]
    );

    const handleAddItemClick = useCallback(
        (id: number) => {
            getQuizData(id);
        },
        [importVideos, importTemplateQuestions, leftList, rightList]
    );

    const handleRemoveItemClick = useCallback(
        (id: number) => {
            const list = activityItems.reduce((result: number[], item, index) => {
                item.originId === id && result.push(index);
                return result;
            }, []);
            remove(list);
            setLeftList((prevState) => [...prevState, ...rightList.filter((item) => item.id === id)].sort(sortFunction));
            setRightList((prevState) => [...prevState.filter((item) => item.id !== id)].sort(sortFunction));
        },
        [leftList, rightList, activityItems]
    );

    const handleSearchKeywordChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const searchValue = e.target.value.trim().toLowerCase();
            searchValue
                ? setLeftList((prevState) => [...prevState.filter((item) => item.title.toLowerCase().includes(searchValue))])
                : setLeftList([...defaultPlaylistsList]);
        },
        [leftList]
    );

    const handleImportVideosCheckboxChange = useCallback(() => {
        setImportVideos((prevState) => {
            if (prevState) {
                return !importTemplateQuestions;
            } else {
                return true;
            }
        });
    }, [importVideos, importTemplateQuestions]);

    const handleImportTemplateQuestionsCheckboxChange = useCallback(() => {
        setImportTemplateQuestions((prevState) => {
            if (prevState) {
                return !importVideos;
            } else {
                return true;
            }
        });
    }, [importVideos, importTemplateQuestions]);

    useEffect(() => {
        isOpen && getPlaylists(buildPlaylistParams(1, '')).then((res) => setTotalPlaylists(res.navigation.total_items));
    }, [isOpen, buildPlaylistParams]);

    useEffect(() => {
        totalPlaylists && getPlaylists(buildPlaylistParams(totalPlaylists, '')).then((res) => setPlaylistsList(res.result));
    }, [totalPlaylists, buildPlaylistParams]);

    useEffect(() => {
        setLeftList([...defaultPlaylistsList]);
    }, [playlistsList]);

    return (
        <Modal onClose={onClose} size="3xl" isOpen={isOpen} isCentered autoFocus={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody p="5%">
                    <Text fontSize="20px" fontWeight="400" lineHeight="1.5" color="#000" mt="20px">
                        Select one or multiple playlists to add content.
                    </Text>
                    <Text fontSize="12px" fontWeight="400" lineHeight="18px" color="#b58020" mb="20px">
                        Please note that importing a playlist will take a few seconds.
                    </Text>
                    <InputGroup minW={'400px'} mb="15px" position="relative">
                        <Input
                            placeholder="Search Playlists..."
                            bg={'#ffffff'}
                            type={'text'}
                            borderRadius="1000px"
                            fontSize="16px"
                            fontWeight="400"
                            color="#666"
                            w="50%"
                            onChange={handleSearchKeywordChange}
                        />
                        <InputRightElement position="absolute" left="43%">
                            <FaSearch size="20px" color="#4193F4" />
                        </InputRightElement>
                    </InputGroup>
                    <Flex justifyContent="space-between" h="200px" alignItems="center">
                        <Box
                            style={{
                                width: '45%',
                                height: '100%',
                                border: '1px solid #ccc',
                            }}
                        >
                            <AutoSizer>
                                {({ width, height }) => (
                                    <List
                                        width={width}
                                        height={height}
                                        rowHeight={leftCache.current.rowHeight}
                                        deferredMeasurementCache={leftCache.current}
                                        rowCount={leftList.length}
                                        rowRenderer={({
                                            key,
                                            index,
                                            style,
                                            parent,
                                        }: {
                                            key: string;
                                            index: number;
                                            style: React.CSSProperties;
                                            parent: MeasuredCellParent;
                                        }) => {
                                            const listItem = leftList[index];
                                            return (
                                                <CellMeasurer key={key} cache={leftCache.current} parent={parent} columnIndex={0} rowIndex={index}>
                                                    <Box style={style}>
                                                        <Text
                                                            p="5px 10px"
                                                            borderBottom="1px solid #ccc"
                                                            _hover={{ backgroundColor: '#08c', cursor: 'pointer', color: '#fff' }}
                                                            onClick={() => handleAddItemClick(listItem.id)}
                                                        >
                                                            {listItem.title}
                                                        </Text>
                                                    </Box>
                                                </CellMeasurer>
                                            );
                                        }}
                                    />
                                )}
                            </AutoSizer>
                        </Box>
                        <Icon icon='repeat' />
                        <Box
                            style={{
                                width: '45%',
                                height: '100%',
                                border: '1px solid #ccc',
                            }}
                        >
                            <AutoSizer>
                                {({ width, height }) => (
                                    <List
                                        width={width}
                                        height={height}
                                        rowHeight={rightCache.current.rowHeight}
                                        deferredMeasurementCache={rightCache.current}
                                        rowCount={rightList.length}
                                        rowRenderer={({
                                            key,
                                            index,
                                            style,
                                            parent,
                                        }: {
                                            key: string;
                                            index: number;
                                            style: React.CSSProperties;
                                            parent: MeasuredCellParent;
                                        }) => {
                                            const listItem = rightList[index];
                                            return (
                                                <CellMeasurer key={key} cache={rightCache.current} parent={parent} columnIndex={0} rowIndex={index}>
                                                    <Box style={style}>
                                                        <Text
                                                            p="5px 10px"
                                                            borderBottom="1px solid #ccc"
                                                            _hover={{ backgroundColor: '#08c', cursor: 'pointer', color: '#fff' }}
                                                            onClick={() => handleRemoveItemClick(listItem.id)}
                                                        >
                                                            {listItem.title}
                                                        </Text>
                                                    </Box>
                                                </CellMeasurer>
                                            );
                                        }}
                                    />
                                )}
                            </AutoSizer>
                        </Box>
                    </Flex>
                    <br />
                    <Stack direction="column" spacing={1} mt="5px">
                        <CheckboxGroup>
                            <Checkbox isChecked={importVideos} onChange={handleImportVideosCheckboxChange}>
                                Import Videos
                            </Checkbox>
                            <Checkbox isChecked={importTemplateQuestions} onChange={handleImportTemplateQuestionsCheckboxChange}>
                                Import Template Questions
                            </Checkbox>
                        </CheckboxGroup>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ImportPlaylist;
