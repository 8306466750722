import { API_URL } from "@/config";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from 'react-router-dom';
import { ClassCreateData, ClassItem } from "../../types";

export const createClass = (data: ClassCreateData): Promise<ClassItem> => {
    return axios.post(`${API_URL}/api/admin/class/`, data);
}

export const useCreateClass = () => {
    const { addNotification } = useNotificationStore();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation({
        onError: () => {
            addNotification({
                type: 'error',
                title: 'Unable to create Class',
            });
        },
        onSuccess: (res) => {
            queryClient.refetchQueries(['classes']);
            addNotification({
                type: 'success',
                title: 'Class successfully created',
            });
            navigate(`/classes/${res.id}`);
        },
        mutationFn: createClass,
    })
}