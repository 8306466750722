import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { getUserAreaDomain } from '@/utils/helpers';
import FileDownload from "js-file-download";
import { useState } from "react";
import { API_URL } from "@/config";
import { PackageDownloadTypes } from "@/types";

const getQuizScorm = (scormType: PackageDownloadTypes, scormId: number, title: string, institutionId: number) => {
    return axios
        .get(`${API_URL}/api/admin/quiz/${scormType}/${scormId}/scorm?videoLink=${getUserAreaDomain(window.location.origin)}/embed/player&institutionId=${institutionId}`,
            { responseType: 'blob' })
        .then((response) => {
            FileDownload(response.data, `${title || scormId}-scorm-export.zip`);
        });
};

export const usePlaylistScorm = ({
                                     scormType,
                                     scormId,
                                     playlistTitle
                                 }: { scormType: PackageDownloadTypes, scormId: number; playlistTitle: string; }) => {
    const { addNotification } = useNotificationStore();
    const [isLoading, setIsLoading] = useState(false);

    const handleScormDownload = async (institutionId: number) => {
        if (scormId && playlistTitle) {
            try {
                setIsLoading(true);
                await getQuizScorm(scormType, scormId, playlistTitle, institutionId);
                setIsLoading(false);
            } catch (error: any) {
                const responseObj = JSON.parse(await error.response.data.text());
                addNotification({
                    type: 'error',
                    title: responseObj.content.message,
                });
                setIsLoading(false);
            }
        }
    };
    return { isLoading, handleScormDownload }
};
