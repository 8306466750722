import { API_URL } from "@/config";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation, useQueryClient } from "react-query";

type DeleteData = {
    classId: number;
    playlistId: number;
}

const deletePlaylistFromClass = ({ classId, playlistId }: DeleteData): Promise<string> => {
    return axios.delete(`${API_URL}/api/admin/class/${classId}/playlist/${playlistId}`);
}

export const useDeletePlaylistFromClass = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation({
        onMutate: async ({ classId }: DeleteData) => {
            await queryClient.cancelQueries(['getClass', classId]);
        },
        onError: () => {
            addNotification({
                type: 'error',
                title: 'Unable to delete Playlist from Class',
            });
        },
        onSuccess: (_,{ classId } ) => {
            queryClient.refetchQueries(['getClass', classId]);
            addNotification({
                type: 'success',
                title: 'Playlist successfully deleted',
            });
        },
        mutationFn: deletePlaylistFromClass,
    })
}