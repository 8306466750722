import React, { useCallback, useEffect, ChangeEvent, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/redux/store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Table,
    Tbody, Td,
    Thead,
    Tr,
    Spinner as SpinnerChakra, Stack, Switch, Text
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { HiX } from 'react-icons/hi';
import * as yup from 'yup';
import { debounce } from '@/utils/helpers';
import { Pagination } from "@/components/Pagination";
import { ThEl } from "@/features/Quiz/components/ArticlesQuiz";
import {
    getAllCustomerQuizzes,
    resetQuizCustomerPages,
    selectQuizCustomerActivePage,
    selectQuizCustomerLoading, selectQuizCustomerPage,
    selectQuizCustomerTotalPages, setQuizCustomerActivePage
} from "@/redux/reducers/quizzes/quizCustomer";
import { CustomerQuizItem } from "@/features/Quiz/components/CustomerQuizItem";

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

type SearchFormData = {
    keyword: string;
};

export const CustomerQuiz = () => {
    const dispatch = useDispatch<AppDispatch>();

    const activePage = useSelector(selectQuizCustomerActivePage, shallowEqual);
    const totalPages = useSelector(selectQuizCustomerTotalPages, shallowEqual);
    const quizzes = useSelector((state: RootState) => selectQuizCustomerPage(state, activePage), shallowEqual);
    const isLoading = useSelector(selectQuizCustomerLoading, shallowEqual);

    const [isShowExpired, setIsShowExpired] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
        watch,
        getValues,
        setValue,
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        },
    });

    const values = getValues();

    const onSearchKeywordSubmit = useCallback(
        (values: SearchFormData) => {
            dispatch(resetQuizCustomerPages());
            dispatch(setQuizCustomerActivePage(1));

            dispatch(
                getAllCustomerQuizzes({
                    activePage: 1,
                    activeSearchKeyword: values.keyword,
                    isShowExpired: isShowExpired
                })
            );
        },
        [dispatch, isShowExpired]
    );

    const onSearchKeywordChange = debounce(useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            setValue('keyword', e.target.value);

            dispatch(resetQuizCustomerPages());
            dispatch(setQuizCustomerActivePage(1));

            dispatch(
                getAllCustomerQuizzes({
                    activePage: 1,
                    activeSearchKeyword: e.target.value ?? '',
                    isShowExpired: isShowExpired
                })
            );
        },
        [dispatch, isShowExpired]
    ), 300);

    const handleReset = useCallback(() => {
        reset();

        dispatch(
            getAllCustomerQuizzes({
                activePage: 1,
                activeSearchKeyword: '',
                isShowExpired: isShowExpired
            })
        );
    }, []);

    const handlePaginationChange = useCallback(
        (page: number) => {
            dispatch(setQuizCustomerActivePage(page));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(
            getAllCustomerQuizzes({
                activePage,
                activeSearchKeyword: values.keyword ?? '',
                isShowExpired: isShowExpired
            })
        );

    }, [dispatch, activePage, isShowExpired]);

    useEffect(() => () => {
        dispatch(setQuizCustomerActivePage(1));
    }, [dispatch]);

    const handleToggleHideEnabled = () => {
        setIsShowExpired(!isShowExpired);
    };

    return (
        <Flex w="100%" flexDir="column" mb={'10px'}>
            <Flex gap="20px" sx={{ '.btn-half-width': { flex: '1 0 45%' } }} w="100%">
                <Flex  alignItems={'center'} mb={3} w="100%">
                    <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                        <InputGroup minW={'400px'} className="input-left-wrap">
                            <InputLeftElement
                                className="InputLeft"
                                pointerEvents={isSubmitting ? 'none' : 'auto'}
                                onClick={handleSubmit(onSearchKeywordSubmit)}
                            >
                                {isSubmitting || isLoading ? <SpinnerChakra size="20px" /> : <FaSearch size="20px" />}
                            </InputLeftElement>
                            <Input
                                placeholder="Search Customer Quizzes by Name"
                                bg={'#ffffff'}
                                id={'keyword'}
                                type={'text'}
                                borderRadius="1000px"
                                {...register('keyword')}
                                onChange={onSearchKeywordChange}
                            />
                            {watch('keyword') && (
                                <InputRightElement cursor="pointer" onClick={handleReset}>
                                    <HiX />
                                </InputRightElement>
                            )}
                        </InputGroup>
                    </form>
                    <Stack ml="20px" alignItems="center" direction='row'>
                        <Switch
                            onChange={handleToggleHideEnabled}
                            isChecked={isShowExpired}
                            size="lg"/>
                        <Text>Show Expired Quizzes</Text>
                    </Stack>
                </Flex>
            </Flex>
            <Flex flexDir={'column'}>
                <Table bgColor={'#ffffff'}>
                    {quizzes.length > 0 && (
                        <Thead borderBottom="1px solid #e5ebf3">
                            <Tr w="100%">
                                <ThEl align="start" w="65%">
                                    Title
                                </ThEl>
                                <ThEl w="15%">Access</ThEl>
                                <ThEl w="10%" >
                                    Customer Name
                                </ThEl>

                                <ThEl w="15%">Customer Email</ThEl>
                                <ThEl w="10%" >
                                    Created Date
                                </ThEl>
                                <ThEl w="15%">Deadline</ThEl>
                                <ThEl w="15%">Actions</ThEl>
                            </Tr>
                        </Thead>
                    )}
                    <Tbody>
                        {quizzes.length > 0 ? (
                            quizzes.map((quiz) => <CustomerQuizItem key={quiz.id} quiz={quiz} isAdminQuiz />)
                        ) : (
                            <>
                                {!isLoading && (
                                    <Tr>
                                        <Td>
                                            <Box>No list items</Box>
                                        </Td>
                                    </Tr>
                                )}
                            </>
                        )}
                    </Tbody>
                </Table>

                {totalPages > 1 && (
                    <Flex justifyContent={'end'} mt={'20px'}>
                        <Pagination currentPage={activePage} totalPages={totalPages}
                                    handlePagination={handlePaginationChange} />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};