import { LoadingSpinner } from '@/components/LoadingSpinner';
import { uploadQuizQti } from '@/features/Quiz/api/getQuizzes';
import React, { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    ResponsiveValue,
    Spinner,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
} from '@chakra-ui/react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/redux/store';
import {
    getAllQuizzes,
    resetQuizPages,
    selectQuizActivePage,
    selectQuizByPage,
    selectQuizLoading,
    selectQuizTotalPages,
    setQuizActivePage,
} from '@/redux/reducers/quizzes/quizSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from '@/utils/helpers';
import { FaSearch } from 'react-icons/fa';
import { HiX } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { QuizListItem } from '@/features/Quiz/components/QuizListItem/QuizListItem';
import { Pagination } from '@/components/Pagination';
import { useFileUpload } from 'use-file-upload';
import * as yup from 'yup';
import Icon from '@/components/Icon';

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

type SearchFormData = {
    keyword: string;
};

export const ThEl = ({ children, w, align = 'center' }: { children: ReactNode; w: string; align?: ResponsiveValue<any> }) => (
    <Th
        p=".25rem"
        textAlign={align}
        textTransform={'capitalize'}
        fontFamily="Roboto, sans-serif;"
        fontSize="14px"
        fontWeight="400"
        color="#76797d"
        w={w}>
        {children}
    </Th>
);

export const ArticlesQuiz = () => {
    const dispatch = useDispatch<AppDispatch>();

    const activePage = useSelector(selectQuizActivePage, shallowEqual);
    const totalPages = useSelector(selectQuizTotalPages, shallowEqual);
    const quizzes = useSelector((state: RootState) => selectQuizByPage(state, activePage), shallowEqual);
    const isLoading = useSelector(selectQuizLoading, shallowEqual);
    const [, selectFile] = useFileUpload();
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
        watch,
        setValue,
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        },
    });

    const onSearchKeywordSubmit = useCallback(
        (values: SearchFormData) => {
            dispatch(resetQuizPages());
            dispatch(setQuizActivePage(1));

            dispatch(
                getAllQuizzes({
                    activePage: 1,
                    activeSearchKeyword: values.keyword,
                })
            );
        },
        [dispatch]
    );

    const onSearchKeywordChange = debounce(
        useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                e.preventDefault();

                setValue('keyword', e.target.value);

                dispatch(resetQuizPages());
                dispatch(setQuizActivePage(1));

                dispatch(
                    getAllQuizzes({
                        activePage: 1,
                        activeSearchKeyword: e.target.value ?? '',
                    })
                );
            },
            [dispatch]
        ),
        300
    );

    const handleReset = useCallback(() => {
        reset();

        dispatch(
            getAllQuizzes({
                activePage: 1,
                activeSearchKeyword: '',
            })
        );
    }, []);

    const handlePaginationChange = useCallback(
        (page: number) => {
            dispatch(setQuizActivePage(page));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(
            getAllQuizzes({
                activePage,
                activeSearchKeyword: '',
            })
        );
    }, [dispatch, activePage]);

    useEffect(
        () => () => {
            dispatch(setQuizActivePage(1));
        },
        [dispatch]
    );

    return (
        <Flex w="100%" flexDir="column" mb={'10px'}>
            <Flex gap="20px" sx={{ '.btn-half-width': { flex: '1 0 45%' } }} w="100%">
                <Flex justifyContent={'space-between'} alignItems={'center'} mb={3} w="100%">
                    <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                        <InputGroup minW={'400px'}>
                            <InputLeftElement
                                className="InputLeft"
                                pointerEvents={isSubmitting ? 'none' : 'auto'}
                                onClick={handleSubmit(onSearchKeywordSubmit)}>
                                {isSubmitting || isLoading ? <Spinner size="20px" /> : <FaSearch size="20px" />}
                            </InputLeftElement>
                            <Input
                                placeholder="Search by Title"
                                bg={'#ffffff'}
                                id={'keyword'}
                                type={'text'}
                                borderRadius="1000px"
                                {...register('keyword')}
                                onChange={onSearchKeywordChange}
                            />
                            {watch('keyword') && (
                                <InputRightElement cursor="pointer" onClick={handleReset}>
                                    <HiX />
                                </InputRightElement>
                            )}
                        </InputGroup>
                    </form>
                    <Flex>
                        <Button
                            variant={'brand'}
                            mr={5}
                            onClick={() => {
                                selectFile({ accept: '.zip', multiple: false }, async (props) => {
                                    if (props && !Array.isArray(props)) {
                                        const formData = new FormData();

                                        formData.append('file', props.file);

                                        try {
                                            setLoading(true);

                                            await uploadQuizQti(formData);

                                            setLoading(false);
                                            toast({
                                                title: `Uploaded files successfully processed`,
                                                status: 'info',
                                                duration: 4000,
                                                position: 'top',
                                            });
                                        } catch (e: any) {
                                            setLoading(false);
                                            toast({
                                                title: `${e?.message}`,
                                                status: 'error',
                                                duration: 4000,
                                                position: 'top',
                                            });
                                        }
                                    }
                                });
                            }}>
                            <Icon icon="upload" />
                            {loading ? (
                                <Box w="74px">
                                    <LoadingSpinner />
                                </Box>
                            ) : (
                                `Upload QTI`
                            )}
                        </Button>
                        <Button _focus={{ outline: 0 }} _hover={{}} _active={{}} as={Link} to={'/quiz/create'} variant="brand" cursor="pointer">
                            <Icon icon="plus" /> &nbsp; Create a New Quiz
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
            <Flex flexDir={'column'}>
                <Table bgColor="#ffffff">
                    {quizzes.length > 0 && (
                        <Thead borderBottom="1px solid #e5ebf3">
                            <Tr w="100%">
                                <ThEl w="65%" align={'start'}>
                                    Title
                                </ThEl>
                                <ThEl w="10%">Date Created</ThEl>
                                <ThEl w="15%">Questions</ThEl>
                                <ThEl w="15%">Actions</ThEl>
                            </Tr>
                        </Thead>
                    )}
                    <Tbody>
                        {quizzes.length > 0 ? (
                            quizzes.map((quiz) => <QuizListItem key={quiz.id} quiz={quiz} />)
                        ) : (
                            <>
                                {!isLoading && (
                                    <Tr>
                                        <Td>
                                            <Box>No list items</Box>
                                        </Td>
                                    </Tr>
                                )}
                            </>
                        )}
                    </Tbody>
                </Table>

                {totalPages > 1 && (
                    <Flex justifyContent={'end'} mt={'20px'}>
                        <Pagination currentPage={activePage} totalPages={totalPages} handlePagination={handlePaginationChange} />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default ArticlesQuiz;
