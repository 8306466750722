import { QuizzesArticles } from '@/types';
import React, { useCallback } from 'react';
import { Button, Td, Text, Tr } from '@chakra-ui/react';
import parse from 'html-react-parser';

interface Props {
    article: QuizzesArticles;
    onSelectArticle: (id: number) => void;
}

export const ArticlesListItem = ({ article, onSelectArticle }: Props) => {
    const { domain } = article;

    const handleArticleSelect = useCallback(() => {
        onSelectArticle(domain?.id);
    }, [domain?.id, onSelectArticle])

    return (
        <>
            <Tr>
                <Td>{domain?.id}</Td>
                <Td>
                    <Text noOfLines={2}>
                        {parse(domain?.title)}
                    </Text>
                </Td>
                <Td>
                    {domain?.articleType}
                </Td>
                <Td>
                    <Button
                        colorScheme='brand'
                        color='currentColor'
                        border='1px solid lightgrey'
                        w='107px'
                        size='sm'
                        onClick={handleArticleSelect}
                    >
                        Select
                    </Button>
                </Td>
            </Tr>
        </>
    );
};
