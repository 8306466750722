import { createSlice, createEntityAdapter, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import {
    deletePlaylistArticle,
    getPlaylistArticles,
} from '../../../../features/Playlists/api';
import { RootState } from '../../../store';
import { isNotNull } from '../../../../utils/helpers';
import {
    PlaylistArticleSectionPath,
    PlaylistArticlesResponse,
    PlaylistLabelResponse,
    GetListBodyStructure
} from '../../../../features/Playlists';

type AuthorsType = {
    id?: number;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    institutionName?: string | null;
    fullName?: string;
};

type ArticleAuthorsType = {
    author: AuthorsType;
    id: number;
    order: number;
}

export type AdapterArticle = {
    overviewContent: string;
    videos: Record<'en', {
        id: number;
        locale: string;
        cdnFile: string;
        fileIdentifier: string;
    }>,
    authors: ArticleAuthorsType[],
    images: {
        large: string;
        short: string;
    };
    sectionPath: PlaylistArticleSectionPath[];
    id: number;
    title: string;
    titleDescription: string;
    titleImage: string;
    headerDescription: string;
    headerImage: string;
    isActive: boolean;
    type: string;
    inCollection: boolean;
}

type ArticlesState = {
    page: Record<number, AdapterArticle['id'][]>;
    items_per_page: number;
    more: boolean;
    items_on_page: number;
    total_pages: number;
    total_items: number;
    isLoading: boolean;
    isError: boolean;
};

const articlesAdapter = createEntityAdapter<AdapterArticle>();

export const getArticles = createAsyncThunk<PlaylistArticlesResponse,
    GetListBodyStructure,
    { rejectValue: string }>(
    'playlist/articles/getPlaylistArticles',
    async (data, { rejectWithValue }) => {
        try {
            return await getPlaylistArticles(data);
        } catch (error) {
            let message = '';
            if (error instanceof Error) {
                message = error.message ||
                    error.toString();
            }

            return rejectWithValue(message);
        }

    });

export const getArticlesByIds = createAsyncThunk<PlaylistArticlesResponse,
    GetListBodyStructure,
    { rejectValue: string }>(
    'playlist/articles/getPlaylistArticlesByIds',
    async (data, { rejectWithValue }) => {
        try {
            return await getPlaylistArticles(data);
        } catch (error) {
            let message = '';
            if (error instanceof Error) {
                message = error.message ||
                    error.toString();
            }

            return rejectWithValue(message);
        }
    });

export const deleteArticle = createAsyncThunk<PlaylistLabelResponse,
    string,
    { rejectValue: string }>(
    'playlist/articles/deletePlaylistArticle',
    async (id, { rejectWithValue }) => {
        try {
            return await deletePlaylistArticle(id);
        } catch (error) {
            let message = '';
            if (error instanceof Error) {
                message = error.message ||
                    error.toString();
            }

            return rejectWithValue(message);
        }
    });

const articlesSlice = createSlice({
    name: 'articles',
    initialState: articlesAdapter.getInitialState<ArticlesState>({
        page: {},
        items_per_page: 9,
        more: true,
        items_on_page: 9,
        total_pages: 1,
        total_items: 1,
        isLoading: false,
        isError: false,
    }),
    reducers: {
        resetArticlesPages: (state) => {
            state.page = {};
        },
        removeAllArticles: (state) => {
            articlesAdapter.removeAll(state);
        },
        removeArticle: (state, action) => {
            articlesAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: {
        [getArticles.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getArticles.fulfilled.type]: (state, action: PayloadAction<PlaylistArticlesResponse, string, { arg: GetListBodyStructure }>) => {
            articlesAdapter.upsertMany(state, action.payload.result);
            state.page[action.meta.arg.navigation.page] = action.payload.result.map((item) => item.id);
            state.isLoading = false;
            state.items_per_page = action.payload.navigation.items_per_page;
            state.more = action.payload.navigation.more;
            state.items_on_page = action.payload.navigation.items_on_page;
            if (action.payload.navigation.total_pages && action.payload.navigation.total_items) {
                state.total_pages = action.payload.navigation.total_pages;
                state.total_items = action.payload.navigation.total_items;
            }
            state.isError = false;
        },
        [getArticles.rejected.type]: (state) => {
            state.isLoading = false;
            state.isError = true;
        },
        [getArticlesByIds.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getArticlesByIds.fulfilled.type]: (state, action: PayloadAction<PlaylistArticlesResponse, string, { arg: GetListBodyStructure }>) => {
            articlesAdapter.upsertMany(state, action.payload.result);
            state.isLoading = false;
            state.isError = false;
        },
        [deleteArticle.rejected.type]: (state) => {
            state.isLoading = false;
            state.isError = true;
        },
        [deleteArticle.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [deleteArticle.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.isError = true;
        },
    },
});

export const articles = articlesSlice.reducer;
export const { resetArticlesPages, removeArticle, removeAllArticles } = articlesSlice.actions;
const selectState = (state: RootState) => state.playlistReducer.articles;
export const { selectById, selectIds } = articlesAdapter.getSelectors(selectState);
const selectRootState = (state: RootState) => state;

export const selectArticlesById = createSelector(
    [selectRootState, (state: RootState, id: number | null) => (id ? selectById(state, id) ?? null : null)],
    (_, entity) => entity,
);
export const selectArticlesByIds = createSelector(
    [selectRootState, (state: RootState, ids: number[] | null) => ids],
    (state, ids) => {
        if (!ids) {
            return [];
        }
        return ids.map((id) => selectArticlesById(state, id)).filter(isNotNull);
    },
);

const selectIdsByPage = createSelector(
    [selectState, (_: RootState, page: number) => page],
    (state, page) => state.page[page] ?? [],
);

export const selectArticlesByPage = createSelector(
    [selectRootState, (state: RootState, page: number) => selectIdsByPage(state, page)],
    (state, ids) => ids.map((id) => selectArticlesById(state, id)).filter(isNotNull),
);

export const selectArticlesLoading = createSelector([selectState], (state) => state.isLoading);
export const selectArticlesTotalPages = createSelector([selectState], (state) => state.total_pages);
export const selectArticlesTotalItems = createSelector([selectState], (state) => state.total_items);



