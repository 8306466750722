import { useQuery } from 'react-query';
import { API_URL } from "@/config";

import { axios } from '@/lib/axios';

import { InstitutionResponse } from '../types';
import { GetListBodyStructure, GetListParams, InstitutionSubscriptionTypes } from '@/types';

export const buildInstitutionsListParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    activeSortDirection: string,
    activeSortProperty: string,
    isNumber?: boolean,
    isApproved?: boolean,
    subscription?: InstitutionSubscriptionTypes,
    productId?: number | null,
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20
        },
        totalCount: true,
        ...(subscription ? { hasSubscription: subscription } : null),
        filter: {
            collection: [
                ...(activeSearchKeyword
                        ?
                        [
                            ...[{
                                bool_operator: 'and',
                                condition: {
                                    property: isNumber || Number.isInteger(activeSearchKeyword) ? 'id' : 'title',
                                    operator: isNumber || Number.isInteger(activeSearchKeyword) ? 'equals' : 'contains',
                                    value: isNumber || Number.isInteger(activeSearchKeyword)
                                        ? Number(activeSearchKeyword)
                                        : activeSearchKeyword || '',
                                }
                            }],
                            ...isApproved ? [{
                                bool_operator: 'and',
                                condition: {
                                    property: 'approved',
                                    operator: 'equals',
                                    value: true
                                }
                            }] : [],
                            ...productId ? [{
                                bool_operator: 'and',
                                condition: {
                                    property: 'product_id',
                                    operator: 'equals',
                                    value: Number(productId)
                                }
                            }] : [],
                        ] : [...
                            (isApproved ?
                                    [{
                                        bool_operator: 'and',
                                        condition: {
                                            property: 'approved',
                                            operator: 'equals',
                                            value: true
                                        }
                                    }]
                                    :
                                    []
                            ),
                            ...productId ? [{
                                bool_operator: 'and',
                                condition: {
                                    property: 'product_id',
                                    operator: 'equals',
                                    value: Number(productId)
                                }
                            }] : [],
                        ]
                ),
            ]
        },
        sort: [
            [
                activeSortProperty,
                activeSortDirection
            ]
        ]
    }
}

export const getInstitutions = (data: GetListBodyStructure): Promise<InstitutionResponse> => {
    return axios.post(`${API_URL}/api/admin/institution`, data);
};

export const useInstitutions = ({
    activePage,
    activeSearchKeyword,
    activeSortDirection,
    activeSortProperty,
    isNumber,
    isApproved,
    subscription,
    isRefetch,
    productId,
}: GetListParams) => {
    return useQuery<InstitutionResponse, Error>({
        queryKey: [
            'institutions',
            activePage,
            activeSearchKeyword,
            activeSortDirection,
            activeSortProperty,
            isNumber,
            isApproved,
            subscription,
            isRefetch,
            productId,
        ],
        queryFn: () => {
            let params = buildInstitutionsListParams(
                activePage,
                activeSearchKeyword,
                activeSortDirection,
                activeSortProperty,
                isNumber,
                isApproved,
                subscription,
                productId,
            );
            return getInstitutions(params)
        },
        keepPreviousData: true,
    });
};
