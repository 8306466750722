import { QuizType } from '@/redux/reducers/quizzes/quizSlice';
import React, {useCallback} from 'react';
import { FormQuiz } from '../FormQuiz';
import {Navigate, useParams} from 'react-router-dom';
import {useQuery} from "react-query";
import {
    getAdminQuizById,
    getQuiz,
    getQuizLibraryById,
    getQuizTemplateByArticleId, getUserQuizById,
} from '@/features/Quiz/api/getQuizzes';
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {ErrorBlock} from "@/components/ErrorBlock";
import {useAuth} from "@/context/AuthContext";

type Props = {
    isLibrary?: boolean;
    isAdminQuiz?: boolean;
    isUserQuiz?: boolean;
}

export const CreateQuiz = ({ isLibrary = false, isAdminQuiz = false, isUserQuiz = false }: Props) => {
    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const { articleId, quizId, quizLibraryId, adminQuizId, userQuizId } = useParams();

    const quizQueryKey = useCallback(() => {
        if (adminQuizId) {
            return `getAdminQuiz-${adminQuizId}`
        }

        if (userQuizId) {
            return `getUserQuiz-${userQuizId}`;
        }

        if (quizLibraryId) {
            return `getQuizLibrary-${quizLibraryId}`
        }

        if (articleId) {
            return `getQuizArticle-${articleId}`
        }

        return `getQuiz-${quizId}`;
    }, [adminQuizId, quizLibraryId, quizId, articleId]);

    const { data, isLoading, isError, error } = useQuery(
        quizQueryKey(),
        async () => {
            if (quizId || quizLibraryId || adminQuizId || articleId || userQuizId) {
                if (userQuizId) {
                    return await getUserQuizById(Number(userQuizId));
                }
                if (adminQuizId) {
                    return await getAdminQuizById(Number(adminQuizId));
                }
                if (quizLibraryId) {
                    return await getQuizLibraryById(Number(quizLibraryId));
                }
                if (articleId) {
                    const action = await getQuizTemplateByArticleId(Number(articleId));

                    return {
                        id: 0,
                        article: Number(articleId),
                        title: '',
                        active: false,
                        startDate: '',
                        endDate: '',
                        duration: 0,
                        countOfQuestions: 0,
                        editable: false,
                        activities: action.activities,
                        tags: [],
                        createdAt: '',
                        updatedAt: null,
                    };
                }

                return await getQuiz(Number(quizId));
            }

            return null;
        }, {
            useErrorBoundary: (error: any) => error.response?.status >= 400,
        },
    );

    if (!isSuperAdmin && !isLibrary && !isAdminQuiz && !isUserQuiz) {
        return <Navigate to="/"/>
    }

    if (isLoading) return <LoadingSpinner isFullScreen />;
    if (error?.statusCode === 404 || isError) return <ErrorBlock error={'Error'} textOnly />;

    return <FormQuiz
        quiz={(data as QuizType) ?? null}
        isLibrary={isLibrary}
        isAdminQuiz={isAdminQuiz}
        isUserQuiz={isUserQuiz}
    />;
};
