import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import Icon from '@/components/Icon';
import React from 'react';
import { useAddTranslation } from '../../api/addTranslation';
import { TranslationDetailFormData, TranslationForm } from './Components/TranslationForm/TranslationForm';
import { format_TranslationFormData_To_BulkUpload } from './Components/TranslationForm/format_TranslationFormData_To_BulkUpload';

export const AddTranslation = () => {
    const navigate = useNavigate();

    const addTranslationMutation = useAddTranslation();

    const onSubmit = async (values: TranslationDetailFormData) => {
        const data = format_TranslationFormData_To_BulkUpload(values);

        await addTranslationMutation.mutate({ data });
    };

    return (
        <Flex flexDir={'column'} margin={'0 auto'} maxW={'800px'}>
            <Flex mb={3} alignItems={'center'} justifyContent={'space-between'}>
                <Heading size="lg">Add New translation</Heading>

                <Button variant="back" onClick={() => navigate(`/translations`)} leftIcon={<Icon icon={'chevron-left'} />}>
                    Back
                </Button>
            </Flex>

            <Box bgColor={'#ffffff'} p={4}>
                <TranslationForm onSubmit={onSubmit} />
            </Box>
        </Flex>
    );
};
