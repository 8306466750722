export const BANNER_TYPES = [
    {
        key: 1,
        label: 'Informative',
    },
    {
        key: 2,
        label: 'Warning',
    },
    {
        key: 3,
        label: 'Promotional',
    },
    {
        key: 4,
        label: 'System',
    },
];
