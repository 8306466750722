import { API_URL } from '@/config';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import FileDownload from "js-file-download";
import { useState } from "react";
import { PackageDownloadTypes } from "@/types";


const getQti = (qtiType: PackageDownloadTypes, qtiId: number, title: string, institutionId: number) => {
    return axios
        .get(`${API_URL}/api/admin/quiz/${qtiType}/${qtiId}/qti?trackLink=https://stats.jove.com/qti-beacon.php?institutionid=${institutionId}&qtiid=${qtiId}&qtitype=${qtiType}`, { responseType: 'blob' })
        .then((response) => {
            FileDownload(response.data, `${title.toLowerCase().replace(/ /g, '-')}-qti.zip`);
        });
};

export const useQti = ({ qtiType, qtiId, title }: { qtiType: PackageDownloadTypes, qtiId: number; title: string }) => {
    const { addNotification } = useNotificationStore();
    const [isLoading, setIsLoading] = useState(false);

    const handleQtiDownload = async (institutionId: number) => {
        if (qtiId && title) {
            try {
                setIsLoading(true);
                await getQti(qtiType, qtiId, title, institutionId);
                setIsLoading(false);
            } catch (error: any) {
                const responseObj = JSON.parse(await error.response.data.text());
                addNotification({
                    type: 'error',
                    title: `${responseObj?.content?.message ?? responseObj?.message}`,
                });
                setIsLoading(false);
            }
        }
    };
    return { isLoading, handleQtiDownload }
};
