export function resetLScache() {
    const keys = Object.keys(localStorage);
    for (let key of keys) {
        if (key.startsWith('tree') || key.startsWith('list')) localStorage.removeItem(key);
    }
}

export function isLSCacheValid(prevDate: string) {
    const cacheTime = parseFloat(window.__CONFIG__.REACT_APP_LS_CACHE_HOURS || '0');
    const interval = (new Date().valueOf() - new Date(prevDate).valueOf()) / 1000 / 3600;
    const isValid = interval < cacheTime;
    if (!isValid) resetLScache();
    return isValid;
}

export function generateLSKey(language: string, id: number | string = 0, prefix: string = '') {
    const globalLanguage = localStorage.getItem('global-language') || 'en';
    const email = localStorage.getItem('email') || '';
    return `${prefix}-${email}-${globalLanguage}-${language}-${id}`;
}

export function getLSitem(key: string) {
    const storedItem = localStorage.getItem(key);
    if (storedItem) {
        const obj = JSON.parse(storedItem);
        if (obj && isLSCacheValid(obj.datetime) && +obj.statusCode === 200) {
            return obj.content;
        }
    }

    return;
}
