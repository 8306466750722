import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { GetListParams } from '@/types';
import { TranslationsResponse } from '@/features/Translations/types';

type FilterCollection = {
    bool_operator?: string;
    condition?: {
        property: string;
        operator: string;
        value: string | number | boolean;
    };
};

type GetListBodyStructure = {
    navigation: {
        page: number;
        itemsOnPage: number;
    };
    totalCount: boolean;
    filter: {
        collection: FilterCollection[] | null;
    };
    sort: string[][];
};

const buildTranslationsListParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    activeSortDirection: string,
    activeSortProperty: string,
    activeLanguage: string
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20,
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword
                    ? [
                          {
                              bool_operator: 'or',
                              condition: {
                                  property: 'key',
                                  operator: 'contains',
                                  value: activeSearchKeyword || '',
                              },
                          },
                          {
                              bool_operator: 'or',
                              condition: {
                                  property: 'groupTitle',
                                  operator: 'contains',
                                  value: activeSearchKeyword || '',
                              },
                          },
                          {
                              bool_operator: 'or',
                              condition: {
                                  property: 'value',
                                  operator: 'contains',
                                  value: activeSearchKeyword || '',
                              },
                          },
                      ]
                    : []),
                ...(activeLanguage
                    ? [
                          {
                              bool_operator: 'and',
                              condition: {
                                  property: 'language',
                                  operator: 'equals',
                                  value: activeLanguage,
                              },
                          },
                      ]
                    : []),
            ],
        },
        sort: [[activeSortProperty, activeSortDirection]],
    };
};

export const getSiteTranslations = (data?: GetListBodyStructure): Promise<TranslationsResponse> => {
    return axios.post(`/api/admin/site_translation/`, data);
};

export const useTranslations = ({
    activePage,
    activeSearchKeyword,
    activeSortDirection,
    activeSortProperty,
    activeLanguage,
}: GetListParams & { activeLanguage: string }) => {
    return useQuery<TranslationsResponse, Error>({
        queryKey: ['translation', activePage, activeSearchKeyword, activeSortDirection, activeSortProperty, activeLanguage],
        queryFn: () => {
            const params = buildTranslationsListParams(activePage, activeSearchKeyword, activeSortDirection, activeSortProperty, activeLanguage);
            return getSiteTranslations(params);
        },
        keepPreviousData: true,
    });
};
