import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { GroupsResponse } from '@/features/Translations/types';
import { GetListBodyStructure, GetListParams } from '@/types';

const buildTranslationsListParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    activeSortDirection: string,
    activeSortProperty: string
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20,
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword
                    ? [
                          {
                              bool_operator: 'or',
                              condition: {
                                  property: 'key',
                                  operator: 'contains',
                                  value: activeSearchKeyword || '',
                              },
                          },
                          {
                              bool_operator: 'or',
                              condition: {
                                  property: 'title',
                                  operator: 'contains',
                                  value: activeSearchKeyword || '',
                              },
                          },
                      ]
                    : []),
            ],
        },
        sort: [[activeSortProperty, activeSortDirection]],
    };
};

export const getGroupsList = (data?: GetListBodyStructure): Promise<GroupsResponse> => {
    return axios.post(`/api/admin/site_translation/group`, data);
};

export const useGroups = ({ activePage, activeSearchKeyword, activeSortDirection, activeSortProperty }: GetListParams) => {
    return useQuery<GroupsResponse, Error>({
        queryKey: ['group', activePage, activeSearchKeyword, activeSortDirection, activeSortProperty],
        queryFn: () => {
            const params = buildTranslationsListParams(activePage, activeSearchKeyword, activeSortDirection, activeSortProperty);

            return getGroupsList(params);
        },
        keepPreviousData: true,
    });
};
