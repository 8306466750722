import { API_URL } from "@/config";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation, useQueryClient } from "react-query";
import { ClassItem } from "../../types";

type AddData = {
    classId: number;
    playlistsIds: number[];
}

const addPlaylistsToClass = (data: AddData): Promise<ClassItem> => {
    return axios.post(`${API_URL}/api/admin/class/playlist`, { playlistsIds: data.playlistsIds, classIds: [data.classId] });
}

export const useAddPlaylistsToClass = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation({
        onMutate: async ({ classId }) => {
            await queryClient.cancelQueries(['getClass', classId]);
        },
        onError: () => {
            addNotification({
                type: 'error',
                title: 'Unable to add Playlists to Class',
            });
        },
        onSuccess: async (_,{ classId } ) => {
            await queryClient.refetchQueries(['getClass', classId]);
            addNotification({
                type: 'success',
                title: 'Playlists successfully added',
            });
        },
        mutationFn: addPlaylistsToClass,
    })
}