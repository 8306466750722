import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SearchFormData } from '@/features/IdentityProviders/routes/IdentityProviders';
import * as yup from 'yup';
import { useSponsors } from '@/features/Sponsors/api/getSponsorsList';
import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Skeleton,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import Icon from '@/components/Icon';
import { Link } from 'react-router-dom';
import SortableTableHeader from '@/components/Table/SortableTableHeader';
import { Pagination } from '@/components/Pagination';
import { SponsorItem } from '@/features/Sponsors/Components/SponsorItem';

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

export const Sponsors = () => {
    const [activePage, setActivePage] = useState<number>(1);
    const [activeSearchKeyword, setActiveSearchKeyword] = useState<string>('');
    const [activeSortDirection, setActiveSortDirection] = useState<string>('desc');
    const [activeSortProperty, setActiveSortProperty] = useState<string>('id');

    const { data, isLoading } = useSponsors({
        activePage,
        activeSearchKeyword,
        activeSortDirection,
        activeSortProperty,
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = useForm<SearchFormData>({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        },
    });

    useEffect(() => {
        setActivePage(1);
    }, [activeSearchKeyword]);

    const onSearchKeywordSubmit = (values: SearchFormData) => {
        setActiveSearchKeyword(values.keyword);
    };

    const handleReset = () => {
        setActiveSearchKeyword('');
        reset();
    };

    const handleSortSelect = (sortVal: string) => {
        if (activeSortProperty === sortVal) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(sortVal);
            setActiveSortDirection('asc');
        }
    };

    return (
        <>
            <Flex mb={3} alignItems="center" justifyContent="space-between">
                <Heading size="lg">Sponsors</Heading>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" mb={3}>
                <Flex>
                    <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                        <InputGroup minW="400px">
                            <InputLeftElement pointerEvents={isSubmitting ? 'none' : 'auto'} onClick={handleSubmit(onSearchKeywordSubmit)}>
                                {isSubmitting ? <Icon icon="loader" /> : <Icon icon="search" />}
                            </InputLeftElement>
                            <Input placeholder="Search by sponsor name..." bg="#ffffff" id="keyword" {...register('keyword')} />
                            {activeSearchKeyword && (
                                <InputRightElement onClick={handleReset}>
                                    <Icon icon="times" />
                                </InputRightElement>
                            )}
                        </InputGroup>
                    </form>
                </Flex>
                <Box>
                    <Button as={Link} to="/sponsors/add" variant="brand">
                        <Icon icon="plus" />
                        &nbsp; Add New Sponsor
                    </Button>
                </Box>
            </Flex>
            {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i: number) => (
                    <Box display="block" key={i} w="100%">
                        <Flex>
                            <Skeleton m="5px" height="50px" w="30%"></Skeleton>
                            <Skeleton m="5px" height="50px" w="100%"></Skeleton>
                            <Skeleton m="5px" height="50px" w="30%"></Skeleton>
                        </Flex>
                    </Box>
                ))
            ) : (
                <Flex my={4}>
                    <Table bgColor="#ffffff">
                        <Thead>
                            <Tr>
                                <SortableTableHeader
                                    value="company_name"
                                    activeSortDirection={activeSortDirection}
                                    activeSortProperty={activeSortProperty}
                                    handleSortSelect={(val) => handleSortSelect(val)}>
                                    Company Name
                                </SortableTableHeader>

                                <Th textAlign="center">Company URL</Th>
                                <Th textAlign="center">Company Logo</Th>
                                <Th textAlign="center">Video URL</Th>
                                <Th textAlign="center">Assigned Articles</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data ? (
                                data?.result.map((el) => {
                                    return <SponsorItem key={el.id} sponsor={el} />;
                                })
                            ) : (
                                <Tr>
                                    <Td>No identity providers found!</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </Flex>
            )}
            {data && data?.navigation?.total_pages > 1 && (
                <Pagination
                    currentPage={activePage}
                    totalPages={data.navigation.total_pages}
                    handlePagination={(page: number) => setActivePage(page)}
                />
            )}
        </>
    );
};
