import { axios } from '@/lib/axios';
import { useMutation, useQueryClient } from 'react-query';
import { useNotificationStore } from '@/stores/notifications';

export const deleteTranslationGroup = async (groupId: number) => {
    await axios.delete(`/api/admin/site_translation/group/${groupId}`);
};

export const useDeleteTranslationGroup = (closeModalFunction: () => void) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();

    return useMutation({
        onError: (_, __, context: any) => {
            addNotification({
                type: 'error',
                title: 'Unable to delete translation',
            });
            closeModalFunction();
        },
        onSuccess: () => {
            queryClient.refetchQueries(['group']);

            closeModalFunction();
            addNotification({
                type: 'info',
                title: 'Translation Group successfully deleted',
            });
        },
        mutationFn: deleteTranslationGroup,
    });
};
