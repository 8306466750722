import { ActionButton } from '@/components/ActionButton';
import { IconName } from '@fortawesome/pro-solid-svg-icons';
import { rebuildQuizLibraryById } from '@/features/Quiz/api/getQuizzes';
import { useNotificationStore } from '@/stores/notifications';
import React, { ReactNode, useState } from 'react';
import { Tr, Td, Text, useDisclosure, Flex, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { QuizLibrary } from '../../types';
import { DeleteQuizModal } from '@/features/Quiz/components/DeleteQuizModal';
import { useQti } from '@/api/qtiApi';
import InstitutionsModal from '@/features/Playlists/components/InstitutionsModal';
import { usePlaylistCC } from '@/api/ccApi';
import { InstitutionModalTypes } from '@/features/Playlists';
import { usePlaylistScorm } from '@/api/scormApi';

type QuizLibraryItemProps = {
    quiz: QuizLibrary;
    isSectionType: boolean;
};

const TextEl = ({ children }: { children: ReactNode }) => (
    <Text color="black" fontSize="16px" lineHeight="21px" textAlign="center" display="inline-block" p="0 10px" w="auto">
        {children}
    </Text>
);

export const QuizLibraryItem = ({ quiz, isSectionType }: QuizLibraryItemProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isInProcess = quiz?.processStatus === 0;
    const { isOpen: isInstitutionsOpen, onOpen: onInstitutionsOpen, onClose: onInstitutionsClose } = useDisclosure();
    const [type, setType] = useState<InstitutionModalTypes>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotificationStore();

    const downloadType = 'from_quiz_library';

    const { isLoading: isCCLoading, handleCCDownload } = usePlaylistCC({
        ccType: downloadType,
        ccId: quiz?.id ?? 0,
        title: quiz?.title ?? '',
    });

    const { isLoading: isQtiLoading, handleQtiDownload } = useQti({
        qtiType: downloadType,
        qtiId: quiz?.id ?? 0,
        title: quiz?.title ?? '',
    });

    const { isLoading: isScormLoading, handleScormDownload } = usePlaylistScorm({
        scormType: downloadType,
        scormId: quiz?.id ?? 0,
        playlistTitle: quiz?.title ?? '',
    });

    const handleOpenInstitutionsCC = () => {
        setType('cc');
        onInstitutionsOpen();
    };

    const handleOpenInstitutionsScorm = () => {
        setType('scorm');
        onInstitutionsOpen();
    };

    const handleOpenInstitutionsQti = () => {
        setType('qti');
        onInstitutionsOpen();
    };

    const handleRebuildQuiz = async () => {
        setIsLoading(true);
        try {
            await rebuildQuizLibraryById(quiz.id);
            addNotification({
                type: 'success',
                title: 'Quiz has been successfully rebuilt',
            });
        } catch (e: any) {
            addNotification({
                type: 'error',
                title: e?.message,
            });
        }
        setIsLoading(false);
    };
    const actionButtons = [
        {
            link: `/quiz/library/edit/${quiz.id}`,
            tooltip: 'Edit',
            icon: 'pencil',
        },
        {
            link: `/quiz/library/copy/${quiz.id}`,
            tooltip: 'Copy',
            icon: 'clone',
        },
        {
            tooltip: 'Download SCORM',
            icon: 'file-zipper',
            handleClick: handleOpenInstitutionsScorm,
        },
        {
            tooltip: 'Download QTI',
            icon: 'scroll',
            handleClick: handleOpenInstitutionsQti,
        },
        {
            tooltip: 'Download CC',
            icon: 'closed-captioning',
            handleClick: handleOpenInstitutionsCC,
            isLoading: isCCLoading,
        },
        ...(isSectionType
            ? [
                  {
                      tooltip: 'Rebuild quiz',
                      icon: 'trowel-bricks',
                      handleClick: handleRebuildQuiz,
                      isLoading: isLoading,
                  },
              ]
            : []),
        {
            tooltip: 'Delete',
            icon: 'trash',
            handleClick: onOpen,
        },
    ];

    return (
        <>
            <InstitutionsModal
                isOpen={isInstitutionsOpen}
                onClose={onInstitutionsClose}
                handleScormDownload={handleScormDownload}
                handleCCDownload={handleCCDownload}
                handleQtiDownload={handleQtiDownload}
                isLoading={isScormLoading || isCCLoading || isQtiLoading}
                type={type}
            />
            <DeleteQuizModal isOpen={isOpen} onClose={onClose} quiz={quiz} quizType={'library'} />
            <Tooltip
                label={isInProcess ? 'Playlist is not accessible while being processed' : ''}
                aria-label={isInProcess ? 'Playlist is not accessible while being processed' : ''}
                hasArrow>
                <Tr opacity={isInProcess ? '.7' : '1'}>
                    <Td w="65%" py="10px">
                        <Flex as={Link} to={isInProcess ? '' : `/quiz/library/view/${quiz.id}`} py={'10px'}>
                            <Text color={'black'}>{quiz.title}</Text>
                        </Flex>
                    </Td>

                    <Td w={'10%'}>
                        <Text color={'black'}>{quiz.tags && quiz.tags.length ? quiz.tags?.map((item) => item.title).join(', ') : '-'}</Text>
                    </Td>
                    <Td w={'15%'} textAlign="center">
                        <TextEl>{quiz.countOfQuestions ? `${quiz.countOfQuestions} Questions` : '-'}</TextEl>
                    </Td>
                    <Td w="auto" maxWidth={'260px'}>
                        <Flex columnGap={'10px'} align="center" justifyContent={'space-around'}>
                            {actionButtons.map(({ icon, handleClick, tooltip, link, isLoading }, index) => (
                                <ActionButton
                                    key={index}
                                    linkTo={link}
                                    isInProcess={isInProcess}
                                    tooltipTitle={tooltip}
                                    handleClick={handleClick}
                                    isLoading={isLoading}
                                    icon={icon as IconName}
                                />
                            ))}
                        </Flex>
                    </Td>
                </Tr>
            </Tooltip>
        </>
    );
};
