import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import LogoWhite from '@/images/jove-logo-trans.svg';

export const AuthLayout = ({children}: {children: React.ReactChild}) => {
    return (
        <>
            <Flex
                flexDir={'column'}
                h={'full'}
                w={'full'}
                alignItems={'center'}
                bgColor={'brand.blue'}
            >
                <Flex
                    py={'25px'}
                    px={'15px'}
                    w={'full'}
                    maxWidth={'1440px'}
                    width="100%"
                    margin="0 auto"
                    justify={'space-between'}
                    alignItems={'center'}
                >
                    <Link to="/">
                        <Image
                            src={LogoWhite}
                            alt="Jove logo"
                            height="36px"
                            cursor="pointer"
                        />
                    </Link>
                </Flex>
                {children}
            </Flex>
        </>
    );
};
