import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, Button, Flex, Heading, Input, InputGroup, InputLeftElement, Skeleton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import Icon from '@/components/Icon';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination } from '@/components/Pagination';
import { TranslationGroupItem } from './Components/TranslationGroupItem/TranslationGroupItem';
import SortableTableHeader from '@/components/Table/SortableTableHeader';
import { useGroups } from '../../api/getGroupsList';

const searchInputSchema = yup.object().shape({
    keyword: yup.string(),
});

export const TranslationGroups = () => {
    const [activePage, setActivePage] = useState<number>(1);
    const [activeSearchKeyword, setActiveSearchKeyword] = useState<string>('');
    const [activeSortDirection, setActiveSortDirection] = useState<string>('desc');
    const [activeSortProperty, setActiveSortProperty] = useState<string>('key');

    const navigate = useNavigate();

    const {
        register,
        watch,
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = useForm({
        resolver: yupResolver(searchInputSchema),
        defaultValues: {
            keyword: '',
        },
    });

    const { data, isLoading } = useGroups({ activePage, activeSearchKeyword, activeSortDirection, activeSortProperty });

    useEffect(() => {
        setActivePage(1);
    }, [activeSearchKeyword]);

    const onSearchKeywordSubmit = (values: { keyword: string }) => {
        setActiveSearchKeyword(values.keyword);
    };

    const handleReset = () => {
        setActiveSearchKeyword('');

        reset();
    };

    const handleSortSelect = (sortVal: string) => {
        if (activeSortProperty === sortVal) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(sortVal);
            setActiveSortDirection('asc');
        }
    };

    const handleKeywordBlur = () => {
        if (watch('keyword') === '') {
            setActiveSearchKeyword('');
        }
    };

    return (
        <>
            <Flex mb={3} alignItems={'center'} justifyContent={'start'}>
                <Button variant="back" onClick={() => navigate('/translations')} leftIcon={<Icon icon="chevron-left" />}></Button>

                <Heading size="lg">Translation Groups</Heading>
            </Flex>

            <Flex justifyContent={'space-between'} alignItems={'center'} mb={3}>
                <Flex>
                    <form onSubmit={handleSubmit(onSearchKeywordSubmit)}>
                        <InputGroup minW={'400px'}>
                            <InputLeftElement pointerEvents={isSubmitting ? 'none' : 'auto'} onClick={handleSubmit(onSearchKeywordSubmit)}>
                                {isSubmitting ? <Icon icon={'loader'} /> : <Icon icon={'search'} />}
                            </InputLeftElement>

                            <Input placeholder="Search..." bg={'#ffffff'} id={'keyword'} {...register('keyword')} onBlur={handleKeywordBlur} />

                            {activeSearchKeyword && (
                                <Button onClick={handleReset} marginLeft={'15px'}>
                                    <Icon icon={'times'} />
                                </Button>
                            )}
                        </InputGroup>
                    </form>
                </Flex>

                <Box>
                    <Button as={Link} to={'/translations/groups/add'} variant={'brand'}>
                        Add
                    </Button>
                </Box>
            </Flex>

            {isLoading ? (
                [...Array(10)].map((_, i: number) => (
                    <Box display={'block'} key={`translation-groups-skeleton-${i}`} w={'100%'}>
                        <Flex>
                            <Skeleton m={'5px'} height="50px" w={'30%'}></Skeleton>

                            <Skeleton m={'5px'} height="50px" w={'100%'}></Skeleton>

                            <Skeleton m={'5px'} height="50px" w={'30%'}></Skeleton>
                        </Flex>
                    </Box>
                ))
            ) : (
                <Flex my={4}>
                    <Table bgColor={'#ffffff'}>
                        <Thead>
                            <Tr>
                                <SortableTableHeader
                                    value="key"
                                    activeSortDirection={activeSortDirection}
                                    activeSortProperty={activeSortProperty}
                                    handleSortSelect={(val) => handleSortSelect(val)}>
                                    Group Key
                                </SortableTableHeader>

                                <Th textAlign={'center'}>Title</Th>

                                <Th textAlign={'center'}>Is Active</Th>

                                <Th>Actions</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {data && data.result.length ? (
                                data.result.map((group) => {
                                    return (
                                        <TranslationGroupItem
                                            key={group.key}
                                            activePage={activePage}
                                            activeSearchKeyword={activeSearchKeyword}
                                            activeSortDirection={activeSortDirection}
                                            activeSortProperty={activeSortProperty}
                                            group={group}
                                        />
                                    );
                                })
                            ) : (
                                <Tr>
                                    <Td>No identity providers found!</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </Flex>
            )}

            {data && data?.navigation?.total_pages > 1 && (
                <Pagination
                    currentPage={activePage}
                    totalPages={data.navigation.total_pages}
                    handlePagination={(page: number) => setActivePage(page)}
                />
            )}
        </>
    );
};
