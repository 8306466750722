import { combineReducers } from '@reduxjs/toolkit';
import { articles } from './articles/articlesSlice';
import { label } from './articles/labelSlice';
import { subjectTree } from './articles/sectionSlice';
import { articleToPlaylist } from './articles/articleToPlaylistSlice';

export const playlistReducer = combineReducers({
    articles,
    label,
    subjectTree,
    articleToPlaylist
});
