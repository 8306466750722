import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import {
    Button,
    Box,
    FormLabel,
    Input,
    FormErrorMessage,
    FormControl,
    FormHelperText,
    Select,
    Flex,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Modal,
    NumberInput,
    NumberDecrementStepper,
    NumberInputField,
    NumberIncrementStepper,
    NumberInputStepper,
    useDisclosure,
    Text,
    Switch,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItemOption,
    MenuOptionGroup,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getQuizTags, selectAllTags, selectTagsTotal } from '@/redux/reducers/quizzes/tag/tagSlice';
import { AppDispatch } from '@/redux/store';
import { LeftActivitiesFieldArray } from '../LeftActivitiesFieldArray';
import moment from 'moment';
import { ArticlesModal } from '@/features/Quiz/components/ArticlesModal';
import Icon from '@/components/Icon';
import { useQuery } from 'react-query';
import { getArticle } from '@/features/Quiz/api/getArticles';
import { ArticleCtx } from '@/types';

const typeOptions: Record<string, string> = {
    graded: 'Graded',
    ungraded: 'Ungraded',
};

type Props = {
    isLibrary?: boolean;
    isEdit?: boolean;
    isUserQuiz?: boolean;
    isCustomerQuiz?: boolean;
    onSubmit: () => void;
    isFormSubmitting: boolean;
    isAssignSomeone?: boolean;
};

export const LeftCreateQuiz = ({
    isLibrary = false,
    isEdit = false,
    isUserQuiz = false,
    onSubmit,
    isFormSubmitting,
    isAssignSomeone = false,
    isCustomerQuiz = false,
}: Props) => {
    const { isOpen: isOpenArticleModal, onOpen: onOpenArticleModal, onClose: onCloseArticleModal } = useDisclosure();

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useDispatch<AppDispatch>();

    const {
        register,
        formState: { errors, isSubmitting },
        setValue,
        getValues,
        watch,
    } = useFormContext();

    const { data } = useQuery<ArticleCtx | null>(
        'article',
        () => {
            if (!isUserQuiz && !isLibrary && watch().article) {
                return getArticle(Number(watch().article));
            }
            return null;
        },
        {
            enabled: Boolean(!isUserQuiz && !isLibrary && watch().article),
            useErrorBoundary: (error: any) => error.response?.status >= 400,
        }
    );

    const filterPassedTime = (time: Date) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const filterPassedTimeByStartDate = (time: Date) => {
        const currentDate = new Date(time);
        const selectedDate = new Date(getValues('startDate'));

        return currentDate.getTime() > selectedDate.getTime();
    };

    const tagsOptions = useSelector(selectAllTags, shallowEqual);
    const totalTags = useSelector(selectTagsTotal, shallowEqual);

    const quizTemplateTag = tagsOptions.filter((tag) => tag.title.toLowerCase() === 'biology');
    const isCreatePage = pathname.includes('create');

    const [isOpen, setOpen] = useState(false);
    const [tags, setTags] = useState<number[]>(getValues('tags'));

    const datePickerConfig = {
        placeholderText: 'mm.dd.yyyy hh:mm',
        dateFormat: 'MM.dd.yyyy hh:mm a',
        showTimeSelect: true,
    };

    const handleCloseModal = useCallback(() => {
        setOpen(false);
        setTags([]);
    }, []);

    const handleOpenModal = useCallback((e: MouseEvent) => {
        e.preventDefault();
        setOpen(true);
    }, []);

    const handleAddSubject = useCallback(
        (id: number) => (e: MouseEvent) => {
            e.preventDefault();
            setTags((state) => (state.includes(id) ? state.filter((oldId) => oldId !== id) : [...state, id]));
        },
        []
    );

    const handleSaveSubject = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            setValue('tags', tags);
            setOpen(false);
        },
        [tags]
    );

    const handleArticleSelect = useCallback(
        (id: number | null) => {
            setValue('article', id);
            onCloseArticleModal();
        },
        [setValue, onCloseArticleModal]
    );

    useEffect(() => {
        if (!isLibrary && isCreatePage && quizTemplateTag.length) {
            setValue('tags', [quizTemplateTag[0].id]);
            setTags([quizTemplateTag[0].id]);
        }
    }, [isCreatePage, isLibrary, tagsOptions]);

    const handleRemoveArticle = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            setValue('article', null);
        },
        [setValue]
    );

    const handleBackToQuizzesClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            navigate('/quiz', {
                replace: true,
                state: {
                    tabMyQuizzes: true,
                },
            });
        },
        [navigate]
    );

    useEffect(() => {
        if (!totalTags) {
            dispatch(getQuizTags());
        }
    }, [totalTags, dispatch]);

    return (
        <>
            {!isLibrary && <ArticlesModal isOpen={isOpenArticleModal} onClose={onCloseArticleModal} onSelectArticle={handleArticleSelect} />}
            <Box>
                <Button
                    display="block"
                    as={Link}
                    to={'/quiz'}
                    color="primary.blue"
                    p="0"
                    bg="primary.grey"
                    _hover={{}}
                    _focus={{ outline: 0 }}
                    _active={{}}
                    onClick={handleBackToQuizzesClick}>
                    <Icon icon="circle-chevron-left" fontSize="30px" />
                </Button>
            </Box>
            <Box my="20px" letterSpacing="0.4px" fontSize="20px" fontWeight="bold" lineHeight="20px">
                {isEdit ? 'Edit' : 'Create'} Quiz
            </Box>
            <Box
                mt="10px"
                mb="20px"
                pb="7px"
                fontSize="15px"
                fontWeight="500"
                color="primary.greyText"
                lineHeight="20px"
                borderBottomStyle="solid"
                borderBottomWidth="1px"
                borderBottomColor="primary.greyLine">
                Quiz Details
            </Box>
            {!isLibrary && !isUserQuiz && (
                <Box display={'flex'} width="100%" mt="10px" mb="20px" pb="7px" fontSize="15px" fontWeight="500">
                    {!watch().article ? (
                        <Button leftIcon={<Icon icon="plus" />} onClick={onOpenArticleModal} variant="brand" size="sm" width="100%">
                            Select Article
                        </Button>
                    ) : (
                        <>
                            {data?.domain ? (
                                <>
                                    <Text dangerouslySetInnerHTML={{ __html: `${data.domain.id ?? ''} ${data.domain.title ?? ''}` }} />
                                    <Button
                                        onClick={handleRemoveArticle}
                                        h="auto"
                                        display="block"
                                        color="red"
                                        p="0"
                                        fontSize="12px"
                                        lineHeight="12px"
                                        fontWeight="normal"
                                        bg="primary.grey"
                                        _hover={{}}
                                        _focus={{ outline: 0 }}
                                        _active={{}}>
                                        <Icon icon="trash-can" fontSize="20px" />
                                    </Button>
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </Box>
            )}

            <FormControl isInvalid={errors.hasOwnProperty('title')}>
                <FormLabel htmlFor="title" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                    Title *
                </FormLabel>
                <Input id="title" placeholder="Input Quiz Name" p="6px" {...register('title', { required: true })} />
                <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
            </FormControl>

            {isLibrary && (
                <FormControl isInvalid={errors.hasOwnProperty('isGraded')}>
                    <FormLabel htmlFor="isGraded" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                        Quiz Type *
                    </FormLabel>
                    <Select id="isGraded" placeholder="Select Quiz Type" {...register('isGraded', { required: true })}>
                        {Object.keys(typeOptions).map((option, key) => {
                            return (
                                <option key={key} value={option}>
                                    {typeOptions[option]}
                                </option>
                            );
                        })}
                    </Select>
                    <FormErrorMessage>{errors.type?.message}</FormErrorMessage>
                </FormControl>
            )}

            {!isUserQuiz && (
                <>
                    <Flex
                        justify="space-between"
                        flexDir="row"
                        mt="30px"
                        mb="20px"
                        pb="7px"
                        fontSize="15px"
                        fontWeight="500"
                        color="primary.greyText"
                        lineHeight="15px"
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                        borderBottomColor="primary.greyLine">
                        <Box lineHeight="15px" h="auto">
                            Subjects
                        </Box>
                        <Button
                            h="auto"
                            display="block"
                            color="primary.blue"
                            p="0"
                            fontSize="12px"
                            lineHeight="12px"
                            fontWeight="normal"
                            bg="primary.grey"
                            _hover={{}}
                            _focus={{ outline: 0 }}
                            _active={{}}
                            onClick={handleOpenModal}>
                            Add Subjects
                        </Button>
                    </Flex>

                    {!!tags.length ? (
                        <Flex flexDir="row" wrap="wrap">
                            {tagsOptions
                                .filter((tag) => getValues('tags').includes(tag.id))
                                .map((tag) => (
                                    <Box mb={isLibrary ? '0' : '36px'} key={tag.id} mr="15px" dangerouslySetInnerHTML={{ __html: tag.title }} />
                                ))}
                        </Flex>
                    ) : (
                        <Box mb={isLibrary ? '0' : '36px'}>Select a Subject</Box>
                    )}
                </>
            )}

            {isLibrary && (
                <>
                    <Flex
                        justify="start"
                        flexDir="row"
                        mt="30px"
                        mb="20px"
                        pb="7px"
                        fontSize="15px"
                        fontWeight="500"
                        color="primary.greyText"
                        lineHeight="15px"
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                        borderBottomColor="primary.greyLine">
                        <Box lineHeight="15px" h="auto">
                            Timing
                        </Box>
                    </Flex>

                    <FormControl isInvalid={errors.hasOwnProperty('startDate')}>
                        <FormLabel htmlFor="StartDate" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                            Start Date
                        </FormLabel>
                        <DatePicker
                            autoComplete="off"
                            {...datePickerConfig}
                            id="StartDate"
                            className="datePicker"
                            {...register('startDate')}
                            selected={watch('startDate')}
                            onChange={(date: Date) => setValue('startDate', date)}
                            minDate={new Date()}
                            filterTime={filterPassedTime}
                        />
                        <FormErrorMessage>{errors.startDate?.message}</FormErrorMessage>
                        <FormHelperText fontSize="12px" lineHeight="18px">
                            The date and time selected reflect your local time zone.
                        </FormHelperText>
                    </FormControl>

                    <FormControl isInvalid={errors.hasOwnProperty('endDate')}>
                        <FormLabel htmlFor="EndDate" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                            End Date
                        </FormLabel>
                        <DatePicker
                            autoComplete="off"
                            {...datePickerConfig}
                            id="EndDate"
                            className="datePicker"
                            {...register('endDate', { required: false })}
                            selected={watch('endDate')}
                            onChange={(date: Date) => setValue('endDate', date)}
                            minDate={moment(getValues('startDate')).isAfter(new Date()) ? getValues('startDate') : new Date()}
                            filterTime={filterPassedTimeByStartDate}
                        />
                        <FormErrorMessage>{errors.endDate?.message}</FormErrorMessage>
                        <FormHelperText fontSize="12px" lineHeight="18px">
                            The date and time selected reflect your local time zone. Leave the date field blank to allow students to participate
                            indefinitely.
                        </FormHelperText>
                    </FormControl>

                    <FormControl isInvalid={errors.hasOwnProperty('duration')}>
                        <FormLabel htmlFor="QuizDuration" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                            Quiz Duration (in minutes)
                        </FormLabel>
                        <NumberInput min={0}>
                            <NumberInputField id="QuizDuration" placeholder="Quiz Duration" {...register('duration')} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>{errors.duration?.message}</FormErrorMessage>
                        <FormHelperText fontSize="12px" lineHeight="18px" mb={isCustomerQuiz ? '20px' : 0}>
                            Entering a value of 0 will have no time limit
                        </FormHelperText>
                    </FormControl>
                </>
            )}

            {isLibrary && !isCustomerQuiz && <LeftActivitiesFieldArray />}

            {isUserQuiz && (
                <FormControl>
                    <Flex alignItems="start" m="0 auto 2rem auto" fontSize="14px" width="100%">
                        <Box mt="4px">
                            <FormLabel margin={0} fontSize="14px" w="90px" fontWeight="500">
                                Access Type:
                            </FormLabel>
                        </Box>
                        <Tooltip
                            hasArrow
                            ml="-50px"
                            label={isAssignSomeone ? 'Access type cannot be changed once the quiz has been distributed to students' : ''}>
                            <Box>
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        outline="1px solid transparent"
                                        isDisabled={isAssignSomeone}
                                        h="2rem"
                                        bgColor="transparent"
                                        rightIcon={<Icon icon="chevron-down" />}
                                        _hover={{ backgroundColor: 'transparent' }}
                                        _active={{ backgroundColor: 'transparent' }}
                                        _focus={{ backgroundColor: 'transparent' }}>
                                        {!watch('restrictNonInstitutional') ? 'Open' : 'Restricted'}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuOptionGroup
                                            value={!watch('restrictNonInstitutional') ? 'open' : 'restricted'}
                                            onChange={(e) => setValue('restrictNonInstitutional', e === 'restricted')}>
                                            <MenuItemOption value="open">Open</MenuItemOption>
                                            <MenuItemOption value="restricted">Restricted</MenuItemOption>
                                        </MenuOptionGroup>
                                    </MenuList>
                                </Menu>
                                <Box ml={'1rem'} color={isAssignSomeone ? 'rgba(113,113,113,0.75)' : '#717171'}>
                                    {!!watch('restrictNonInstitutional')
                                        ? 'Only students from your institute can take this quiz using their institutional email'
                                        : 'Students can take quiz using any email address'}
                                </Box>
                            </Box>
                        </Tooltip>
                    </Flex>
                </FormControl>
            )}

            {isUserQuiz ? (
                <FormControl
                    display="flex"
                    alignItems="center"
                    isInvalid={errors.hasOwnProperty('showCaption')}
                    mb="20px"
                    pb="7px"
                    justifyContent={'space-between'}>
                    <FormLabel htmlFor="show-caption" fontSize="14px" fontWeight="500" color="primary.greyText" lineHeight="36px" m="0">
                        Enable Closed Caption
                    </FormLabel>
                    <Switch id="show-caption" {...register('showCaption')} />
                </FormControl>
            ) : null}

            <Button
                borderRadius="100px"
                w={'full'}
                type="submit"
                disabled={isSubmitting || isFormSubmitting}
                variant={'authBtn'}
                _hover={{}}
                isLoading={isSubmitting || isFormSubmitting}
                textTransform={'uppercase'}
                size="sm"
                onClick={onSubmit}>
                Save Quiz
            </Button>

            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Choose relevant subjects</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        {tagsOptions.map((tag) => {
                            return (
                                <Button
                                    key={tag.id}
                                    onClick={handleAddSubject(tag.id)}
                                    mr="15px"
                                    borderRadius="100px"
                                    bg={tags.includes(tag.id) ? '#4193f4' : ''}
                                    color={tags.includes(tag.id) ? '#fff' : '#000'}
                                    _hover={{}}
                                    _focus={{ outline: 0 }}
                                    _active={{}}>
                                    <Box dangerouslySetInnerHTML={{ __html: tag.title }} />
                                </Button>
                            );
                        })}
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={handleCloseModal} borderRadius="100px">
                            Close
                        </Button>
                        <Button colorScheme="blue" onClick={handleSaveSubject} borderRadius="100px">
                            Save Subjects
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
