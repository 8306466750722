import { axios } from '@/lib/axios';
import { useQuery } from "react-query";
import { GetListParams } from "@/types";
import { SponsorsResponse } from "@/features/Sponsors/types";


type FilterCollection = {
    bool_operator?: string;
    condition?: {
        property: string;
        operator: string;
        value: string | number | boolean;
    };
};

type GetListBodyStructure = {
    navigation: {
        page: number;
        itemsOnPage: number;
    };
    totalCount: boolean;
    filter: {
        collection: FilterCollection[] | null;
    };
    sort: string[][];
};

const buildSponsorsListParams = (activePage: number, activeSearchKeyword: string | number, activeSortDirection: string, activeSortProperty: string) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword ? [{
                    bool_operator: 'or',
                    condition: {
                        property: 'company_name',
                        operator: 'contains',
                        value: activeSearchKeyword || '',
                    }
                }] : []),
            ]
        },
        sort: [
            [
                activeSortProperty,
                activeSortDirection
            ]
        ]
    }
}

export const getSponsorsList = (data: GetListBodyStructure): Promise<SponsorsResponse> => {
    return axios.post(`/api/admin/sponsor/list`, data);
};

export const useSponsors = ({
                                activePage,
                                activeSearchKeyword,
                                activeSortDirection,
                                activeSortProperty
                            }: GetListParams) => {
    return useQuery<SponsorsResponse, Error>({
        queryKey: ['sponsor', activePage, activeSearchKeyword, activeSortDirection, activeSortProperty],
        queryFn: () => {
            let params = buildSponsorsListParams(activePage, activeSearchKeyword, activeSortDirection, activeSortProperty);
            return getSponsorsList(params)
        },
        keepPreviousData: true
    });
};
