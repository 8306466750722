import {
    Flex,
    Button,
    Image,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay, ModalHeader,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

type Props = {
    isOpen: boolean;
    imagePreviewUrl: string;
    submitting: boolean;
    onSubmit: (fileBase64: string) => Promise<void>;
    onClose: () => void;
}

export const CropModal = ({ isOpen, onClose, submitting, imagePreviewUrl, onSubmit }: Props) => {
    const imgRef = useRef(null);
    const [crop, setCrop] = useState<any>({ unit: 'px', width: 150, height: 50 });
    const [previewUrl, setPreviewUrl] = useState<string>('');

    const makeClientCrop = async (crop: any) => {
        if (imgRef.current && crop.width && crop.height) {
            createCropPreview(imgRef.current, crop);
        }
    };

    const createCropPreview = async (image: any, crop: any) => {
        const canvas = document.createElement('canvas');

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx: any = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        setPreviewUrl(canvas.toDataURL('image/png'));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Crop your image
                </ModalHeader>
                <ModalCloseButton />
                <Flex m="20px auto">
                    <ReactCrop
                        crop={crop}
                        onChange={c => setCrop(c)}
                        onComplete={makeClientCrop}>
                        <Image ref={imgRef} src={imagePreviewUrl} alt="logo" w='100%' maxW="300px" minW="150px" />
                    </ReactCrop>
                </Flex>
                <ModalFooter>
                    <Button mr="10px" onClick={onClose}>Cancel</Button>
                    <Button
                        color="primary"
                        onClick={() => onSubmit(previewUrl)}
                        disabled={!imagePreviewUrl || !previewUrl}
                        isLoading={submitting}>
                        Upload
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
