import React, { FC } from 'react';
import { Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Select, Flex } from '@chakra-ui/react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useUpdateTranslation } from '@/features/Translations/api/updateTranslation';
import 'react-image-crop/dist/ReactCrop.css';
import { useGroups } from '@/features/Translations/api/getGroupsList';
import { languages } from '@/features/Translations/Utils/languages';
import { TranslationDetailFormData } from '@/features/Translations/Components/AddTranslation/Components/TranslationForm/TranslationForm';

export type UpdateTranslationDetailFormData = {
    key: string;
    groupId: string;
    language: string;
    value: string;
    translationEnglishValue: string;
    translationId?: number;
};

const translationDetailsSchema = yup.object().shape({
    key: yup.string().required('This field is required'),
    translationEnglishValue: yup.string().required('This field is required'),
    groupId: yup.string().required('This field is required'),
    value: yup.string().required('This field is required'),
});

type Props = Omit<UpdateTranslationDetailFormData, 'value' | 'key'> & {
    onSubmit: SubmitHandler<UpdateTranslationDetailFormData>;
    translationKey: string;
    translationEnglishValue: string;
    onClose: () => void;
    previousValue: string;
    activePage: number;
    activeSearchKeyword: string | number;
    activeSortDirection: string;
    activeSortProperty: string;
    activeLanguage: string;
};

const getLanguage = (key: string) => languages.find((language) => language.key === key);

export const UpdateTranslationForm: FC<Props> = (props) => {
    const {
        activePage,
        activeSearchKeyword,
        activeSortDirection,
        activeSortProperty,
        activeLanguage,
        onClose,
        translationKey,
        translationEnglishValue,
        groupId,
        language,
        onSubmit,
        previousValue,
    } = props;

    const updateTranslationMutation = useUpdateTranslation(
        onClose,
        activePage,
        activeSearchKeyword,
        activeSortDirection,
        activeSortProperty,
        activeLanguage
    );

    const selectedLanguage = getLanguage(language);

    const { data: groups, isLoading: groupsAreLoading } = useGroups({
        activePage,
        activeSearchKeyword,
        activeSortDirection,
        activeSortProperty,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateTranslationDetailFormData>({
        resolver: yupResolver(translationDetailsSchema),
        defaultValues: {
            key: translationKey,
            groupId: groupId,
            language: language,
            translationEnglishValue,
            value: previousValue,
        },
    });

    const formIsLoading = updateTranslationMutation.isLoading;
    const fieldsAreDisabled = groupsAreLoading || formIsLoading;

    return (
        <>
            <Heading size="md" mb={3}>
                Add translation
            </Heading>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack mb={2} spacing={4}>
                    <FormControl disabled isInvalid={errors.hasOwnProperty('key')} isDisabled={fieldsAreDisabled}>
                        <FormLabel htmlFor="key">Write Key</FormLabel>

                        <Input disabled id="key" placeholder="Enter translation key" {...register('key')} />

                        <FormErrorMessage>{errors.key?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl disabled isInvalid={errors.hasOwnProperty('key')} isDisabled={fieldsAreDisabled}>
                        <FormLabel htmlFor="translationEnglishValue">Default Value (English)</FormLabel>

                        <Input
                            disabled
                            id="translationEnglishValue"
                            placeholder="Enter translation translationEnglishValue"
                            {...register('translationEnglishValue')}
                        />

                        <FormErrorMessage>{errors.translationEnglishValue?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.hasOwnProperty('group')} isDisabled={fieldsAreDisabled}>
                        <FormLabel htmlFor="group">Add group fot this translation</FormLabel>

                        {groups && (
                            <FormControl disabled isInvalid={errors.hasOwnProperty('group')}>
                                <Select disabled {...register('groupId')} placeholder="All" bg="white">
                                    {groups.result
                                        .filter((group) => group.isActive)
                                        .map((group) => (
                                            <option key={group.key} value={group.id}>
                                                {group.title}
                                            </option>
                                        ))}
                                </Select>

                                <FormErrorMessage>{errors.groupId && errors.groupId.message}</FormErrorMessage>
                            </FormControl>
                        )}
                    </FormControl>

                    <FormControl isDisabled={fieldsAreDisabled} isInvalid={errors.hasOwnProperty('value')}>
                        <FormLabel htmlFor={'value'}>Add Language Translation ({selectedLanguage?.name})</FormLabel>

                        <Input maxLength={256} id={'value'} placeholder={`Enter translation for ${selectedLanguage?.name}`} {...register('value')} />

                        <FormErrorMessage>{errors['value']?.message}</FormErrorMessage>
                    </FormControl>
                </Stack>

                <Flex mb="5" direction="row" justifyContent="flex-end">
                    <Button mr={3} mt={4} onClick={onClose}>
                        Close
                    </Button>

                    <Button mt={4} isLoading={updateTranslationMutation.isLoading} type={'submit'} colorScheme="red">
                        Update
                    </Button>
                </Flex>
            </form>
        </>
    );
};
