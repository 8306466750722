import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';

interface CustomButtonProps {}

// Define custom toolbar component props
interface CustomToolbarProps {}

const CustomToolbar: React.FC<CustomToolbarProps> = () => (
    <Box id="toolbar" borderTopStartRadius="15px" borderTopEndRadius="15px" borderBottom="0px solid transparent !important">
        {/* <select className="ql-header" defaultValue={''} onChange={(e) => e.persist()}>
            <option value="1"></option>
            <option value="2"></option>
            <option selected></option>
        </select>
        <Button
            display="flex !important"
            justifyContent="center !important"
            alignItems="center !important"
            me="5px !important"
            className="ql-bold"></Button>
        <Button
            display="flex !important"
            justifyContent="center !important"
            alignItems="center !important"
            me="5px !important"
            className="ql-italic"></Button>
        <Button
            display="flex !important"
            justifyContent="center !important"
            alignItems="center !important"
            me="5px !important"
            className="ql-underline"></Button>
        <Button
            display="flex !important"
            justifyContent="center !important"
            alignItems="center !important"
            me="5px !important"
            className="ql-list"
            value="ordered"></Button>
        <Button
            display="flex !important"
            justifyContent="center !important"
            alignItems="center !important"
            className="ql-list"
            value="bullet"></Button> */}
        <select className="ql-size" defaultValue={''} onChange={(e) => e.persist()}>
            <option value="small"></option>
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
        </select>
        <Button
            display="flex !important"
            justifyContent="center !important"
            alignItems="center !important"
            me="5px !important"
            className="ql-link"></Button>
    </Box>
);

interface EditorProps {
    name: string;
    placeholder: string;
}

const RHFEditor: React.FC<EditorProps> & { modules: Modules } = ({ name, placeholder }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div className="text-editor">
                    <CustomToolbar />
                    <ReactQuill
                        {...field}
                        onChange={(html) => field.onChange(html)}
                        placeholder={placeholder}
                        modules={RHFEditor.modules} // Accessing static property
                    />
                </div>
            )}
        />
    );
};

// Define module types
interface Modules {
    toolbar: {
        container: string;
        handlers: { [key: string]: () => void };
    };
    clipboard: {
        matchVisual: boolean;
    };
}

// Define module options
const modules: Modules = {
    toolbar: {
        container: '#toolbar',
        handlers: {},
    },
    clipboard: {
        matchVisual: false,
    },
};

// Assign modules to the Editor component (static property)
RHFEditor.modules = modules;

export default RHFEditor;
