import { API_URL } from "@/config";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation, useQueryClient } from "react-query";

type DeleteData = {
    classId: number;
    quizId: number;
}

const deleteQuizFromClass = ({ classId, quizId }: DeleteData): Promise<string> => {
    return axios.delete(`${API_URL}/api/admin/class/${classId}/quiz/${quizId}`);
}

export const useDeleteQuizFromClass = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation({
        onMutate: async ({ classId }: DeleteData) => {
            await queryClient.cancelQueries(['getClass', classId]);
        },
        onError: () => {
            addNotification({
                type: 'error',
                title: 'Unable to delete Quiz from Class',
            });
        },
        onSuccess: (_,{ classId } ) => {
            queryClient.refetchQueries(['getClass', classId]);
            addNotification({
                type: 'success',
                title: 'Quiz successfully deleted',
            });
        },
        mutationFn: deleteQuizFromClass,
    })
}