import { MyQuiz } from '@/features/Quiz/components/MyQuiz';
import { SharedQuiz } from '@/features/Quiz/components/SharedQuiz';
import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { CreateQuiz } from '@/features/Quiz/components/CreateQuiz';
import { EditQuiz } from '@/features/Quiz/components/EditQuiz';
import { ViewQuiz } from '@/features/Quiz/components/ViewQuiz';
import { Box, Spinner, Tab, TabList, TabPanels, Tabs, useMediaQuery } from '@chakra-ui/react';
import { ArticlesQuiz } from '@/features/Quiz/components/ArticlesQuiz';
import { QuizLibrary } from '@/features/Quiz/components/QuizLibrary';
import { AdminQuiz } from '@/features/Quiz/components/AdminQuiz';
import { useAuth } from '@/context/AuthContext';
import { CustomerQuiz } from '@/features/Quiz/components/CustomerQuiz';
import { CustomerResults } from '@/features/Quiz/components/CustomerResults';
import { CustomerUserResults } from '@/features/Quiz/components/CustomerUserResults';

export const QuizRoutes = () => {
    const element = useRoutes([...quizRoutes]);

    return <>{element}</>;
};

const Quizzes = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { user } = useAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const [isSmThan900] = useMediaQuery(['(max-width: 900px)']);

    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');
    const isArticleQuiz = !isSuperAdmin && (pathname === '/quiz' || pathname === '/quiz/');

    useEffect(() => {
        if (!pathname) return setTabIndex(0);

        if (pathname.includes('/quiz/library')) {
            setTabIndex(isSuperAdmin ? 1 : 0);
        } else if (pathname.includes('/quiz/admin-quiz') || pathname.includes('/quiz/my-quiz')) {
            setTabIndex(isSuperAdmin ? 2 : 0);
        } else if (pathname.includes('/quiz/shared-quiz')) {
            setTabIndex(isSuperAdmin ? 3 : 1);
        } else if (pathname.includes('/quiz/customer-quiz')) {
            setTabIndex(isSuperAdmin ? 4 : 1);
        } else return setTabIndex(0);
    }, [pathname]);

    if (isArticleQuiz && pathname.includes('/quiz/library')) {
        return <Navigate to="/quiz/admin-quiz" />;
    }
    if (!isSuperAdmin && pathname === '/quiz') {
        return <Navigate to="/quiz/my-quiz" />;
    }
    if (!isSuperAdmin && !pathname.includes('my-quiz') && !pathname.includes('shared-quiz')) {
        return <Navigate to="/quiz/my-quiz" />;
    }

    return (
        <Box>
            <Tabs variant="line" isLazy index={tabIndex}>
                {!pathname.includes('/quiz/user-quiz') ? (
                    isSuperAdmin ? (
                        <TabList>
                            <Tab onClick={() => navigate('')}>Articles Quiz Template</Tab>
                            <Tab onClick={() => navigate('library')}>Quiz Library</Tab>
                            <Tab onClick={() => navigate('admin-quiz')}>Admin Quizzes</Tab>
                            <Tab onClick={() => navigate('shared-quiz')}>Shared By Admins</Tab>
                            <Tab onClick={() => navigate('customer-quiz')}>Customer Quizzes</Tab>
                        </TabList>
                    ) : (
                        <TabList>
                            <Tab onClick={() => navigate('my-quiz')}>My Quizzes</Tab>
                            <Tab onClick={() => navigate('shared-quiz')}>Shared By Me</Tab>
                        </TabList>
                    )
                ) : null}
                <TabPanels h={isSmThan900 ? 'calc(100vh - 195px)' : 'calc(100vh - 165px)'} px={1} pb={4} pt={6}>
                    <Suspense
                        fallback={
                            <div className="h-full w-full flex items-center justify-center">
                                <Spinner size="xl" />
                            </div>
                        }>
                        <Outlet />
                    </Suspense>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

const quizRoutes = [
    {
        path: '/',
        element: <Quizzes />,
        children: [
            { path: 'admin-quiz/create', element: <CreateQuiz isAdminQuiz /> },
            { path: 'admin-quiz/copy/:adminQuizId', element: <CreateQuiz isAdminQuiz /> },
            { path: 'admin-quiz/view/:adminQuizId', element: <ViewQuiz /> },
            { path: 'admin-quiz/edit/:adminQuizId', element: <EditQuiz isAdminQuiz /> },
            { path: 'admin-quiz', element: <AdminQuiz /> },

            { path: 'shared-quiz/copy/:adminQuizId', element: <CreateQuiz isAdminQuiz /> },
            { path: 'shared-quiz/view/:adminQuizId', element: <ViewQuiz /> },
            { path: 'shared-quiz/edit/:adminQuizId', element: <EditQuiz isAdminQuiz /> },
            { path: 'shared-quiz', element: <SharedQuiz /> },

            { path: 'customer-quiz', element: <CustomerQuiz /> },
            { path: 'customer-quiz/view/:userQuizId', element: <ViewQuiz /> },
            { path: 'customer-quiz/edit/:userQuizId', element: <EditQuiz isUserQuiz /> },
            { path: 'customer-quiz/copy/:userQuizId', element: <CreateQuiz isUserQuiz /> },
            { path: 'customer-quiz/results/:userQuizId', element: <CustomerResults /> },
            { path: 'customer-quiz/user-results/:assignId', element: <CustomerUserResults /> },

            { path: 'my-quiz/view/:userQuizId', element: <ViewQuiz /> },
            { path: 'my-quiz/edit/:userQuizId', element: <EditQuiz isUserQuiz /> },
            { path: 'my-quiz/create', element: <CreateQuiz isUserQuiz /> },
            { path: 'my-quiz/copy/:userQuizId', element: <CreateQuiz isUserQuiz /> },
            { path: 'my-quiz', element: <MyQuiz /> },

            { path: 'library/create', element: <CreateQuiz isLibrary /> },
            { path: 'library/copy/:quizLibraryId', element: <CreateQuiz isLibrary /> },
            { path: 'library/view/:quizLibraryId', element: <ViewQuiz /> },
            { path: 'library/edit/:quizLibraryId', element: <EditQuiz isLibrary /> },
            { path: 'library', element: <QuizLibrary /> },

            { path: 'user-quiz/edit/:userQuizId', element: <EditQuiz isUserQuiz /> },
            { path: 'user-quiz/view/:userQuizId', element: <ViewQuiz /> },

            { path: 'create/:articleId', element: <CreateQuiz /> },
            { path: 'create', element: <CreateQuiz /> },
            { path: 'copy/:quizId', element: <CreateQuiz /> },
            { path: 'view/:id', element: <ViewQuiz /> },
            { path: 'edit/:quizId', element: <EditQuiz /> },
            { path: '/', element: <ArticlesQuiz /> },
            { path: '*', element: <Navigate to="/" /> },
        ],
    },
];
