import React, { ReactText } from 'react';
import { Flex, FlexProps, Box, Link as ChakraLink } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import Icon from '@/components/Icon';

interface NavItemProps extends FlexProps {
    icon: IconName;
    children: ReactText;
    url: string;
}

export const NavItem = ({ icon, url, children,  ...rest }: NavItemProps) => {
    const { pathname } = useLocation();

    return (
        <ChakraLink
            as={Link}
            to={url}
            style={{ textDecoration: 'none' }}
            overflow={'hidden'}
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p={4}
                role="group"
                maxH={'55px'}
                cursor="pointer"
                color={'#ffffff'}
                bg={pathname.includes(url) ? 'brand.blue' : 'transparent'}
                _hover={{
                    bg: 'brand.blue',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Box
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: '#ffffff',
                        }}>
                        <Icon fontSize={'22px'} icon={icon} />
                    </Box>
                )}
                {children}
            </Flex>
        </ChakraLink>
    );
};
