import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Spinner, Flex } from '@chakra-ui/react';

import { Layout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { ClassDocumentView } from '@/features/Classes/components/ClassDocuments/ClassDocumentView';
import { SponsorsRoutes } from '@/features/Sponsors/routes';
import { TranslationsRoutes } from '@/features/Translations/routes';
import { BannerRoutes } from '@/features/Banner/routes';

const { MethodsCollectionsRoutes } = lazyImport(() => import('@/features/MethodsCollections'), 'MethodsCollectionsRoutes');
const { PlaylistsRoutes } = lazyImport(() => import('@/features/Playlists'), 'PlaylistsRoutes');
const { LtiRoutes } = lazyImport(() => import('@/features/Lti'), 'LtiRoutes');
const { ClassesRoutes } = lazyImport(() => import('@/features/Classes'), 'ClassesRoutes');
const { AdminsRoutes } = lazyImport(() => import('@/features/Admins'), 'AdminsRoutes');
const { InstitutionsRoutes } = lazyImport(() => import('@/features/Institutions'), 'InstitutionsRoutes');
const { QuizRoutes } = lazyImport(() => import('@/features/Quiz'), 'QuizRoutes');
const { ProductClassesRoutes } = lazyImport(() => import('@/features/ProductClasses'), 'ProductClassesRoutes');
const { AdminClientHubRoutes } = lazyImport(() => import('@/features/AdminClientHub'), 'AdminClientHubRoutes');
const { ClientHubPlaylistsRoutes } = lazyImport(() => import('@/features/ClientHubPlaylists'), 'ClientHubPlaylistsRoutes');
const { IdentityProvidersRoutes } = lazyImport(() => import('@/features/IdentityProviders'), 'IdentityProvidersRoutes');
const { FRCRoutes } = lazyImport(() => import('@/features/FRC'), 'FRCRoutes');
const { PublishRoutes } = lazyImport(() => import('@/features/Publish'), 'PublishRoutes');
const { DashboardRoutes } = lazyImport(() => import('@/features/Dashboard'), 'DashboardRoutes');
const { MoEngageRoutes } = lazyImport(() => import('@/features/MoEngage'), 'MoEngageRoutes');

const App = () => {
    return (
        <Layout>
            <Suspense
                fallback={
                    <Flex minH={'100vh'} h={'full'} w={'full'} alignItems={'center'} justifyContent={'center'}>
                        <Spinner size="lg" />
                    </Flex>
                }>
                <Outlet />
            </Suspense>
        </Layout>
    );
};

export const protectedRoutes = [
    {
        path: '/',
        element: <App />,
        children: [
            { path: '/playlist/*', element: <PlaylistsRoutes /> },
            { path: '/dashboard/*', element: <DashboardRoutes /> },
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: '/methods-collections/*', element: <MethodsCollectionsRoutes /> },
            { path: '/lti/*', element: <LtiRoutes /> },
            { path: '/classes/view-file/:id/document/:documentId', element: <ClassDocumentView /> },
            { path: '/classes/*', element: <ClassesRoutes /> },
            { path: '/institutions/*', element: <InstitutionsRoutes /> },
            { path: '/quiz/*', element: <QuizRoutes /> },
            { path: '/identity-providers/*', element: <IdentityProvidersRoutes /> },
            { path: '/publish/*', element: <PublishRoutes /> },
            { path: '/sponsors/*', element: <SponsorsRoutes /> },
            { path: '/translations/*', element: <TranslationsRoutes /> },
            { path: '/banner/*', element: <BannerRoutes /> },
            { path: '/moengage/*', element: <MoEngageRoutes /> },
            { path: '*', element: <Navigate to="/dashboard" /> },
        ],
    },
];

export const protectedRoutesForSuperAdmin = [
    {
        path: '/',
        element: <App />,
        children: [
            { path: '/playlist/*', element: <PlaylistsRoutes /> },
            { path: '/dashboard/*', element: <DashboardRoutes /> },
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: '/methods-collections/*', element: <MethodsCollectionsRoutes /> },
            { path: '/lti/*', element: <LtiRoutes /> },
            { path: '/classes/view-file/:id/document/:documentId', element: <ClassDocumentView /> },
            { path: '/classes/*', element: <ClassesRoutes /> },
            { path: '/admins/*', element: <AdminsRoutes /> },
            { path: '/institutions/*', element: <InstitutionsRoutes /> },
            { path: '/quiz/*', element: <QuizRoutes /> },
            { path: '/class-access/*', element: <ProductClassesRoutes /> },
            { path: '/client-hub-access/*', element: <AdminClientHubRoutes /> },
            { path: '/client-hub-playlist/*', element: <ClientHubPlaylistsRoutes /> },
            { path: '/identity-providers/*', element: <IdentityProvidersRoutes /> },
            { path: '/faculty-resources/*', element: <FRCRoutes /> },
            { path: '/sponsors/*', element: <SponsorsRoutes /> },
            { path: '/publish/*', element: <PublishRoutes /> },
            { path: '/translations/*', element: <TranslationsRoutes /> },
            { path: '/banner/*', element: <BannerRoutes /> },
            { path: '/moengage/*', element: <MoEngageRoutes /> },
            { path: '*', element: <Navigate to="/dashboard" /> },
        ],
    },
];

export const protectedRoutesForCSUser = [
    {
        path: '/',
        element: <App />,
        children: [
            { path: '/playlist/*', element: <PlaylistsRoutes /> },
            { path: '/dashboard/*', element: <DashboardRoutes /> },
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: '/classes/view-file/:id/document/:documentId', element: <ClassDocumentView /> },
            { path: '/classes/*', element: <ClassesRoutes /> },
            { path: '/quiz/*', element: <QuizRoutes /> },
            { path: '*', element: <Navigate to="/dashboard" /> },
        ],
    },
];

export const protectedRoutesForPublishUser = [
    {
        path: '/',
        element: <App />,
        children: [
            { path: '/dashboard/*', element: <DashboardRoutes /> },
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: '/publish/*', element: <PublishRoutes /> },
            { path: '*', element: <Navigate to="/dashboard" /> },
        ],
    },
];
